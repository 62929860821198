import { Grid, Stack, Typography, Box, Modal, MenuItem, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react'
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import CustomInput from '../../components/Widgets/CustomInput';
import CustomSelect from '../../components/Widgets/CustomSelect';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddIcon from '@mui/icons-material/Add';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import BackupIcon from '@mui/icons-material/Backup';
import { useDispatch, useSelector } from 'react-redux';
import { addSchoolClass, updateSchoolClass } from '../../Redux/actions/schoolActions';
const ClassModal = ({ openM, handleCloseM, schoolid, rowRes }) => {


    const dispatch = useDispatch()

    const { loading } = useSelector(state => state.auth);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: 3
    };

    const schema = object().shape({
        name: yup.string().max(10,"Maximum character exceed").required('Class is Required'),
        division: yup.string().max(10,"Maximum character exceed").required('Division is Required'),
        roll_no_from: yup.string().max(10,"Maximum character exceed").required('Required'),
        roll_no_to: yup.string().max(10,"Maximum character exceed").required('Required')
    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({
        defaultValues: {
            school_id: schoolid
        },

        resolver: yupResolver(schema)
    });


    useEffect(() => {
        if (rowRes) {
            setValue('name', rowRes?.name)
            setValue('division', rowRes?.division)
            setValue('roll_no_from', rowRes?.roll_no_from)
            setValue('roll_no_to', rowRes?.roll_no_to)
        }
    }, [rowRes])


    const SubmitForm = (data) => {

        if (rowRes) {
            let val = {
                "id": rowRes?._id,
                "name": data?.name,
                "roll_no_from": data?.roll_no_from,
                "roll_no_to": data?.roll_no_to,
                "division": data?.division
            }
            dispatch(updateSchoolClass(val))
        } else {
            dispatch(addSchoolClass(data))
        }

    }

    return (
        <Modal
            keepMounted
            open={openM}
            onClose={handleCloseM}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack justifyContent={'space-between'} direction={'row'} px={2} py={2} alignItems='center'>
                    <Box></Box>
                    <Typography sx={{
                        fontFamily: 'Quicksand',
                        fontWeight: 'bold',
                        fontSize: 22,
                        color: '#FF0053',
                        letterSpacing: 1
                    }}>{rowRes ? 'Edit Class' : 'Add Class' }</Typography>
                    <HighlightOffIcon onClick={() => handleCloseM()} sx={{
                        cursor: 'pointer'
                    }} />

                </Stack>

                <Grid container spacing={2} px={2}>
                    <Grid item xs={6}>
                        <CustomInput
                            control={control}
                            error={errors.name}
                            fieldName="name"
                            fieldLabel="Class"
                        />

                    </Grid>
                    <Grid item xs={6}>
                        <CustomInput
                            control={control}
                            error={errors.division}
                            fieldName="division"
                            fieldLabel="Division"
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} px={5} py={2} justifyContent={'center'}>
                    <Grid item xs={4}>
                        <CustomInput
                            control={control}
                            error={errors.roll_no_from}
                            fieldName="roll_no_from"
                            fieldLabel="Roll no"
                            placeholder={'From'}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CustomInput
                            control={control}
                            error={errors.roll_no_to}
                            fieldName="roll_no_to"
                            fieldLabel=""
                            placeholder={'To'}
                        />
                    </Grid>
                </Grid>



                <Box py={3} display={'flex'} justifyContent={'center'}>
                    <CustomIconButton
                        label={loading ? <CircularProgress style={{ color: '#FF0053' }} size={20} /> :rowRes ? 'Update' : 'Add'}
                        Icon={AddIcon} disabled={loading ? true : false}
                        onClick={handleSubmit(SubmitForm)}
                    />
                </Box>

            </Box>
        </Modal>
    )
}

export default ClassModal