import { LOADER, LOADING } from "../constants/userConstant";
import axios from "../../CustomAxios";
import { GET_REVIEW_FAIL, GET_REVIEW_SUCCESS, UPDATE_REVIEW_FAIL, UPDATE_REVIEW_SUCCESS } from "../constants/reviewConstants";




export const getReview = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/review/postlist/${data}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_REVIEW_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_REVIEW_FAIL ,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const updateReview = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/review/post`,data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: UPDATE_REVIEW_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: UPDATE_REVIEW_FAIL ,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}
