
import React from 'react'
import search from '../../../Assets/images/search.png'
import { Avatar, Box, FormGroup, styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
const CustomSearch = ({ placeholder, size, onSearch, value, lg, md, sm, xs }) => {
    return (
        <Box>
            <TextField
                className="form-control"
                placeholder={placeholder}
                id="exampleInputEmail1"
                variant="standard"
                onChange={(e) => onSearch(e.target.value)}
                value={value}
                sx={{ width: { lg: lg ? lg : '35vw', md: md ? md : '40vw', sm: sm ? sm : '45vw', xs: xs ? xs : '50vw' } }}
                InputProps={{
                    disableUnderline: true,
                    style: {
                        borderRadius: "14px",
                        opacity: "1",
                        background: "#FFEFEC",
                        height: "48px",
                        fontFamily: "Quicksand",
                        letterSpacing: "1px",
                        fontWeight: 'bold',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        borderBottom: 'none',

                    },
                    endAdornment: (
                        <InputAdornment position={'end'}>
                            <Box>
                                <Avatar src={search} variant="square" style={{ width: "30px", height: "30px", display: "flex" }} />
                            </Box>
                        </InputAdornment>
                    )
                }}
            />
        </Box>
    )
}

export default CustomSearch