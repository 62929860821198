import { Grid, MenuItem } from '@mui/material';
import React, { useState } from 'react'
import CustomMultipleSelectInput from '../../components/Widgets/CustomMultiSelect';
import CustomSelect from '../../components/Widgets/CustomSelect';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { getDivision } from '../../Redux/actions/schoolActions';
import { useDispatch, useSelector } from 'react-redux';

const CustomEditTeacher = ({ onClassChanged, onDivisionChaged, getClassesList, getDivisionList, schoolTeacherSingleList, index }) => {

    const { classFullList } = useSelector(state => state.school);



    console.log({classFullList})

    const dispatch = useDispatch()

    const [classes, setClasses] = useState(null);
    const [classArray, setclassArray] = useState([]);
    const [multpleArray, setMultpleArray] = React.useState([]);


    console.log({classes})

    const schema = object().shape({

    });



    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({
        resolver: yupResolver(schema)
    })



    const onChangeSelect = (value) => {
        setClasses(value)

        let find = classFullList?.filter((res) => res.class === value);
        setclassArray(find)


        // setValue('class', value)
        // setError('class', '')

        // let val = {
        //     school_id: schoolTeacherSingleList?.school_id,
        //     class: value
        // }
        // dispatch(getDivision(val))

    }


    //MULTI SELECT FEILD.................................................................
    const onChangeMultiple = (event) => {
        // const {
        // 	target: { value },
        // } = event;
        const values = event.target.value;

        setValue('division', values)
        setError('division', '')
        setMultpleArray(
            values
        );

    }

    return (
        <>

            <Grid item lg={3} md={4} sm={6} xs={12}>
                <CustomSelect
                    control={control}
                    error={errors.classes}
                    fieldName="classes"
                    fieldLabel="Class"
                    background="#FFEFEC"
                    size="16px"
                    value={classes}
                    onChangeValue={(e) => {
                        onClassChanged(e.target.value, index, 'classValue');
                        onChangeSelect(e.target.value)
                    }
                    }
                >
                    <MenuItem value="" disabled >
                        <>Select Class</>
                    </MenuItem>
                    {classFullList?.map((C, I) => (
                        <MenuItem key={I} value={C?.class} >
                            {C?.class}
                        </MenuItem>
                    ))}

                </CustomSelect>
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12}>
                <CustomMultipleSelectInput
                    // readOnly={!id ? false : true}
                    control={control}
                    error={errors.division}
                    fieldName="division"
                    fieldLabel="Division"
                    background="#FFEFEC"
                    multiline
                    height="48px"
                    size="15px"
                    multiple={true}
                    defaultValue={""}
                    value={multpleArray}
                    onChangeValue={(e) => {
                        onChangeMultiple(e)
                        onDivisionChaged(e.target.value, index)

                    }}
                >
                    <MenuItem value="" disabled >
                        <em>Select  Division</em>
                    </MenuItem>
                    {classArray?.[0]?.division.map((C, I) => (
                        <MenuItem key={C?._id} value={C?._id} >
                            {C?.division}
                        </MenuItem>
                    ))}
                </CustomMultipleSelectInput>
            </Grid>
        </>
    )
}

export default CustomEditTeacher