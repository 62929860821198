import { GET_INDIVIDUAL_NETWORK_FAIL, GET_INDIVIDUAL_NETWORK_SUCCESS, GET_INDIVIDUAL_STUDENT_FAIL, GET_INDIVIDUAL_STUDENT_SUCCESS, GET_PLAN_INDIVIDUAL_FAIL, GET_PLAN_INDIVIDUAL_SUCCESS, GET_PLAN_LOG_INDIVIDUAL_FAIL, GET_PLAN_LOG_INDIVIDUAL_SUCCESS, SINGLE_INDIVIDUAL_STUDENT_FAIL, SINGLE_INDIVIDUAL_STUDENT_SUCCESS, UPDATE_INDIVIDUAL_NETWORK_FAIL, UPDATE_INDIVIDUAL_NETWORK_SUCCESS, UPDATE_INDIVIDUAL_STUDENT_FAIL, UPDATE_INDIVIDUAL_STUDENT_SUCCESS, UPDATE_PLAN_INDIVIDUAL_FAIL, UPDATE_PLAN_INDIVIDUAL_SUCCESS } from "../constants/individualConstant";
import { RESET_ERROR } from "../constants/userConstant";

export const individualReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                studentUpdated: null,
                networkUpdated: null,
                planUpdated: null

            }
        case GET_INDIVIDUAL_STUDENT_SUCCESS:
            return {
                ...state,
                studentList: action.payload
            }
        case GET_INDIVIDUAL_STUDENT_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case SINGLE_INDIVIDUAL_STUDENT_SUCCESS:
            return {
                ...state,
                singleStudentList: action.payload
            }

        case SINGLE_INDIVIDUAL_STUDENT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case UPDATE_INDIVIDUAL_STUDENT_SUCCESS:
            return {
                ...state,
                studentUpdated: true,
                studentList: state.studentList?.map(std => std._id === action.payload._id ? action.payload : std),
            }

        case UPDATE_INDIVIDUAL_STUDENT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case GET_INDIVIDUAL_NETWORK_SUCCESS:
            return {
                ...state,
                networkList: action.payload
            }
        case GET_INDIVIDUAL_NETWORK_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case UPDATE_INDIVIDUAL_NETWORK_SUCCESS:
            return {
                ...state,
                networkUpdated: true,
                networkList: state.networkList?.map(net => net._id === action.payload._id ? action.payload : net),

            }
        case UPDATE_INDIVIDUAL_NETWORK_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case UPDATE_PLAN_INDIVIDUAL_SUCCESS:
            return {
                ...state,
                planUpdated: true,
                planList: state.planList?.map(pln => pln._id === action.payload._id ? action.payload : pln),
            }
        case UPDATE_PLAN_INDIVIDUAL_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_PLAN_INDIVIDUAL_SUCCESS:
            return {
                ...state,
                planList: action.payload,

            }
        case GET_PLAN_INDIVIDUAL_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_PLAN_LOG_INDIVIDUAL_SUCCESS:
            return {
                ...state,
                planLogList: action.payload
            }
        case GET_PLAN_LOG_INDIVIDUAL_FAIL:
            return{
                ...state,
                error:action.payload
            }    
        default:
            return state;
    }

}