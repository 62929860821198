export const CREATE_SCHOOL_SUCCESS = 'CREATE_SCHOOL_SUCCESS'
export const CREATE_SCHOOL_FAIL = 'CREATE_SCHOOL_FAIL'
export const UPDATE_SCHOOL_SUCCESS = 'UPDATE_SCHOOL_SUCCESS'
export const UPDATE_SCHOOL_FAIL = 'UPDATE_SCHOOL_FAIL'
export const DELETE_SCHOOL_SUCCESS = 'DELETE_SCHOOL_SUCCESS'
export const DELETE_SCHOOL_FAIL = " DELETE_SCHOOL_FAIL"
export const STATUS_SCHOOL_SUCCESS = 'STATUS_SCHOOL_SUCCESS'
export const STATUS_SCHOOL_FAIL = 'STATUS_SCHOOL_FAIL'
export const GET_SCHOOL_SUCCESS = 'GET_SCHOOL_SUCCESS'
export const GET_SCHOOL_FAIL = 'GET_SCHOOL_FAIL'
export const SINGLE_SCHOOL_SUCCESS = 'SINGLE_SCHOOL_SUCCESS'
export const SINGLE_SCHOOL_FAIL = 'SINGLE_SCHOOL_FAIL'


export const CLASS_SCHOOL_SUCCESS = 'CLASS_SCHOOL_SUCCESS'
export const CLASS_SCHOOL_FAIL = 'CLASS_SCHOOL_FAIL'
export const GET_SCHOOL_CLASS_SUCCESS = 'GET_SCHOOL_CLASS_SUCCESS'
export const GET_SCHOOL_CLASS_FAIL = ' GET_SCHOOL_CLASS_FAIL'
export const UPDATE_SCHOOL_CLASS_SUCCESS = 'UPDATE_SCHOOL_CLASS_SUCCESS'
export const UPDATE_SCHOOL_CLASS_FAIL = 'UPDATE_SCHOOL_CLASS_FAIL'
export const DELETE_SCHOOL_CLASS_SUCCESS = 'DELETE_SCHOOL_CLASS_SUCCESS'
export const DELETE_SCHOOL_CLASS_FAIL = 'DELETE_SCHOOL_CLASS_FAIL'
export const GET_CLASS_TEACHER_SUCCESS = 'GET_CLASS_TEACHER_SUCCESS'
export const GET_CLASS_TEACHER_FAIL = 'GET_CLASS_TEACHER_FAIL'
export const GET_DIVISION_TEACHER_SUCCESS = 'GET_DIVISION_TEACHER_SUCCESS'
export const GET_DIVISION_TEACHER_FAIL = 'GET_DIVISION_TEACHER_FAIL'
export const CREATE_SCHOOL_TEACHER_SUCCESS = 'CREATE_SCHOOL_TEACHER_SUCCESS'
export const CREATE_SCHOOL_TEACHER_FAIL = 'CREATE_SCHOOL_TEACHER_FAIL'
export const GET_SCHOOL_TEACHER_SUCCESS = 'GET_SCHOOL_TEACHER_SUCCESS '
export const GET_SCHOOL_TEACHER_FAIL = 'GET_SCHOOL_TEACHER_FAIL'
export const SCHOOL_TEACHER_STATUS_SUCCESS='SCHOOL_TEACHER_STATUS_SUCCESS'
export const SCHOOL_TEACHER_STATUS_FAIL='SCHOOL_TEACHER_STATUS_FAIL'
export const DELETE_SCHOOL_TEACHER_SUCCESS='DELETE_SCHOOL_TEACHER_SUCCESS'
export const DELETE_SCHOOL_TEACHER_FAIL='DELETE_SCHOOL_TEACHER_FAIL'
export const GET_SCHOOL_TEACHER_SINGLE_LIST_SUCCESS = 'GET_SCHOOL_TEACHER_SINGLE_LIST_SUCCESS'
export const GET_SCHOOL_TEACHER_SINGLE_LIST_FAIL = 'GET_SCHOOL_TEACHER_SINGLE_LIST_FAIL'
export const REASIGN_SCHOOL_TEACHER_SUCCESS = 'REASIGN_SCHOOL_TEACHER_SUCCESS'
export const REASIGN_SCHOOL_TEACHER_FAIL = 'REASIGN_SCHOOL_TEACHER_FAIL'
export const EDIT_SCHOOL_TEACHER_SUCCESS = 'EDIT_SCHOOL_TEACHER_SUCCESS'
export const EDIT_SCHOOL_TEACHER_FAIL = 'EDIT_SCHOOL_TEACHER_FAIL'

export const GET_SCHOOL_STUDENT_SUCCESS = 'GET_SCHOOL_STUDENT_SUCCESS'
export const GET_SCHOOL_STUDENT_FAIL = 'GET_SCHOOL_STUDENT_FAIL'
export const STATUS_SCHOOL_STUDENT_SUCCESS = 'STATUS_SCHOOL_STUDENT_SUCCESS'
export const STATUS_SCHOOL_STUDENT_FAIL = 'STATUS_SCHOOL_STUDENT_FAIL'


export const GET_SCHOOL_NETWORK_SUCCESS = 'GET_SCHOOL_NETWORK_SUCCESS'
export const GET_SCHOOL_NETWORK_FAIL = 'GET_SCHOOL_NETWORK_FAIL'
export const UPDATE_SCHOOL_NETWORK_SUCCESS = 'UPDATE_SCHOOL_NETWORK_SUCCESS'
export const UPDATE_SCHOOL_NETWORK_FAIL = 'UPDATE_SCHOOL_NETWORK_FAIL'


export const GET_FULL_CLASS_LIST_SUCCESS = 'GET_FULL_CLASS_LIST_SUCCESS'
export const GET_FULL_CLASS_LIST_FAIL = 'GET_FULL_CLASS_LIST_FAIL'