import { CREATE_CATEGORY_FAIL, CREATE_CATEGORY_SUCCESS, DELETE_CATEGORY_FAIL, DELETE_CATEGORY_SUCCESS, GET_CATEGORY_FAIL, GET_CATEGORY_SUCCESS, STATUS_CATEGORY_FAIL, STATUS_CATEGORY_SUCCESS, UPDATE_CATEGORY_FAIL, UPDATE_CATEGORY_SUCCESS } from "../constants/categoryConstants";
import { RESET_ERROR } from "../constants/userConstant";
export const categoryReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                categoryCreated: null,
                categoryStatusUpdated:null,
                caltegoryDeleted:null,
                categoryUpdated:null
            }
        case CREATE_CATEGORY_SUCCESS:
            return {
                ...state,
                categoryCreated: true,
                categoryList: state.categoryList ? [...state.categoryList, action.payload] : [action.payload],
            }
        case CREATE_CATEGORY_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_CATEGORY_SUCCESS:
            return {
                ...state,
                categoryList: action.payload
            }
        case GET_CATEGORY_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case STATUS_CATEGORY_SUCCESS:
            return{
                ...state,
                categoryStatusUpdated:true,
                categoryList: state.categoryList?.map(cat => cat._id === action.payload._id ? action.payload : cat),
            }   
        case STATUS_CATEGORY_FAIL:
            return{
                ...state,
                error:action.payload
            }     
        case DELETE_CATEGORY_SUCCESS:
            return{
                ...state,
                caltegoryDeleted:true,
                categoryList: state?.categoryList.filter(cat => cat._id !== action.payload)
            }    
        case DELETE_CATEGORY_FAIL:
            return{
                ...state,
                error:action.payload
            } 
        case UPDATE_CATEGORY_SUCCESS:
            return{
                ...state,
                categoryUpdated:true,
                categoryList: state.categoryList?.map(cat => cat._id === action.payload._id ? action.payload : cat),
            }  
        case UPDATE_CATEGORY_FAIL:
            return{
                ...state,
                error:action.payload
            }         
        default:
            return state;
    }

}