
import { LOADER, LOADING } from "../constants/userConstant";
import axios from "../../CustomAxios";
import { CREATE_CATEGORY_FAIL, CREATE_CATEGORY_SUCCESS, DELETE_CATEGORY_FAIL, DELETE_CATEGORY_SUCCESS, GET_CATEGORY_FAIL, GET_CATEGORY_SUCCESS, STATUS_CATEGORY_FAIL, STATUS_CATEGORY_SUCCESS, UPDATE_CATEGORY_FAIL, UPDATE_CATEGORY_SUCCESS } from "../constants/categoryConstants";



export const createCategory = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/category/create`, data)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: CREATE_CATEGORY_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: CREATE_CATEGORY_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const getCategory = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/category/list`)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: GET_CATEGORY_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_CATEGORY_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const CategoryStatus = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/category/status`,data)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: STATUS_CATEGORY_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: STATUS_CATEGORY_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}


export const deleteCategory = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.delete(`admin/category/delete/${id}`)
        .then(async response => {
            dispatch({
                type:DELETE_CATEGORY_SUCCESS ,
                payload: id
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: DELETE_CATEGORY_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const updateCategory = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/category/update`,data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type:UPDATE_CATEGORY_SUCCESS ,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: UPDATE_CATEGORY_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}
