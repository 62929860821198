import { Box, Grid, Typography } from '@mui/material'
import React from 'react'

const AnswerComponents = ({Icon,label,i,res,heading,validation}) => {

    console.log({validation})
    return (
        <>
            <Grid item lg={8} md={8} xs={12}>
                <Typography fontFamily={'Quicksand'} fontSize={'14px'} fontWeight={'bold'}>{heading}</Typography>
                <Typography style={{
                    fontFamily: 'QuickSand',
                    fontWeight: 'bold',
                    height: '40px',
                    paddingLeft: '10px',
                    borderRadius: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    background: validation  ? '#DBFADA' : '#FFEFEC'
                }} >{label}<Box
                    sx={{ height: '40px', width: '30px', background: validation ? '#F3FFF9' : '#E8E8E8', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '10px' }}> <Icon sx={{ color: validation ? '#29C617' : '#B4B4B4' }} /></Box>
                </Typography>
            </Grid>
        </>
    )
}

export default AnswerComponents