import { Avatar, Box, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Modal, Stack, Typography } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import React, { useState,useEffect } from 'react'
import CustomTypography from '../../../components/Widgets/CustomTypography';
import CustomAvatar from '../../../components/Widgets/CustomAvatar';
import { IMAGE_URL } from '../../../config/constants';
import CustomInput from '../../../components/Widgets/CustomInput/index.js';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { toast } from 'react-toastify';
import CustomButton from '../../../components/Widgets/CustomButton';
import customAxios from '../../../CustomAxios';
import greysilver from '../../../Assets/images/greysilver.png';
import greygold from '../../../Assets/images/greygold.png';
import greybromance from '../../../Assets/images/greybromance.png';
import silver from '../../../Assets/images/medalsilver.png';
import gold from '../../../Assets/images/medalgold.png';
import bromance from '../../../Assets/images/medalbronze.png';

const AssignTeacherTextCompetion
 = ({ open, handleClose, res, getTextCompetion, getbadgeCount, idd,mode, type }) => {

    const [loading, setLoading] = useState(false)
    const [badge, setBadge] = useState('')

    const schema = object().shape({
        mark: yup.number().required('Required')


    });


    let image = [
        {
            id: 1,
            pic: greygold,
            sel: 'gold',
            main:gold
        },
        {
            id: 2,
            pic: greysilver,
            sel: 'silver',
            main:silver
        },
        {
            id: 3,
            pic: greybromance,
            sel: 'bronze',
            main:bromance
        },


    ]



    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({

        resolver: yupResolver(schema)
    });


    const postCompetion = async (data) => {
        let val = {
            id: res?._id,
            mark: data?.mark,
            badge: badge === 0 ? 'gold' : badge === 1 ? 'silver' :badge ===2 ? 'bronze':''
        }
        setLoading(true)
        await customAxios.post(`teacher/test/editattendee`, val)
            .then(async response => {
                toast.success('Mark Assigned Successfully')
                setLoading(false)
                getTextCompetion(idd);
                getbadgeCount(idd);
                handleClose()
            }).catch(async error => {
                setLoading(false)
                toast.error(error)
            })
    }

 
    useEffect(() => {
      if(res){
        setValue('mark',res?.mark_obtained);
        setBadge(res?.badge === 'gold' ? 0 :res?.badge === 'silver' ? 1 : res?.badge === 'bronze' ? 2 :'')

      }
    }, [res])
    


    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={"sm"}
            onClose={handleClose}>
            <DialogTitle>
                <Stack justifyContent={'space-between'} direction={'row'} px={2} py={2} alignItems='center'>
                    <Box></Box>
                    <Typography sx={{
                        fontFamily: 'Quicksand',
                        fontWeight: 'bold',
                        fontSize: 22,
                        color: '#FF0053',
                        letterSpacing: 1
                    }}>{`Post ID : #${res?.test_attendee_id}`}</Typography>
                    <HighlightOffIcon onClick={() => handleClose()} sx={{
                        cursor: 'pointer'
                    }} />

                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack justifyContent={'center'} alignItems={'center'}>
                    <Typography fontFamily={'QuickSand'} fontWeight={'bold'}>
                        Submitted by
                    </Typography>
                    <Typography fontFamily={'QuickSand'} fontWeight={'bold'}>
                        {res?.student_details?.name}
                    </Typography>
                    <Typography fontFamily={'QuickSand'} fontWeight={'bold'}>
                        
                    </Typography>
                </Stack>
                <Grid container spacing={2} px={5} py={1}>
                    <Grid item xs={12}>
                        <CustomTypography formlabel={'Answer'} h={100} label={res?.answer} />
                    </Grid>
                    <Grid item xs={8} justifyContent={'center'} px={10}>
                        <CustomAvatar avatar={`${IMAGE_URL}${res?.image}`} h={120} />
                    </Grid>
                </Grid>

                {type !== "Normal" && <Grid container px={5} py={1}>
                    <Grid item xs={12} md={4}>
                        <Typography fontFamily={'Quicksand'} fontWeight={'bold'}>Assign Mark</Typography>
                        <Box display={'flex'}>
                            <CustomInput
                               view={mode ? true :false}
                                defaultValue={res?.mark_obtained}
                                w={60}
                                b={'10px 0px 0px 10px'}
                                control={control}
                                error={errors.mark}
                                fieldName="mark"
                                fieldLabel=""
                                Not={true}
                            />
                            <Box

                                style={{
                                    borderRadius: '0px 10px 10px 0px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: 50,
                                    height: 50,
                                    color: '#ffff',
                                    fontFamily: 'Quicksand',
                                    fontWeight: 'boild',
                                    backgroundColor: '#FF0053'
                                }}>
                                <Typography> {res?.test_details?.max_mark}</Typography>
                            </Box>

                        </Box>

                    </Grid>
                    <Grid item xs={6}>
                        <Typography fontFamily={'Quicksand'} fontWeight={'bold'}>Assign A Badge</Typography>
                        <Stack direction={'row'} gap={1}>
                            {image.map((res, i) => (
                                <Avatar  style={{ cursor:'pointer'}} src={badge+1  === res?.id ? res?.main : res?.pic} 
                                onClick={() => {
                                    if(!mode){
                                        setBadge(i)
                                    }
                                }
                                } ></Avatar>
                            ))}
                        </Stack>
                    </Grid>

                </Grid>}
                {!mode &&
                <Box display={'flex'} justifyContent={'center'} py={1}>
                    <CustomButton label={'Confirm'} disable={loading ? true : false} onClick={handleSubmit(postCompetion)} />
                </Box>}
            </DialogContent>
        </Dialog>
    )
}

export default AssignTeacherTextCompetion
