import { GET_PROFILE_FAIL, GET_PROFILE_SUCCESS, UPDATE_PROFILE_FAIL, UPDATE_PROFILE_SUCCESS } from "../constants/profileConstant";
import axios from "../../CustomAxios";
import { RESET_ERROR } from "../constants/userConstant";
export const profileReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                profileUpdate: null,
                error: null
            }
        case GET_PROFILE_SUCCESS:
            return {
                ...state,
                profileList: action.payload
            }
        case GET_PROFILE_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                profileUpdate: true,
                profileList:action.payload
            }
        case UPDATE_PROFILE_FAIL:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }

}