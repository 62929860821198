import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import CustomSearch from '../../components/Widgets/CustomSearch';
import DataTable from '../../components/Widgets/CustomTable';
import Header from '../Header';
import AddIcon from '@mui/icons-material/Add';
import customAxios from '../../CustomAxios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Stack } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useTransition } from 'react';

const Test = ({search,setSearch}) => {



    const [loading, setLoading] = useState(false)
    const [active, setActive] = useState(null)
    const [open, setOpen] = useState(false)
    const [testList, setTestList] = useState([])
    const [pending, startTransition] = useTransition();
    const [data, setData] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        getAllTest();
        setSearch(null)
    }, [])




    const getAllTest = async() => {
        setLoading(true)
        await customAxios.get(`admin/tests`)
        .then(async response => {
            setTestList(response?.data?.data);
            setData(response?.data?.data);
            setLoading(false)

        }).catch(async error => {
            setTestList([])
            setData([])
            setLoading(false)
            toast.error(error)
        })
    }

    const handleDelete = async() => {
        setOpen(false)
        setLoading(true)
        await customAxios.delete(`admin/test/delete/${active}`)
        .then(async response => {
            getAllTest()
            toast.success("Test Deleted Successfully")
            setLoading(false)
            setActive(null)

        }).catch(async error => {
            setActive(null)
            setLoading(false)
            toast.error(error)
        })
    }
    

    useEffect(() => {
        if (search) {
            let Result = data?.filter(text => text?.test_id?.toString().toLowerCase().includes(search.toLowerCase()) || text?.title?.toString().toLowerCase().includes(search.toLowerCase()) || text?.posted_by?.toString().toLowerCase().includes(search.toLowerCase()))
            console.log({
                Result
            })
            startTransition(() => {
                setTestList(Result)
            })
        }
        if(search === ''){
            getAllTest();
        }
    }, [search])

    const columns = [
        { 
            field: 'test_id', 
            headerName: 'Post ID', 
            width: 120,
            headerAlign: 'center',
            align: 'center', 
        },
        {
            field: 'created_at',
            headerName: 'Created Date',
            width: 130,
           
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => moment(params.row.created_at).format("DD-MM-YYYY"),
        },
        {
            field: 'title',
            headerName: 'Title',
            width: 200,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'posted_by',
            headerName: 'Posted By',
            width: 130,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => params.row.posted_by === "Admin" ? "Admin" : params.row.created_by?.name,
        },
        {
            field: 'grade',
            headerName: 'Grade',
            width: 150,
           
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => params.row.grade_details?.name,

        },
        {
            field: 'test_type',
            headerName: 'Type',
            width: 160,
            editable: true,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'expiry_date',
            headerName: 'Expire Date',
            width: 120,
            editable: true,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => moment(params.row.expiry_date).format("DD-MM-YYYY"),
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            width: 150,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                        <span style={{ color: row?.status === 'Ongoing' ?'#C2620E' : row?.status === 'active' ? "#2E75C7" : 
                        row?.status === 'Completed' ? '#21AA12' :'', cursor: 'pointer' }}>{row?.status}</span>
                     )
        },
        {
            field: 'Action',
            headerName: 'Action',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={1} direction={'row'}>
                    <RemoveRedEyeIcon
                        onClick={() => navigate(`/viewTest`,{ state : row })}
                        style={{

                            color: '#FF0053',
                            cursor: 'pointer'
                        }} 
                    />
                    { moment(row?.expiry_date, "YYYY-MM-DD") < moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")  && <EmojiEventsIcon  
                    onClick={()=>navigate(`/textcompetitions/${row._id}`,{
                        state : row
                    })}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />}
                    {row?.status === "active" && <BorderColorTwoToneIcon
                        onClick={() => navigate(`/newText`, { state: row })}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />}
                   {row?.status === "active" && <DeleteOutlineTwoToneIcon
                        onClick={() => {
                            setActive(row?._id)
                            setOpen(true)
                        }}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }} 
                    />}

                </Stack>
            )
        },
    ];

  return (
    <Box >
            <Box px={5} py={2}>
                <Paper style={{
                    borderRadius: "20px"
                }}>
                    <DataTable
                        rows={testList}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                //onClick={handleClose}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Warning?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure want to delete this Test.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setOpen(false)}>No</Button>
                <Button onClick={handleDelete} autoFocus>
                    Yes
                </Button>
                </DialogActions>
            </Dialog>
        </Box>
  )
}

export default Test