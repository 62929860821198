import { Box, Grid, Stack } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js'
import CustomTypography from '../../components/Widgets/CustomTypography/index.js'
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import CustomTextArea from '../../components/Widgets/CustomTextArea/index.js';
import CustomApproveRejectButton from '../../components/Widgets/CustomApproveRejectButton/index.js';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomAvatar from '../../components/Widgets/CustomAvatar/index.js';
import { IMAGE_URL } from '../../config/constants.js';
import CustomCardMedia from '../../components/Widgets/CustomCardMedia/index.js';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { RESET_ERROR } from '../../Redux/constants/userConstant.js';
import { updateIndividualNetwork } from '../../Redux/actions/individualAction.js';
import UserContext from '../../helpers/user/index.js';
const EditNetworkIndividual = () => {
    //mobile Validation

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const userContext = useContext(UserContext)

    const { state } = useParams()

   

    const { loading, loader, userData } = useSelector(state => state.auth);
    const { error, networkUpdated } = useSelector(state => state.individual);

    //console.log({userData: userContext.user})

    const schema = object().shape({

        content: yup.string().required('Content is Required')

    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({
        defaultValues: {

        },
        resolver: yupResolver(schema)
    });


    useEffect(() => {
        if (location?.state?.item) {
            setValue('content', location?.state?.item?.content)

        }
    }, [location?.state?.item])
    useEffect(() => {


        if (networkUpdated) {
            toast.success(`Status Changed Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            navigate(-1)
        }

        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, networkUpdated])

    const SubmitApprove = (data) => {
        let val = {
            id: location?.state?.item?._id,
            reviewed_by: userContext?.user?._id,
            status: 'active'
        }
        dispatch(updateIndividualNetwork(val))

    }
    const SubmitRejected = (data) => {

        let val = {
            id: location?.state?.item?._id,
            status: 'Rejected'
        }
        dispatch(updateIndividualNetwork(val))
    }

    return (
        <Box>
            <CustomBackHeader label={`Edit Post`} />
            <Grid container spacing={3} px={5} mt={2}>
                <Grid item lg={3} md={3} xs={12}>
                    <CustomTypography formlabel={'Creator Name'} label={location?.state?.item?.creator_details?.name} />
                </Grid>
                <Grid item lg={3} md={3} xs={12}>
                    <CustomTypography formlabel={'Class'} label={location?.state?.item?.class} />
                </Grid>
                {/* <Grid item lg={4} md={4} xs={12}>
                    <CustomTypography formlabel={'Reviewed By'} label={location?.state?.item?.reviewed_by} />
                </Grid> */}
                <Grid item lg={6} md={6} xs={12}>
                    <CustomTextArea
                        control={control}
                        error={errors.content}
                        fieldName="content"
                        multiline={true}
                        height={40}
                        row={4}
                        fieldLabel="Post Content"
                        background={'#FFEFEC'}
                    />
                </Grid>
                {location?.state?.item?.file_type === 'image' &&
                    <Grid item lg={4} md={4} xs={12}>
                        <CustomAvatar label={'Post'} avatar={`${IMAGE_URL}${location?.state?.item?.file}`} />
                    </Grid>}
                {location?.state?.item?.file_type === 'video' &&
                    <Grid item lg={4} md={4} xs={12}>
                        <CustomCardMedia label={'Post'} videoProp={`${IMAGE_URL}${location?.state?.item?.file}`} />
                    </Grid>}
                <Grid item lg={4} md={4} xs={12}>
                    <Stack direction={'row'} gap={4} alignItems={"center"} justifyContent={'center'} py={5}
                    >

                        <CustomApproveRejectButton label={'Approve'} shadow={'#DBFADA'} color={'#29C617'} background={'#F3FFF9'}
                            onClick={handleSubmit(SubmitApprove)} />
                        <CustomApproveRejectButton label={'Reject'} shadow={'#FFE8E8'} color={'#FF0000'} background={'#FFE8E8'} onClick={handleSubmit(SubmitRejected)} />
                    </Stack>
                </Grid>

            </Grid>
        </Box>
    )
}

export default EditNetworkIndividual