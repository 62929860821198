import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router';
import RoutingContext from '../../helpers/Routing';
import UserContext from '../../helpers/user';

export default function LogoutModal({ open, handleClose }) {
  const navigate = useNavigate()
  const routesContext = React.useContext(RoutingContext);
  const context = React.useContext(UserContext)
       
  const Agree = () => {
    localStorage.clear();
    routesContext.setRouting(null)
    context.setUser(null)
    navigate('/login', { replace: true })
    
    handleClose()   
    this.props.history.index=0
    
  }



  return (

    <Dialog
      open={open}
      onClose={handleClose}
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{
        fontFamily: 'Quicksand',
        fontWeight: 'bold',
        fontSize: '18px'
      }}>
        {"Are you sure you want to log out?"}
      </DialogTitle>
      {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
      <DialogActions>
        <Button sx={{ color: '#FF0053' }} onClick={handleClose}>Disagree</Button>
        <Button sx={{ color: '#FF0053' }} onClick={() => Agree()} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>

  );
}



