import { Box, Grid, Stack } from '@mui/material';
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import CustomApproveRejectButton from '../../components/Widgets/CustomApproveRejectButton/index.js';
import CustomAvatar from '../../components/Widgets/CustomAvatar/index.js';
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js';
import CustomTextArea from '../../components/Widgets/CustomTextArea/index.js';
import CustomTypography from '../../components/Widgets/CustomTypography/index.js';
import { IMAGE_URL } from '../../config/constants.js';
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import CustomCardMedia from '../../components/Widgets/CustomCardMedia/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_ERROR } from '../../Redux/constants/userConstant.js';
import { updateSchoolNetwork } from '../../Redux/actions/schoolActions.js';

const EditSchoolNetwork = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { error, schoolNetworkUpdated } = useSelector(state => state.school);
    // schoolNetworkUpdated

    console.log({ location })


    const schema = object().shape({

        content: yup.string().required('Payment Status is Required')

    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({
        defaultValues: {

        },
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        if (location) {
            setValue('content', location?.state?.item?.content)

        }
    }, [location])

    useEffect(() => {


        if (schoolNetworkUpdated) {
            toast.success(`Status Changed Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            navigate(-1)
        }

        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, schoolNetworkUpdated])
    const SubmitApprove = (data) => {
        let val = {
            content: data?.content,
            id: location?.state?.item?._id,
            status: 'active'
        }
        dispatch(updateSchoolNetwork(val))

    }
    const SubmitRejected = (data) => {

        let val = {
            content: data?.content,
            id: location?.state?.item?._id,
            status: 'Rejected'
        }
        dispatch(updateSchoolNetwork(val))
    }


    return (
        <Box>
            <CustomBackHeader label={`Edit Post`} />
            <Grid container spacing={3} px={5} mt={2}>
                <Grid item lg={4} md={4} xs={12}>
                    <CustomTypography lg={'100%'} md={'100%'} xs={'100%'} formlabel={'Creator Name'} label={location?.state?.item?.creator_details?.name} />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                    <CustomTypography lg={'100%'} md={'100%'} xs={'100%'} formlabel={'Class'} label={location?.state?.item?.creator_details?.class} />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                    <CustomTypography lg={'100%'} md={'100%'} xs={'100%'} formlabel={'Reviewed By'} label={location?.state?.item?.reviewd_by_details?.name} />
                </Grid>
                <Grid item lg={8} md={8} xs={12}>
                    <CustomTextArea
                        control={control}
                        error={errors.content}
                        fieldName="content"
                        multiline={true}
                        height={150}
                        row={10}
                        fieldLabel="Post Content"
                        background={'#FFEFEC'}
                    />
                </Grid>
                {location?.state?.item?.file_type === 'image' &&
                    <Grid item lg={4} md={4} xs={12}>
                        <CustomAvatar label={'Post'} avatar={`${IMAGE_URL}${location?.state?.item?.file}`} />
                    </Grid>}
                {location?.state?.item?.file_type === 'video' &&
                    <Grid item lg={4} md={4} xs={12}>
                        <CustomCardMedia label={'Post'} videoProp={`${IMAGE_URL}${location?.state?.item?.file}`} />
                    </Grid>}
                <Grid item lg={4} md={4} xs={12}>
                    <Stack direction={'row'} gap={4} justifyContent={'center'} py={5}
                    >
                        <CustomApproveRejectButton label={'Approve'} shadow={'#DBFADA'} color={'#29C617'} background={'#F3FFF9'}
                            onClick={handleSubmit(SubmitApprove)} />
                        <CustomApproveRejectButton label={'Reject'} shadow={'#FFE8E8'} color={'#FF0000'} background={'#FFE8E8'} onClick={handleSubmit(SubmitRejected)} />
                    </Stack>
                </Grid>

            </Grid>
        </Box>
    )
}

export default EditSchoolNetwork