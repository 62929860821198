
import { ADS_STATUS_FAIL, ADS_STATUS_SUCCESS, CREATE_ADS_FAIL, CREATE_ADS_SUCCESS, DELETE_ADS_FAIL, DELETE_ADS_SUCCESS, GET_ADS_FAIL, GET_ADS_SUCCESS, GET_SINGLE_ADS_FAIL, GET_SINGLE_ADS_SUCCESS, UPDATE_ADS_FAIL, UPDATE_ADS_SUCCESS } from "../constants/adsConstants";
import { RESET_ERROR } from "../constants/userConstant";
export const adsReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                adsCreated: null,
                error: null,
                adsDeleted: null,
                statusChanged: null,
                adsUpdated:null
            }
        case CREATE_ADS_SUCCESS:
            return {
                ...state,
                adsCreated: true
            }
        case CREATE_ADS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_ADS_SUCCESS:
            return {
                ...state,
                adsList: action.payload
            }
        case GET_ADS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case DELETE_ADS_SUCCESS:
            return {
                ...state,
                adsDeleted: true,
                adsList: state?.adsList.filter(ads => ads._id !== action.payload)
            }
        case DELETE_ADS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case ADS_STATUS_SUCCESS:
            return {
                ...state,
                statusChanged: true,
                adsList: state.adsList?.map(ads => ads._id === action.payload._id ? action.payload : ads),

            }
        case ADS_STATUS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case UPDATE_ADS_SUCCESS:
            return {
                ...state,
                adsUpdated: true,
                adsList: state.adsList?.map(ads => ads._id === action.payload._id ? action.payload : ads),
            }
        case UPDATE_ADS_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case GET_SINGLE_ADS_SUCCESS:
            return {
                ...state,
                adsSingleList: action.payload
            }

        case GET_SINGLE_ADS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }

}