import React, { useState } from 'react'
import { Avatar, Box, Grid, MenuItem, Modal, Stack, Typography, DialogTitle, Dialog, DialogContent } from '@mui/material'
import CustomTextArea from '../CustomTextArea';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import TextField from '@mui/material/TextField';
import CustomButton from '../CustomButton';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CustomSlider from './CustomSlider';
import { Label } from '@mui/icons-material';
import customAxios from '../../../CustomAxios';
const CustomTextEditor = ({ open, handleClose }) => {



    const schema = object().shape({

    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({

        resolver: yupResolver(schema)
    });


    const [Temparature, setTemparature] = useState(0.01)
    const [MaxLength, setMaxLength] = useState(1)
    const [TopP, setTopP] = useState(0.01)
    const [freqPenality, setFreqPenality] = useState(0.01)
    const [presencePenality, setPresencePenality] = useState(0.01)
    const [prompt, setPrompt] = useState(null)
    const [promptError, setPromptError] = useState(null)
    const [loading, setLoading] = useState(false)

    const getContent = async() => {
        if(prompt){
            setLoading(true)
            let data = {
                model: "text-davinci-003",
                prompt: prompt,
                temperature: Temparature,
                max_tokens: MaxLength,
                top_p: TopP,
                frequency_penalty: freqPenality,
                presence_penalty: presencePenality
            }

            await customAxios.post(`admin/postopen`, data)
            .then(async response => {
                handleClose(response.data.data?.choices?.[0]?.text.slice(2))
                //setCategoryList(response.data.data)
                setLoading(false)

            }).catch(async error => {

                setLoading(false)

            })
        }
        else{
            setPromptError("Content Required")
        }
    }

    

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={"lg"}
            onClose={handleClose}>
                <DialogTitle>
                <Box display={'flex'} py={1} justifyContent={'space-between'} >
                    <span>OPEN AI</span>
                    <HighlightOffIcon style={{ fontSize: '30px', cursor: 'pointer' }} onClick={() => handleClose('')} />
                </Box>
                </DialogTitle>
                <DialogContent>
                
                <Grid  container>
                    <Grid  lg={8} md={8} xs={12} pr={{xs:0, sm:0, md:5}} >
                        <TextField
                            fullWidth
                            id="outlined-multiline-flexible"
                            onChange={(e) => {
                                setPrompt(e.target.value)
                                setPromptError('')
                            }}
                            multiline
                            maxRows={15}
                            InputProps={{
                                style: { borderRadius: "10px", height: 350, background: '#fff', color: "black" },
                            }}
                        />
                        {promptError && <span style={{ color: 'red', fontSize: 10 }}>{promptError}</span>}
                    </Grid>
                    <Grid  lg={4} md={4} xs={12} >
                        <Grid   xs={12}>
                            <Typography
                                sx={{
                                    fontSize: {
                                        lg: 15,
                                        md: 14,
                                        sm: 13,
                                        xs: 12,

                                    },
                                    fontFamily: 'Quicksand',
                                    fontWeight: "bold",

                                }}>Modal</Typography>
                            <Select
                                fullWidth
                                style={{
                                    height: 30
                                }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={"text-davinci-003"}
                                // label="Age"
                                // onChange={handleChange}
                            > 
                                <MenuItem value={"text-davinci-003"}>text-davinci-003</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12}>

                            <CustomSlider label={'Temparature'} onChange={(e) => setTemparature(e.target.value)} min={0.01} max={1} step={0.01} />
                        </Grid>
                        <Grid item xs={12}>

                            <CustomSlider label={'Maximum length'} onChange={(e) => setMaxLength(e.target.value)} min={1} max={4000} />
                        </Grid>

                        <Grid item xs={12}>

                            <CustomSlider label={'Top P'} onChange={(e) => setTopP(e.target.value)}  min={0.01} max={1} step={0.01} />
                        </Grid>
                        <Grid item xs={12}>

                            <CustomSlider label={'Frequency Penality'} onChange={(e) => setFreqPenality(e.target.value)}  min={0.01} max={2} step={0.01} />
                        </Grid>
                        <Grid item xs={12}>

                            <CustomSlider label={'Presence Penality'}  onChange={(e) => setPresencePenality(e.target.value)} min={0.01} max={2} step={0.01} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid display={"flex"} justifyContent={"center"}>
                    <CustomButton label={'Submit'} onClick={getContent} loading={loading} />
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default CustomTextEditor