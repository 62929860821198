import { Box, Button, Stack, styled } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CustomButton from '../../components/Widgets/CustomButton'
import CustomIconButton from '../../components/Widgets/CustomIconButton'
import CustomSearch from '../../components/Widgets/CustomSearch'
import Header from '../Header'
import FilterListIcon from '@mui/icons-material/FilterList';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import School from './School'
import Individual from './Individual'
import { useDispatch, useSelector } from 'react-redux'
import { getReview } from '../../Redux/actions/reviewAction'
import { useTransition } from 'react'

export const ReviewManagement = () => {

    const dispatch = useDispatch();

    const { loading, loader } = useSelector(state => state.auth);
    const { reviewList } = useSelector(state => state.review);

    const [active, setActive] = useState('Individual')
    const [focus, setFocus] = useState('Individual')
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [individualRes, setIndividualRes] = useState([]);
    const [SchoolRes, setSchoolRes] = useState([]);
    const [pending, startTransition] = useTransition();



 

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    //style...........................
    const BUTTONBOX = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    }))
    const BUTTONBOXSMALL = styled(Box)(({ theme }) => ({
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        }
    }))


    useEffect(() => {
        if (active === 'Individual') {
            setIndividualRes(reviewList)
         
        }
    }, [reviewList,focus,active])


    useEffect(() => {
        if (active === 'School') {
            setSchoolRes(reviewList)
           
        }
    }, [reviewList,focus,active])

    useEffect(() => {
        switch (active) {
            case 'Individual':
                dispatch(getReview('Individual'))
                break;
            case 'School':
                dispatch(getReview('School'))
                break;
        }
    }, [active])

    //search function.........................................
    const searchResult = (value) => {
        switch (active) {
            case 'Individual':
                searchIndividual(value)
                break;

        }
    }


    //search result..............................................
    const searchIndividual = (value) => {
        let individualsearch = reviewList?.filter(indi => `#${indi?.post_id}`.toString().toLowerCase().includes(value.toLowerCase())

        )
        startTransition(() => {
            setIndividualRes(individualsearch)
        })
    }



    return (
        <Box>
            <Header label={'Review Management'} />
            <Stack direction={'row'} px={5} justifyContent={'space-between'} alignItems={'center'} py={1}>
                <BUTTONBOX display={'flex'} py={1} sx={{ gap: { xl: 2, md: 1.5, xs: 1 }, flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' } }} >
                    {['Individual', 'School'].map((res) => (
                        <CustomButton label={res} active={active === res ? true : false}
                         focus={focus} 
                         onClick={() =>{ 
                            setActive(res)
                            setFocus(res)
                        }} />
                    ))}
                </BUTTONBOX>
                <BUTTONBOXSMALL>
                    <CustomIconButton
                        active={active}
                        focus={focus} Icon={FilterListIcon} onClick={handleClick} />
                </BUTTONBOXSMALL>
                <CustomSearch placeholder={'Search'} onSearch={(value) => searchResult(value)} />
            </Stack>
            {open && <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {['Individual', 'School'].map((res) => (
                    <MenuItem onClick={handleClose} >
                        <Button style={{
                            color: '#FF0053',
                            backgroundColor: '#FFFAFA'
                        }} onClick={() => setActive(res)}>{res}</Button>

                    </MenuItem>
                ))}
            </Menu>}
            {focus === 'Individual' && <Individual res={individualRes} />}
            {focus === 'School' && <School res={SchoolRes} />}



        </Box>
    )
}
