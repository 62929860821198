import { Backdrop, Box, CircularProgress, Paper } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import CustomSwitch from '../../../components/Widgets/CustomSwitch';
import DataTable from '../../../components/Widgets/CustomTable';
import { Stack } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import CustomSpinner from '../../../components/Widgets/CustomSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { SchooolteacherStatus } from '../../../Redux/actions/schoolActions';
import { RESET_ERROR } from '../../../Redux/constants/userConstant';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import ReasignModal from '../ReasignModal';
const Teachers = ({ res }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, loader } = useSelector(state => state.auth);
    const { error, schoolTeacherStatusChange } = useSelector(state => state.school);

    console.log({res})

 
    

    const columns = [

        {
            field: 'teacher_id',
            headerName: 'Teacher ID',
            flex: 1,
            valueGetter: (params) => (`#${params.row?.teacher_id}`),
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'user_id',
            headerName: 'Created Date',
            flex: 1,
            valueGetter: (params) => (moment(params.row.created_at).format('DD/MM/YYYY')),
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'email',
            headerName: 'Email Address',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'phone',
            headerName: 'Mobile Number',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {

            headerName: 'Class & Division',
            flex: 1,
            valueGetter: (params) => (params.row.classes.map((res)=>res?.division?.map((res1)=>`${res?.class}-${res1?.division}`))),
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <Box>
                    <CustomSwitch
                        checked={row?.status === "active" ? true : false}
                        onClick={(e) => ChangeStatus(e.target.checked, row?._id)}
                    />
                </Box>
            )
        },
        {
            field: 'Action',
            headerName: 'Action',
            flex: 1,
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={1} direction={'row'}>
                    <RemoveRedEyeIcon
                        onClick={() => navigate(`/ViewSchoolTeacher`, {
                            state: {
                                item: row
                            }
                        })}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }} />
                    <BorderColorTwoToneIcon
                        onClick={() => navigate(`/editSchoolTeacher/${row?._id}`)}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />
                    {/* <DeleteOutlineTwoToneIcon
                        onClick={() => handleOpenR()}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }} /> */}

                </Stack>
            )
        },
    ];

    const ChangeStatus = (checked, row) => {
        let status = checked === true ? 'active' : 'inactive'
        let val = {
            id: row,
            status: status
        }
        dispatch(SchooolteacherStatus(val))
    }



    useEffect(() => {
        if (schoolTeacherStatusChange) {
            toast.success(`Status Changed Successfully`);
            dispatch({
                type: RESET_ERROR
            })
        }
        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, schoolTeacherStatusChange])




    return (
        <Box>
            <Box px={5} py={2}>
                <Paper style={{
                    borderRadius: "20px"
                }}>
                    <DataTable
                        rows={res ? res : []}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default Teachers