
import { LOADER, LOADING } from "../constants/userConstant";
import axios from "../../CustomAxios";
import { ADS_STATUS_FAIL, ADS_STATUS_SUCCESS, CREATE_ADS_FAIL, CREATE_ADS_SUCCESS, DELETE_ADS_FAIL, DELETE_ADS_SUCCESS, GET_ADS_FAIL, GET_ADS_SUCCESS, GET_SINGLE_ADS_FAIL, GET_SINGLE_ADS_SUCCESS, UPDATE_ADS_FAIL, UPDATE_ADS_SUCCESS } from "../constants/adsConstants";


export const createAds = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/ads/create`, data)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: CREATE_ADS_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: CREATE_ADS_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const getAds = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/ads/list`)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: GET_ADS_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_ADS_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}


export const deleteAds = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`admin/ads/delete/${id}`)
        .then(async response => {
            dispatch({
                type: DELETE_ADS_SUCCESS,
                payload: id
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: DELETE_ADS_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}


export const adsStatus = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/ads/status`,data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: ADS_STATUS_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: ADS_STATUS_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}


export const updateAds = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/ads/update`,data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: UPDATE_ADS_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: UPDATE_ADS_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}


export const getSingleAds = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    dispatch({
        type: LOADER,
        payload: true
    })
    await axios.get(`admin/ads/show/${id}`,)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_SINGLE_ADS_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
            dispatch({
                type: LOADER,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_SINGLE_ADS_FAIL,
                payload: error
            })
            dispatch({
                type: LOADING,
                payload: false
            })
            dispatch({
                type: LOADER,
                payload: false
            })
        })
}