import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import UserContext from '../helpers/user';

export { PrivateRoute };

function PrivateRoute({ children }) {
  const context = useContext(UserContext)

  const navigate = useNavigate()
   
    useEffect(() => {
      if(!context?.user){
        navigate('/login')
      }
      else{
        //alert(JSON.stringify(context?.user))
      }
      
    }, [context])
    
  
    if (!context?.user) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/login"  />
    }

    // authorized so return child components
    return children;
}