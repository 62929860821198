import { Box, CircularProgress, Grid, MenuItem, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js'
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomInput from '../../components/Widgets/CustomInput/index.js';
import CustomImageUploader from '../../components/Widgets/CustomImageUploder/index.js';
import CustomIconButton from '../../components/Widgets/CustomIconButton/index.js';
import { useDispatch, useSelector } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate, useParams } from 'react-router-dom';
import { getClasses, getClassFullList, getDivision, getSchoolClass, getSchoolTeacherSingleList, getSchooolteacher, UpdateSchoolTeacher } from '../../Redux/actions/schoolActions.js';
import CustomSpinner from '../../components/Widgets/CustomSpinner/index.js';
import CustomBox from '../../components/Widgets/CustomBox/index.js';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import ReasignModal from './ReasignModal.js';
import { RESET_ERROR } from '../../Redux/constants/userConstant.js';
import CustomSelect from '../../components/Widgets/CustomSelect/index.js';
import CustomMultipleSelectInput from '../../components/Widgets/CustomMultiSelect/index.js';
import AddIcon from '@mui/icons-material/Add';
import CustomIconOnlyButton from '../../components/Widgets/CustomIconOnlyButton/index.js';
import CustomEditTeacher from './CustomEditTeacher.js';
import { isEmpty, uniqueId } from 'lodash';
const EditSchoolTeacher = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, loader } = useSelector(state => state.auth);
    const { schoolTeacherSingleList,
        getDivisionList,
        error,
        schoolTeacherList,
        schoolTeacherReasigned,
        schoolTeacherUpdated,
        getClassesList, classSchoolList } = useSelector(state => state.school);


    console.log({ schoolTeacherSingleList })

    const [images, setimages] = useState(null)
    const [previewEdit, setPreviewEdit] = useState(null);
    const [open, setOpen] = useState(false);
    const [divID, setDivId] = useState(null);



    const [addClass, setaddClass] = useState([{
        id: uniqueId(),
        classValue: '',
        division: [

        ]

    },])




    const { id } = useParams()




    //mobile Validation
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const schema = object().shape({
        name: yup.string().required('Name is Required'),
        email: yup.string().email().required('Email is Required'),
        phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Mobile Number is Required'),
    });



    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({
        resolver: yupResolver(schema)
    })

    const setimagefile = (e) => {
        if (e.size > 500000) {
            toast.warning("Image Size Should be less than 500KB");
            setPreviewEdit(null)
            setimages(null)
        } else {
            setPreviewEdit(null)
            setimages(e)
        }
    }


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const DeleteApires = (id) => {
        console.log({id})
        setDivId(id)
        handleOpen()

    }





    const addMultipleClass = () => {
        addClass.push({
            id: uniqueId(),
            classValue: '',
            division: [

            ]


        })
        setaddClass([...addClass])

    }

    const removeClass = (id) => {
        const data = addClass.filter((res, i) => res.id !== id)
        setaddClass([...data])
    }



    const SubmitForm = (data) => {
        let multiarray = schoolTeacherSingleList?.class ? schoolTeacherSingleList?.class : [];
        addClass.map((res) => (
            res?.division.map((res) => (
                multiarray.push(res)
            ))
        ))

        const formData = new FormData();
        formData.append('school_id', schoolTeacherSingleList?.school_id);
        formData.append('id', id);
        formData.append('email', data?.email);
        formData.append('name', data?.name);
        formData.append('phone', data?.phone);
        formData.append('division', JSON.stringify(multiarray));
        if (images) {
            formData.append('image', images);
        }
        dispatch(UpdateSchoolTeacher(formData))


    }


    useEffect(() => {
        if (id && schoolTeacherList) {
            dispatch(getSchoolClass(schoolTeacherSingleList?.school_id))
        }
    }, [schoolTeacherList])


    useEffect(() => {
        if (id) {
            dispatch(getSchoolTeacherSingleList(id))
        }
    }, [id])

    useEffect(() => {
        if (schoolTeacherSingleList) {
            dispatch(getSchooolteacher(schoolTeacherSingleList?.school_id))
            dispatch(getClassFullList(schoolTeacherSingleList?.school_id))
        }                               
    }, [schoolTeacherSingleList])


    useEffect(() => {
        if (schoolTeacherSingleList) {
            setValue('name', schoolTeacherSingleList?.name)
            setValue('phone', schoolTeacherSingleList?.phone)
            setValue('email', schoolTeacherSingleList?.email)
            setPreviewEdit(schoolTeacherSingleList?.image)
        }

    }, [schoolTeacherSingleList])







    useEffect(() => {
        if (schoolTeacherReasigned) {
            toast.success(`Teacher Reasigned Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            handleClose()
        }
        if (schoolTeacherUpdated) {
            toast.success(`Teacher Updated Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            navigate(-1)
        }
        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [error, schoolTeacherReasigned, schoolTeacherUpdated])



    // if (loader) {
    //     return <><CustomSpinner /></>
    // }


    return (
        <Box>
            <CustomBackHeader label={'Edit Teacher'} />
            <Box px={5} mt={5}>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.name}
                            fieldName="name"
                            fieldLabel="Name"
                        />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.email}
                            fieldName="email"
                            fieldLabel="Email Address"
                        />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.phone}
                            fieldName="phone"
                            fieldLabel="Mobile Number"
                        />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <CustomImageUploader
                            accept={'image/*'}
                            format={'image'}
                            fieldLabel='Profile Image'
                            control={control}
                            error={errors.image}
                            fieldName='image'
                            type={"file"}
                            height={300}
                            width={300}
                            background="#ffff"
                            id="contained-button-file"
                            preview={images}
                            previewEditimage={previewEdit}
                            onChangeValue={(file, e) => {
                                setimagefile(file)
                                setValue("image", file)
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Box px={5} py={2}>
                    <Grid container spacing={2}>
                        {schoolTeacherSingleList?.classes?.map((res) => (
                            res?.division.map(div => (
                                <Grid container spacing={2}>
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                        <CustomBox label={res?.class} heading={'Class'} />
                                    </Grid>
                                    <Grid item lg={3} md={4} sm={6} xs={12}>
                                        <CustomBox label={div?.division} heading={'Division'} />
                                    </Grid>
                                    <Grid item lg={3} md={4} sm={6} xs={12} mt={7}>
                                        <DeleteOutlineTwoToneIcon
                                            onClick={() => DeleteApires(div?._id)}
                                            style={{
                                                color: '#FF0053',
                                                cursor: 'pointer'
                                            }} 
                                        />
                                    </Grid>
                                </Grid>
                            ))
                        )
                        )}

                        {addClass.map((value, index) => (
                            <Grid container spacing={2} mt={.5}>
                                <CustomEditTeacher
                                    onClassChanged={(value, index, mode) => addClass[index][mode] = value}

                                    onDivisionChaged={(value, index,) => addClass[index].division = value}
                                    getClassesList={getClassesList} getDivisionList={getDivisionList}
                                    schoolTeacherSingleList={schoolTeacherSingleList}
                                    index={index}

                                />
                                <Grid item lg={.5} md={.5} sm={6} xs={12}>
                                    <Box mt={2}></Box>
                                    <CustomIconOnlyButton Icon={AddIcon} onClick={() => addMultipleClass(index)} />
                                </Grid>
                                <Grid item lg={.5} md={.5} sm={6} xs={12}>
                                    {addClass.length > 1 &&
                                        <Box mt={4}>
                                            <DeleteOutlineTwoToneIcon
                                                onClick={() => removeClass(value.id)}
                                                style={{
                                                    color: '#FF0053',
                                                    cursor: 'pointer'
                                                }} />
                                        </Box>}
                                </Grid>
                                <Grid item lg={5} md={5} sm={6} xs={12}>

                                </Grid>
                            </Grid>

                        ))}





                    </Grid>
                </Box>
                <Box display={'flex'} justifyContent={'flex-end'} py={10} px={5}>
                    <CustomIconButton
                        onClick={handleSubmit(SubmitForm)}
                        label={loading ? <CircularProgress style={{ color: '#FF0053' }} size={20} /> : 'Update'}
                        disabled={loading ? true : false}
                        Icon={RefreshIcon} />
                </Box>
                {open &&
                    <ReasignModal open={open} handleClose={handleClose} divID={divID} res={schoolTeacherSingleList} schoolTeachers={schoolTeacherList} />}
            </Box>
        </Box>
    )
}

export default EditSchoolTeacher