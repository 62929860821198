import { Box, Button, Stack, styled } from '@mui/material'
import React, { useState, useEffect, useTransition } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CustomButton from '../../components/Widgets/CustomButton/index.js';
import CustomIconButton from '../../components/Widgets/CustomIconButton/index.js';
import CustomSearch from '../../components/Widgets/CustomSearch/index.js';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import Classes from './Tables/classes.js';
import ClassModal from './ClassModal.js';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { RESET_ERROR } from '../../Redux/constants/userConstant.js';
import { getSchoolClass, getSchoolNetwork, getSchoolStudents, getSchooolteacher } from '../../Redux/actions/schoolActions.js';
import Teachers from './Tables/Teachers.js';
import CustomIconOnlyButton from '../../components/Widgets/CustomIconOnlyButton/index.js';
import Students from './Tables/Students.js';
import Network from './Tables/Network.js';
import { GET_SCHOOL_CLASS_SUCCESS, GET_SCHOOL_STUDENT_SUCCESS, GET_SCHOOL_TEACHER_SUCCESS } from '../../Redux/constants/schoolConstant.js';
const SchoolUserIndex = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { error,
		classCreated,
		classSchoolList,
		schoolClassUpdated,
		schoolTeacherList,
		schoolStudentList,
		SchoolNetworkList } = useSelector(state => state.school);


	


	const focus = localStorage.getItem('default');

   console.log({location})
	const [active, setActive] = useState(focus ? focus : 'Classes');
	const [pending, startTransition] = useTransition()
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [classListRes, setClassListRes] = useState([]);
	const [classTeacherListRes, setClassTeacherListRes] = useState([]);
	const [schoolStudentRes, setSchoolStudentRes] = useState([]);
	const [schoolNetworkRes, setSchoolNetworkRes] = useState([]);
	const [openM, setOpenM] = useState(false)


	console.log({schoolNetworkRes})

	const handleOpenM = () => setOpenM(true);
	const handleCloseM = () => setOpenM(false);



	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};


	//style...........................
	const BUTTONBOX = styled(Box)(({ theme }) => ({
		[theme.breakpoints.down('md')]: {
			display: 'none',
		}
	}))

	const BUTTONBOXSMALL = styled(Box)(({ theme }) => ({
		display: 'none',
		[theme.breakpoints.down('md')]: {
			display: 'block',
			display: 'flex',
			justifyContent: 'space-beetween',
			width: '84vw'
		}
	}))

	const addButton = (mode) => {
		if (mode === 'Classes') {
			handleOpenM()
		} else if (mode === 'Teachers') {
			navigate('/createSchoolTeacher', {
				state: {
					school_id: location?.state?.item?._id
				}
			})
		}

	}

	//search class list ..............................
	const searchClass = (value) => {
		let schoolClass = classSchoolList?.filter(classS => classS?.name.toString().toLowerCase().includes(value.toLowerCase())
			|| classS?.division.toString().toLowerCase().includes(value.toLowerCase())
		)
		startTransition(() => {
			setClassListRes(schoolClass)
		})
	}

	//search teacher list ..............................
	const searchSchoolTeacher = (value) => {
		let schoolTeacher = schoolTeacherList?.filter(teacher => teacher?.name.toString().toLowerCase().includes(value.toLowerCase()))
		startTransition(() => {
			setClassTeacherListRes(schoolTeacher)
		})
	}

	//search Student List..............................
	const searchSchoolStudents = (value) => {
		let schoolStudents = schoolStudentList?.filter(student => student?.name.toString().toLowerCase().includes(value.toLowerCase())
			|| `#${student.user_id}`.toString().toLowerCase().includes(value.toLowerCase()))
		startTransition(() => {
			setSchoolStudentRes(schoolStudents)
		})
	}


	//search Netrwork List..............................
	const searchSchoolNetworks = (value) => {
		let schoolNetworks = SchoolNetworkList?.filter(network => network?.creator_details?.name.toString().toLowerCase().includes(value.toLowerCase())
			|| `#${network.post_id}`.toString().toLowerCase().includes(value.toLowerCase()))
		startTransition(() => {
			setSchoolNetworkRes(schoolNetworks)
		})
	}


	//search function.........................................
	const searchResult = (value) => {
		switch (active) {
			case 'Classes':
				searchClass(value)
				break;
			case 'Teachers':
				searchSchoolTeacher(value)
				break;
			case 'Students':
				searchSchoolStudents(value)
				break
			case 'Network':
				searchSchoolNetworks(value)
				break
		}
	}


	useEffect(() => {
		if (classCreated) {
			toast.success(`Class Created Successfully`);
			dispatch({
				type: RESET_ERROR
			})
			handleCloseM()
		}
		if (error) {
			toast.error(`${error.message}`);
			dispatch({
				type: RESET_ERROR
			})
		}
	}, [error, classCreated])


	//class Table Request..............................
	useEffect(() => {
		switch (active) {
			case 'Classes':
				dispatch({
					type:GET_SCHOOL_CLASS_SUCCESS	,
					payload:[]
				})
				dispatch(getSchoolClass(location?.state?.item?._id))
				break;
			case 'Teachers':
				dispatch({
					type: GET_SCHOOL_TEACHER_SUCCESS,
					payload: []
				});
				dispatch(getSchooolteacher(location?.state?.item?._id))
				break;
			case 'Students':
				dispatch({
					type: GET_SCHOOL_STUDENT_SUCCESS,
					payload: []
				});
				dispatch(getSchoolStudents(location?.state?.item?._id))
				break;
			case 'Network':
				dispatch(getSchoolNetwork(location?.state?.item?._id))
		}
	}, [active])


	//api get class list...Table View.........................
	useEffect(() => {
		if (classSchoolList) {
			setClassListRes(classSchoolList)
		}
		if (schoolTeacherList) {
			setClassTeacherListRes(schoolTeacherList)
		}
		if (schoolStudentList) {
			setSchoolStudentRes(schoolStudentList)
		}
		if (SchoolNetworkList) {
			setSchoolNetworkRes(SchoolNetworkList)
		}
	}, [classSchoolList, schoolTeacherList, schoolStudentList, SchoolNetworkList])



	useEffect(() => {
		if(focus){
			setActive(focus)
		} else{
			setActive('Classes')
		}
	}, [focus])



	return (
		<>
			<CustomBackHeader label={location?.state?.item?.name} />
			<Stack
				direction={'row'}
				px={5}
				justifyContent={'space-between'}
				alignItems={'center'}
				py={1}>
				<BUTTONBOX
					display={'flex'}
					py={1}
					sx={{
						gap: { xl: 1, md: 1, xs: 1 },
						flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' }
					}} >
					{['Classes', 'Teachers', 'Students', 'Network'].map((res) => (
						<CustomButton label={res} active={active === res ? true : false} onClick={() => {
							setActive(res)
							localStorage.setItem('default', res)
						}} />
					))}
				</BUTTONBOX>

				<BUTTONBOXSMALL>
					<CustomIconOnlyButton Icon={FilterListIcon} onClick={handleClick} />
				</BUTTONBOXSMALL>

				<Stack
					direction={{
						lg: 'row',
						md: 'row',
						sm: 'column'
					}}
					gap={2}>
					{active === 'Classes' || active === 'Teachers'  ? 
						<CustomIconButton
							label={'Add'}
							Icon={AddIcon} onClick={() => addButton(active)} /> :''}
					<CustomSearch lg={'100%'} md={'100%'} sm={'50vw'} xs={'60vw'} placeholder={'Search'} onSearch={(value) => searchResult(value)
					} />
				</Stack>
			</Stack>
			{open && <Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{['Classes', 'Teachers', 'Students', 'Network'].map((res) => (
					<MenuItem onClick={handleClose} >
						<Button style={{
							color: '#FF0053',
							backgroundColor: '#FFFAFA'
						}}>{res}</Button>

					</MenuItem>
				))}
			</Menu>}

			{openM && <ClassModal
				openM={openM}
				handleCloseM={handleCloseM}
				schoolid={location?.state?.item?._id} />}

			{active === 'Classes' && <Classes res={classListRes} />}
			{active === 'Teachers' && <Teachers res={classTeacherListRes} />}
			{active === 'Students' && <Students res={schoolStudentRes} />}
			{active === 'Network' && <Network res={schoolNetworkRes} />}
		</>
	)
}

export default SchoolUserIndex