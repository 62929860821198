import { Box, CircularProgress, Grid, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js'
import DataTable from '../../components/Widgets/CustomTable/index.js'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomSelect from '../../components/Widgets/CustomSelect/index.js';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import CustomInput from '../../components/Widgets/CustomInput/index.js';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleStudentIndividual, updateStudentIndividual } from '../../Redux/actions/individualAction.js';
import { toast } from "react-toastify";
import { RESET_ERROR } from '../../Redux/constants/userConstant.js';
const EditStudents = () => {

    const { id } = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, loader } = useSelector(state => state.auth);
    const { singleStudentList ,studentUpdated,error} = useSelector(state => state.individual);

    
     console.log({singleStudentList})


    const [payment, setPayment] = useState(null);


  //mobile Validation
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const schema = object().shape({
    name: yup.string().required('Name is Required').max(30, "Name must be less than 30 characters"),
    email: yup.string().email("Please enter valid email").required('Email is Required').max(30, "Email must be less than 30 characters"),
    mobile: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Mobile Number is Required').min(8, "Phone Number must be greater than 8 characters").max(14, "Phone number must be less than 14 characters"),
    class:yup.string().required('Class is Required').max(15, "Class must be less than 15 characters"),
    payment_status:yup.string().required('Payment Status is Required')

    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({
            defaultValues:{
                id:id,
                status:singleStudentList?.status

            },
        resolver: yupResolver(schema)
    });



    useEffect(() => {
        if (id && singleStudentList) {
            setValue('name', singleStudentList?.name)
            setValue('email', singleStudentList?.email)
            setValue('mobile', singleStudentList?.mobile)
            setValue('class', singleStudentList?.class)
            setPayment( singleStudentList?.payment_status)
            setValue('payment_status', singleStudentList?.payment_status)
        }
    }, [singleStudentList])



    useEffect(() => {
        if (id) {
            dispatch(getSingleStudentIndividual(id))
        }
    }, [id])

    useEffect(() => {

        if (studentUpdated) {
            toast.success(`Student Updated Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            navigate(-1)

        }

        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, studentUpdated])


  const SubmitForm = (data)=>{
       dispatch(updateStudentIndividual(data))

  }


    return (
        <Box>
            <CustomBackHeader label={'Edit Student'} />
            <Box py={2} px={5}>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.name}
                            fieldName="name"
                            fieldLabel="Name"
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.email}
                            fieldName="email"
                            fieldLabel="Email Address"
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                           view={true}
                            control={control}
                            error={errors.mobile}
                            fieldName="mobile"
                            fieldLabel="Mobile Number"
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.class}
                            fieldName="class"
                            fieldLabel="Class"
                        />
                    </Grid>

                    <Grid item lg={3} md={4} xs={12}>
                        <CustomSelect
                            control={control}
                            error={errors.payment}
                            fieldName="payment"
                            fieldLabel="Payment Status"
                            background="#FFEFEC"
                            size="16px"
                            value={payment}
                            onChangeValue={(e) => {
                                setPayment(e.target.value)
                                setValue('payment_status', e.target.value)
                                setError('payment_status', '')
                            }}
                        >
                            <MenuItem value="" disabled >
                                <em>Payment Status</em>
                            </MenuItem>
                            {[{name: 'Paid', value: 'paid'}, { value: 'pending', name: 'Pending'}].map((res, i) => (
                                <MenuItem value={res.value} key={i} >
                                    {res.name}
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </Grid>

                </Grid>
            </Box>
            <Box display={'flex'} justifyContent={'center'} py={10}>
                <CustomIconButton label={loading ? <CircularProgress  style={{ color: '#FF0053' }} size={20}/> : 'Update'}
                    //  disabled={loading ? true : false}
                    Icon={RefreshIcon} onClick={handleSubmit(SubmitForm)} />
            </Box>
        </Box>
    )
}

export default EditStudents