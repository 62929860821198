import { Backdrop, Box, CircularProgress, Paper } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import CustomSearch from '../../components/Widgets/CustomSearch';
import DataTable from '../../components/Widgets/CustomTable';
import Header from '../Header';
import AddIcon from '@mui/icons-material/Add';
import AddCategoryModal from './AddCategoryModal';
import CustomSwitch from '../../components/Widgets/CustomSwitch';
import { Stack } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { RESET_ERROR } from '../../Redux/constants/userConstant';
import { CategoryStatus, deleteCategory, getCategory } from '../../Redux/actions/categoryActions';
import moment from 'moment/moment';
import { useTransition } from 'react';
import ViewCategoryModal from './ViewCategoryModal';
import CustomDelete from '../../components/Widgets/CustomDelete';
const CategoryManagement = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { loading, loader } = useSelector(state => state.auth);
    const { error, categoryCreated, categoryList, categoryStatusUpdated, caltegoryDeleted, categoryUpdated } = useSelector(state => state.category);


    const [open, setOpen] = useState(false);
    const [dataRes, setDataRes] = useState([]);
    const [openV, setOpenV] = useState(false);
    const [viewRes, setViewRes] = useState(null)

    const [pending, startTransition] = useTransition();
    const [iD, setID] = useState(null);
    const [openD, setOpenD] = useState(false);

    const handleOpenD = () => setOpenD(true);
    const handleCloseD = () => setOpenD(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setViewRes(null)

    }


    const handleOpenV = () => setOpenV(true);
    const handleCloseV = () => setOpenV(false);


    console.log({ dataRes })

    const columns = [
        {
            field: 'id',
            headerName: 'Category ID',
            width: 160,
            valueGetter: (params) => (`#${params.row?.category_id}`),
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'createdOn',
            headerName: 'Created Date',
            type: 'date',
            width: 200,
            valueGetter: (params) => (moment(params.row.created_at).format('DD/MM/YYYY')),
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'name',
            headerName: 'Category Name',
            width: 200,
            headerAlign: 'center',
            align: 'center',

            valueGetter: (params) => (params?.row?.name)
        },
        {
            field: 'country',
            headerName: 'Country',
            width: 200,
            headerAlign: 'center',
            align: 'center',


        },
        {
            field: 'Status',
            headerName: 'Status',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <Box>

                    <CustomSwitch checked={row?.status === "active" ? true : false}
                        onClick={(e) => ChangeStatus(e.target.checked, row?._id)} />
                </Box>
            )
        },
        {
            field: 'Action',
            headerName: 'Action',
            width: 200,
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={2} direction={'row'}>
                    <RemoveRedEyeIcon
                        onClick={() => ViewMOdalOnclick(row)}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }} />
                    <BorderColorTwoToneIcon
                        onClick={() => {
                            setViewRes(row)
                            handleOpen()
                        }}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />
                    <DeleteOutlineTwoToneIcon
                        onClick={() => handleDelete(row?._id)}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }} />

                </Stack>
            )



        },

    ];


    const searchCategory = (value) => {
        let category = categoryList?.filter(cat => cat?.name.toString().toLowerCase().includes(value.toLowerCase())
            || `#${cat?.category_id}`.toString().toLowerCase().includes(value.toLowerCase())
        )
        startTransition(() => {
            setDataRes(category)
        })
    }


    const ViewMOdalOnclick = (row) => {
        setViewRes(row)
        handleOpenV()
    }

    useEffect(() => {
        if (categoryList) {
            setDataRes(categoryList)

        }
    }, [categoryList])


    useEffect(() => {

        if (categoryCreated) {
            toast.success(`Category Created Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            handleClose();

        }
        if (caltegoryDeleted) {
            toast.success(`Category Deleted Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            handleCloseD();
        }
        if (categoryUpdated) {
            toast.success(`Category Updated Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            handleClose();
        }
        if (categoryStatusUpdated) {
            toast.success(`Status Changed Successfully`);
            dispatch({
                type: RESET_ERROR
            })
        }

        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, categoryCreated, categoryStatusUpdated, caltegoryDeleted, categoryUpdated])



    const ChangeStatus = (checked, row) => {
        let status = checked === true ? 'active' : 'inactive'
        let val = {
            id: row,
            status: status
        }
        dispatch(CategoryStatus(val))
    }

    const handleDelete = (id) => {
        setID(id);
        handleOpenD();

    }

    useEffect(() => {
        dispatch(getCategory())
    }, [])


    return (
        <Box height={'100%'} >
            <Header label={'Category Management'} />
            <Box px={2} py={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <CustomIconButton onClick={handleOpen} label={'Add'} Icon={AddIcon} />
                <CustomSearch placeholder={'Category ID, Category Name'} onSearch={(value) => searchCategory(value)} />
            </Box>
            <Box px={2}>
                <Paper style={{
                    borderRadius: "20px"
                }}>
                    <DataTable
                        rows={dataRes ? dataRes : []}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                //onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
            {open && <AddCategoryModal open={open} handleClose={handleClose} res={viewRes} />}
            {openV && <ViewCategoryModal open={openV} handleClose={() => {
                handleCloseV()
                setViewRes(null)
            }
            } res={viewRes} />}
            {openD && <CustomDelete open={handleOpenD} handleClose={handleCloseD} mode={'Category'} id={iD} />}
        </Box>
    )
}

export default CategoryManagement