import { Backdrop, Box, CircularProgress, Paper } from '@mui/material'
import React, { useEffect, useState, useTransition } from 'react'
import { useNavigate } from 'react-router-dom';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import CustomSearch from '../../components/Widgets/CustomSearch';
import DataTable from '../../components/Widgets/CustomTable';
import Header from '../Header';
import AddIcon from '@mui/icons-material/Add';
import CustomSwitch from '../../components/Widgets/CustomSwitch';
import { Stack } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, getUserManagement, userStatusChange } from '../../Redux/actions/userActions';
import { toast } from "react-toastify";
import moment from 'moment/moment';
import { RESET_ERROR } from '../../Redux/constants/userConstant';
import CustomDelete from '../../components/Widgets/CustomDelete';
const UserManagement = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

   
   

    const { userList, userDeleted, error, userStatusChanged } = useSelector(state => state.user);
    const { loading, loader } = useSelector(state => state.auth);

    


    const [resData, setResDta] = useState([]);

   
    const [pending, startTransition] = useTransition();
    const[iD,setID]=useState(null);
    const[openD,setOpenD]=useState(false);

    const handleOpenD = () => setOpenD(true);
    const handleCloseD = () => setOpenD(false);


    const columns = [
        {
            field: 'user_id',
            headerName: 'User ID',
            width: 150,
            valueGetter: (params) => (`#${params.row?.user_id}`),
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'created_at',
            headerName: 'Created Date',
            type: 'date',
            width: 180,
            valueGetter: (params) => (moment(params.row.created_at).format('DD/MM/YYYY')),
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            headerAlign: 'center',
            align: 'center',


        },
        {
            field: 'email',
            headerName: 'Email Address',
            width: 300,
            headerAlign: 'center',
            align: 'center',


        },
        {
            field: 'mobile',
            headerName: 'Mobile Number',
            width: 200,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'role',
            headerName: 'User Type',
            width: 200,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'status',
            headerName: 'Status',
            width: 250,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <Box>
                    <CustomSwitch
                        checked={row?.status === "active" ? true : false}
                        onClick={(e) => ChangeStatus(e.target.checked, row?._id)}
                    />
                </Box>
            )
        },
        {
            field: 'Action',
            headerName: 'Action',
            width: 120,
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={1} direction={'row'}>
                    <RemoveRedEyeIcon
                        onClick={() => navigate(`/ViewUserManagement/${row?._id}`)}
                        style={{

                            color: '#FF0053',
                            cursor: 'pointer'
                        }} />
                    <BorderColorTwoToneIcon
                        onClick={() => navigate(`/AddUserManagement/${row?._id}`)}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />
                    <DeleteOutlineTwoToneIcon
                        onClick={() => handleDelete(row?._id)}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }} />

                </Stack>
            )
        },

    ];

    const ChangeStatus = (checked, row) => {
        let status = checked === true ? 'active' : 'inactive'
        let val = {
            id: row,
            status: status
        }
        dispatch(userStatusChange(val))
    }

    const handleDelete = (id) => {
              setID(id);
              handleOpenD();
            
        
    }



    const searchuser = (value) => {
        let users = userList?.filter(user => user?.name.toString().toLowerCase().includes(value.toLowerCase())
            || `#${user?.user_id}`.toString().toLowerCase().includes(value.toLowerCase()) ||
            user?.email.toString().toLowerCase().includes(value.toLowerCase()) ||
            user?.mobile.toString().toLowerCase().includes(value.toLowerCase())
        )
        startTransition(() => {
            setResDta(users)
        })
    }



    useEffect(() => {
        dispatch(getUserManagement())
    }, [])


    useEffect(() => {
        if (userList) {
            setResDta(userList)
        }
    }, [userList])






    useEffect(() => {
        if (userDeleted) {
            toast.success(`User Deleted Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            handleCloseD();

        }
        if (userStatusChanged) {
            toast.success(`Status Changed Successfully`);
            dispatch({
                type: RESET_ERROR
            })
        }

        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, userDeleted, userStatusChanged])



    return (
        <Box height={'100%'} >
            <Header label={'User Management'} />
            <Box px={2} py={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <CustomIconButton onClick={() => navigate('/AddUserManagement')} label={'Add'} Icon={AddIcon} />
                <CustomSearch placeholder={'User ID, User Name'} onSearch={(value) => searchuser(value)} />
            </Box>
            <Box px={2}>
                <Paper style={{
                    borderRadius: "20px"
                }}>
                    <DataTable
                        rows={resData}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                //onClick={handleClose}
                >
                <CircularProgress color="inherit" />
            </Backdrop>

            {openD && <CustomDelete open={handleOpenD} handleClose={handleCloseD} mode={'User'} id={iD}/>}
        </Box>
    )
}

export default UserManagement