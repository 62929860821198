import React, { useState, useEffect, useLayoutEffect } from 'react'
import LoginLayout from '../components/Common/Layouts/LoginLayout'
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from '../Screens/auth';
import OTP from '../Screens/auth/otp';
import HomeLayout from '../components/Common/Layouts/HomeLayout';
import UserManagement from '../Screens/UserManagement';
import AddUserManagement from '../Screens/UserManagement/AddUserManagement';
import Profile from '../Screens/Profile';
import { IndividualManagement } from '../Screens/IndividaualManagement';
import SchoolManagement from '../Screens/SchoolManagement.js';
import AddSchoolManagement from '../Screens/SchoolManagement.js/AddSchoolManagement';
import OrderManagement from '../Screens/OrderManagment/OrderManagement';
import { ReviewManagement } from '../Screens/ReviewManagement';
import AddAds from '../Screens/AdsManagement/AddAds';
import { NewsManagement } from '../Screens/NewsManagement';
import AddNewsManagement from '../Screens/NewsManagement/AddNews';
import NewText from '../Screens/NewsManagement/NewText';
import { VocabularyManagement } from '../Screens/VocabularyManagement';
import AddVocabulary from '../Screens/VocabularyManagement/AddVocabulary';
import CategoryManagement from '../Screens/CategoryManagement';
import ViewUserManagement from '../Screens/UserManagement/ViewUserManagement';
import EditOrderManagement from '../Screens/OrderManagment/EditOrderManagement';
import EditStudents from '../Screens/IndividaualManagement/EditStudent';

import EditSchool from '../Screens/ReviewManagement/EditSchool';
import ViewSchoolManagement from '../Screens/SchoolManagement.js/ViewSchoolManagement';
import { PrivateRoute } from './PrivateRoutes';
import ErrorPage from './ErrorPage';
import SchoolUserIndex from '../Screens/SchoolManagement.js/SchoolUserIndex';
import CreateSchoolTeacher from '../Screens/SchoolManagement.js/CreateTeacher';
import ViewSchoolTeacher from '../Screens/SchoolManagement.js/ViewTeacher';
import EditSchoolTeacher from '../Screens/SchoolManagement.js/EditSchoolTeacher';
import ViewAds from '../Screens/AdsManagement/ViewAds';
import AdsManagement from '../Screens/AdsManagement';
import ViewVocabulary from '../Screens/VocabularyManagement/ViewVocabulary';
import ViewStudentIndividual from '../Screens/IndividaualManagement/viewStudent';
import EditNetworkIndividual from '../Screens/IndividaualManagement/EditNetwork';
import ViewNews from '../Screens/NewsManagement/ViewNews';
import ViewTest from '../Screens/NewsManagement/ViewTest';
import NewQuiz from '../Screens/NewsManagement/NewQuiz';
import Questions from '../Screens/NewsManagement/Questions';
import EditIndividualReview from '../Screens/ReviewManagement/EditIndividual';
import EditSchoolReview from '../Screens/ReviewManagement/EditSchool';
import ViewNetworkIndividual from '../Screens/IndividaualManagement/viewNetworkIndividual';
import EditSchoolStudent from '../Screens/SchoolManagement.js/EditSchoolStudent';
import ViewSchoolStudent from '../Screens/SchoolManagement.js/ViewSchoolStudent';
import EditSchoolNetwork from '../Screens/SchoolManagement.js/EditSchoolNetwork';
import ViewSchoolNetwork from '../Screens/SchoolManagement.js/ViewSchoolNetwork';
import QuizeCompatitons from '../Screens/NewsManagement/QuizeCompatitons';
import ViewQuiz from '../Screens/NewsManagement/ViewQuiz';
import ViewOnlyQuestions from '../Screens/NewsManagement/ViewOnlyQuestions';
import MyClassRoom from '../Screens/Teacher/MyclassRoom';
import AddText from '../Screens/Teacher/MyclassRoom/AddText';
import AddQuiz from '../Screens/Teacher/MyclassRoom/AddQuiz';
import QuestionsTeacher from '../Screens/Teacher/MyclassRoom/Questions';
import MyStudents from '../Screens/Teacher/MyStudents';
import EditTeacherStudent from '../Screens/Teacher/MyStudents/EditTeacherStudent';
import ViewTeacherStudents from '../Screens/Teacher/MyStudents/ViewTeacherStudents';
import StudentsTeacherNetwork from '../Screens/Teacher/Students Network';
import EditTeacherStudentNetwork from '../Screens/Teacher/Students Network/EditTeacherStudentNetwork';
import ViewTeacherStudentsNetwork from '../Screens/Teacher/Students Network/ViewTeacherStudentsNetwork';
import TeacherProfile from '../Screens/Teacher/Profile';
import TextTeacherCompetition from '../Screens/Teacher/MyclassRoom/TextCompetition';
import { useContext } from 'react';
import RoutingContext from '../helpers/Routing';
import TextComaptitions from '../Screens/NewsManagement/TextComaptitions';
import ModeratorReviewManagement from '../Screens/Moderator/ReviewManagement';
import ModeratorProfile from '../Screens/Moderator/profile';
import ContentWriterProfile from '../Screens/Content Writer/profile';
import AdminSettings from '../Screens/settings';
import { useDispatch } from 'react-redux';
import { getImageResolution } from '../Redux/actions/userActions';
import UserContext from '../helpers/user';
import LoginTeacherProfile from '../Screens/Teacher/Profile/LoginProfile';
import EditReview from '../Screens/Moderator/ReviewManagement/EditReview';
import ChangeProfile from '../Screens/Moderator/profile/ChangeProfile';
import ChangeContentProfile from '../Screens/Content Writer/profile/ChangeProfile';
import ViewTeacherText from '../Screens/Teacher/MyclassRoom/ViewText';
import ViewTeacherQuiz from '../Screens/Teacher/MyclassRoom/ViewQuiz';
import Promote from '../Screens/Teacher/Promote';


const Router = () => {

    const dispatch = useDispatch();

    const routesContext = useContext(RoutingContext);
    const userContext = useContext(UserContext)
    const navigate = useNavigate()

   


    const adminArray = [
        { path: '/', title: "User Management", element: <UserManagement />, index: true },
        { path: '/AddUserManagement', element: <AddUserManagement /> },
        { path: '/AddUserManagement/:id', element: <AddUserManagement /> },
        { path: '/ViewUserManagement/:id', element: <ViewUserManagement /> },
        { path: '/IndividualManagement', title: "Individual Management", element: <IndividualManagement /> },
        { path: '/EditStudents/:id', element: <EditStudents /> },
        { path: '/SchoolManagement', title: "School Management", element: <SchoolManagement /> },
        { path: '/AddSchoolManagement', element: <AddSchoolManagement /> },
        { path: '/AddSchoolManagement/:id', element: <AddSchoolManagement /> },
        { path: '/ViewSchoolManagement', element: <ViewSchoolManagement /> },
        { path: '/schoolUser', element: <SchoolUserIndex /> },
        { path: '/createSchoolTeacher', element: <CreateSchoolTeacher /> },
        { path: '/ViewSchoolTeacher', element: <ViewSchoolTeacher /> },
        { path: '/ViewSchoolStudent', element: <ViewSchoolStudent /> },
        { path: '/viewSchoolNetwork', element: <ViewSchoolNetwork /> },
        { path: '/editSchoolTeacher/:id', element: <EditSchoolTeacher /> },
        { path: '/editSchoolStudent', element: <EditSchoolStudent /> },
        { path: '/editSchoolNetwork', element: <EditSchoolNetwork /> },
        { path: '/OrderManagement', title: "Order Management", element: <OrderManagement /> },
        { path: '/editOrderManagement', element: <EditOrderManagement /> },
        { path: '/ReviewManagement', title: "Review Management", element: <ReviewManagement /> },
        { path: '/editIndividualReview', element: <EditIndividualReview /> },
        { path: '/editSchoolReview', element: <EditSchoolReview /> },
        { path: '/AdsManagement', title: "Ads Management", element: <AdsManagement /> },
        { path: '/AddAds', element: <AddAds /> },
        { path: '/editAds/:id', element: <AddAds /> },
        { path: '/viewAds', element: <ViewAds /> },
        { path: '/NewsManagement', title: "News Management", element: <NewsManagement /> },
        { path: '/AddNewsManagment', element: <AddNewsManagement /> },
        { path: '/quizecompetitons/:id', element: <QuizeCompatitons /> },
        { path: '/textcompetitions/:id', element: <TextComaptitions /> },
        { path: '/viewNews', element: <ViewNews /> },
        { path: '/newText', element: <NewText /> },
        { path: '/viewTest', element: <ViewTest /> },
        { path: '/viewquiz', element: <ViewQuiz /> },
        { path: '/viewonlyquestions', element: <ViewOnlyQuestions /> },
        { path: '/newQuiz', element: <NewQuiz /> },
        { path: '/questions', element: <Questions /> },
        { path: '/VocabularyManagement', title: "Vocabulary Management", element: <VocabularyManagement /> },
        { path: '/viewVocabulary', element: <ViewVocabulary /> },
        { path: '/viewStudentIndividual', element: <ViewStudentIndividual /> },
        { path: '/viewNetworkIndividual', element: <ViewNetworkIndividual /> },
        { path: '/editNetworkIndividual', element: <EditNetworkIndividual /> },
        { path: '/AddVocabulary', element: <AddVocabulary /> },
        { path: '/editVocabulary/:id', element: <AddVocabulary /> },
        { path: '/CategoryManagement', title: "Category Management", element: <CategoryManagement /> },
        { path: '/Profile', element: <Profile /> },
        { path: '/adminsettings', title: 'Settings', element: <AdminSettings /> },
    ]

    const TeacherArray = [
        { path: '/', title: "My Classroom", element: <MyClassRoom />, index: true },
        { path: '/addtextteacher', element: <AddText /> },
        { path: '/edittextteacher', element: <AddText /> },
        { path: '/addquizteacher', element: <AddQuiz /> },
        { path: '/editquizteacher', element: <AddQuiz /> },
        { path: '/questionteacher', element: <QuestionsTeacher /> },
        { path: '/myStudents', title: "My Students", element: <MyStudents /> },
        { path: '/editteacherStudent', element: <EditTeacherStudent /> },
        { path: '/viewteacherstudent', element: <ViewTeacherStudents /> },
        { path: '/studentteachernetwork', title: "Students Network", element: <StudentsTeacherNetwork /> },
        { path: '/editteacherstudentnetwork', element: <EditTeacherStudentNetwork /> },
        { path: '/viewteacherstudentnetwork', element: <ViewTeacherStudentsNetwork /> },
        { path: '/promoteTeacher',title:"Promote", element: <Promote /> },
        { path: '/teacherprofile', title: "Profile", element: <TeacherProfile /> },
        { path: '/textteachercompetition/:id', element: <TextTeacherCompetition /> },
        { path: '/Viewteachertext', element: <ViewTeacherText /> },
        { path: '/Viewteacherquiz', element: <ViewTeacherQuiz /> },
        { path: '/viewonlyquestions', element: <ViewOnlyQuestions /> },
   
        // { path: '/teacherprofilelogin', element: <LoginTeacherProfile /> },
    ]


    const ModeratorArray = [
        { path: '/', title: "Review Management", element: <ModeratorReviewManagement />, index: true },
        { path: '/moderatorprofile', title: "Profile", element: <ModeratorProfile /> },
        { path: '/editReview', element: <EditReview /> },
        { path: '/changeProfile', element: <ChangeProfile /> },
    ]


    const ContentWriterArray = [
        { path: '/', title: "News Management", element: <NewsManagement />, index: true },
        { path: '/AddNewsManagment', element: <AddNewsManagement /> },
        { path: '/quizecompetitons/:id', element: <QuizeCompatitons /> },
        { path: '/textcompetitions/:id', element: <TextComaptitions /> },
        { path: '/viewNews', element: <ViewNews /> },
        { path: '/newText', element: <NewText /> },
        { path: '/viewTest', element: <ViewTest /> },
        { path: '/viewquiz', element: <ViewQuiz /> },
        { path: '/viewonlyquestions', element: <ViewOnlyQuestions /> },
        { path: '/newQuiz', element: <NewQuiz /> },
        { path: '/questions', element: <Questions /> },
        { path: '/VocabularyManagement', title: "Vocabulary Management", element: <VocabularyManagement /> },
        { path: '/viewVocabulary', element: <ViewVocabulary /> },
        { path: '/viewStudentIndividual', element: <ViewStudentIndividual /> },
        { path: '/viewNetworkIndividual', element: <ViewNetworkIndividual /> },
        { path: '/editNetworkIndividual', element: <EditNetworkIndividual /> },
        { path: '/AddVocabulary', element: <AddVocabulary /> },
        { path: '/editVocabulary/:id', element: <AddVocabulary /> },
        { path: '/Profile', title: 'Profile', element: <ContentWriterProfile /> },
        { path: '/changeProfile', element: <ChangeContentProfile /> },


    ]




    useLayoutEffect(() => {
        switch (userContext?.user?.role) {
            case "admin":
                routesContext.setRouting(adminArray)
                break;

            case "teacher":
                routesContext.setRouting(TeacherArray)
                break;

            case "moderator":
                routesContext.setRouting(ModeratorArray)
                break;

            case "content-writer":
                routesContext.setRouting(ContentWriterArray)
                break;

            default:
                break;
        }
        dispatch(getImageResolution())
    }, [userContext])


    


    useEffect(() => {
        window.onpopstate = () => {
            if(!userContext?.user){
                navigate('/login')
            }
        }
    }, [])
    



    return (
        <Routes>

            <Route path="/teacherprofilelogin" element={<LoginTeacherProfile />} > </Route>
            <Route path="/Login" element={<LoginLayout />} >
                <Route index element={<Login />} />
            </Route>
            <Route path="/otp" element={<LoginLayout />} >
                <Route index element={<OTP />} />
            </Route>
            <Route path={`/`} element={<PrivateRoute><HomeLayout />
            </PrivateRoute>} errorElement={<ErrorPage />} >
                {routesContext.routing?.map((rou, i) => (
                    <Route key={i} index={rou?.index ? true : false} path={rou.path} element={rou.element} />
                ))}
            </Route>
        </Routes>
    )
}

export default Router