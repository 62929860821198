import { GET_PROFILE_FAIL, GET_PROFILE_SUCCESS, UPDATE_PROFILE_FAIL, UPDATE_PROFILE_SUCCESS } from "../constants/profileConstant";
import axios from "../../CustomAxios";
import { GET_ROLE_FAIL, RESET_ERROR } from "../constants/userConstant";
import { GET_ORDER_FAIL, GET_ORDER_SUCCESS, UPDATE_ORDER_FAIL, UPDATE_ORDER_SUCCESS } from "../constants/orderConstants";
export const orderReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                orderUpdated:null
            }
        case GET_ORDER_SUCCESS:
            return {
                ...state,
                orderList: action.payload
            }
        case GET_ORDER_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case UPDATE_ORDER_SUCCESS:
            return {
                ...state,
                orderUpdated: true,
                orderList: state.orderList?.map(order => order._id === action.payload._id ? action.payload : order),
            }
        case UPDATE_ORDER_FAIL:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }

}