import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import CustomSearch from '../../components/Widgets/CustomSearch';
import DataTable from '../../components/Widgets/CustomTable';
import Header from '../Header';
import AddIcon from '@mui/icons-material/Add';
import customAxios from '../../CustomAxios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Stack } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js';
import CustomButton from '../../components/Widgets/CustomButton';
import NewQuestions from './NewQuestions';
import ViewQuestion from './viewQuestion';


const Questions = () => {

    const { state } = useLocation();




    console.log({state})


    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [active, setActive] = useState(null)

    const [openv, setOpenv] = useState(false);
    const [open, setOpen] = useState(false)
    const [rowList, setRowlist] = useState(null);
    const [testList, setTestList] = useState([]);

    const handleDelete = async (id) => {
        setOpen(false)
        setLoading(true)
        await customAxios.delete(`admin/quiz/question/delete/${id}`)
            .then(async response => {
                toast.success("Questions Deleted Successfully");
                let result = testList.filter((res)=>res?._id !== id)
                setTestList([...result])
                setLoading(false)
                setActive(null)

            }).catch(async error => {
                setActive(null)
                setLoading(false)
                toast.error(error)
            })
    }

    const Confirm = async () => {
        if (testList?.length != parseInt(state?.totalquestions) * 2) {

            toast.warning(`Need ${parseInt(state?.totalquestions) * 2 - testList.length} More Questions to Publish`);
        } else {
            let val = {
                quiz_id: state?._id
            }
            setOpen(false)
            setLoading(true)
            await customAxios.post(`admin/quiz/publish`, val)
                .then(async response => {

                    // setTestList(response.data.data.questions)
                    navigate(-2)
                    setLoading(false)
                    setActive(null)

                }).catch(async error => {
                    setActive(null)
                    setLoading(false)
                    toast.error(error)
                })

        }


    }




    const OpenView = (data) => {
        setRowlist(data)
        setOpenv(true);
    }

    const EditView = (data) => {
        setRowlist(data)
        setOpen(true);
    }




    useEffect(() => {
        if (state) {
            setTestList(state?.questions)
        }
    }, [state])



    const columns = [
        {

            headerName: '#',
            width: 120,
            headerAlign: 'center',
            align: 'center',
            renderCell: (index) => index.api.getRowIndex(index.row._id) + 1
        },
        {
            field: 'question',
            headerName: 'Question',
            width: 300,
            editable: true,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'correct_answer',
            headerName: 'Correct Option',
            width: 200,
            editable: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <span>{row.options[row?.correct_answer]}</span>
            )
        },
        {
            field: 'Action',
            headerName: 'Action',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={1} direction={'row'}>
                    <RemoveRedEyeIcon
                        onClick={() => OpenView(row)}
                        style={{

                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />
                    {row?.status !== "active" && row?.test_type === "Competition" && <EmojiEventsIcon
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />}
                    <BorderColorTwoToneIcon
                        onClick={() => EditView(row)}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />
                    <DeleteOutlineTwoToneIcon
                        onClick={() => {
                            handleDelete(row?._id)
                        }}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />

                </Stack>
            )
        },
    ];

    return (
        <Box>
            <CustomBackHeader label={'Add Quiz'} back={() => navigate(-2) } />
            <Box px={5} py={2} >
                <CustomButton label={"Add New Question"} disable={testList?.length === parseInt(state?.totalquestions) * 2 ? true : false} onClick={() => setOpen(true)} />
                <Paper style={{
                    borderRadius: "20px",
                    marginTop: '10px'
                }}>

                    <DataTable
                        rows={testList}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
            </Box>

            <Box px={5} gap={1} display={'flex'} py={5}>
                <CustomButton label={'Previous'} onClick={() => navigate(-1)} />
                <CustomButton label={'Confirm'} colorText={'#29C617'} onClick={() => Confirm()} />
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {open && <NewQuestions open={open} handleClose={() => {
                setOpen(false)
                setRowlist(null)
            }} quizId={state?._id} setTestList={setTestList} res={rowList} setRowlist={setRowlist} />}
            {openv && <ViewQuestion open={openv} handleClose={() => {
                setRowlist(null)
                setOpenv(false)
            }} res={rowList} />}
        </Box>
    )
}

export default Questions