import { GET_REVIEW_FAIL, GET_REVIEW_SUCCESS, UPDATE_REVIEW_FAIL, UPDATE_REVIEW_SUCCESS } from "../constants/reviewConstants"
import { RESET_ERROR } from "../constants/userConstant"


export const reviewReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                reviewUpdated:null
            }
        case GET_REVIEW_SUCCESS:
            return {
                ...state,
                reviewList: action.payload
            }
        case GET_REVIEW_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case UPDATE_REVIEW_SUCCESS:
            return {
                ...state,
                reviewUpdated: true,
                reviewList: state.reviewList?.map(review => review._id === action.payload._id ? action.payload : review),
            }
        case UPDATE_REVIEW_FAIL:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }

}