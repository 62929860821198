import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import customAxios from '../../../../CustomAxios';
import { toast } from "react-toastify";
import banner from '../../../../Assets/images/homescreen.png';
import { Avatar, Box, CircularProgress, Stack, styled, Typography } from '@mui/material';
import logo from '../../../../Assets/images/Logo.png';
import CustomOtp from '../../../../components/Widgets/CustomOtp';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomLoginInput from '../../../../components/Widgets/CustomLoginInput';
import emailIcon from '../../../../Assets/images/email.png';
import phoneCall from '../../../../Assets/images/phoneCall.png';
import CustomButton from '../../../../components/Widgets/CustomButton';
const LoginTeacherProfile = () => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const [otp, setOTP] = useState(null);
    const [loading, setLoading] = useState(false)



    const BOX = styled(Box)({
        width: '100%',
        height: '100vh',
        backgroundImage: `url(${banner})`,
        alignItems: 'center',
        justifyContent: 'center',
        objectFit: 'contain',
        display: 'flex',
        flexDirection: 'column'

    })

    useEffect(() => {
        if (state) {
            otpGenerator()
        }
    }, [state])

    const schema = object().shape({

    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {

        }
    });

    const otpGenerator = async () => {
        setLoading(true)
        await customAxios.post(`auth/teacherotp`, state.mode === 'email' ?
            { email: state?.item?.email } :
            { mobile: state?.item?.phone })
            .then(async response => {
                toast.success('Otp send Successfully')
                setLoading(false);
                console.log("error")
            }).catch(async error => {
                toast.error(error.message )
                setLoading(false)
            })
    }


    const profileChange = async (data) => {
        setLoading(true)
        await customAxios.post(`teacher/profile/verify`,
            state.mode === 'email' ? {
                otp: localStorage.getItem('otp'),
                email: data?.email_mobile,
                id: state?.item?._id,
            } : {
                otp: localStorage.getItem('otp'),
                mobile: data?.email_mobile,
                id: state?.item?._id,
            })
            .then(async response => {
                navigate('/teacherprofile')
                setValue('email_mobile', '')
                toast.success(`Profile ${state?.mode} changed`)
                setLoading(false);
            }).catch(async error => {
                setValue('email_mobile', '')
                toast.error({ error })
                setLoading(false)
            })
    }



    return (
        <BOX>
            <Avatar src={logo} variant="square" sx={{ width: 250, height: 100 }} />
            <Typography fontFamily={'Quicksand'} letterSpacing={1} fontWeight={'bold'} py={2}>{state?.mode === 'email' ? `Change your ${state?.mode}` : `Change your ${state?.mode}`} </Typography>
            <Stack gap={5} alignItems={'center'}>
                <CustomOtp setOtp={setOTP} />
                <CustomLoginInput
                    icon={state?.mode === 'email' ? emailIcon : phoneCall}
                    control={control}
                    error={errors.email_mobile}
                    fieldName="email_mobile"
                    placeholder={`Enter your ${state?.mode}`}
                />
            </Stack>
            <Box py={5}>
                <CustomButton
                    onClick={handleSubmit(profileChange)}
                    label={loading ? <CircularProgress style={{ color: '#FF0053' }} size={20} /> : 'UPDATE'} />
            </Box>
        </BOX>
    )
}

export default LoginTeacherProfile