import { Box, Grid } from '@mui/material';
import React from 'react'
import { useLocation } from 'react-router-dom'
import CustomAvatar from '../../components/Widgets/CustomAvatar/index.js';
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js';
import CustomCardMedia from '../../components/Widgets/CustomCardMedia/index.js';
import CustomTypography from '../../components/Widgets/CustomTypography/index.js';
import { IMAGE_URL } from '../../config/constants';

const ViewVocabulary = () => {
    const location = useLocation();

    console.log(location.state.item)
    return (
        <Box>
            <CustomBackHeader label={`Vocabulary ID :#${location?.state?.item?.voc_id}`} />
            <Grid container spacing={3} px={5} mt={2}>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Grade'} label={location?.state?.item?.grade_details?.name} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Word'} label={location?.state?.item?.word} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Meaning'} h={100} label={location?.state?.item?.meaning} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Example'} h={100} label={location?.state?.item?.example} />
                </Grid>
                {location?.state?.item.file_type === 'image' &&
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomAvatar avatar={`${IMAGE_URL}${location?.state?.item?.file}`} />
                    </Grid>}
                {location?.state?.item.file_type === 'video' &&
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomCardMedia videoProp={`${IMAGE_URL}${location?.state?.item?.file}`} />
                    </Grid>}
            </Grid>
        </Box>
    )
}

export default ViewVocabulary