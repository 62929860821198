import { Box, CircularProgress, Grid, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from '../../../components/Widgets/CustomInput';
import CustomSelect from '../../../components/Widgets/CustomSelect';
import CustomIconButton from '../../../components/Widgets/CustomIconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import CustomBackHeader from '../../../components/Widgets/CustomBackarrowHeader.js';
const EditTeacherStudent = () => {
    const { id } = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, loader } = useSelector(state => state.auth);
    const [payment, setPayment] = useState(null);


    //mobile Validation
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const schema = object().shape({
        // name: yup.string().required('Name is Required'),
        // email: yup.string().email().required('Email is Required'),
        // mobile: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Mobile Number is Required'),
        // class:yup.string().required('Class is Required'),
        // payment_status:yup.string().required('Payment Status is Required')

    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({
        defaultValues: {
            id: id

        },
        resolver: yupResolver(schema)
    });

    const SubitForm = () => {

    }

    return (
        <Box>
            <CustomBackHeader label={'Edit Student'} />
            <Box py={2} px={5}>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.name}
                            fieldName="name"
                            fieldLabel="Name"
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.email}
                            fieldName="email"
                            fieldLabel="Email Address"
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            view={true}
                            control={control}
                            error={errors.mobile}
                            fieldName="mobile"
                            fieldLabel="Mobile Number"
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.class}
                            fieldName="class"
                            fieldLabel="Class"
                        />
                    </Grid>

                    <Grid item lg={3} md={4} xs={12}>
                        <CustomSelect
                            control={control}
                            error={errors.payment}
                            fieldName="payment"
                            fieldLabel="Payment Status"
                            background="#FFEFEC"
                            size="16px"
                            value={payment}
                            onChangeValue={(e) => {
                                setPayment(e.target.value)
                                setValue('payment_status', e.target.value)
                                setError('payment_status', '')
                            }}
                        >
                            <MenuItem value="" disabled >
                                <em>Payment Status</em>
                            </MenuItem>
                            {['Paid', 'Pending', 'Completed'].map((res, i) => (
                                <MenuItem value={res} key={i} >
                                    {res}
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </Grid>

                </Grid>
            </Box>
            <Box display={'flex'} justifyContent={'center'} py={10}>
                <CustomIconButton label={loading ? <CircularProgress style={{ color: '#FF0053' }} size={20} /> : 'Update'}
                    //  disabled={loading ? true : false}
                    Icon={RefreshIcon} onClick={handleSubmit(SubitForm)} />
            </Box>
        </Box>
    )
}

export default EditTeacherStudent