import { Box, Grid } from '@mui/material';
import React from 'react'
import { useLocation } from 'react-router-dom';
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js';
import CustomTypography from '../../components/Widgets/CustomTypography/index.js';

const ViewSchoolStudent = () => {
    const location = useLocation();
    return (
        <Box>
            <CustomBackHeader label={`View Student`} />
            <Grid container spacing={3} px={5} mt={2}>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Name'} label={location.state.item?.name} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Email Address'} label={location.state.item?.email} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Mobile Number'} label={location.state.item?.mobile} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Class'} label={location.state.item?.class} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Division'} label={location.state.item?.division} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'SMS Status'} label={location.state.item?.smsstatus} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Payment Status'} label={location.state.item?.payment_status} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    {/* <CustomTypography formlabel={'Class'} label={location.state.item?.classes?.[0]?.class} /> */}
                </Grid>

            </Grid>

        </Box>
    )
}

export default ViewSchoolStudent