import { Backdrop, Box, CircularProgress, Paper } from '@mui/material'
import React, { useEffect, useState, useTransition } from 'react'
import { useNavigate } from 'react-router-dom';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import CustomSearch from '../../components/Widgets/CustomSearch';
import DataTable from '../../components/Widgets/CustomTable';
import Header from '../Header';
import AddIcon from '@mui/icons-material/Add';
import CustomSwitch from '../../components/Widgets/CustomSwitch';
import { Stack } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { adsStatus, deleteAds, getAds } from '../../Redux/actions/adsActions';
import moment from 'moment';
import { toast } from "react-toastify";
import { RESET_ERROR } from '../../Redux/constants/userConstant';
import CustomDelete from '../../components/Widgets/CustomDelete';
const AdsManagement = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { loading, loader, } = useSelector(state => state.auth);
    const { error, adsList, adsDeleted, statusChanged } = useSelector(state => state.ads);

    const [dataRes, setDataRes] = useState([]);
    const [pending, startTransition] = useTransition();
    const [iD, setID] = useState(null);
    const [openD, setOpenD] = useState(false);

    const handleOpenD = () => setOpenD(true);
    const handleCloseD = () => setOpenD(false);





    const columns = [
        {
            field: 'ad_id',
            headerName: 'Add ID',
            width: 150,
            valueGetter: (params) => (`#${params.row?.ad_id}`),
            headerAlign: 'center',
            align: 'center',

        },
        {

            headerName: 'Created Date',
            type: 'date',
             width: 200,
            valueGetter: (params) => (moment(params.row.created_at).format('DD/MM/YYYY')),
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'heading',
            headerName: 'Ad Heading',
             width: 200,
            headerAlign: 'center',
            align: 'center',


        },
        {
            field: 'country',
            headerName: 'Country',
             width: 200,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'createdOns',
            headerName: 'Expiry Date',
            valueGetter: (params) => (moment(params.row.expiry_date).format('DD/MM/YYYY')),
            headerAlign: 'center',
            align: 'center',
            width: 200,

        },

        {
            field: 'Status',
            headerName: 'Status',
             width: 180,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <Box>

                    <CustomSwitch
                        checked={row?.status === "active" ? true : false}
                        onClick={(e) => ChangeStatus(e.target.checked, row?._id)} />
                </Box>
            )
        },
        {
            field: 'Action',
            headerName: 'Action',
            width:250,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={1} direction={'row'}>
                    <RemoveRedEyeIcon
                        onClick={() => navigate(`/viewAds`, {
                            state: {
                                item: row
                            }
                        })}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }} />
                    <BorderColorTwoToneIcon
                        onClick={() => navigate(`/editAds/${row?._id}`)}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />
                    <DeleteOutlineTwoToneIcon
                        onClick={() => handleDelete(row?._id)}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }} />

                </Stack>
            )

        },

    ];


    const handleDelete = (id) => {
        setID(id);
        handleOpenD();

    }



    const ChangeStatus = (checked, row) => {
        let status = checked === true ? 'active' : 'inactive'
        let val = {
            id: row,
            status: status
        }
        dispatch(adsStatus(val))
    }


    const searchAds = (value) => {
        let school = adsList?.filter(ads => ads?.heading.toString().toLowerCase().includes(value.toLowerCase()) ||
            `#${ads?.ad_id}`.toString().toLowerCase().includes(value.toLowerCase())
        )
        startTransition(() => {
            setDataRes(school)
        })
    }


    useEffect(() => {

        if (adsDeleted) {
            toast.success(`Deleted Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            handleCloseD();

        }
        if (statusChanged) {
            toast.success(`Status Changed Successfully`);
            dispatch({
                type: RESET_ERROR
            })
        }

        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, adsDeleted, statusChanged])

    useEffect(() => {
        if (adsList) {
            setDataRes(adsList)
        }
    }, [adsList])


    useEffect(() => {
        dispatch(getAds())
    }, [])



    return (
        <Box >
            <Header label={'Ads Management'} />
            <Box px={2} py={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <CustomIconButton onClick={() => navigate('/AddAds')} label={'Add'} Icon={AddIcon} />
                <CustomSearch placeholder={'Ad ID, Ad Heading'} onSearch={(value) => searchAds(value)} />
            </Box>
            <Box px={2}>
                <Paper style={{
                    borderRadius: "20px"
                }}>
                    <DataTable
                        rows={dataRes ? dataRes : []}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {openD && <CustomDelete open={handleOpenD} handleClose={handleCloseD} mode={'Ads'} id={iD} />}
        </Box>
    )
}

export default AdsManagement