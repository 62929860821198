import React, { useState, useEffect } from 'react'
import { Avatar, Box, Stack, styled, Button, Typography, Grid, CircularProgress, Backdrop } from '@mui/material';
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js';
import CustomInput from '../../components/Widgets/CustomInput/index.js';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomSelect from '../../components/Widgets/CustomSelect/index.js';
import MenuItem from '@mui/material/MenuItem';
import BackupIcon from '@mui/icons-material/Backup';
import CustomIconButton from '../../components/Widgets/CustomIconButton/index.js';
import AddIcon from '@mui/icons-material/Add';
import CustomSwitch from '../../components/Widgets/CustomSwitch/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { createSchool, editSchool, singleSchool } from '../../Redux/actions/schoolActions.js';
import { toast } from "react-toastify";
import { RESET_ERROR } from '../../Redux/constants/userConstant.js';
import CustomSpinner from '../../components/Widgets/CustomSpinner/index.js';
const AddSchoolManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, loader } = useSelector(state => state.auth);
    const { schoolCreated, error, singleSchoolList, schoolEdited } = useSelector(state => state.school);
    const [selectCountry, setSelectCountry] = useState(null)
    const[num,setNum]=useState(10);

    const [network, setNetwork] = useState(false)


    const { id } = useParams();
    //mobile Validation
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const schema = object().shape({
        name: yup.string().max(60,"Maximum character exceed").matches(
            /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
            'Name can only contain Latin letters.'
          ).required('Name is Required'),
        email: yup.string().max(30,"Maximum character exceed").email().required('Email is Required'),
        uae_val:yup.boolean(),
        phone: yup.string()
        .required('Number is Required')
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(num, `Must be exactly ${num} digits`)
        .max(num,` Must be exactly ${num} digits`),
        address: yup.string().max(100,"Maximum character exceed").required('Address is Required'),
        country: yup.string().required('Country is Required'),
        fees: yup.number( 'Fees must be number').required('Fees is Required').typeError('Fees must be number'),

    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({
        defaultValues: {
            network: false,
            uae_val:false
        },

        resolver: yupResolver(schema)
    });

    const SubmitForm = (data) => {
        if (id && singleSchoolList) {
            let val = {
                "name": data?.name,
                "email": data?.email,
                "phone": data?.phone,
                "country": data?.country,
                "address": data?.address,
                "fees": data?.fees,
                "network": data?.network,
                "id": id
            }
            dispatch(editSchool(val))
        } else {
            dispatch(createSchool(data))
        }
    }



    const ChangeStatus = (checked) => {
        setNetwork(checked)
        let status = checked === true ? true : false
        setValue('network', status)

    }

    useEffect(() => {
        if (id && singleSchoolList) {
            setValue('name', singleSchoolList?.name)
            setValue('email', singleSchoolList?.email)
            setValue('address', singleSchoolList?.address)
            setValue('fees', singleSchoolList?.fees)
            setValue('phone', singleSchoolList?.phone)
            setValue('country', singleSchoolList?.country)
            setNetwork(singleSchoolList?.network)
            //setValue('network', singleSchoolList?.network)
            setSelectCountry(singleSchoolList?.country)
            setNum( singleSchoolList?.country === 'UAE' ? 13 : 10)
        }

    }, [singleSchoolList])



    useEffect(() => {
        if (schoolCreated) {
            toast.success(`School Created Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            navigate(-1)
        }
        if (schoolEdited) {
            toast.success(`School Edited Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            navigate(-1)
        }
        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, schoolCreated, schoolEdited])


    useEffect(() => {
        if (id) {
            dispatch(singleSchool(id))
        }
    }, [id])


    return (
        <Box>
            <CustomBackHeader label={!id ? 'Add School' : 'Edit School'} />
            <Box px={5} mt={5}>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.name}
                            fieldName="name"
                            fieldLabel="School Name"
                        />
                    </Grid>
                    <Grid item lg={6} md={5} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.address}
                            fieldName="address"
                            fieldLabel="Address"
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomSelect
                            control={control}
                            error={errors.country}
                            fieldName="country"
                            fieldLabel="Country"
                            background="#FFEFEC"
                            size="16px"
                            value={selectCountry}
                            onChangeValue={(e) => {
                                setSelectCountry(e.target.value)
                                setNum(e.target.value === 'UAE' ? 13 : 10)
                                setValue('country', e.target.value)
                                setError('country', '')
                            }}
                        >
                            <MenuItem value="" disabled >
                                <em>Select Country</em>
                            </MenuItem>
                            {[{ name: 'UAE' }, { name: 'India' }].map((res) => (
                                <MenuItem value={res?.name} >
                                    {res?.name}
                                </MenuItem>
                            ))}

                        </CustomSelect>
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.email}
                            fieldName="email"
                            fieldLabel="Email Address"
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.phone}
                            fieldName="phone"
                            fieldLabel="Mobile Number"
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            type={'number'}
                            control={control}
                            error={errors.fees}
                            fieldName="fees"
                            fieldLabel="Fees"
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <Typography fontFamily={'Quicksand'} fontWeight={'700'} px={'3px'} mb={'2px'}
                            sx={{
                                fontSize: {
                                    lg: 16,
                                    md: 14,
                                    sm: 12,
                                    xs: 11,
                                },
                            }}
                        >{`School Network`}</Typography>
                        <Stack justifyContent={'center'}>
                            <CustomSwitch
                                checked={network}
                                onClick={(e) => ChangeStatus(e.target.checked)} />
                        </Stack>

                    </Grid>
                </Grid>
            </Box>
            <Box display={'flex'} justifyContent={'center'} py={10}>
                <CustomIconButton
                    onClick={handleSubmit(SubmitForm)}
                    label={loading ? <CircularProgress style={{ color: '#FF0053' }} size={20} /> : !id ? 'Add' : 'Update'}
                    Icon={AddIcon}
                    disabled={loading ? true : false}
                />
            </Box>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                //onClick={handleClose}
                >
                <CircularProgress color="inherit" />
            </Backdrop>

        </Box>
    )
}

export default AddSchoolManagement