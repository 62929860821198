import React, { useState, useEffect, useContext } from 'react'
import { Avatar, Box, Stack, styled, Button, Typography, Grid } from '@mui/material';
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js';
import CustomInput from '../../components/Widgets/CustomInput/index.js';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomSelect from '../../components/Widgets/CustomSelect/index.js';
import MenuItem from '@mui/material/MenuItem';
import BackupIcon from '@mui/icons-material/Backup';
import CustomIconButton from '../../components/Widgets/CustomIconButton/index.js';
import AddIcon from '@mui/icons-material/Add';
import CustomTextArea from '../../components/Widgets/CustomTextArea/index.js';
import CustomTextEditor from '../../components/Widgets/CustomTextEditor.js/index.js';
import CustomImageUploader from '../../components/Widgets/CustomImageUploder/index.js';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux'
import { LOADING } from '../../Redux/constants/userConstant.js';
import customAxios from '../../CustomAxios.js';
import { isEmpty } from 'lodash'
import UserContext from '../../helpers/user/index.js';
import { useLocation } from 'react-router-dom';
import { IMAGE_URL } from '../../config/constants.js';
import moment from 'moment';



const ViewTest = ({route}) => {

    const { state: data } = useLocation()


    console.log({state:data})

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    return (
        <Box >
            <CustomBackHeader label={`Text ID: ${data?.test_id}`} />
            <Box px={5} mt={5}>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput 
                            view
                            defaultValue={data?.grade_details?.name}
                            fieldName="grade"
                            fieldLabel={"Grade"}
                            control={control}
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput 
                            view
                            defaultValue={data?.test_type}
                            fieldName="Category"
                            fieldLabel={"Type"}
                            control={control}
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput 
                            view
                            defaultValue={data?.country}
                            fieldName="Country"
                            fieldLabel={"country"}
                            control={control}
                        />
                    </Grid>
                    <Grid item lg={6} md={8} xs={12}>
                        <CustomTextArea
                            control={control}
                            fieldName="postTitle"
                            multiline={true}
                            height={90}
                            row={10}
                            fieldLabel="Title"
                            background={'#FFEFEC'}
                            readOnly
                            defaultValue={data?.title}

                        />
                    </Grid>
                    <Grid item lg={6} md={8} xs={12}>
                        <CustomTextArea
                            control={control}
                            fieldName="postContent"
                            multiline={true}
                            height={110}
                            row={10}
                            fieldLabel="Task Content"
                            background={'#FFEFEC'}
                            readOnly
                            defaultValue={data?.content}
                        />

                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                       
                        <CustomImageUploader
                            fieldLabel='Post Image/Video'
                            accept={'image/*,video/*'}
                            control={control}
                            vedeoPreview={`${IMAGE_URL}${data?.file}`}
                            format={data?.file_type ? data?.file_type : 'image'}
                            previewEditimage={`${IMAGE_URL}${data?.file}`}
                            error={errors.image_video}
                            fieldName='image_video'
                            type={"file"}
                            height="120px"
                            background="#ffff"
                            id="contained-button-file"
                            view
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            fieldName="contentLink"
                            fieldLabel="Marks"
                            defaultValue={data?.max_mark}
                        />
                    </Grid> 
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            fieldName="contentLink"
                            fieldLabel="Expiry Date"
                            defaultValue={moment(data?.expiry_date).format('DD-MM-YYYY')}
                        />
                    </Grid> 
                </Grid>
            </Box>
        </Box>
    )
}

export default ViewTest