import { Backdrop, Box, CircularProgress, Paper } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import CustomSearch from '../../components/Widgets/CustomSearch';
import DataTable from '../../components/Widgets/CustomTable';
import Header from '../Header';
import AddIcon from '@mui/icons-material/Add';
import CustomSwitch from '../../components/Widgets/CustomSwitch';
import { Stack } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
const School = ({ res }) => {


    console.log({res},'inschool')
    const navigate = useNavigate()
    const { loading, loader } = useSelector(state => state.auth);


    
    const columnstudent = [
        {
            field:'postid',
            headerName: 'Post ID',
            headerAlign: 'center',
            align: 'center',
            width: 100,
            valueGetter: (params) => (`#${params.row?.post_id}`)


        },
        {
            field: 'reportedDate',
            headerName: 'Reported Date',
            headerAlign: 'center',
            align: 'center',
            width: 150,
            valueGetter: (params) => (moment(params.row?.reported_details?.created_at).format('DD/MM/YYYY'))


        },
        {
            field: 'schoolname',
            headerName: 'School Name',
 
            valueGetter: (params) => (params.row?.creator_details?.schoolName),
            headerAlign: 'center',
            align: 'center',
            width: 200,
        },
        {
            field: 'studenname',
            headerName: 'Student Name',
            headerAlign: 'center',
            align: 'center',
            width: 180,
            
            valueGetter: (params) => (params.row?.creator_details?.name),

        },
        {
            field: 'Class and Division',
            headerName: 'Class and Division',
            headerAlign: 'center',
            align: 'center',
            width: 150,
            valueGetter: (params) => (`${params.row?.creator_details?.class} - ${params.row?.creator_details?.division}` ),


        },
        {
            field: 'EmailAddress',
            headerName: 'Reviewed By',
            headerAlign: 'center',
            align: 'center',
            width: 150,

        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            width: 150,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <span style={{
                    color: row?.status === 'Rejected' ? '#DC1616' : row?.status === 'Pending' ? "#EAAD04" :
                        row?.status === 'Approved' ? '#21AA12' : row?.status === 'Reported' ? '#DC1616' : '', cursor: 'pointer'
                }}>{row?.status}</span>
            )
        },
        {
            field: 'Action',
            headerName: 'Action',
            flex: 1,
            width: 150,
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={1} direction={'row'}>
                   {row?.status !== 'Rejected' &&
                    <BorderColorTwoToneIcon
                        onClick={() => navigate(`/editSchoolReview`, {
                            state: {
                                item: row,
                                mode: 'edit'
                            }
                        })}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />}
                    <RemoveRedEyeIcon
                        onClick={() => navigate(`/editSchoolReview`, {
                            state: {
                                item: row, 
                                mode: 'view'
                            }
                        })}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />  
                </Stack>
            )

        },

    ];


    return (
        <Box >
            <Box px={5} py={2}>
                <Paper style={{
                    borderRadius: "20px"
                }}>
                    <DataTable
                        rows={res ? res : []}
                        columns={columnstudent}
                        id={"_id"}
                    />
                </Paper>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default School