import logo from './logo.svg';
import './App.css';

import { Box, createTheme, ThemeProvider } from '@mui/material';
import LoginLayout from './components/Common/Layouts/LoginLayout';
import { BrowserRouter, Routes, useNavigate } from 'react-router-dom';
import Router from './Router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
function App() {

    
    


    return (

        <BrowserRouter>
            <Router />
            <ToastContainer />
        </BrowserRouter>

    );
}

export default App;
