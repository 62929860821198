import { Backdrop, Box, CircularProgress, Paper, Stack, styled } from '@mui/material'
import React, { useState, useEffect, useTransition } from 'react'
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js'
import moment from 'moment/moment.js';
import { toast } from 'react-toastify';
import CustomWinnerIcon from './QuizeComponents/CustomWinnerIcon.js';
import CustomApproveRejectButton from '../../components/Widgets/CustomApproveRejectButton';
import { useLocation, useParams } from 'react-router-dom';
import CustomSearch from '../../components/Widgets/CustomSearch/index.js';
import silver from '../../Assets/images/medalsilver.png';
import gold from '../../Assets/images/medalgold.png';
import bromanze from '../../Assets/images/medalbronze.png';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DataTable from '../../components/Widgets/CustomTable/index.js';
import customAxios from '../../CustomAxios.js';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import AssignTextCompetion from './AsignTextCompetion.js';
const TextComaptitions = () => {

    const { id } = useParams();
    const {state} = useLocation();

    console.log({state})

    const [loading, setLoading] = useState(false);
    const [rowRes, setRowres] = useState([]);
    const [open, setOpen] = useState(false);
    const [setSerachList, setSearchList] = useState([])
    const [pending, startTransition] = useTransition();
    const [dataModal, setDataModal] = useState([]);
    const [varify, setVarify] = useState(false)
    const [badgeCount, setbadgeCount] = useState('');
    const [diablePublish, setdisablePublish] = useState(false);
    const [mode, setMode] = useState(null);


    console.log({diablePublish})



    //style...........................
    const BUTTONBOX = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {

        }
    }))

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);





    const columns = [
        {
            field: 'Post ID',
            headerName: 'Post ID',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (`#${params.row.test_attendee_id}`),

        },
        {
            field: 'created_at',
            headerName: 'Submitted Date',
            width: 150,
            valueGetter: (params) => (moment(params.row.created_at).format('DD/MM/YYYY')),

            headerAlign: 'center',
            align: 'center',

        },
        {

            headerName: 'Posted By',
            width: 130,
            valueGetter: (params) => (params.row.student_details?.name),
            headerAlign: 'center',
            align: 'center',
        },

        {
            field: 'Class & Division',
            headerName: 'Class & Division',
            width: 160,
            editable: true,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (params.row.student_details?.class),
        },
        {
            field: 'School',
            headerName: 'School',
            width: 160,
            editable: true,
            headerAlign: 'center',
            align: 'center',


        },

        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            width: 130,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                row.status === 'Unchecked' ?
                    <span style={{ color: '#0008FF', cursor: 'pointer' }}>{row?.status}</span> : <span style={{ color: '#21AA12', cursor: 'pointer' }} >{row?.status}</span>
            )
        },
        {
            field: 'Action',
            headerName: 'Action',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={1} direction={'row'}>
                    <RemoveRedEyeIcon
                        onClick={() => {
                            handleOpen()
                            setMode('View')
                            setDataModal(row)
                        }}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />
                 
                        <BorderColorTwoToneIcon
                            onClick={() => {
                                setDataModal(row)
                                setOpen(true)
                            }}
                            style={{
                                color: '#FF0053',
                                cursor: 'pointer'
                            }}
                        />
                </Stack>
            )
        },
    ];

    const searchCompetions = (value) => {
        let competitiions = setSerachList?.filter(com => com?.student_details?.name.toString().toLowerCase().includes(value.toLowerCase()) ||
            `#${com?.test_attendee_id}`.toString().toLowerCase().includes(value.toLowerCase())
        )
        startTransition(() => {
            setRowres(competitiions)
        })
    }

    useEffect(() => {
        getTextCompetion(id);
        getbadgeCount(id);
    }, [id])

    const getTextCompetion = async (id) => {
        setLoading(true)
        await customAxios.get(`admin/test/testattendees/${id}`)
            .then(async response => {
                setRowres(response.data.data);
                setSearchList(response.data.data);
                setLoading(false);
            }).catch(async error => {
                setRowres([]);
                setLoading(false);
                toast.error(error);
            })
    }


    const getbadgeCount = async (id) => {
        setLoading(true)
        await customAxios.get(`admin/test/count/${id}`)
            .then(async response => {
                setbadgeCount(response.data.data)
                setLoading(false)

            }).catch(async error => {
                setRowres([])
                setLoading(false)
                toast.error(error)
            })
    }


    const publishQuize = async (id) => {
        let val = {
            id: id
        }
        setLoading(true)
        await customAxios.post(`admin/test/publish`, val)
            .then(async response => {
                toast.success('Published  Successfully')
                setLoading(false)
            }).catch(async error => {
                setLoading(false)
                toast.error(error)
            })
    }



    //this is for checking the status validation.................................  

    useEffect(() => {
        if (rowRes) {
            if(moment(state?.expiry_date, "YYYY-MM-DD") > moment(moment().format("YYYY-MM-DD"))){
                setVarify(true)
            }
            else if(rowRes?.length === 0){
                setVarify(true)
            }
            else{
                setVarify(false)
            }
            // let find = rowRes.filter(res => res.status === 'Unchecked');
            // if(find.length > 0){
            //     setVarify(true)
            // }
            // else if(state?.status === "Completed"){
            //     setVarify(true)
            // }
            // else {
            //     setVarify(false)
            // }
          
            // let publish = rowRes.map(res => res.test_details?.status).includes('Completed');
            // setdisablePublish(publish)
        }

    }, [rowRes])


    return (
        <Box>
            <CustomBackHeader label={`Post ID :#${state?.test_id} `} />
            <Box px={5} display={'flex'} justifyContent={state?.test_type === 'Competition' ? 'space-between' : 'flex-end'} gap={2} >
                {state?.test_type === 'Competition' &&
                <BUTTONBOX
                    display={'flex'} sx={{
                        gap: { xl: 1, md: 1, xs: 1 },
                        flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' }
                    }} >
                    <CustomWinnerIcon img={gold} value={badgeCount?.goldCount} />
                    <CustomWinnerIcon img={silver} value={badgeCount?.silverCount} />
                    <CustomWinnerIcon img={bromanze} value={badgeCount?.bronzeCount} />
                </BUTTONBOX>}
                <Box display={'flex'} sx={{
                    gap: { xl: 2, md: 4, xs: 4 },
                    flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' }
                }}  >

                    <CustomApproveRejectButton disabled={varify} onClick={() => publishQuize(id)} label={'Publish'} shadow={'#DBFADA'} color={'#29C617'} background={'#F3FFF9'} />
                    <CustomSearch placeholder={'Search'} lg={'100%'} md={'100%'} sm={'100%'} xs={'100%'}
                        onSearch={(value) => searchCompetions(value)} />
                </Box>
            </Box>
            <Box px={5} py={2}>
                <Paper style={{
                    borderRadius: "20px"
                }}>
                    <DataTable
                        rows={rowRes}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {open && <AssignTextCompetion mode={mode} open={open} type={state?.test_type} handleClose={() => {
                handleClose()
                setMode(null)
            }

            } res={dataModal} getTextCompetion={getTextCompetion} getbadgeCount={getbadgeCount} idd={id} />}
        </Box>
    )
}

export default TextComaptitions