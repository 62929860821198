import { Backdrop, Box, CircularProgress, Paper } from '@mui/material'
import React, { useEffect, useState, useTransition } from 'react'
import { useNavigate } from 'react-router-dom';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import CustomSearch from '../../components/Widgets/CustomSearch';
import DataTable from '../../components/Widgets/CustomTable';
import Header from '../Header';
import AddIcon from '@mui/icons-material/Add';
import { Stack } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import CustomSwitch from '../../components/Widgets/CustomSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { getOrder } from '../../Redux/actions/orderActions';
import moment from 'moment/moment';

const OrderManagement = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();




    const { error, orderList, orderUpdated } = useSelector(state => state.order);
    const { loading, loader } = useSelector(state => state.auth);

    const [orderRes, setOrderRes] = useState([]);
    const [pending, startTransition] = useTransition();



    const columns = [
        {
            field: 'dateTime',
            headerName: 'Date & Time',
            width: 160,
            valueGetter: (params) => (moment(params.row.created_at).format('DD/MM/YYYY h:mm A')),
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'orderId',
            headerName: 'Order ID',
            type: 'date',
            width: 130,
            valueGetter: (params) => (`#${params.row?.order_id}`),
            headerAlign: 'center',
            align: 'center',


        },
        {
            field: 'name',
            headerName: 'Name',
            width: 130,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (`${params.row?.userdetails?.name}`),
        },
        {
            field: 'mobile',
            headerName: 'Mobile Number',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (`${params.row?.userdetails?.mobile}`),
        },
        {
            field: 'payment_status',
            headerName: 'Payment Status',
            sortable: false,
            width: 150,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <span style={{
                    color: row?.payment_status === 'Paid' ? '#21AA12' : row?.payment_status === 'Pending' ? "#DD8E16" :
                        row?.payment_status === 'Completed' ? '#21AA12' : row?.payment_status === 'Reported' ? '#DC1616' : '', cursor: 'pointer'
                }}>{row?.payment_status}</span>
            )
        },
        {
            field: 'userdetails',
            headerName: 'Plan',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (params.row?.userdetails?.plan_details?.planduration),
        },
        {
            field: 'Expiry_Date',
            headerName: 'Expiry Date',
            width: 180,
            valueGetter: (params) => (moment(params.row.expiry,"YYYY-MM-DD").format('DD/MM/YYYY')),
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'Action',
            headerName: 'Action',
            width: 160,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} >
                    <BorderColorTwoToneIcon
                        onClick={() => navigate(`/editOrderManagement`, {
                            state: {
                                item: row
                            }
                        })}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />

                </Stack>
            )
        },

    ];



    const searchOrder = (value) => {
        let orders = orderList?.filter(order => order?.userdetails?.name.toString().toLowerCase().includes(value.toLowerCase())
            || `#${order?.order_id}`.toString().toLowerCase().includes(value.toLowerCase())

        )
        startTransition(() => {
            setOrderRes(orders)
        })
    }


    useEffect(() => {
        if (orderList) {
            setOrderRes(orderList)
        }
    }, [orderList])



    useEffect(() => {
        dispatch(getOrder())
    }, [])



    return (
        <Box height={'100%'} >
            <Header label={'Order Management'} />
            <Box px={2} py={3} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                <CustomSearch placeholder={'Order ID,Name'} onSearch={(value) => searchOrder(value)} />
            </Box>
            <Box px={2}>
                <Paper style={{
                    borderRadius: "20px"
                }}>
                    <DataTable
                        rows={orderRes}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default OrderManagement