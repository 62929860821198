import React from 'react'
import {  Box,  Typography, DialogTitle, Dialog, DialogContent } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../components/Widgets/CustomTable';
import moment from 'moment/moment';
const CreateLogModal = ({ open, handleClose, res, view }) => {

    const { planLogList } = useSelector(state => state.individual);
  

    const columns = [
        {
            headerName: 'Date',
            flex: 1,
            valueGetter: (params) => (moment(params?.row?.created_at).format('DD-MM-YYYY'))
        },
        {
            field: 'monthly',
            headerName: 'Monthly',
            type: 'date',
            flex: 1,
         

        },
        {
            field: 'quarterly',
            headerName: 'Quarterly',
            flex: 1,
       

        },
        {
            field: 'halfyearly',
            headerName: 'Half Yearly',
            flex: 1,
          

        },
      
        {
            field: 'yearly',
            headerName: 'Yearly',
            flex: 1,

        },
    ];

   
    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={"md"}
            onClose={handleClose}>
            <DialogTitle>
                <Box display={'flex'} py={1} justifyContent={'space-between'} >
                <Typography sx={{
                        fontFamily: 'Quicksand',
                        fontWeight: 'bold',
                        fontSize: 22,
                        color: '#FF0053',
                        letterSpacing: 1
                    }}></Typography>
                    <Typography sx={{
                        fontFamily: 'Quicksand',
                        fontWeight: 'bold',
                        fontSize: 22,
                        color: '#FF0053',
                        letterSpacing: 1
                    }}>{'Price Logs'}</Typography>
                    <HighlightOffIcon style={{ fontSize: '30px', cursor: 'pointer' }} onClick={() => handleClose()} />
                </Box>
            </DialogTitle>
            <DialogContent>
               
            <Box px={1} py={2}>
               
                    <DataTable
                        bg={'#fff'}
                        rows={planLogList  ? planLogList  : [] }
                        columns={columns}
                        id={"_id"}
                    />
                
            </Box>
                

            </DialogContent>
        </Dialog>
    )
}

export default CreateLogModal