import { Avatar, Backdrop, Box, CircularProgress, Grid, IconButton, styled, Typography } from '@mui/material';
import React,{ useContext, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CustomBackHeader from '../../../components/Widgets/CustomBackarrowHeader.js';
import CustomIconButton from '../../../components/Widgets/CustomIconButton/index.js';
import CustomInput from '../../../components/Widgets/CustomInput/index.js';
import UserContext from '../../../helpers/user';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import RefreshIcon from '@mui/icons-material/Refresh';
import { LOADING } from '../../../Redux/constants/userConstant.js';
import customAxios from '../../../CustomAxios.js';
import { toast } from 'react-toastify';
import { IMAGE_URL } from '../../../config/constants.js';
import CustomInputEndIcon from '../../../components/Widgets/CustomInputEndICon/index.js';
import { useNavigate } from 'react-router-dom';

const ModeratorProfile = () => {

    const user = useContext(UserContext);
    const dispatch = useDispatch()
    const [Image, setImage] = useState(null)
    const [email, setEmail] = useState(false)
    const [mobile, setMobile] = useState(false)
    const { loading, loader } = useSelector(state => state.auth);

    const navigate = useNavigate()

    
    //mobile Validation
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const schema = object().shape({
        name: yup.string().required('Name is required'),
        email: yup.string().email().required('Email is required'),
        address: yup.string().required('Adress is Required'),
        phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Mobile Number is Required'),
    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({

        resolver: yupResolver(schema)
    });

    //style....................................................................

    const BOX = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    }))

    const IMGBOX = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '10px'
        }
    }))

    useEffect(() => {
        if(Image){
            updateProfileImage()
        }
    }, [Image])

    const updateProfileImage = async() => {
        let formData = new FormData();
        formData.append("id",user?.user?._id);
        formData.append("image", Image);
        dispatch({
            type: LOADING,
            payload: true
        })

        await customAxios.post(`moderator/profileimage`, formData)
        .then(async response => {

            let data = response.data.data;
            user?.setUser(data)
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            toast.error(error)

            dispatch({
                type: LOADING,
                payload: false
            })
        })

    }
    


    useEffect(() => {
        if(user?.user){
            setValue("name", user?.user?.name)
            setValue("email", user?.user?.email)
            setValue("phone", user?.user?.mobile)
        }
        
    }, [user?.user])


    const otpGenerator = async (mode) => {
        await customAxios.post(`auth/contentotp`, mode === 'email' ?
            { email: user?.user?.email } :
            { mobile: user?.user.mobile })
            .then(async response => {
                toast.success('Otp send Successfully')
                navigate('/changeProfile', {
                    state: {
                        mode: mode,
                        item: user?.user
                    }
                })
            }).catch(async error => {
                toast.error(error.message )
            })
    }
    


  return (
    <Box>
    <CustomBackHeader label={'User Profile'} hidden={true} />
    <BOX display={"flex"} flex={1} px={5} mt={10} gap={5}>
        <Box flex={2}>
            <IMGBOX >
                <Box position={"relative"} width={"200px"} height={"200px"} >
                    <Avatar src={Image ? URL.createObjectURL(Image): `${IMAGE_URL}${user?.user?.image}`} style={{ width: "200px", height: "200px", objectFit: "contain" ,border: '5px solid #FF0053'}}>
                    </Avatar>
                    <Box display={"flex"} position={"absolute"} zIndex={"100"} justifyContent={"center"} bottom={"0px"}
                        width={"200px"}  >
                        <IconButton color="primary" aria-label="upload picture" component="label" className='iconbutton' >
                            <input hidden name="myImage" type="file" onChange={(e) => setImage(e.target.files[0])} />
                            <PhotoCamera className='photoicon'  sx={{
                                color:'#FF0053',
                                 
                            }}/>
                        </IconButton>
                    </Box>
                </Box>
            </IMGBOX>
        </Box>
        <Box flex={8}>
            <Box py={1}>
                <Typography sx={{
                    fontFamily: 'Quicksand',
                    fontWeight: '700',
                    letterSpacing: '1px',
                    pl: '5px',
                    color: '#FF0053',
                    fontSize: {
                        lg: 25,
                        md: 20,
                        sm: 16,
                        xs: 14,
                    }
                }}>Basic Details</Typography>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6} xl={4}>
                    <CustomInput
                        control={control}
                        error={errors.name}
                        fieldName="name"
                        fieldLabel="Name"
                        view={true}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={4}>
                    <CustomInputEndIcon
                        onclick={() => otpGenerator('email')}
                        view={true}
                        control={control}
                        error={errors.email}
                        fieldName="email"
                        fieldLabel="Email Address"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={4}>
                    <CustomInputEndIcon
                        onclick={() => otpGenerator('phone')}
                        view={true}
                        control={control}
                        error={errors.phone}
                        fieldName="phone"
                        fieldLabel="Mobile Number"
                    />
                </Grid>
             
            </Grid>
        </Box>
    </BOX>
    {/* <Box display={'flex'} justifyContent={'center'} py={10}>
        <CustomIconButton label={loading ? <CircularProgress  style={{ color: '#FF0053' }} size={20}/> : 'Update'}
            //  disabled={loading ? true : false}
            Icon={RefreshIcon}  />
    </Box> */}
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        //onClick={handleClose}
        >
        <CircularProgress color="inherit" />
    </Backdrop>
</Box>
  )
}

export default ModeratorProfile