import React, { useEffect } from 'react'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Avatar, Box, Stack, styled, Button, Typography } from '@mui/material';
import { AvTimer, Home, Looks4 } from '@mui/icons-material';
import { Sidebardata } from './data';
import Logo from '../../Assets/images/Logo.png';
import setting from '../../Assets/images/setting.png'
import logout from '../../Assets/images/logout.png'
import { height } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import menu from '../../Assets/images/menu.png'
import LogoutModal from './LogoutModal';
import { useContext } from 'react';
import RoutingContext from '../../helpers/Routing';
import UserContext from '../../helpers/user';
const Sidebar = () => {
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [open, setOpen] = React.useState(false);

    const routesContext = useContext(RoutingContext)
    const userContext = useContext(UserContext)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const BOX = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        width: '16vw',
        height: '100vh',
        backgroundColor: '#FFF6ED',
        [theme.breakpoints.down('md')]: {
            display: 'none',
            width: 'unset',
        }

    }))




    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const local = localStorage.getItem('link')
    useEffect(() => {
        if (local) {
            setSelectedIndex(parseInt(local))
        }
    }, [local])



    return (
        <BOX>
            <Box
                py={3}
                justifyContent={'center'}
                width={'100%'}
                display={'flex'}>
                <Avatar src={Logo} variant="square"
                    style={{ width: '200px', height: '80px' }} ></Avatar>
            </Box>
            <List style={{ maxHeight: '65%', overflowY: 'scroll', minHeight: '65%' }} component="nav" aria-label="main mailbox folders">
                {routesContext.routing?.map((res, i) => {
                    if (res?.title) {
                        return (
                            <ListItemButton
                                key={i}
                                style={{ display: 'flex', justifyContent: 'space-between' }}
                                selected={selectedIndex === i}
                                onClick={
                                    (event) => {
                                        localStorage.setItem('link', i)
                                        handleListItemClick(event, i)
                                        navigate(res?.path)
                                    }
                                }
                            >
                                <ListItemText
                                    key={i}
                                    disableTypography
                                    primary={<Typography type="body2"
                                        style={{
                                            color: selectedIndex === i ? '#FD085A' : '#grey',
                                            fontFamily: 'Quicksand',
                                            fontWeight: selectedIndex === i ? '700' : '600'
                                        }}>{res?.title ? res?.title : ''}</Typography>}
                                ></ListItemText>
                                {selectedIndex === i && <Avatar src={menu} sx={{ height: 15, width: 20 }} variant={'square'}></Avatar>}
                            </ListItemButton>
                        )
                    }
                }
                )}

            </List>

            {open && <LogoutModal open={open} handleClose={handleClose} />}
            <Stack direction={'row'} justifyContent={'center'} my={1} gap={2}  >
                {userContext.user?.role === 'admin' &&
                    <Button sx={{ borderRadius: 3, cursor: 'pointer' }} onClick={() => navigate('/Profile')}>
                        <Avatar src={setting}
                            sx={{
                                width: 30,
                                height: 30,
                                p: 1.5,
                                bgcolor: '#fff',
                                borderRadius: 3,
                                boxShadow: '0px 8px #FADEDA'
                            }}  ></Avatar>
                    </Button>}
                <Button sx={{ borderRadius: 3, cursor: 'pointer' }} onClick={() => handleClickOpen()}>
                    <Avatar src={logout} sx={{
                        width: 30,
                        height: 30,
                        p: 1.5,
                        bgcolor: '#fff',
                        borderRadius: 3,
                        boxShadow: 1,
                        boxShadow: '0px 8px #FADEDA',
                        cursor: 'pointer'
                    }}></Avatar>
                </Button>
            </Stack>
        </BOX>
    )
}

export default Sidebar