import axios from "../../CustomAxios";
import { CLASS_SCHOOL_FAIL, CLASS_SCHOOL_SUCCESS, CREATE_SCHOOL_FAIL, CREATE_SCHOOL_SUCCESS, CREATE_SCHOOL_TEACHER_FAIL, CREATE_SCHOOL_TEACHER_SUCCESS, DELETE_SCHOOL_CLASS_FAIL, DELETE_SCHOOL_CLASS_SUCCESS, DELETE_SCHOOL_FAIL, DELETE_SCHOOL_SUCCESS, EDIT_SCHOOL_TEACHER_FAIL, EDIT_SCHOOL_TEACHER_SUCCESS, GET_CLASS_TEACHER_FAIL, GET_CLASS_TEACHER_SUCCESS, GET_DIVISION_TEACHER_FAIL, GET_DIVISION_TEACHER_SUCCESS, GET_FULL_CLASS_LIST_FAIL, GET_FULL_CLASS_LIST_SUCCESS, GET_SCHOOL_CLASS_FAIL, GET_SCHOOL_CLASS_SUCCESS, GET_SCHOOL_FAIL, GET_SCHOOL_NETWORK_FAIL, GET_SCHOOL_NETWORK_SUCCESS, GET_SCHOOL_STUDENT_FAIL, GET_SCHOOL_STUDENT_SUCCESS, GET_SCHOOL_SUCCESS, GET_SCHOOL_TEACHER_FAIL, GET_SCHOOL_TEACHER_SINGLE_LIST_FAIL, GET_SCHOOL_TEACHER_SINGLE_LIST_SUCCESS, GET_SCHOOL_TEACHER_SUCCESS, REASIGN_SCHOOL_TEACHER_FAIL, REASIGN_SCHOOL_TEACHER_SUCCESS, SCHOOL_TEACHER_STATUS_FAIL, SCHOOL_TEACHER_STATUS_SUCCESS, SINGLE_SCHOOL_FAIL, SINGLE_SCHOOL_SUCCESS, STATUS_SCHOOL_FAIL, STATUS_SCHOOL_STUDENT_FAIL, STATUS_SCHOOL_STUDENT_SUCCESS, STATUS_SCHOOL_SUCCESS, UPDATE_SCHOOL_CLASS_FAIL, UPDATE_SCHOOL_CLASS_SUCCESS, UPDATE_SCHOOL_FAIL, UPDATE_SCHOOL_NETWORK_FAIL, UPDATE_SCHOOL_NETWORK_SUCCESS, UPDATE_SCHOOL_SUCCESS } from "../constants/schoolConstant";
import { LOADER, LOADING } from "../constants/userConstant";


export const createSchool = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/school/create`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: CREATE_SCHOOL_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: CREATE_SCHOOL_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getSchool = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/school/list`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_SCHOOL_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: GET_SCHOOL_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const SchoolStatus = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/school/status`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: STATUS_SCHOOL_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: STATUS_SCHOOL_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const deleteSchool = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`admin/school/delete/${id}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: DELETE_SCHOOL_SUCCESS,
                payload: id
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: DELETE_SCHOOL_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const singleSchool = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    dispatch({
        type: LOADER,
        payload: true
    })
    await axios.get(`admin/school/show/${id}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: SINGLE_SCHOOL_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
            dispatch({
                type: LOADER,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: SINGLE_SCHOOL_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
            dispatch({
                type: LOADER,
                payload: false
            })
        })
}

export const editSchool = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/school/update`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: UPDATE_SCHOOL_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: UPDATE_SCHOOL_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}


export const addSchoolClass = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/class/create`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: CLASS_SCHOOL_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: CLASS_SCHOOL_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const getSchoolClass = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    dispatch({
        type: LOADER,
        payload: true
    })
    await axios.get(`admin/class/list/${id}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_SCHOOL_CLASS_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
            dispatch({
                type: LOADER,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_SCHOOL_CLASS_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
            dispatch({
                type: LOADER,
                payload: false
            })


        })
}

export const updateSchoolClass = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/class/update`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: UPDATE_SCHOOL_CLASS_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: UPDATE_SCHOOL_CLASS_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const deleteSchoolClass = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`admin/class/delete/${id}`,)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: DELETE_SCHOOL_CLASS_SUCCESS,
                payload: id
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: DELETE_SCHOOL_CLASS_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const getClasses = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/teacher/class/${id}`,)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_CLASS_TEACHER_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_CLASS_TEACHER_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const getDivision = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/teacher/division`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_DIVISION_TEACHER_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_DIVISION_TEACHER_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const createSchooolteacher = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/teacher/create`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: CREATE_SCHOOL_TEACHER_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: CREATE_SCHOOL_TEACHER_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}


export const getSchooolteacher = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    dispatch({
        type: LOADER,
        payload: true
    })
    await axios.get(`admin/teacher/list/${id}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_SCHOOL_TEACHER_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
            dispatch({
                type: LOADER,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_SCHOOL_TEACHER_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
            dispatch({
                type: LOADER,
                payload: false
            })

        })
}

export const SchooolteacherStatus = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/teacher/status`, data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: SCHOOL_TEACHER_STATUS_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: SCHOOL_TEACHER_STATUS_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getSchoolTeacherSingleList = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    dispatch({
        type: LOADER,
        payload: true
    })
    await axios.get(`admin/teacher/show/${id}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_SCHOOL_TEACHER_SINGLE_LIST_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
            dispatch({
                type: LOADER,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_SCHOOL_TEACHER_SINGLE_LIST_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
            dispatch({
                type: LOADER,
                payload: false
            })

        })
}

export const reasignSchoolTeacher = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/teacher/remove/division`,data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: REASIGN_SCHOOL_TEACHER_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: REASIGN_SCHOOL_TEACHER_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })


        })
}


export const UpdateSchoolTeacher = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/teacher/update`,data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: EDIT_SCHOOL_TEACHER_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: EDIT_SCHOOL_TEACHER_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })


        })
}



export const getClassFullList = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/class/classlist/${id}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_FULL_CLASS_LIST_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: GET_FULL_CLASS_LIST_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })


        })
}

export const getSchoolStudents = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/student/list/${id}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_SCHOOL_STUDENT_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: GET_SCHOOL_STUDENT_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })


        })
}

export const statusSchoolStudents = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/student/status`,data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: STATUS_SCHOOL_STUDENT_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: STATUS_SCHOOL_STUDENT_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })


        })
}


export const getSchoolNetwork = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/school/network/list/${id}`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_SCHOOL_NETWORK_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: GET_SCHOOL_NETWORK_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })


        })
}


export const updateSchoolNetwork = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/school/network/edit`,data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: UPDATE_SCHOOL_NETWORK_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: UPDATE_SCHOOL_NETWORK_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })


        })
}