import { Box, Grid, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import CustomTypography from '../../../components/Widgets/CustomTypography';
import CustomBackHeader from '../../../components/Widgets/CustomBackarrowHeader.js';
import CustomTextArea from '../../../components/Widgets/CustomTextArea';
import CustomAvatar from '../../../components/Widgets/CustomAvatar';
import CustomCardMedia from '../../../components/Widgets/CustomCardMedia';
import CustomApproveRejectButton from '../../../components/Widgets/CustomApproveRejectButton';
import { IMAGE_URL } from '../../../config/constants';
import customAxios from '../../../CustomAxios';
import { useContext } from 'react';
import UserContext from '../../../helpers/user';

const EditTeacherStudentNetwork = () => {
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate()



    const user = useContext(UserContext);
    const [loading, setLoading] = useState();


    const schema = object().shape({

        content: yup.string().required('content Status is Required')

    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({
        defaultValues: {

        },
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        if (state) {
            setValue('content', state?.post_details?.content)
        }

    }, [state])




    const approvedata = async (data) => {
        let val = {
            id: state?.post_details?._id,
            status: 'Approved',
            reviewed_by: user?.user?._id,
            content: data?.content
        }
        setLoading(true)
        await customAxios.post(`teacher/reviewposts`, val)
            .then(async response => {
                toast.success(`Approved Successfully`)
                setLoading(false);
            }).catch(async error => {
                toast.error({ error })
                setLoading(false)
            })

    }
    const Rejectdata = async (data) => {
        let val = {
            id: state?.post_details._id,
            status: 'Rejected',
            reviewed_by: user?.user?._id,
            content: data?.content
        }
        setLoading(true)
        await customAxios.post(`teacher/reviewposts`, val)
            .then(async response => {
                toast.success(`Rejected Successfully`)
                setLoading(false);

            }).catch(async error => {
                toast.error({ error })
                setLoading(false)
            })

    }

    return (
        <Box>
            <CustomBackHeader label={`Edit Post`} />
            <Grid container spacing={3} px={5} mt={2}>
                <Grid item lg={4} md={4} xs={12}>
                    <CustomTypography formlabel={'Creator Name'} label={state?.creator_details?.name} />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                    <CustomTypography formlabel={'Class'} label={state?.creator_details?.class} />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                    <CustomTypography formlabel={'Reviewed By'} label={state?.review_details?.name} />
                </Grid>
                <Grid item lg={8} md={8} xs={12}>
                    <CustomTextArea
                        defaultValue={state?.post_details?.content}
                        control={control}
                        error={errors.content}
                        fieldName="content"
                        multiline={true}
                        height={150}
                        row={10}
                        fieldLabel="Post Content"
                        background={'#FFEFEC'}
                    />
                </Grid>
                {state?.post_details.file_type === 'image' &&
                    <Grid item lg={4} md={4} xs={12}>
                        <CustomAvatar label={'Post'} avatar={`${IMAGE_URL}${state?.post_details?.file}`} />
                    </Grid>}
                {state?.post_details.file_type === 'video' &&
                    <Grid item lg={4} md={4} xs={12}>
                        <CustomCardMedia label={'Post'} videoProp={`${IMAGE_URL}${state?.post_details?.file}`} />
                    </Grid>}
                <Grid item lg={4} md={4} xs={12}>
                    <Stack direction={'row'} gap={4} justifyContent={'center'} py={5}
                    >

                        <CustomApproveRejectButton label={'Approve'} shadow={'#DBFADA'} color={'#29C617'}
                            background={'#F3FFF9'}
                            onClick={handleSubmit(approvedata)}

                        />
                        <CustomApproveRejectButton label={'Reject'} shadow={'#FFE8E8'} color={'#FF0000'} background={'#FFE8E8'}
                            onClick={handleSubmit(Rejectdata)}

                        />
                    </Stack>
                </Grid>

            </Grid>
        </Box>
    )
}

export default EditTeacherStudentNetwork