import { Backdrop, Box, CircularProgress, Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import CustomSearch from '../../components/Widgets/CustomSearch';
import DataTable from '../../components/Widgets/CustomTable';
import Header from '../Header';
import AddIcon from '@mui/icons-material/Add';
import CustomSwitch from '../../components/Widgets/CustomSwitch';
import { Stack } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { deleteVocabulary, getVocabulary, statusVocabulary } from '../../Redux/actions/vocabularyActions';
import { IMAGE_URL } from '../../config/constants'
import moment from 'moment/moment';
import { useTransition } from 'react';
import { RESET_ERROR } from '../../Redux/constants/userConstant';
import { toast } from "react-toastify";
import CustomDelete from '../../components/Widgets/CustomDelete';

export const VocabularyManagement = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { vocabularyList, vocabularyDeleted, error, vocabularyStatusChanged } = useSelector(state => state.vocabulory);
    const { loading, loader } = useSelector(state => state.auth);

    const [dataRes, setDataRes] = useState([]);
    const [pending, startTransition] = useTransition();
    const [iD, setID] = useState(null);
    const [openD, setOpenD] = useState(false);

    const handleOpenD = () => setOpenD(true);
    const handleCloseD = () => setOpenD(false);






    const columns = [
        {
            field: 'voc_id',
            headerName: 'Post ID',
            width:160,
            valueGetter: (params) => (`#${params.row?.voc_id}`),
            headerAlign: 'center',
            align: 'center',


        },
        {
            field: 'createdOn',
            headerName: 'Created Date',
            width:180,
            valueGetter: (params) => (moment(params.row.created_at).format('DD/MM/YYYY')),
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'posted_by',
            headerName: 'Posted By',
            width:150,
            headerAlign: 'center',
            align: 'center',

        },
        {

            headerName: 'Grade',
            width:150,
            valueGetter: (params) => (params.row.grade_details?.name),
            headerAlign: 'center',
            align: 'center',


        },
        {
            field: 'word',
            headerName: 'Word',
            width:180,
            headerAlign: 'center',
            align: 'center',

        },

        {
            field: 'Status',
            headerName: 'Status',
            width:180,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <Box>

                    <CustomSwitch checked={row?.status === "active" ? true : false}
                        onClick={(e) => ChangeStatus(e.target.checked, row?._id)} />
                </Box>
            )

        },
        {
            field: 'Action',
            headerName: 'Action',
            width:200,
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={2} direction={'row'}>
                    <RemoveRedEyeIcon
                        onClick={() => navigate('/viewVocabulary', {
                            state: {
                                item: row
                            }
                        })}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }} />
                    <BorderColorTwoToneIcon
                        onClick={() => navigate(`/editVocabulary/${row?._id}`)}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />
                    <DeleteOutlineTwoToneIcon
                        onClick={() => handleDelete(row?._id)}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }} />

                </Stack>
            )
        },

    ];

    const ChangeStatus = (checked, row) => {
        let status = checked === true ? 'active' : 'inactive'
        let val = {
            id: row,
            status: status
        }
        dispatch(statusVocabulary(val))
    }



    const handleDelete = (id) => {
        setID(id);
        handleOpenD()
    }


    const searchVocabulary = (value) => {
        let vocabulary = vocabularyList?.filter(voc => voc?.word.toString().toLowerCase().includes(value.toLowerCase()) ||
            `#${voc?.voc_id}`.toString().toLowerCase().includes(value.toLowerCase())
        )
        startTransition(() => {
            setDataRes(vocabulary)
        })
    }



    useEffect(() => {
        if (vocabularyList) {
            setDataRes(vocabularyList)

        }

    }, [vocabularyList])





    useEffect(() => {

        if (vocabularyDeleted) {
            toast.success(`Deleted Successfully`);
            dispatch({
                type: RESET_ERROR
            })

            handleCloseD();
        }
        if (vocabularyStatusChanged) {
            toast.success(`Status Changed Successfully`);
            dispatch({
                type: RESET_ERROR
            })

        }


        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, vocabularyDeleted, vocabularyStatusChanged])


    useEffect(() => {
        dispatch(getVocabulary())
    }, [])


    return (
        <Box >
            <Header label={'Vocabulary Management'} />
            <Box px={2} py={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <CustomIconButton onClick={() => navigate('/AddVocabulary')} label={'Add'} Icon={AddIcon} />
                <CustomSearch placeholder={'Post ID, Word'} onSearch={(value) => searchVocabulary(value)} />
            </Box>
            <Box px={2}>
                <Paper style={{
                    borderRadius: "20px"
                }}>
                    <DataTable
                        rows={dataRes}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {openD && <CustomDelete open={handleOpenD} handleClose={handleCloseD} mode={'Vocabulary'} id={iD} />}

        </Box>
    )
}
