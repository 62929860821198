import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Stack } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useTransition } from 'react';
import DataTable from '../../../components/Widgets/CustomTable';
import Header from '../../Header';
import CustomSearch from '../../../components/Widgets/CustomSearch';
import customAxios from '../../../CustomAxios';

const StudentsTeacherNetwork = () => {

    const [loading, setLoading] = useState(false)
    const [active, setActive] = useState(null)
    const [open, setOpen] = useState(false)
    const [search, setSearchList] = useState([]);
    const [pending, startTransition] = useTransition();
    const [stdNetworkList, setstdNetworkList] = useState([]);



    const navigate = useNavigate()

    const columns = [
        {
            field: 'Post ID',
            headerName: 'Post ID',
            width: 120,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (params.row.post_details?.post_id),

        },
        {
            field: 'Creator Date',
            headerName: 'Creator Date',
            width: 180,

            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => moment(params.row.created_at).format("DD-MM-YYYY"),
        },
        {
            field: 'Creator Name',
            headerName: 'Creator Name',
            width: 180,

            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (params.row.creator_details?.name),
        },
        {
            field: 'Class & Division',
            headerName: 'Class & Division',
            width: 200,

            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (`${params.row.creator_details?.class} - ${params.row.creator_details?.division}`),
        },
        {
            field: 'Reporting Teacher',
            headerName: 'Reporting Teacher',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (params.row.review_details?.name),


        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            width: 150,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <span style={{
                    color: row?.post_details?.status === 'Reported' ? '#C2620E' : row?.post_details?.status === 'active' ? "#2E75C7" :
                        row?.post_details?.status === 'Completed' ? '#21AA12' : row?.post_details?.status === 'Rejected' ? 'red' : '', cursor: 'pointer'
                }}>{row?.post_details?.status}</span>
            )
        },
        {
            field: 'Action',
            headerName: 'Action',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={1} direction={'row'}>
                    <RemoveRedEyeIcon
                        onClick={() => navigate(`/viewteacherstudentnetwork     `, { state: row })}
                        style={{

                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />
                    {row?.post_details?.status !== 'Rejected' &&
                        <BorderColorTwoToneIcon
                            onClick={() => navigate(`/editteacherstudentnetwork`, { state: row })}
                            style={{
                                color: '#FF0053',
                                cursor: 'pointer'
                            }}
                        />}
                </Stack>
            )
        },
    ];



    useEffect(() => {
        getStudentNetwork();
    }, [])


    const getStudentNetwork = async () => {
        setLoading(true)
        await customAxios.get(`teacher/studentnetworks`)
            .then(async response => {
                setstdNetworkList(response.data.data);
                setSearchList(response.data.data);
                setLoading(false);
            }).catch(async error => {
                toast.error({ error })
                setLoading(false)
            })
    }


    const searchstudentList = (value) => {
        console.log({ value })
        let students = search?.filter(nt => nt?.post_details?.post_id.toString().toLowerCase().includes(value.toLowerCase()) ||  nt?.creator_details?.name.toString().toLowerCase().includes(value.toLowerCase()))
        if (value === '') {
            getStudentNetwork();
        }
        startTransition(() => {
            setstdNetworkList(students)
        })
    }




    return (
        <Box>
            <Header label={'School Network'} />
            <Box px={2} py={3} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                <CustomSearch placeholder={'Search'} onSearch={(value) => searchstudentList(value)} />
            </Box>
            <Box px={5} py={2}>
                <Paper style={{
                    borderRadius: "20px"
                }}>
                    <DataTable
                        rows={stdNetworkList}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </Box>
    )
}

export default StudentsTeacherNetwork