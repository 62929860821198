import React, { useState } from 'react'
import { Box, FormGroup, Typography, Avatar } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import { styled } from '@mui/material/styles';
import BackupIcon from '@mui/icons-material/Backup';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Padding, WidthFull } from '@mui/icons-material';

const CustomImageUploader = ({ 
    fieldName,
    control,
    fieldLabel,
    placeholder,
    error,
    type,
    max,
    onChangeValue,
    height,
    background,
    ICON,
    width,
    preview,
    previewEditimage,
    myid,
    format,
    video,
    accept,
    vedeoPreview,
    view
}) => {
    const Input = styled('input')({
        display: 'none',

    });

    const [file, setFile] = useState(null)
    const [localError, setLocalError] = useState(null)

    const onImgLoad = ({target:img}) => {
        console.log({dimensions:{height:img.offsetHeight,
            width:img.offsetWidth}});
        if(img.offsetHeight === height && img.offsetWidth === width){
            onChangeValue(file)
            setLocalError(null)
        }
        else{
            setLocalError(`Image resolution should be ${width}px * ${height}px. Uploaded image resolution ${img.offsetWidth}px * ${img.offsetHeight}px`)
            onChangeValue(null)
        }
    }





    return (
        <>
            <FormGroup>
                <Typography
                    htmlFor="recipient-name"
                    className="col-form-label"
                    sx={{
                        fontFamily: 'Quicksand',
                        fontWeight: "bold"
                    }}
                >
                    {fieldLabel}
                </Typography>
                <Controller
                    name={fieldName}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) =>
                        <Box
                            height={150}
                            flexDirection={'column'}
                            sx={{ width: { xs: '100%', } }}
                            borderRadius={3}
                            border={'2px dotted #FF0053'}
                            alignItems={'center'}
                            display={'flex'}
                            justifyContent={'center'}
                            position={'relative'}
                        >
                            {!preview || previewEditimage &&
                                <Typography sx={{
                                    zIndex: '1000',
                                    position: 'absolute',
                                    fontFamily: 'Quicksand',
                                    fontSize: '16px',
                                    letterSpacing: '1px'
                                }} >Upload Image</Typography>}

                            {format.includes('image') ? <Avatar
                                variant='square'
                                src={previewEditimage ? previewEditimage : preview ? URL.createObjectURL(preview) : null}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    zIndex: '50'

                                }}

                            ></Avatar> :
                                <video
                                    className="VideoInput_video"
                                    width="100%"
                                    height={'100%'}
                                    controls
                                    src={vedeoPreview ? vedeoPreview : video ? URL.createObjectURL(video) : null}
                                />
                            }
                            {!view && <label htmlFor={myid} >
                                <Input
                                    accept={accept}
                                    onBlur={onBlur}
                                    aria-invalid={error ? "true" : "false"}
                                    className="form-control"
                                    placeholder={placeholder}
                                    type={type ? type : "text"}
                                    maxLength={max}
                                    id={myid}
                                    onChange={(e) => onChangeValue(e?.target?.files[0])}
                                />
                                <BackupIcon style={{
                                    color: '#ffff',
                                    backgroundColor: '#FF0053',
                                    zIndex: "1000",
                                    position: "absolute",
                                    bottom: "10%",
                                    right: "40%",
                                    padding: 3,
                                    borderRadius: "5px",
                                    width: "40px",
                                    cursor: 'pointer'
                                }} />
                            </label>}
                        </Box>
                    }
                />
                {error && <p role="alert" style={{
                    color: "red",
                    display: "flex",
                    flexDirection: "start",
                    paddingLeft: "10px",
                    fontSize: "12px"
                }}>{error?.message}</p>}
                {localError && <p role="alert" style={{
                    color: "red",
                    display: "flex",
                    flexDirection: "start",
                    paddingLeft: "10px",
                    fontSize: "12px",
                    width: "300px"
                }}>{localError}</p>}
                {/* <div style={{ height: '2px', overflow: 'scroll' }}>
                {file && <img src={URL.createObjectURL(file)} style={{ opacity: 0 }} onLoad={onImgLoad} />}
                </div> */}
                
            </FormGroup>
        </>
    )
}

export default CustomImageUploader