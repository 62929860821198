import React, { useEffect, useState } from 'react'
import { Avatar, Box, Stack, styled, Button, Typography, Grid, CircularProgress } from '@mui/material';
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js';
import CustomInput from '../../components/Widgets/CustomInput/index.js';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomSelect from '../../components/Widgets/CustomSelect/index.js';
import MenuItem from '@mui/material/MenuItem';
import BackupIcon from '@mui/icons-material/Backup';
import CustomIconButton from '../../components/Widgets/CustomIconButton/index.js';
import AddIcon from '@mui/icons-material/Add';
import CustomTextArea from '../../components/Widgets/CustomTextArea/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { createVocabulary, getGrade, singleVocabulary, updateVocabulary } from '../../Redux/actions/vocabularyActions.js';
import { toast } from "react-toastify";
import CustomImageUploader from '../../components/Widgets/CustomImageUploder/index.js';
import { RESET_ERROR } from '../../Redux/constants/userConstant.js';
import UserContext from '../../helpers/user/index.js';
import { useContext } from 'react';
import { IMAGE_URL } from '../../config/constants.js';
import CustomSpinner from '../../components/Widgets/CustomSpinner/index.js';
import { SINGLE_VOCABULARY_SUCCESS } from '../../Redux/constants/vocabularyConstants.js';
const AddVocabulary = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const context = useContext(UserContext)
    const { id } = useParams()



    const { loading, loader,mediaSize } = useSelector(state => state.auth);
    const { gradeList, vocabularyCreated, error, singleVocabularyList, vocabularyUpdated } = useSelector(state => state.vocabulory);



    const [grade, setGrade] = useState(null);
    const [video, setVideo] = useState(null);
    const [images, setimages] = useState(null);
    const [previewEdit, setPreviewEdit] = useState(null);
    const [videoPreviewEdit, setVideoPreviewEdit] = useState(null);
    const [type, setType] = useState(null);
    const [thumbnails, setThumbnails] = useState(null);
    const [thumbnailsPreview, setThumbnailsPreview] = useState(null);
    const [postThumbnail, setPostThumbnail] = useState(false);



    const schema = object().shape({
        grade: yup.string().required('Grade is Required'),
        word: yup.string().required('Word is Required'),
        meaning: yup.string().required('Meaning is Required'),
        example: yup.string().required('Example is Required'),
        // file: yup.string().required('Required')
    });


   
    




    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({
        defaultValues: {
            posted_by: context?.user?.name

        },

        resolver: yupResolver(schema)

    });

    useEffect(() => {
        return () => {
            dispatch({
                type: SINGLE_VOCABULARY_SUCCESS,
                payload: null
            });
          reset()
        }
      }, [])

  
    const setimagefile = (e) => {
        setPreviewEdit(null)
        setimages(null)
        setVideo(null)
        setType(e.type.split('/', 1))
        if (e.type.split("/", 1).includes('video')) {
          if (e.size > mediaSize[0]?.videoSize) {
            toast.warning(`Video Size Should be less than ${mediaSize[0]?.videoOrginal}MB`);
            setPreviewEdit(null)
            setimages(null)
            setVideo(null)
            setType('image')
          } else {
            setVideo(e);
            setPostThumbnail(true);
          }
        } else if (e.type.split("/", 1).includes('image')) {
          setPostThumbnail(false);
          setThumbnails(null);
          if (e.size > mediaSize[0]?.imageSize) {
            toast.warning(`Image Size Should be less than ${mediaSize[0]?.imageOrginal}MB`);
            setPreviewEdit(null)
            setimages(null)
          } else {
            setPreviewEdit(null)
            setimages(e)
          }
    
        }
      }

    const Submit = (data) => {

        if ( id && singleVocabularyList) {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('grade', data?.grade);
            formData.append('word', data?.word);
            formData.append('meaning', data?.meaning);
            formData.append('example', data?.example);
            formData.append('posted_by', data?.posted_by);
            formData.append('status', singleVocabularyList?.status);
            if (images || video) {
                formData.append('file', images ? images : video);
            }
            if (thumbnails) {
                formData.append('thumbnail', thumbnails);
            }
            dispatch(updateVocabulary(formData))

        } else {
            const formData = new FormData();
            formData.append('grade', data?.grade);
            formData.append('word', data?.word);
            formData.append('meaning', data?.meaning);
            formData.append('example', data?.example);
            formData.append('posted_by', data?.posted_by);
            if (images || video) {
                formData.append('file', images ? images : video);
            }
            if (thumbnails) {
                formData.append('thumbnail', thumbnails);
            }
            dispatch(createVocabulary(formData))

        }

    }


    useEffect(() => {
        if (singleVocabularyList) {
            setValue('grade', singleVocabularyList?.grade)
            setGrade(singleVocabularyList?.grade)
            setValue('word', singleVocabularyList?.word)
            setValue('meaning', singleVocabularyList?.meaning)
            setValue('example', singleVocabularyList?.example)
            setValue('file', singleVocabularyList?.file)
            if (singleVocabularyList?.file_type === 'image') {
                setPreviewEdit(`${IMAGE_URL}${singleVocabularyList?.file}`)
                setType('image')
            } else {
                setVideoPreviewEdit(`${IMAGE_URL}${singleVocabularyList?.file}`)
                setType('video')

            }
            if(singleVocabularyList.file_type === 'video'){
                setPostThumbnail(true)
                setThumbnailsPreview(`${IMAGE_URL}${singleVocabularyList?.thumbnail}`)
              }
        }
    }, [singleVocabularyList])


    useEffect(() => {
        dispatch(getGrade())
    }, [])


    useEffect(() => {
        if (id) {
            dispatch(singleVocabulary(id))
        }
    }, [id])


    useEffect(() => {
        if (vocabularyCreated) {
            toast.success(`Vocabulary Created Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            navigate(-1)
        }
        if (vocabularyUpdated) {
            toast.success(`Vocabulary Updated Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            navigate(-1)
        }

        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, vocabularyCreated, vocabularyUpdated])

    if (loader) {
        return <><CustomSpinner /></>
    }
    return (
        <Box>
            <CustomBackHeader label={id ? 'Edit Vocabulary' : 'Add Vocabulary'} />
            <Box px={5} mt={5}>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={4} xs={6}>
                        <CustomSelect
                            control={control}
                            error={errors.grade}
                            fieldName="grade"
                            fieldLabel="Grade"
                            background="#FFEFEC"
                            size="16px"
                            value={grade}
                            onChangeValue={(e) => {
                                setGrade(e.target.value)
                                setValue('grade', e.target.value)
                                setError('grade', '')
                            }}
                        >
                            <MenuItem value="" disabled >
                                <>Select Grade</>
                            </MenuItem>
                            {gradeList?.map((res) => (
                                <MenuItem value={res?._id} key={res?.id} >
                                    {res?.name}
                                </MenuItem>
                            ))}

                        </CustomSelect>
                    </Grid>
                    <Grid item lg={3} md={4} xs={6}>
                        <CustomInput
                            control={control}
                            error={errors.word}
                            fieldName="word"
                            fieldLabel="Word"
                        />
                    </Grid>
                    <Grid item lg={6} md={8} xs={12}>
                        <CustomTextArea
                            control={control}
                            error={errors.meaning}
                            fieldName="meaning"
                            multiline={true}
                            height={90}
                            row={10}
                            fieldLabel="Meaning"
                            background={'#FFEFEC'}

                        />
                    </Grid>
                    <Grid item lg={6} md={8} xs={12}>
                        <CustomTextArea
                            control={control}
                            error={errors.example}
                            fieldName="example"
                            multiline={true}
                            height={90}
                            row={10}
                            fieldLabel="Example"
                            background={'#FFEFEC'}
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={6}>
                        <CustomImageUploader
                            fieldLabel='Upload Image / Video'
                            accept={'image/*,video/*'}
                            control={control}
                            format={type ? type : 'image'}
                            error={errors.file}
                            fieldName='file'
                            type={"file"}
                            height={350}
                            width={500}
                            background="#ffff"
                            id="contained-button-file"
                            preview={images}
                            vedeoPreview={videoPreviewEdit}
                            video={video}
                            previewEditimage={previewEdit}
                            onChangeValue={(file, e) => {
                                setimagefile(file)
                                setValue("file", file)
                                setError('file', '')
                            }}
                        />

                    </Grid>
                    {postThumbnail &&
                        <Grid item lg={3} md={4} xs={12}>
                            <CustomImageUploader
                                fieldLabel='Add Thumbnail'
                                accept={'image/*'}
                                control={control}
                                format={'image'}
                                error={errors.thumbnails}
                                fieldName='thumbnail'
                                type={"file"}
                                height={350}
                                width={500}
                                background="#ffff"
                                id="contained-button-file"
                                preview={thumbnails}
                                previewEditimage={thumbnailsPreview}
                                onChangeValue={(file, e) => {
                                    setThumbnails(file)
                                    setValue("thumbnail", file)
                                    setThumbnailsPreview(null)
                                }}
                            />
                        </Grid>}
                </Grid>
            </Box>
            <Box display={'flex'} justifyContent={'center'} py={10}>
                <CustomIconButton label={loading ? <CircularProgress style={{ color: '#FF0053' }} size={20} /> : !id ? 'Add' : 'Update'} Icon={AddIcon} onClick={handleSubmit(Submit)} />
            </Box>
        </Box>
    )
}

export default AddVocabulary