import { Avatar, Box, Button, Stack, styled } from '@mui/material'
import React, { useState, useEffect, useContext } from 'react'
import CustomLoginInput from '../../components/Widgets/CustomLoginInput'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { useNavigate } from 'react-router-dom';
import CustomLoginButton from '../../components/Widgets/CustomLoginButton';
import { useDispatch, useSelector } from 'react-redux';
import { EMAIL_ADDRESS_STORE, RESET_ERROR } from '../../Redux/constants/userConstant';
import { toast } from "react-toastify";
import { RegUser } from '../../Redux/actions/userActions';
import Grid from '@mui/material/Grid';
import UserContext from '../../helpers/user';
const Login = () => {
    const { authReg, error } = useSelector(state => state.auth);
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const context = useContext(UserContext)

    useEffect(() => {
        if(context.user){
            navigate('/')
        }
    }, [context])
    

    const STACK = styled(Stack)({
        justifyContent: 'center',
        alignItems: 'center',
        gap: 15
    })

    const schema = object().shape({
        email: yup.string().email().required('Email is required')
    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
      
    });

    const SubmitForm = (data) => {
        dispatch({
            type: EMAIL_ADDRESS_STORE,
            payload: data?.email
        })
        dispatch(RegUser(data))
    }

    useEffect(() => {
        if (authReg) {
            // toast.success(`OTP has been sent to your email id`);
            dispatch({
                type: RESET_ERROR
            })
            navigate('/otp')
        }

        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, authReg])

    const enterKye=(e)=>{
        if(e.key==="Enter"){
          alert("hello");
          }
        }

    return (
        <STACK>
            <Box mt={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomLoginInput
                   
                            control={control}
                            error={errors.email}
                            fieldName="email"
                            placeholder={"Email Address"}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box mt={5}>
                <CustomLoginButton onclick={handleSubmit(SubmitForm)} />
            </Box>
        </STACK>
    )
}

export default Login