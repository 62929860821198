export const GET_VOCABULARY_LIST_SUCCESS = 'GET_VOCABULARY_LIST_SUCCESS'
export const GET_VOCABULARY_LIST_FAIL = 'GET_VOCABULARY_LIAST_FAIL'

export const CREATE_VOCABULARY_SUCCESS = 'CREATE_VOCABULARY_LIST_SUCCESS'
export const CREATE_VOCABULARY_FAIL='CREATE_VOCABULARY_LIST_FAIL'

export const DELETE_VOCABULARY_SUCCESS = 'DELETE_VOCABULARY_LIST_SUCCESS'
export const DELETE_VOCABULARY_FAIL = 'DELETE_VOCABULARY_LIST_FAIL'

export const UPDATE_VOCABULARY_SUCCESS='UPDATE_VOCABULUARY_LIST_SUCCESS'
export const UPDATE_VOCABULARY_FAIL='UPDATE_VOCABULARY_LIST_FAIL'

export const STATUS_VOCABULARY_SUCCESS = 'STATUS_VOCABULARY_SUCCESS'
export const STATUS_VOCABULARY_FAIL = 'STATUS_VOCABUALARY_FAIL'
export const SINGLE_VOCABULARY_SUCCESS = 'SINGLE_VOCABULARY_SUCCESS'
export const SINGLE_VOCABULARY_FAIL = 'SINGLE_VOCABULARY_FAIL'

export const GET_GRADE_SUCCESS = 'GET_GRADE_SUCCESS'
export const GET_GRADE_FAIL = 'GET_GRADE_FAIL'