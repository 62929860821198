import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import CardMedia from '@mui/material/CardMedia';
const CustomCardMedia = ({ videoProp, label,component }) => {

  const [play, setPlay] = useState(false)

  return (
    <Box style={{ position: 'relative' }}>
      <Typography px={1} mb={.5} style={{
        fontFamily: 'Quicksand',
        fontWeight: 'bold',
        fontSize: 16
      }}>{label}</Typography>
      <CardMedia
        component={component ? component : 'video'}
        sx={{
          width: { lg: '95%', md: '18vw', sm: '35vw', xs: '80vw' },
          height: 160,
          borderRadius: 5

        }}

        autoPlay={play}
        controls
        src={videoProp}
      ></CardMedia>
     
    </Box>
  )
}

export default CustomCardMedia