export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS'
export const CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL'

export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL'

export const STATUS_CATEGORY_SUCCESS = 'STATUS_CATEGORY_SUCCESS'
export const STATUS_CATEGORY_FAIL = 'STATUS_CATEGORY_FAIL'

export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS'
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL'

export const GET_CATEGORY_SUCCESS ='GET_CATEGORY_SUCCESS'
export const GET_CATEGORY_FAIL = 'GET_CATEGORY_FAIL'


    