import { Backdrop, Box, CircularProgress, Paper, styled } from '@mui/material'
import React,{ useState, useEffect, useTransition } from 'react'
import CustomSearch from '../../../components/Widgets/CustomSearch'
import Header from '../../Header'
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Stack } from '@mui/system';
import DataTable from '../../../components/Widgets/CustomTable';
import CustomButton from '../../../components/Widgets/CustomButton';
import { toast } from 'react-toastify';
import { LOADING } from '../../../Redux/constants/userConstant';
import customAxios from '../../../CustomAxios';
import AllReviews from './AllReviews';
import { RemoveRedEye, RemoveRedEyeOutlined } from '@mui/icons-material';



const ModeratorReviewManagement = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [datas, setDatas] = useState(null)
    const [allPosts, setAllPosts] = useState([])
    const [pendingPosts, setPendingPosts] = useState([])
    const [pending, startTransition] = useTransition();


    const [active, setActive] = useState('All')


    const[loading,setLoading]=useState(false)

    const BUTTONBOX = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    }))

    
    


    useEffect(() => {
       if(active === "All"){
            getAllReviews();
       }
       else if(active === "Pending"){
            //getPendingReviews()
            startTransition(() => {
                let pendings = datas?.filter(post => post.status === "Reported")
                setPendingPosts(pendings)
            })
            
       }
    }, [active])


    const getAllReviews = async() => {
        dispatch({
            type: LOADING,
            payload: true
        })
        await customAxios.get(`moderator/postlist`)
        .then(async response => {
            let data = response.data.data;
            setDatas(data)
            setAllPosts(data)
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            toast.error(error)

            dispatch({
                type: LOADING,
                payload: false
            })

        })
    }

    const getPendingReviews = async() => {
        dispatch({
            type: LOADING,
            payload: true
        })
        await customAxios.get(`moderator/pendinglist`)
        .then(async response => {
            let data = response.data.data;
            setDatas(data)
            setPendingPosts(data)
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            toast.error(error)

            dispatch({
                type: LOADING,
                payload: false
            })

        })
    }
    
    

    const columns = [
        {
            field: 'post_id',
            headerName: 'Post ID',
            width:160,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'created_at',
            headerName: 'Reported Date',
            type: 'date',
            width:130,
            valueGetter: (params) => (moment(params.row?.created_at).format("DD-MM-YYYY") ),
            headerAlign: 'center',
            align: 'center',


        },
        {
            field: 'creator_details',
            headerName: 'Posted By',
            width:130,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (params?.row?.creator_details?.name),
        },
        {
            headerName: 'Class',
            width:200,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (params?.row?.creator_details?.class),
        },
        {
            field: 'category',
            headerName: 'Reported By',
            width:140,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (params?.row?.reported_by_details?.name),

        },
        {
            field: 'status',
            headerName: 'Status',
            width:150,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <span style={{ color: row?.status === 'Rejected' ?'#DC1616' : row?.status === 'Pending' ? "#EAAD04" : 
                row?.status === 'Approved' ? '#21AA12' :row?.status === 'Reported' ? '#DC1616': '', cursor: 'pointer' }}>{row?.status}</span>
             )

        },
        {
            field: 'Action',
            headerName: 'Action',
            width:160,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} flexDirection={"row"}>
                    { row?.status === "Reported" && <BorderColorTwoToneIcon
                        onClick={() => navigate(`/editReview`,{
                            state:{
                                item : row,
                                mode: 'edit'
                            }
                        })}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />}
                    <RemoveRedEye
                        onClick={() => navigate(`/editReview`,{
                            state:{
                                item : row,
                                mode: 'view'
                            }
                        })}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />      
                </Stack>
            )
        },

    ];

    const searchResult = (value) => {
        switch (active) {
            case 'All':
                searchAll(value)
                break;

            case 'Pending':
                searchPending(value)
                break;
            }
    }

    const searchAll = (value) => {
        console.log({datas})
        let allSearch = datas?.filter(indi => indi?.class?.toLowerCase().includes(value?.toLowerCase()) || indi?.creator_details?.name?.toLowerCase().includes(value?.toLowerCase()) || indi?.creator_details?.class?.toLowerCase().includes(value?.toLowerCase()) || indi?.post_id?.toString().toLowerCase().includes(value?.toLowerCase()) || indi?.status?.toLowerCase().includes(value?.toLowerCase()))
        startTransition(() => {
            setAllPosts(allSearch)
        })
    }

    const searchPending = (value) => {
        let pengingSearch = datas?.filter(indi => (indi?.class?.toLowerCase().includes(value?.toLowerCase()) || indi?.creator_details?.name?.toLowerCase().includes(value?.toLowerCase()) || indi?.creator_details?.class?.toLowerCase().includes(value?.toLowerCase()) || indi?.post_id?.toString().toLowerCase().includes(value?.toLowerCase()) || indi?.status?.toLowerCase().includes(value?.toLowerCase())) && indi?.status === "Reported")
        startTransition(() => {
            setPendingPosts(pengingSearch)
        })
    }

  return (
    <Box height={'100%'} >
    <Header label={'Review Management'} />
    <Stack direction={'row'} px={5} justifyContent={'space-between'} alignItems={'center'} py={1}>
        <BUTTONBOX display={'flex'} py={1} sx={{ gap: { xl: 2, md: 1.5, xs: 1 }, flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' } }} >
            {['All', 'Pending'].map((res) => (
                <CustomButton key={res} label={res} active={active === res ? true : false}  onClick={() => setActive(res)} />
            ))}
        </BUTTONBOX>
        <CustomSearch placeholder={'Search'} onSearch={(value) => searchResult(value)} />
    </Stack>
    <Box px={2}>
        <Paper style={{
            borderRadius: "20px"
        }}>
            <AllReviews columns={columns} datas={active === "All" ? allPosts : pendingPosts} />
        </Paper>
    </Box>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        //onClick={handleClose}
        >
        <CircularProgress color="inherit" />
    </Backdrop>
</Box>
  )
}

export default ModeratorReviewManagement