import React, { useState ,useEffect} from 'react'
import { Dialog, DialogTitle, Box, DialogContent, Grid, Typography, Button, CircularProgress } from '@mui/material'
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import CustomImageUploader from '../../../components/Widgets/CustomImageUploder';
import CustomInput from '../../../components/Widgets/CustomInput';
import CustomTextArea from '../../../components/Widgets/CustomTextArea';
import CustomButton from '../../../components/Widgets/CustomButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { isEmpty, uniqueId } from 'lodash';
import { toast } from 'react-toastify';
import { Add, CheckCircleOutline, CheckOutlined } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import customAxios from '../../../CustomAxios';
import { useSelector } from 'react-redux';


const AddQuestion = ({ open, handleClose, quizId, setTestList, res, setRowlist }) => {


            

    const [loading, setLoading] = useState(false);
    const [images, setimages] = useState(null);
    const [previewEdit, setpreviewEdit] = useState(null);
    const [selected, setSelected] = useState(false);
    const [options, setOptions] = useState([
        { id: uniqueId(), value: '' }
    ]);
    const { mediaSize } = useSelector(state => state.auth);

    console.log({mediaSize})


    const schema = object().shape({
        question: yup.string().required('Question is Required')
    });


    const {
        control,
        formState: { errors },
        setValue,
        handleSubmit
    } = useForm({
        defaultValues: {
            question: '',
            image: null
        },
        resolver: yupResolver(schema)
    });


    const AddOption = () => {

        if (options?.length < 4) {
            let option = {
                id: uniqueId(),
                value: ''
            }
            options.push(option)
            setOptions([...options])
        }
        else {
            toast.error('Maximum four options are allowed')
        }


    }


    const saveQuestion = async (data) => {
        
        let noAnswers = options?.filter(opt => isEmpty(opt.value))
        if (noAnswers && noAnswers?.length > 0) {
            toast.warning("All options should have value")
        }
        if (selected === false) {
            toast.warning('Choose Answer')
            return
        }
        else {
            setLoading(true)
            let answers = options.map(ans => ans?.value);
            let formData = new FormData();
            if (res) {
                formData.append("id", res?._id);
            }
            formData.append("quiz_id", quizId);
            formData.append("question", data?.question);
            formData.append("options", JSON.stringify(answers));
            formData.append("correct_answer", selected);
            if (data?.image) {
                formData.append("image", data?.image);
            }

            let url = res ? `teacher/quiz/question/edit` : `teacher/quiz/addquestion`
            await customAxios.post(url, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(async response => {
                    setTestList(response.data.data.questions);
                    if(res){
                        toast.success("Questions Edited Successfully");
                    }else {
                        toast.success("Questions Added Successfully");
                    }
                   
                    setLoading(false)
                    setOptions([
                        { id: uniqueId(), value: '' }
                    ])

                    setValue('question', '')
                    setSelected(false);
                    setpreviewEdit(null);
                    setimages(null)
                    handleClose()
                }).catch(async error => {
                    setLoading(false)
                    toast.error(error)
                })
        }
    }


    useEffect(() => {
        if (res) {
            setValue('question', res?.question)
            let options = []
            res?.options.map((valres) => {
                let val = {
                    id: uniqueId(),
                    value: valres
                }
                options.push(val)
            })
            setOptions([...options])
            setSelected(res?.correct_answer)
        }
    }, [res])


    const clearClose = ()=>{
        setRowlist(null);
        setOptions([...options])
        handleClose()
    }

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={"lg"}
            onClose={clearClose}>
            <DialogTitle>
                <Box display={'flex'} py={1} justifyContent={'center'} >
                    <Typography variant='caption' fontFamily={'Quicksand'}
                        sx={{
                            fontSize: {
                                lg: 30,
                                md: 25,
                                sm: 18,
                                xs: 16,
                            },

                        }} letterSpacing={1} color={'#FF0053'} fontWeight={'700'}>
                            {res ? 'Edit Question' : 'Add Question'}
                    </Typography>
                    <HighlightOffIcon 
                    style={{ fontSize: '30px', cursor: 'pointer', position: 'absolute', right: 10 }} onClick={() => clearClose()} />
                </Box>
            </DialogTitle>
            <DialogContent >
                <Grid container justifyContent={'center'} alignItems={'center'}>
                    <Grid lg={8} md={8} xs={12} pr={{ xs: 0, sm: 0, md: 5 }} >
                        <CustomTextArea
                            control={control}
                            error={errors.question}
                            fieldName="question"
                            multiline={true}
                            height={150}
                            row={10}
                            fieldLabel="Question"
                            background={'#FFEFEC'}
                        />
                    </Grid>
                    <Grid lg={4} md={4} xs={12} alignItems={'center'} >
                        <CustomImageUploader
                            fieldLabel='Post Image'
                            accept={'image/*'}
                            control={control}
                            format={'image'}
                            error={errors.image}
                            fieldName='image'
                            type={"file"}
                            height="90px"
                            background="#ffff"
                            preview={images}
                            id="contained-button-file"
                            previewEditimage={previewEdit}
                            onChangeValue={(file, e) => {
                                setimages(file)
                                setValue("image", file)
                            }}
                        />
                    </Grid>
                    <Grid container spacing={2} py={2}>
                        {options?.map((opt, index) => (
                            <Grid item lg={3} md={3} xs={6} key={opt?.id}>
                                <Box position={"relative"}>
                                    <CustomInput
                                        defaultValue={opt.value}
                                        control={control}
                                        error={errors[opt?.id]}
                                        fieldName={opt?.id}
                                        fieldLabel="Answer"
                                        changeValue={(value) => options[index].value = value}
                                    />
                                    <Box
                                        onClick={() => {
                                            setSelected(index)
                                        }}
                                        position={"absolute"} top={26.7} right={0.57} justifyContent="center" alignItems={"center"} padding={1.21} bgcolor={index === selected ? "green" : "#E8E8E8"} borderRadius={2.8} style={{ cursor: 'pointer' }}>
                                        <CheckOutlined style={{ color: index === selected ? '#fff' : "#B4B4B4", fontWeight: 'bold' }} />
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                        {options?.length < 4 && <Button
                            onClick={AddOption}
                            startIcon={<Add fontWeight={'bold'} style={{ fontSize: 40 }} />}
                            style={{
                                width:50,
                                borderRadius: 12,
                                backgroundColor: "#FF0053",
                                color: '#fff',
                                height: 45,
                                paddingLeft: 5,
                                fontWeight: 'bold',
                                paddingLeft: 15,
                                marginTop: 44,
                                marginLeft: 5
                            }}
                        >
                        </Button>}
                    </Grid>
                </Grid>
                <Grid display={"flex"} justifyContent={"center"} py={2}>
                    <CustomButton  disable={loading ? true : false} label={loading ? <CircularProgress style={{ color: '#FF0053' }} size={20} /> : res ? 'Update' : 'Submit'} onClick={handleSubmit(saveQuestion)} />
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default AddQuestion