import { Box, Button, Stack, styled } from '@mui/material'
import React, { useState, useEffect, useTransition } from 'react'
import Header from '../Header'
import FilterListIcon from '@mui/icons-material/FilterList';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import CustomButton from '../../components/Widgets/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import AdminSize from './Size';
import AddSize from './AddSize';
import { useSelector } from 'react-redux';
const AdminSettings = () => {


    const [active, setActive] = useState('Size')
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openC, setOpenC] = useState(false);

    //style...........................
    const BUTTONBOX = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {
            display: 'none',
        }
    }))
    const BUTTONBOXSMALL = styled(Box)(({ theme }) => ({
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        }
    }))


    const handleOpenC = () => setOpenC(true);
    const handleCloseC = () => {
        setOpenC(false);
    }


    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    return (
        <Box>
            <Header label={'Settings'} />
            <Stack direction={'row'} justifyContent={'space-between'} px={5} py={2}>
                {['Size'].map((res) => (
                    <CustomButton label={res} active={active === res ? true : false} onClick={() => {
                        setActive(res)
                        localStorage.setItem('defaultInd', res)
                    }} />
                ))}
                <CustomIconButton label={'Add'} Icon={AddIcon} onClick={() => setOpenC(true)} />
            </Stack>

            {active === 'Size' && <AdminSize />}
            {openC && <AddSize open={openC} handleClose={handleCloseC} />}
        </Box>
    )
}

export default AdminSettings