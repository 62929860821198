import { EMAIL_ADDRESS_STORE, IMAGE_RESOLUTION_FAIL, IMAGE_RESOLUTION_SUCCESS, LOADER, LOADING, LOGIN_FAILED, LOGIN_SUCCESS, REG_USER_FAIL, REG_USER_SUCCESS, RESET_ERROR } from "../constants/userConstant";

export const authReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                userLogined: null,
                authReg: null,
                error: null

            }
        case LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case LOADER:
            return {
                ...state,
                loader: action.payload
            }
        case EMAIL_ADDRESS_STORE:
            return {
                ...state,
                emailAddress: action.payload
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                userLogined: true,
                userData: action.payload
            }
        case LOGIN_FAILED:
            return {
                ...state,
                error: action.payload
            }
        case REG_USER_SUCCESS:
            return {
                ...state,
                authReg: true
            }
        case REG_USER_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case IMAGE_RESOLUTION_SUCCESS:
            return {
                ...state,
                mediaSize: action.payload
            }
        case IMAGE_RESOLUTION_FAIL:
            return {
                ...state,
                error: action.payload
            }

        default:
            return state;

    }

}