import { Avatar, Backdrop, Box, CircularProgress, Grid, IconButton, styled, Typography } from '@mui/material';
import React, { useContext, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CustomBackHeader from '../../../components/Widgets/CustomBackarrowHeader.js';
import CustomIconButton from '../../../components/Widgets/CustomIconButton/index.js';
import CustomInput from '../../../components/Widgets/CustomInput/index.js';
import UserContext from '../../../helpers/user';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import RefreshIcon from '@mui/icons-material/Refresh';
import customAxios from '../../../CustomAxios.js';
import { toast } from "react-toastify";
import CustomInputEndIcon from '../../../components/Widgets/CustomInputEndICon/index.js';
import { useNavigate } from 'react-router-dom';
import { IMAGE_URL } from '../../../config/constants.js';

const TeacherProfile = () => {

    const navigate = useNavigate();
    const user = useContext(UserContext);
    const dispatch = useDispatch()
    const [Image, setImage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [profileList, setProfileList] = useState(null);



    //mobile Validation
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const schema = object().shape({
        name: yup.string().required('Name is required'),
        email: yup.string().email().required('Email is required'),
        address: yup.string().required('Adress is Required'),
        phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Mobile Number is Required'),
    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({

        resolver: yupResolver(schema)
    });

    //style....................................................................

    const BOX = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    }))

    const IMGBOX = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '10px'
        }
    }))


    useEffect(() => {
        getProfile();
    }, [])


    const getProfile = async (data) => {
        setLoading(true)
        await customAxios.get(`teacher/profile/${user?.user._id}`)
            .then(async response => {
                localStorage.setItem("user", JSON.stringify(response.data.data));
                setProfileList(response?.data?.data)
                setImage(null)
                setLoading(false);
            }).catch(async error => {
                toast.error({ error })
                setLoading(false)
            })
    }



    const updateProfile = async (data) => {
        setLoading(true)
        const formData = new FormData();
        formData.append('id', profileList?._id);
        if (Image) {
            formData.append('image', Image);
        }
        await customAxios.post(`teacher/profile/update`, formData)
            .then(async response => {
                setProfileList(response?.data?.data)
                toast.success('Profile Updated Successfully')
                setLoading(false);
            }).catch(async error => {
                toast.error( error.message)
                setLoading(false)
            })
    }

    useEffect(() => {
        if (profileList) {
            setValue('name', profileList?.name);
            setValue('email', profileList?.email);
            setValue('phone', profileList?.phone);

        }
    }, [profileList])




    return (
        <Box>
            <CustomBackHeader label={'User Profile'} hidden={true} />
            <BOX display={"flex"} flex={1} px={5} mt={10} gap={5}>
                <Box flex={2}>
                    <IMGBOX >
                        <Box position={"relative"} width={"200px"} height={"200px"} >
                            <Avatar src={Image ? URL.createObjectURL(Image) : `${IMAGE_URL}${profileList?.teacher_details?.image}`} style={{ width: "200px", height: "200px", objectFit: "contain", border: '5px solid #FF0053' }}>
                            </Avatar>
                            <Box display={"flex"} position={"absolute"} zIndex={"100"} justifyContent={"center"} bottom={"0px"}
                                width={"200px"}  >
                                <IconButton color="primary" aria-label="upload picture" component="label" className='iconbutton' >
                                    <input hidden name="myImage" type="file" onChange={(e) => setImage(e.target.files[0])} />
                                    <PhotoCamera className='photoicon' sx={{
                                        color: '#FF0053',

                                    }} />
                                </IconButton>
                            </Box>
                        </Box>
                    </IMGBOX>
                </Box>
                <Box flex={8}>
                    <Box py={1}>
                        <Typography sx={{
                            fontFamily: 'Quicksand',
                            fontWeight: '700',
                            letterSpacing: '1px',
                            pl: '5px',
                            color: '#FF0053',
                            fontSize: {
                                lg: 25,
                                md: 20,
                                sm: 16,
                                xs: 14,
                            }
                        }}>Basic Details</Typography>
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6} xl={4}>
                            <CustomInput
                                view={true}
                                control={control}
                                error={errors.name}
                                fieldName="name"
                                fieldLabel="Name"

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} xl={4}>
                            <CustomInputEndIcon
                                onclick={() => navigate('/teacherprofilelogin', {
                                    state: {
                                        mode: 'email',
                                        item: profileList
                                    }
                                })}
                                view={true}
                                control={control}
                                error={errors.email}
                                fieldName="email"
                                fieldLabel="Email Address"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} xl={4}>
                            <CustomInputEndIcon
                                onclick={() => navigate('/teacherprofilelogin', {
                                    state: {
                                        mode: 'mobile number',
                                        item: profileList
                                    }
                                })}
                                view={true}
                                control={control}
                                error={errors.phone}
                                fieldName="phone"
                                fieldLabel="Mobile Number"
                            />
                        </Grid>

                    </Grid>
                </Box>
            </BOX>
            <Box display={'flex'} justifyContent={'center'} py={10}>
                <CustomIconButton onClick={() => updateProfile()}
                    label={loading ? <CircularProgress style={{ color: '#FF0053' }} size={20} /> : 'Update'}
                    //  disabled={loading ? true : false}

                    Icon={RefreshIcon} />
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default TeacherProfile