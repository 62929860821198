import React, { useState, useEffect } from 'react'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone'
import { Avatar, Box, Stack, Typography, Button } from '@mui/material'
import DataTable from '../../components/Widgets/CustomTable';
import CreatePlansModal from './CreatePlansModal';


import { useDispatch, useSelector } from 'react-redux';
import CreateLogModal from './CreateLogModal';
import { getIndividualPlanslogs } from '../../Redux/actions/individualAction';

const CustomPlan = ({ avatar, label, res, log }) => {
   
    const dispatch = useDispatch();


    const { loading, loader } = useSelector(state => state.auth);


    const [open, setOpen] = useState(false);
    const [openL, setOpenL] = useState(false);
    const [editRes, setEditREs] = useState(null)
   


    const [view, setView] = useState(false)

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOpenL = () => setOpenL(true);
    const handleCloseL = () => setOpenL(false);



    const handleOpenRes = (data) => {
        setEditREs(data)
        setView(false)
        handleOpen()

    }
    const handleOpenView = (data, mode) => {
        setEditREs(data)
        setView(true)
        handleOpen()
    }

    const handleOpenLog = (log) => {
        dispatch(getIndividualPlanslogs(log))
        handleOpenL()
    }



    const columns = [

        {
            field: 'planlabel',
            headerName: 'Plan Label',
            flex: 1
        },
        {
            field: 'plan_type',
            headerName: 'Plan Type',
            flex: 1
        },
        {
            field: 'planduration',
            headerName: 'Plan Duration',
            flex: 1
        },
      
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,

        },
    ];

  
    return (
        <>
            <Box>
                <Box bgcolor={'#F9F9F9'} py={3} borderRadius={5} position={'relative'}>
                    <Avatar variant='square' src={avatar} sx={{ position: 'absolute', top: -20 }}  ></Avatar>
                    <Stack justifyContent={'space-between'} direction={'row'}>
                        <Typography px={6} sx={{
                            fontSize: {
                                lg: 20,
                                md: 18,
                                sm: 14,
                                xs: 12,
                            },
                            fontFamily: 'Quicksand',
                            letterSpacing: 1,
                            fontWeight: '700',
                            color: '#FF0053'

                        }} >{label}</Typography>
                        {/* <Typography px={6} sx={{
                            fontSize: {
                                lg: 18,
                                md: 16,
                                sm: 14,
                                xs: 12,
                            },
                            fontFamily: 'Quicksand',
                            fontWeight: '700',
                            color: '#006FFF',
                            cursor: 'pointer'

                        }} onClick={() => handleOpenLog(log)}>View Log</Typography> */}
                    </Stack>
                    <Box px={5}>
                        <DataTable
                            borderGrid={2}
                            border={2}
                            height={200}
                            rows={res}
                            columns={columns}
                            id={"_id"}
                        />
                    </Box>

                    <Stack direction={'row'} alignItems={'center'} px={5} py={2}>
                        {/* <Typography variant='caption' sx={{
                            px: '1px',
                            fontSize: {
                                lg: 18,
                                md: 16,
                                sm: 14,
                                xs: 12,
                            },
                            fontFamily: 'Quicksand',
                            fontSize: 14,
                            height: 30,
                            width: 90,
                            bgcolor: '#FFEFEC',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            {'Trial Days'}

                        </Typography>
                        <Typography variant='caption' sx={{
                            px: '1px',
                            fontSize: {
                                lg: 18,
                                md: 16,
                                sm: 14,
                                xs: 12,
                            },
                            fontFamily: 'Quicksand',
                            height: 30,
                            fontSize: 14,
                            width: 40,
                            bgcolor: '#FF0053',
                            display: 'flex',
                            fontWeight: 'bold',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#fff'

                        }}>
                            {res?.[0]?.trial}
                        </Typography> */}
                    </Stack>
                    {open && <CreatePlansModal open={open} handleClose={handleClose} res={editRes} view={view} />}
                    {openL && <CreateLogModal open={openL} handleClose={handleCloseL} />}
                </Box>
            </Box>

        </>
    )
}

export default CustomPlan