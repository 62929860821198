import { Backdrop, Box, CircularProgress, Paper } from '@mui/material'
import React, { useEffect, useTransition, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import CustomSearch from '../../components/Widgets/CustomSearch';
import DataTable from '../../components/Widgets/CustomTable';
import Header from '../Header';
import AddIcon from '@mui/icons-material/Add';
import { Stack } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import CustomSwitch from '../../components/Widgets/CustomSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSchool, getSchool, SchoolStatus } from '../../Redux/actions/schoolActions';
import moment from 'moment/moment';
import { toast } from "react-toastify";
import { RESET_ERROR } from '../../Redux/constants/userConstant';
import CustomDelete from '../../components/Widgets/CustomDelete';
const SchoolManagement = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { schoolList, schoolStatusChanged, error, schoolDeleted } = useSelector(state => state.school);
    const { loading } = useSelector(state => state.auth);

    const [resData, setResDta] = useState([]);
    const [pending, startTransition] = useTransition();
    const [iD, setID] = useState(null);
    const [openD, setOpenD] = useState(false);

    const handleOpenD = () => setOpenD(true);
    const handleCloseD = () => setOpenD(false);




    const columns = [
        {
            field: 'school_id',
            headerClassName: 'text',
            headerName: 'School ID',
            width: 130,
            valueGetter: (params) => (`#${params.row?.school_id}`),
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'Created Date',
            headerName: 'Created Date',
            type: 'date',
            width: 180,

            valueGetter: (params) => (moment(params.row.created_at).format('DD/MM/YYYY')),
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'name',
            headerName: 'School Name',
            headerAlign: 'center',
            align: 'center',
            width: 300,
            renderCell: ({ row }) => (
                <Link style={{ color: '#0008FF', cursor: 'pointer', flexWrap: 'wrap', wordWrap: 'inherit' }} 
                to={"/schoolUser"}  
                state={{ item: row }}
                >{row?.name}</Link>
            )


        },
        {
            field: 'fees',
            headerName: 'Fees',
            width: 130,
            headerAlign: 'center',
            align: 'center',


        },
        {
            field: 'country',
            headerName: 'Country',
            width: 140,
            headerAlign: 'center',
            align: 'center',
            // type:'number',


        },

        {
            field: 'Status',
            headerName: 'Status',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <Box>
                    <CustomSwitch checked={row?.status === "active" ? true : false}
                        onClick={(e) => ChangeStatus(e.target.checked, row?._id)} />
                </Box>
            )
        },
        {
            field: 'Action',
            headerName: 'Action',
            width: 120,
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={1} direction={'row'}>
                    <RemoveRedEyeIcon
                        onClick={() => navigate('/ViewSchoolManagement', {
                            state: {
                                item: row
                            }
                        })}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer',

                        }} />
                    <BorderColorTwoToneIcon
                        onClick={() => navigate(`/AddSchoolManagement/${row?._id}`)}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />
                    <DeleteOutlineTwoToneIcon
                        onClick={() => handleDelete(row?._id)}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }} />

                </Stack>
            )
        },

    ];

    const searchuser = (value) => {
        let school = schoolList?.filter(user => user?.name.toString().toLowerCase().includes(value.toLowerCase())
            || `#${user?.school_id}`.toString().toLowerCase().includes(value.toLowerCase())
        )
        startTransition(() => {
            setResDta(school)
        })
    }


    const ChangeStatus = (checked, row) => {
        let status = checked === true ? 'active' : 'inactive'
        let val = {
            id: row,
            status: status
        }
        dispatch(SchoolStatus(val))
    }


    const handleDelete = (id) => {
        setID(id);
        handleOpenD();
    }


    useEffect(() => {

        if (schoolStatusChanged) {
            toast.success(`Status Changed Successfully`);
            dispatch({
                type: RESET_ERROR
            })

        }

        if (schoolDeleted) {
            toast.success(`Deleted Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            handleCloseD();

        }

        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, schoolStatusChanged, schoolDeleted])


    useEffect(() => {
        if (schoolList) {
            setResDta(schoolList)
        }
    }, [schoolList])


    useEffect(() => {
        dispatch(getSchool())
    }, [])

    return (
        <Box height={'100%'} >
            <Header label={'School Management'} />
            <Box px={2} py={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <CustomIconButton onClick={() => navigate('/AddSchoolManagement')} label={'Add'} Icon={AddIcon} />
                <CustomSearch placeholder={'School ID, School Name'} onSearch={(value) => searchuser(value)} />
            </Box>
            <Box px={2}>
                <Paper style={{
                    borderRadius: "20px"
                }}>
                    <DataTable
                        rows={resData}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {openD && <CustomDelete open={handleOpenD} handleClose={handleCloseD} mode={'School'} id={iD} />}
        </Box>
    )
}

export default SchoolManagement