import { Box, CircularProgress, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js'
import DataTable from '../../components/Widgets/CustomTable/index.js'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomSelect from '../../components/Widgets/CustomSelect/index.js';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment/moment.js';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrder } from '../../Redux/actions/orderActions.js';
import { toast } from "react-toastify";
import { RESET_ERROR } from '../../Redux/constants/userConstant.js';
const EditOrderManagement = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [res, setRes] = useState([]);

    console.log({res})
    const [payment, setPayment] = useState(null);

    const { loading, loader } = useSelector(state => state.auth);
    const { error, orderList,orderUpdated } = useSelector(state => state.order);
  

    const schema = object().shape({
        payment_status: yup.string().required('Required')

    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({

        defaultValues:{
            id:location?.state?.item?._id

        },

        resolver: yupResolver(schema)
    });

    const columns = [
        {
            field: 'dateTime',
            headerName: 'Date & Time',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (moment(params.row.created_at).format('DD/MM/YYYY h:mm A'))

        },
        {
            field: 'orderId',
            headerName: 'Order ID',
            type: 'date',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (`#${params.row?.order_id}`)


        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (params.row?.userdetails?.name)

        },
        {
            field: 'mobile',
            headerName: 'Mobile Number',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (params.row?.userdetails?.mobile)

        },
      
        {
            field: 'payment_status',
            headerName: 'Payment Status',
            flex: 1,
            headerAlign: 'center',
            align: 'center',


        },
        {
            field: 'Expiry_Date',
            headerName: 'Expiry Date',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (moment(params.row.expiry).format('DD/MM/YYYY'))

        },



    ];




    useEffect(() => {
        if (location?.state?.item) {
            setRes([location?.state?.item])
            setPayment(location?.state?.item?.payment_status)
            setValue('payment_status', location?.state?.item?.payment_status)
        }
    }, [location?.state?.item])





    const SubmitForm = (data) => {
        dispatch(updateOrder(data))
    }

    useEffect(() => {
    
        if (orderUpdated) {
          toast.success(`Order Updated Successfully`);
          dispatch({
            type: RESET_ERROR
          })
          navigate(-1)
        }
    
        if (error) {
          toast.error(`${error.message}`);
          dispatch({
            type: RESET_ERROR
          })
        }
    
      }, [error,orderUpdated])

    return (
        <Box>
            <CustomBackHeader label={'Edit Order'} />
            <Box py={2} px={5}>
                <DataTable
                    height={150}
                    rows={res}
                    columns={columns}
                    id={'_id'}
                />
                <Box py={2} width={'20vw'}>
                    <CustomSelect
                        control={control}
                        error={errors.payment_status}
                        fieldName="payment_status"
                        fieldLabel="Payment Status"
                        background="#FFEFEC"
                        size="16px"
                        value={payment}
                        onChangeValue={(e) => {
                            setPayment(e.target.value)
                            setValue('payment_status', e.target.value)
                            setError('payment_status', '')
                        }}
                    >
                        <MenuItem value="" disabled >
                            <em>Payment Status</em>
                        </MenuItem>
                        {[{value:'paid',name:'Paid'},{value:'pending',name:'Pending'},{value:'completed',name:'Completed'},].map((res, i) => (
                            <MenuItem value={res?.value} key={i} >
                                {res?.name}
                            </MenuItem>
                        ))}

                    </CustomSelect>

                </Box>
                <Box py={5} justifyContent={'center'} display='flex'>
                    <CustomIconButton
                        label={loading ? <CircularProgress style={{ color: '#FF0053' }} size={20} />  : 'Update'}
                        Icon={RefreshIcon}
                        onClick={handleSubmit(SubmitForm)}
                        disabled={loading ? true : false} />
                </Box>
            </Box>
        </Box>
    )
}

export default EditOrderManagement