import { Box, Button, Stack, styled } from '@mui/material'
import React, { useState, useEffect, useTransition } from 'react'
import CustomButton from '../../components/Widgets/CustomButton'
import CustomIconButton from '../../components/Widgets/CustomIconButton'
import CustomSearch from '../../components/Widgets/CustomSearch'
import Header from '../Header'
import FilterListIcon from '@mui/icons-material/FilterList';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Plan from './Plan'
import Network from './Network'
import Student from './Student'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getIndividualNetwork, getIndividualPlans, getStudentIndividual } from '../../Redux/actions/individualAction'
export const IndividualManagement = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { loading, loader } = useSelector(state => state.auth);
    const { studentList, networkList, planList } = useSelector(state => state.individual);



    let focus = localStorage.getItem('defaultIndA')
    const [active, setActive] = useState(focus ? focus : 'Students')
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [pending, startTransition] = useTransition();

    const [studentRes, setStudentRes] = useState([]);
    const [networkRes, setNetworktRes] = useState([]);
    const [planRes, setPlanRes] = useState([])


    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    //style...........................
    const BUTTONBOX = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {
            display: 'none',
        }
    }))
    const BUTTONBOXSMALL = styled(Box)(({ theme }) => ({
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        }
    }))


    //class Table Request..............................
    useEffect(() => {
        switch (active) {
            case 'Students':
                dispatch(getStudentIndividual())
                break;
            case 'Network':
                dispatch(getIndividualNetwork())
                break;
            case 'Plans':
                dispatch(getIndividualPlans())
                break;

        }
    }, [active])


    useEffect(() => {
        if (studentList) {
            setStudentRes(studentList)
        }
    }, [studentList])


    useEffect(() => {
        if (networkList) {
            setNetworktRes(networkList)
        }
    }, [networkList])

    useEffect(() => {
        if (planList) {
            setPlanRes(planList)
        }
    }, [planList])



    //search teacher list ..............................
    const searchStudent = (value) => {
        let schoolStudent = studentList?.filter(student => student?.name.toString().toLowerCase().includes(value.toLowerCase()) ||
            `#${student?.user_id}`.toString().toLowerCase().includes(value.toLowerCase()) || student?.class.toString().toLowerCase().includes(value.toLowerCase())
        )
        startTransition(() => {
            setStudentRes(schoolStudent)
        })
    }

    //search teacher list ..............................
    const searchNetwork = (value) => {
        let schoolNetwork = networkList?.filter(net =>
            `#${net?.post_id}`.toString().toLowerCase().includes(value.toLowerCase()) || net?.creator_details?.name?.toLowerCase().includes(value.toLowerCase())
        )
        startTransition(() => {
            setNetworktRes(schoolNetwork)
        })
    }

    //search function.........................................
    const searchResult = (value) => {
        switch (active) {
            case 'Students':
                searchStudent(value)
                break;
            case 'Network':
                searchNetwork(value)
                break;
        }
    }


    useEffect(() => {
        if (focus) {
            setActive(focus)
        }
    }, [focus])



    return (
        <Box>
            <Header label={'Individual Management'} />
            <Stack direction={'row'} px={5} justifyContent={'space-between'} alignItems={'center'} py={1}>
                <BUTTONBOX display={'flex'} py={1} sx={{ gap: { xl: 2, md: 1.5, xs: 1 }, flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' } }} >
                    {['Students', 'Network', 'Plans'].map((res) => (
                        <CustomButton label={res} active={active === res ? true : false} onClick={() => {
                            setActive(res)
                            localStorage.setItem('defaultIndA', res)
                        }} />
                    ))}
                </BUTTONBOX>
                <BUTTONBOXSMALL>
                    <CustomIconButton
                        active={active}
                        Icon={FilterListIcon} onClick={handleClick} />
                </BUTTONBOXSMALL>
                {active !== 'Plans' &&
                    <CustomSearch placeholder={'Search'} onSearch={searchResult} />}
            </Stack>
            {open && <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {['Students', 'Network', 'Plans'].map((res) => (
                    <MenuItem onClick={handleClose} >
                        <Button style={{
                            color: '#FF0053',
                            backgroundColor: '#FFFAFA'
                        }}  onClick={() => {
                            setActive(res)
                            localStorage.setItem('defaultInd', res)
                        }}>{res}</Button>

                    </MenuItem>
                ))}
            </Menu>}


            {active === 'Plans' &&
                <Plan res={planRes} />}
            {active === 'Network' && <Network res={networkRes} />}
            {active === 'Students' && <Student res={studentRes} />}

        </Box>
    )
}
