export const CREATE_ADS_SUCCESS = "CREATE_ADS_SUCCESS"
export const CREATE_ADS_FAIL="CREATE_ADS_FAIL"
export const GET_ADS_SUCCESS = 'GET_ADS_SUCCESS'
export const GET_ADS_FAIL = 'GET_ADS_FAIL'
export const DELETE_ADS_SUCCESS = 'DELETE_ADS_SUCCESS'
export const DELETE_ADS_FAIL = 'DELETE_ADS_FAIL'
export const ADS_STATUS_SUCCESS = 'ADS_STATUS_SUCCESS'
export const ADS_STATUS_FAIL= 'ADS_STATUS_FAIL'
export const UPDATE_ADS_SUCCESS = 'UPDATE_ADS_SUCCESS'
export const UPDATE_ADS_FAIL='UPDATE_ADS_FAIL'
export const GET_SINGLE_ADS_SUCCESS = 'GET_SINGLE_ADS_SUCCESS'
export const GET_SINGLE_ADS_FAIL = 'GET_SINGLE_ADS_FAIL'