import React, { useState, useEffect } from 'react'
import { Avatar, Box, Stack, styled, Button, Typography, Grid, CircularProgress, Backdrop } from '@mui/material';
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js';
import CustomInput from '../../components/Widgets/CustomInput/index.js';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomSelect from '../../components/Widgets/CustomSelect/index.js';
import MenuItem from '@mui/material/MenuItem';
import BackupIcon from '@mui/icons-material/Backup';
import CustomIconButton from '../../components/Widgets/CustomIconButton/index.js';
import AddIcon from '@mui/icons-material/Add';
import CustomTextArea from '../../components/Widgets/CustomTextArea/index.js';
import CustomCalender from '../../components/Widgets/CustomCalender/index.js';
import { toast } from "react-toastify";
import CustomImageUploader from '../../components/Widgets/CustomImageUploder/index.js';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { createAds, getSingleAds, updateAds } from '../../Redux/actions/adsActions.js';
import { RESET_ERROR } from '../../Redux/constants/userConstant.js';
import { useNavigate, useParams } from 'react-router-dom';
import CustomSpinner from '../../components/Widgets/CustomSpinner/index.js';
import { IMAGE_URL } from '../../config/constants.js';
const AddAds = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { id } = useParams();

  const { loading, loader, mediaSize } = useSelector(state => state.auth);
  const { error, adsCreated, adsSingleList, adsUpdated } = useSelector(state => state.ads);






  const [dateTime, setDateTime] = useState(null);
  const [type, setType] = useState(null);
  const [video, setVideo] = useState(null);
  const [images, setimages] = useState(null)
  const [previewEdit, setPreviewEdit] = useState(null);
  const [selectCountry, setSelectCountry] = useState(null);
  const [videoPreviewEdit, setVideoPreviewEdit] = useState(null);
  const [thumbnails, setThumbnails] = useState(null);
  const [thumbnailsPreview, setThumbnailsPreview] = useState(null);
  const [postThumbnail, setPostThumbnail] = useState(false);


  console.log({ images   })


  const schema = object().shape({
    text: yup.string().required('Text is Required'),
    country: yup.string().required('Country is required'),
    position: yup.string().required('Position is Required'),
    expirydate: yup.string().required('Expiry Date is Required'),
    heading: yup.string().required('Heading is required'),
    image_video: yup.string().required('Image/Video is Required')
  });



  const {
    register,
    handleSubmit,
    setFieldValue,
    control,
    formState: { errors },
    reset,
    setValue,
    setError
  } = useForm({
    defaultValues: {
      school: null,
      expirydate: adsSingleList ? adsSingleList?.expiry_date : new Date()


    },

    resolver: yupResolver(schema)
  });

  const onChangeDateNtime = (value) => {

    setValue('expirydate', value)
    setError('expirydate', '')
    setDateTime(value)

  }


  const setimagefile = (e) => {

    setPreviewEdit(null)
    setimages(null)
    setVideo(null)
    setType(e.type.split('/', 1))
    if (e.type.split("/", 1).includes('video')) {
      if (e.size > mediaSize[0]?.videoSize) {
        toast.warning(`Video Size Should be less than ${mediaSize[0]?.videoOrginal}MB`);
        setPreviewEdit(null)
        setimages(null)
        setVideo(null)
        setType('image')
      } else {
        setVideo(e);
        setPostThumbnail(true);
      }
    } else if (e.type.split("/", 1).includes('image')) {
     
      setPostThumbnail(false);
      setThumbnails(null);
      if (e.size > mediaSize[0]?.imageSize) {
        toast.warning(`Image Size Should be less than ${mediaSize[0]?.imageOrginal}MB`);
        setPreviewEdit(null)
        setimages(null)
      } else {
        setPreviewEdit(null)
        setimages(e)
      }

    }
  }

  const SubmitForm = (data) => {

    if (id && adsSingleList) {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('heading', data?.heading);
      formData.append('country', data?.country);
      formData.append('school', data?.school ? data?.school : "null");
      formData.append('expiry_date', moment(data?.expirydate).format('YYYY-MM-DD'));
      formData.append('position', data?.position ?  data?.position : "null");
      formData.append('text', data?.text);
      if (images || video) {
        formData.append('file', images ? images : video);
      }
      if (thumbnails) {
        formData.append('thumbnail', thumbnails);
      }
      dispatch(updateAds(formData))

    } else {
      const formData = new FormData();
      formData.append('heading', data?.heading);
      formData.append('country', data?.country);
      formData.append('school', data?.school ? data?.school : "null");
      formData.append('expiry_date', moment(data?.expirydate).format('YYYY-MM-DD'));
      formData.append('position', data?.position ?  data?.position : "null");
      formData.append('text', data?.text);
      if (images || video) {
        formData.append('file', images ? images : video);
      }
      if (thumbnails) {
        formData.append('thumbnail', thumbnails);
      }
      dispatch(createAds(formData))

    }

  }

  useEffect(() => {
    if (id) {
      dispatch(getSingleAds(id))
    }
  }, [id])



  useEffect(() => {
    if (id && adsSingleList) {
      setValue('text', adsSingleList?.text)
      setValue('heading', adsSingleList?.heading)
      setValue('school', adsSingleList?.school === "null" ? "" : adsSingleList?.school)
      setValue('country', adsSingleList?.country)
      setSelectCountry(adsSingleList?.country)
      setValue('position', adsSingleList?.position)
      setValue('expiry_date', adsSingleList?.expiry_date)
      setDateTime(adsSingleList?.expiry_date)
      setValue('image_video', adsSingleList?.file)
      if (adsSingleList?.type === 'image') {
        setPreviewEdit(adsSingleList?.file)
        setType('image')
      } else {
        setVideoPreviewEdit(adsSingleList?.file)
        setType('video')
      }

      if (adsSingleList.type === 'video') {
        setPostThumbnail(true)
        setThumbnailsPreview(`${IMAGE_URL}${adsSingleList?.thumbnail}`)
      }
    }
  }, [adsSingleList])

  useEffect(() => {
    if (adsCreated) {
      toast.success(`Ads Created Successfully`);
      dispatch({
        type: RESET_ERROR
      })
      navigate(-1)
    }
    if (adsUpdated) {
      toast.success(`Ads Updated Successfully`);
      dispatch({
        type: RESET_ERROR
      })
      navigate(-1)
    }
    if (error) {
      toast.error(`${error.message}`);
      dispatch({
        type: RESET_ERROR
      })
    }

  }, [error, adsCreated, adsUpdated])


  return (
    <Box>
      <CustomBackHeader label={id ? 'Edit Ad' : 'Add Ad'} />
      <Box px={5} mt={5}>
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} xs={12}>
            <CustomInput
              control={control}
              error={errors.heading}
              fieldName="heading"
              fieldLabel="Ad Heading "
            />
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <CustomSelect
              control={control}
              error={errors.country}
              fieldName="country"
              fieldLabel="Country"
              background="#FFEFEC"
              size="16px"
              value={selectCountry}
              onChangeValue={(e) => {
                setSelectCountry(e.target.value)
                setValue('country', e.target.value)
                setError('country', '')
              }}
            >
              <MenuItem value="" disabled >
                <>Select Country</>
              </MenuItem>
              {['India', 'UAE'].map((res, i) => (
                <MenuItem key={i} value={res}  >
                  {res}
                </MenuItem>

              ))}
            </CustomSelect>
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <CustomInput
              control={control}
              error={errors.school}
              fieldName="school"
              fieldLabel="School(Optional)"
            />
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <CustomInput
              control={control}
              error={errors.position}
              fieldName="position"
              fieldLabel="Ad Position"
            />
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <CustomCalender
              minDateres={new Date}
              height="48px"
              fieldLabel="Expiry date"
              control={control}
              error={errors.expirydate}
              fieldName="expirydate"
              type={"text"}
              readOnly={true}
              background="#FFFFFF"
              value={dateTime}
              onChangeValue={onChangeDateNtime}
            />
          </Grid>
          <Grid item lg={6} md={8} xs={12}>
            <CustomTextArea
              control={control}
              error={errors.text}
              fieldName="text"
              multiline={true}
              height={150}
              row={10}
              fieldLabel="Text"
              background={'#FFEFEC'}
            />
          </Grid>
          <Grid item lg={3} md={4} xs={7}>
            <CustomImageUploader
              fieldLabel='Upload Image / Video'
              accept={'image/*,video/*'}
              control={control}
              format={type ? type : 'image'}
              error={errors.image_video}
              vedeoPreview={videoPreviewEdit}
              fieldName='image_video'
              type={"file"}
              height={424}
              width={640}
              background="#ffff"
              id="contained-button-file"
              preview={images}
              video={video}
              previewEditimage={previewEdit}
              onChangeValue={(file, e) => {
                setimagefile(file)
                setValue("image_video", file)
                setError('image_video', '')
              }}
            />
          </Grid>

          {postThumbnail &&
            <Grid item lg={3} md={4} xs={12}>
              <CustomImageUploader
                fieldLabel='Add Thumbnail'
                accept={'image/*'}
                control={control}
                format={'image'}
                error={errors.thumbnails}
                fieldName='thumbnail'
                type={"file"}
                height={500}
                width={500}
                background="#ffff"
                id="contained-button-file"
                preview={thumbnails}
                previewEditimage={thumbnailsPreview}
                onChangeValue={(file, e) => {
                  setThumbnails(file)
                  setValue("thumbnail", file)
                  setThumbnailsPreview(null)
                }}
              />
            </Grid>}
        </Grid>
      </Box>
      <Box display={'flex'} justifyContent={'center'} py={10}>
        <CustomIconButton
          label={loading ? <CircularProgress style={{ color: '#FF0053' }} size={20} /> : !id ? 'Add' : 'Update'}
          Icon={AddIcon} onClick={handleSubmit(SubmitForm)} disabled={loading ? true : false} />
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      //onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}

export default AddAds