import { Avatar, Box, Stack, styled } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import banner from '../../../../Assets/images/homescreen.png'
import Login from '../../../../Screens/auth'
import logo from '../../../../Assets/images/Logo.png';
import UserContext from '../../../../helpers/user'
const LoginLayout = () => {

    const user = useContext(UserContext);
    const navigate = useNavigate()

    useEffect(() => {
      
        if(user?.user){
            navigate('/')
        }
    }, [user])
    

    const BOX = styled(Box)({
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(${banner})`,
        alignItems: 'center',
        justifyContent: 'center',
        objectFit: 'contain',
        display: 'flex',
        flexDirection:'column'

    })

    return (
        <BOX>
            <Avatar src={logo} variant="square" sx={{ width: 250, height: 100 }} />
            <Outlet />
        </BOX>
    )
}

export default LoginLayout