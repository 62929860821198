import { Backdrop, Box, CircularProgress, Paper } from '@mui/material'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import CustomSearch from '../../components/Widgets/CustomSearch';
import DataTable from '../../components/Widgets/CustomTable';
import { capitalize } from 'lodash';
import CustomSwitch from '../../components/Widgets/CustomSwitch';
import { Stack } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
const Network = ({ res }) => {

    const navigate = useNavigate();
    const { loading, loader } = useSelector(state => state.auth);

    

    const columns = [
        {
            field: 'post_id',
            headerName: 'Post ID',
            width: 130,
            valueGetter: (params) => (`#${params.row?.post_id}`),
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'createdOn',
            headerName: 'Created Date',
            type: 'date',
            width: 180,
            valueGetter: (params) => (moment(params.row.created_at).format('DD/MM/YYYY')),
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'name',
            headerName: 'Creator Name',
            width: 160,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (params?.row?.creator_details?.name),

        },
        {
            field: 'class',
            headerName: 'Class',
            width: 150,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'reviewed_by',
            headerName: 'Reviewed By',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (params?.row?.reviewed_by),
        },
        {
            field: 'status',
            headerName: 'Post Status',
            width: 150,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <span style={{
                    fontFamily: 'QuickSand', fontWeight: 'bold',
                    color:
                        row?.status === 'Rejected' ? '#FF0000' :
                            row?.status === 'Approved' ? '#18B023' :
                                row?.status === 'active' ? '#18B023' :
                                    row?.status === 'Under Review' ? '#FF8000' :
                                        ''
                }}>{capitalize(row?.status)}</span>
            )

        },
        {
            field: 'Action',
            headerName: 'Action',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={1} direction={'row'}>

                    <RemoveRedEyeIcon
                        onClick={() => navigate(`/viewNetworkIndividual`, {
                            state: {
                                item: row
                            }
                        })}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }} />

                    {row?.status !== 'Rejected' &&
                        <BorderColorTwoToneIcon
                            onClick={() => navigate(`/editNetworkIndividual`, {
                                state: {
                                    item: row
                                }
                            })}
                            style={{
                                color: '#FF0053',
                                cursor: 'pointer'
                            }}
                        />}

                </Stack>
            )
        },

    ];


    return (
        <Box >
            <Box px={5} py={2}>
                <Paper style={{
                    borderRadius: "20px"
                }}>
                    <DataTable
                        rows={res}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default Network