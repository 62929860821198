import { Box, Stack, styled, Typography } from '@mui/material'
import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomLoginButton from '../../components/Widgets/CustomLoginButton'
import OtpInput from 'react-otp-input';
import './style.css'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { RESET_ERROR } from '../../Redux/constants/userConstant';
import { loginUser } from '../../Redux/actions/userActions';
import Snackbar from '@mui/material/Snackbar';
import { MuiOtpInput } from 'mui-one-time-password-input'
import CustomOtp from '../../components/Widgets/CustomOtp';
import UserContext from '../../helpers/user';
import { useCallback } from 'react';
import customAxios from '../../CustomAxios';

const OTP = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const context = useContext(UserContext)
    const [otp, setOTP] = useState(null);
    const { emailAddress, loading, userLogined, error, userData } = useSelector(state => state.auth);





    const STACK = styled(Stack)({
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,

    })


    const SubmitForm = (data) => {
        let val = {
            email: emailAddress,
            otp: localStorage.getItem('otp')
        }
        dispatch(loginUser(val))

    }

    useEffect(() => {
        if (userLogined) {
            //context.setUser(userData?.user)
            if(userData?.user?.role === "teacher"){
                getTeacherProfile(userData?.user?._id)
            }
            else{
                context.setUser(userData?.user)
            }
            toast.success(`Login Successful`);
            navigate('/')
            dispatch({
                type: RESET_ERROR
            })
         
        }

        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, userLogined])


    const getTeacherProfile = async(id) => {
        await customAxios.get(`teacher/profile/${id}`)
        .then(async response => {
            localStorage.setItem("user", JSON.stringify(response.data.data));
            context.setUser(response.data.data)
        }).catch(async error => {
            toast.error({ error })
        })
    }





    // useEffect(() => {
    //     if (loginSuccess) {
    //       context.setUser(userData)
    //       roleC.setRole(role?.permissions)
    //       roleC.getRoutes()
    //       roleC.getMenus()
    //       console.log("in login", userData, role?.permissions)
    //       //navigate to dashboard
    //       history(`/`);
    //       dispatch({
    //         type: RESET_ERROR
    //       })
    //     }

    //     if (error) {
    //       //using toast display error message
    //       toast.error(error?.message)
    //       dispatch({
    //         type: RESET_ERROR
    //       })
    //     }
    //   }, [loginSuccess, error])

    return (
        <>
            <STACK>
                <Typography variant='caption'
                    mt={3}
                    fontSize={{ md: 18, xs: 14 }}
                    width={{ md: 350, xs: 200 }}
                    textAlign={'center'}
                    fontFamily={'Qicksand'}
                    letterSpacing={.5}
                    fontWeight={'400'}>
                    A 4 digit One Time Password has been sent to your registered email
                </Typography>
                <Box my={5}>
                    <CustomOtp  setOtp={setOTP}  />
                </Box>
                <CustomLoginButton onclick={SubmitForm} />
            </STACK>
        </>

    )
}

export default OTP