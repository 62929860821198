import axios from "../../CustomAxios";
import { GET_INDIVIDUAL_NETWORK_FAIL, GET_INDIVIDUAL_NETWORK_SUCCESS, GET_INDIVIDUAL_STUDENT_FAIL, GET_INDIVIDUAL_STUDENT_SUCCESS, GET_PLAN_INDIVIDUAL_FAIL, GET_PLAN_INDIVIDUAL_SUCCESS, GET_PLAN_LOG_INDIVIDUAL_FAIL, GET_PLAN_LOG_INDIVIDUAL_SUCCESS, SINGLE_INDIVIDUAL_STUDENT_FAIL, SINGLE_INDIVIDUAL_STUDENT_SUCCESS, UPDATE_INDIVIDUAL_NETWORK_FAIL, UPDATE_INDIVIDUAL_NETWORK_SUCCESS, UPDATE_INDIVIDUAL_STUDENT_FAIL, UPDATE_INDIVIDUAL_STUDENT_SUCCESS, UPDATE_PLAN_INDIVIDUAL_FAIL, UPDATE_PLAN_INDIVIDUAL_SUCCESS } from "../constants/individualConstant";
import { LOADING } from "../constants/userConstant";


export const getStudentIndividual = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/individual/studentlist`)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: GET_INDIVIDUAL_STUDENT_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_INDIVIDUAL_STUDENT_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const getSingleStudentIndividual = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/individual/student/${id}`)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: SINGLE_INDIVIDUAL_STUDENT_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: SINGLE_INDIVIDUAL_STUDENT_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const updateStudentIndividual = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/individual/editstudent`,data)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: UPDATE_INDIVIDUAL_STUDENT_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: UPDATE_INDIVIDUAL_STUDENT_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const statusStudentIndividual = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/individual/editstudent`,data)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: UPDATE_INDIVIDUAL_STUDENT_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: UPDATE_INDIVIDUAL_STUDENT_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const getIndividualNetwork = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/individual/network`)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: GET_INDIVIDUAL_NETWORK_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_INDIVIDUAL_NETWORK_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}



export const updateIndividualNetwork = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/individual/network/editpost`,data)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: UPDATE_INDIVIDUAL_NETWORK_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: UPDATE_INDIVIDUAL_NETWORK_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}


export const updatePlans = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/individual/plan/edit`,data)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: UPDATE_PLAN_INDIVIDUAL_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: UPDATE_PLAN_INDIVIDUAL_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}


export const getIndividualPlans = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/individual/plan/list`)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: GET_PLAN_INDIVIDUAL_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_PLAN_INDIVIDUAL_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const getIndividualPlanslogs = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/individual/planlogs/${data}`)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: GET_PLAN_LOG_INDIVIDUAL_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_PLAN_LOG_INDIVIDUAL_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

