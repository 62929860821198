import { Box, CircularProgress, Modal, Stack, Typography, MenuItem } from '@mui/material';
import React, { useState, useEffect } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../components/Widgets/CustomButton';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomSelect from '../../components/Widgets/CustomSelect';
import { getSchooolteacher, reasignSchoolTeacher } from '../../Redux/actions/schoolActions';
const ReasignModal = ({ open, handleClose, divID, res, schoolTeachers }) => {
     

    const dispatch = useDispatch()

    const { loading, loader } = useSelector(state => state.auth);



    const [teacher, setTeacher] = useState([])
    const [reasignTeacher, setReasignTeacher] = useState(null)

    console.log({ res })


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        borderRadius: 3
    };


    const schema = object().shape({
        reasign: yup.string().required('Required'),
    });

    useEffect(() => {
        if (schoolTeachers) {
            let filter = schoolTeachers?.filter((t) => t?._id !== res?._id)
            setTeacher(filter)
        }
    }, [schoolTeachers])




    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({

        resolver: yupResolver(schema)
    })


    const submitForm = (data) => {
        let val = {
            "teacher_id": res?._id,
            "division_id": divID,
            "reassign_teacher_id": reasignTeacher
        }
        dispatch(reasignSchoolTeacher(val))
    }




    return (
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack justifyContent={'space-between'} direction={'row'} px={2} py={1} alignItems='center'>
                    <Box></Box>
                    <HighlightOffIcon onClick={() => handleClose()} sx={{
                        cursor: 'pointer'
                    }} />

                </Stack>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} py={1}>
                    <ErrorOutlineIcon style={{
                        fontSize: '40px',
                        color: "#FD085A"
                    }} />
                    <Typography fontFamily={'Quicksand'} px={2} textAlign={'center'} color={"#FD085A"} py={1} letterSpacing={1} fontWeight={'bold'}>Reassign Teacher</Typography>
                    <Typography fontFamily={'Quicksand'} px={2} textAlign={'center'}>Reassign a teacher to this class in order to delete this class</Typography>
                </Box>
                <Box px={3}>
                    <CustomSelect
                        control={control}
                        error={errors.reasign}
                        fieldName="reasign"
                        fieldLabel={''}
                        background="#FFEFEC"
                        size="16px"
                        value={reasignTeacher}
                        onChangeValue={(e) => {
                            setReasignTeacher(e.target.value)
                            setValue('reasign', e.target.value)
                            setError('reasign', '')
                        }}
                    >
                        <MenuItem value="" disabled >
                            <>Select Teachers</>
                        </MenuItem>
                        {teacher.map((res) => (
                            <MenuItem key={res?._id} value={res?._id}  >
                                {res?.name.charAt(0).toUpperCase() + res?.name.slice(1)}
                            </MenuItem>
                        ))}


                    </CustomSelect>
                </Box>
                <Box py={3} display={'flex'} justifyContent={'center'}>

                    <CustomButton label={'Confirm'} onClick={handleSubmit(submitForm)} />
                </Box>
            </Box>

        </Modal>
    )
}

export default ReasignModal