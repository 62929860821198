import { Box, Button, ButtonBase } from '@mui/material'
import React from 'react'

const CustomApproveRejectButton = ({label,background,color,shadow ,onClick,disabled}) => {
  return (
    <Box>
        <Button 
        disabled={disabled}
        onClick={onClick}
        sx={{
            background:background,
            color:color,
            boxShadow:`0px 10px ${shadow}`,
            width:100,
            borderRadius:2,
            letterSpacing:1
        }}>{label}</Button>
    </Box>
  )
}

export default CustomApproveRejectButton