import { Backdrop, Box, CircularProgress, Paper } from '@mui/material'
import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import CustomSearch from '../../components/Widgets/CustomSearch';
import DataTable from '../../components/Widgets/CustomTable';
import CustomSwitch from '../../components/Widgets/CustomSwitch';
import { Stack } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import moment from 'moment/moment';
import { updateStudentIndividual } from '../../Redux/actions/individualAction';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { RESET_ERROR } from '../../Redux/constants/userConstant';

const Student = ({ res }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { loading } = useSelector(state => state.auth);
    const { singleStudentList ,studentUpdated,error} = useSelector(state => state.individual);

    const columns = [
        {
            field: 'user_id',
            headerName: 'Student ID',
            flex: 1,
            valueGetter: (params) => (`#${params.row?.user_id}`),
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'createdOn',
            headerName: 'Created Date',
            flex: 1,
            valueGetter: (params) => (moment(params.row.created_at).format('DD/MM/YYYY')),
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'country',
            headerName: 'Country',
            flex: 1,
            headerAlign: 'center',
            align: 'center',

        },
        {
            headerName: 'Plan',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (params.row.plan_details?.planduration),

        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            headerAlign: 'center',
            align: 'center',


        },
        {
            field: 'class',
            headerName: 'Class',
            flex: 1,
            headerAlign: 'center',
            align: 'center',


        },
        {
            field: 'email',
            headerName: 'Email Address',
            flex: 1,
            headerAlign: 'center',
            align: 'center',


        },
        {
            field: 'mobile',
            headerName: 'Mobile Number',
            flex: 1,
            headerAlign: 'center',
            align: 'center',


        },

        {
            field: 'Status',
            headerName: 'Status',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <Box>

                    <CustomSwitch
                        checked={row?.status === "active" ? true : false}
                        onClick={(e) => ChangeStatus(e.target.checked, row)}
                    />
                </Box>
            )
        },
        {
            field: 'Action',
            headerName: 'Action',
            flex: 1,
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={1} direction={'row'}>
                    <RemoveRedEyeIcon
                        onClick={() => navigate(`/viewStudentIndividual`, {
                            state: {
                                item: row
                            }
                        })}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }} />
                    <BorderColorTwoToneIcon
                        onClick={() => navigate(`/EditStudents/${row._id}`)}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />

                </Stack>
            )
        },

    ];

    const ChangeStatus = (checked, row) => {
        let status = checked === true ? 'active' : 'inactive'
        let val = {
            "id": row?._id,
            "name": row?.name,
            "email": row?.email,
            "class": row?.class,
            "payment_status": row?.payment_status,
            "status": status
        }
        dispatch(updateStudentIndividual(val))
    }

    useEffect(() => {

        if (studentUpdated) {
            toast.success(`Status Updated Successfully`);
            dispatch({
                type: RESET_ERROR
            })
   

        }

        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, studentUpdated])


    return (
        <Box >
            <Box px={5} py={2}>
                <Paper style={{
                    borderRadius: "20px"
                }}>
                    <DataTable
                        rows={res ? res : []}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                //onClick={handleClose}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default Student