import { LOADER, LOADING } from "../constants/userConstant";
import axios from "../../CustomAxios";
import { GET_ORDER_FAIL, GET_ORDER_SUCCESS, UPDATE_ORDER_FAIL, UPDATE_ORDER_SUCCESS } from "../constants/orderConstants";



export const getOrder = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/order/list`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: GET_ORDER_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_ORDER_FAIL ,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const updateOrder = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/order/update`,data)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: UPDATE_ORDER_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: UPDATE_ORDER_FAIL ,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}
