import React from 'react'
import Slider from '@mui/material/Slider';
import { Typography} from '@mui/material'
const CustomSlider = ({ label, defaultValue, value, onChange, min, max, step }) => {
    return (
        <>
            <Typography sx={{
                fontSize: {
                    lg: 15,
                    md: 14,
                    sm: 13,
                    xs: 12,

                },
                fontFamily: 'Quicksand',
                fontWeight: "bold",

            }}>{label}</Typography>
            <Slider 
                onChange={onChange} 
                min={min} max={max} 
                value={value} 
                defaultValue={defaultValue} 
                aria-label="Default" 
                valueLabelDisplay="auto"  
                step={step} 
            />
        </>
    )
}

export default CustomSlider