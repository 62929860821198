import React from 'react'
import DataTable from '../../../components/Widgets/CustomTable'

const AllReviews = ({columns, datas}) => {
  return (
    <DataTable
        rows={datas}
        columns={columns}
        id={"_id"}
    />
  )
}

export default AllReviews