import axios from "../../CustomAxios";
import { CREATE_USER_FAIL, CREATE_USER_SUCCESS, DELETE_USER_FAIL, DELETE_USER_SUCCESS, GET_ROLE_FAIL, GET_ROLE_SUCCESS, GET_USER_FAIL, GET_USER_SINGLE_FAIL, GET_USER_SINGLE_SUCCESS, GET_USER_SUCCESS, IMAGE_RESOLUTION_FAIL, IMAGE_RESOLUTION_SUCCESS, LOADER, LOADING, LOGIN_FAILED, LOGIN_SUCCESS, REG_USER_FAIL, REG_USER_SUCCESS, UPDATE_USER_FAIL, UPDATE_USER_SUCCESS, USER_STATUS_FAIL, USER_STATUS_SUCCESS } from "../constants/userConstant";

export const loginUser = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`auth/login`, data)
        .then(async response => {
        
            let data = response.data;
            await localStorage.setItem("user", JSON.stringify(data.user));
            await localStorage.setItem("token", response.data.access_token);
            await localStorage.setItem("role", JSON.stringify(response.data.role));
            dispatch({
                type: LOGIN_SUCCESS,
                payload: data
            });

            dispatch({
                type: LOADING,
                payload: false
            })


        }).catch(async error => {
            dispatch({
                type: LOGIN_FAILED,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}



export const RegUser = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`auth/loginotp`, data)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: REG_USER_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: REG_USER_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })

}

export const CreateUser = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/user/create`, data)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: CREATE_USER_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: CREATE_USER_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getRole = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/role/list`)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: GET_ROLE_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: GET_ROLE_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const getUserManagement = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/user/list`)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: GET_USER_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: GET_USER_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getUserSingleList = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    dispatch({
        type: LOADER,
        payload: true
    })
    await axios.get(`admin/user/show/${id}`)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: GET_USER_SINGLE_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
            dispatch({
                type: LOADER,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: GET_USER_SINGLE_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
            dispatch({
                type: LOADER,
                payload: false
            })
        })
}

export const updateUser = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/user/update`, data)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: UPDATE_USER_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: UPDATE_USER_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const deleteUser = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`admin/user/delete/${id}`,)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: DELETE_USER_SUCCESS,
                payload: id
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: DELETE_USER_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}


export const userStatusChange = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/user/status`, data)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: USER_STATUS_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: USER_STATUS_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}

export const getImageResolution = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/settings`)
        .then(async response => {
            let data = response.data.data;
            dispatch({
                type: IMAGE_RESOLUTION_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: IMAGE_RESOLUTION_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}