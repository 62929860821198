import { Avatar, Box, Stack, Typography, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import india from '../../Assets/images/india.png'
import CustomPlan from './CustomPlan'
import UAE from '../../Assets/images/united-arab-emirates.png'
import CreatePlansModal from './CreatePlansModal'
const Plan = ({ res }) => {

  

    const [indiaRes, setIndiaRes] = useState([]);
    const [uaeRes, setUAE_RES] = useState([]);
    
   




    useEffect(() => {
        if (res) {
            let india = res?.filter((c) => c.country === 'India');
            let UAE = res?.filter((c) => c.country === 'UAE');
            setUAE_RES(UAE);
            setIndiaRes(india);
        }
    }, [res])



    return (
        <Stack gap={5} px={5} py={2} >
            <CustomPlan avatar={india} label={'Indian Price Plans'}  res={indiaRes} log={'India'}/>
            <CustomPlan avatar={UAE} label={'UAE Price Plans'} res={uaeRes} log={'UAE'}/>
        </Stack>
    )
}

export default Plan