import React from 'react'

import { Add, SaveOutlined } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
const CustomIconButton = ({ onClick, label, Icon, disabled }) => {
  return (
    <Box>
      <Button onClick={onClick} startIcon={<Icon fontWeight={'bold'}  />}
        style={{
          borderRadius: 12,
          backgroundColor: "#FFFAFA",
          padding: "9px 18px",
          fontSize: "16px",
          color: '#FF0053',
          fontWeight: 'bold',
          boxShadow: '0px 6px #FADEDA',
        }}
        disabled={disabled}
      >
        {label}
      </Button>
    </Box>
  )
}

export default CustomIconButton