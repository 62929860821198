import React, { useState, useEffect, useContext } from 'react'
import { Avatar, Box, Stack, styled, Button, Typography, Grid, Backdrop, CircularProgress } from '@mui/material';
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js';
import CustomInput from '../../components/Widgets/CustomInput/index.js';
import CustomCalender from '../../components/Widgets/CustomCalender';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomSelect from '../../components/Widgets/CustomSelect/index.js';
import MenuItem from '@mui/material/MenuItem';
import BackupIcon from '@mui/icons-material/Backup';
import CustomIconButton from '../../components/Widgets/CustomIconButton/index.js';
import AddIcon from '@mui/icons-material/Add';
import CustomTextArea from '../../components/Widgets/CustomTextArea/index.js';
import CustomTextEditor from '../../components/Widgets/CustomTextEditor.js/index.js';
import CustomImageUploader from '../../components/Widgets/CustomImageUploder/index.js';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux'
import { LOADING } from '../../Redux/constants/userConstant.js';
import customAxios from '../../CustomAxios.js';
import { isEmpty } from 'lodash'
import UserContext from '../../helpers/user/index.js';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { IMAGE_URL } from '../../config/constants.js';



const ViewQuiz = () => {

    const { state } = useLocation()


    const navigate = useNavigate()

    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const context = useContext(UserContext)

    console.log({ context })

    const [gradeList, setGradeList] = useState([])
    const [typeList, setTypeList] = useState([
        { _id: 'Normal', value: 'Normal' },
        { _id: 'Competition', value: 'Competition' }
    ])
    const [countryList, setCountryList] = useState([
        { _id: 'India', value: 'India' },
        { _id: 'UAE', value: 'UAE' }
    ])
    const [contentType, setContentType] = useState('')
    const [countryId, setCountryId] = useState('')
    const [grade, setGrade] = useState('')
    const [loading, setLoading] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        if (state) {
            setValue('grade', state?.grade_details?.name);
            setValue('type',state?.type)
            setValue('title',state?.title)
            setValue('country',state?.country)
            setValue('markperquestion',state?.markperquestion)
            setValue('totalquestions',state?.totalquestions)
            setValue('calender',moment(state?.expiry_date).format('DD/MM/YYYY'))

        }

    }, [state])







    const schema = object().shape({

    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({
        defaultValues: {
            grade: '',
            type: '',
            title: '',
            markperquestion: '',
            totalquestions: '',
            expiry_date: null,
            posted_by: context?.user?.name,
            country: ''
        },
        resolver: yupResolver(schema)
    });













    return (
        <Box >
            <CustomBackHeader label={'View Quiz'} />
            <Box px={5} mt={5}>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            view={true}
                            control={control}
                            error={errors.grade}
                            fieldName="grade"
                            fieldLabel="Grade"
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            view={true}
                            control={control}
                            error={errors.type}
                            fieldName="type"
                            fieldLabel="Type"
                        />
                    </Grid>
                    <Grid item lg={6} md={8} xs={12}>
                        <CustomTextArea
                            readOnly={true}
                            control={control}
                            error={errors.title}
                            fieldName="title"
                            multiline={true}
                            height={90}
                            row={10}
                            fieldLabel="Title"
                            background={'#FFEFEC'}

                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                    <CustomInput
                            view={true}
                            control={control}
                            error={errors.country}
                            fieldName="country"
                            fieldLabel="Country"
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            view={true}
                            control={control}
                            error={errors.markperquestion}
                            fieldName="markperquestion"
                            fieldLabel="Marks ( Per Question )"
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            view={true}
                            control={control}
                            error={errors.totalquestions}
                            fieldName="totalquestions"
                            fieldLabel="Total Questions"
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                    <CustomInput
                            view={true}
                            control={control}
                            error={errors.calender}
                            fieldName="calender"
                            fieldLabel="Expiry Date"
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box display={'flex'} justifyContent={'flex-start'} py={10} px={5}>
                <CustomIconButton label={"Next"} Icon={AddIcon} onClick={()=>navigate('/viewonlyquestions',{
                    state:state
                })} />
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </Box>
    )
}

export default ViewQuiz