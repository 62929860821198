import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Stack } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useTransition } from 'react';
import DataTable from '../../../components/Widgets/CustomTable';
import Header from '../../Header';
import CustomSearch from '../../../components/Widgets/CustomSearch';
import customAxios from '../../../CustomAxios';
import CustomSwitch from '../../../components/Widgets/CustomSwitch';

const MyStudents = () => {

    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(null);
    const [open, setOpen] = useState(false);
    const [stdList, setStdList] = useState([]);
    const[search,setSearchList]=useState([]);
    const [pending, startTransition] = useTransition();




    const navigate = useNavigate()

    const columns = [
        {
            field: 'user_id',
            headerName: 'Student ID',
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'Created Date',
            headerName: 'Created Date',
            width: 130,

            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => moment(params.row.created_at).format("DD-MM-YYYY"),
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 130,

            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'Class & Division',
            headerName: 'Class & Division',
            width: 150,

            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (`${params.row.class} - ${params.row.division}`),

        },
        {
            field: 'email',
            headerName: 'Email Address',
            width: 160,
            editable: true,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'mobile',
            headerName: 'Mobile Number',
            width: 150,
            editable: true,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'Status',
            headerName: 'Status',
            width: 130,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <Box>
                    <CustomSwitch
                        checked={row?.status === "active" ? true : false}
                        onClick={(e) => ChangeStatus(e.target.checked, row?._id)} />
                </Box>
            )
        },

        {
            field: 'Action',
            headerName: 'Action',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={1} direction={'row'}>
                    <RemoveRedEyeIcon
                        onClick={() => navigate(`/viewteacherstudent`, { state: row })}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />
                    <DeleteOutlineTwoToneIcon
                        onClick={() => handleDelete(row?._id)}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }} />
                </Stack>
            )
        },
    ];



    const searchstudentList = (value) => {
        let students = search?.filter(ST => ST?.name.toString().toLowerCase().includes(value.toLowerCase())||ST?.mobile.toString().toLowerCase().includes(value.toLowerCase()))
        startTransition(() => {
            setStdList(students)
        })
    }


    const handleDelete = async (id) => {
        setLoading(true)
        await customAxios.delete(`teacher/delete/student/${id}`)
            .then(async response => {
                let result = stdList?.filter((res)=>res?._id !== id)
                setStdList([...result])
                toast.success(`Deleted Successfully`)
                setLoading(false);
            }).catch(async error => {
                toast.error({ error })
                setLoading(false)
            })
    }


    

    const ChangeStatus = async (checked, row) => {
        let status = checked === true ? 'active' : 'inactive'
        let val = {
            id: row,
            status: status
        }
        setLoading(true)
        await customAxios.post(`teacher/updatestatus`, val)
            .then(async response => {
                getStudentList();
                toast.success(`Status Changed Successfully`)
                setLoading(false);
            }).catch(async error => {
                toast.error({ error })
                setLoading(false)
            })

    }



    useEffect(() => {
        getStudentList()
    }, [])



    const getStudentList = async () => {
        setLoading(true)
        await customAxios.get(`teacher/studentslist`)
            .then(async response => {
                setStdList(response.data.data);
                setSearchList(response.data.data);
                setLoading(false);
            }).catch(async error => {
                toast.error({ error })
                setLoading(false)
            })
    }



    return (
        <Box>
            <Header label={'My Students'} />
            <Box px={2} py={3} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                <CustomSearch placeholder={'Search'}  onSearch={(value) => searchstudentList(value)} />
            </Box>
            <Box px={5} py={2}>
                <Paper style={{
                    borderRadius: "20px"
                }}>
                    <DataTable
                        rows={stdList}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </Box>
    )
}

export default MyStudents