import React from 'react'
import { Controller } from "react-hook-form";
import { Avatar, Box, FormGroup, styled, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
const CustomInput = ({
    fieldName,
    control,
    fieldLabel,
    placeholder,
    error,
    view,
    changeValue,
    disabled,
    defaultValue,
    type,
    w,
    b,
    Not,
    paddingRight
}) => {
   
    return (
        <>
            <FormGroup>
               {!Not && <Typography fontFamily={'Quicksand'} fontWeight={'700'} px={'3px'} mb={'2px'}
                    sx={{
                        fontSize: {
                            lg: 16,
                            md: 14,
                            sm: 12,
                            xs: 11,
                        },

                    }}
                >{`${fieldLabel}`}
                    <span style={{
                        color:'#FF0000'
                    }}>*</span>
                </Typography> }
                <Controller
                    name={fieldName}
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <TextField
                            type={type}
                            defaultValue={defaultValue}
                            value={value}
                            
                            onChange={(e) => {
                                onChange(e)
                                if(changeValue){
                                    changeValue(e.target.value)
                                }
                            }}
                            onBlur={onBlur}
                            aria-invalid={error ? "true" : "false"}
                            className="form-control"
                            placeholder={placeholder}
                            id="exampleInputEmail1"
                            InputProps={{
                                disableUnderline: true,
                                readOnly: view ? true : false,
                                style: {
                                    width:w?w : '',
                                    borderRadius:b ? b : "12px",
                                    opacity: "1",
                                    background: view ? '#F3F3F3' : "#FFEFEC",
                                    height: "48px",
                                    fontFamily: "Quicksand",
                                    letterSpacing: "1px",
                                    fontWeight: '700px',
                                    border: 'none',
                                    paddingRight: paddingRight
                                },
                            }}
                        />
                    )}
                />
                {error && (
                    <p
                        role="alert"
                        style={{
                            color: "red",
                            display: "flex",
                            flexDirection: "start",
                            paddingLeft: "10px",
                            fontSize: "12px",
                        }}
                    >
                        {error?.message}
                    </p>
                )}
            </FormGroup>
        </>
    )
}

export default CustomInput