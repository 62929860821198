import React, { useEffect, useState } from 'react'
import { Avatar, Box, Grid, MenuItem, Modal, Stack, Typography, DialogTitle, Dialog, DialogContent, CircularProgress } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomInput from '../../components/Widgets/CustomInput';
import CustomSelect from '../../components/Widgets/CustomSelect';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { updatePlans } from '../../Redux/actions/individualAction.js';
import { RESET_ERROR } from '../../Redux/constants/userConstant';
import { toast } from "react-toastify";
const CreatePlansModal = ({ open, handleClose, res, view }) => {

    const dispatch = useDispatch()


    const { loading, loader } = useSelector(state => state.auth);
    const { error, planUpdated } = useSelector(state => state.individual);

    const [selectCountry, setSelectCountry] = useState(null)

    const schema = object().shape({
        country: yup.string().required('Country is Required'),
        monthly: yup.string().required('Required'),
        quarterly: yup.string().required('Required'),
        halfyearly: yup.string().required('Required'),
        yearly: yup.string().required('Required'),
        trial: yup.string().required('Required')
    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({
        defaultValues: {
            id: res?._id

        },

        resolver: yupResolver(schema)
    });



    useEffect(() => {
        if (res) {
            setValue('country', res?.country)
            setValue('monthly', res?.monthly)
            setValue('quarterly', res?.quarterly)
            setValue('halfyearly', res?.halfyearly)
            setValue('yearly', res?.yearly)
            setValue('trial', res?.trial)
            setSelectCountry(res?.country)
        }
    }, [res])


    const SubmitForm = (data) => {
        dispatch(updatePlans(data))

    }
    useEffect(() => {
        if (planUpdated) {
            toast.success(`Plan Update Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            handleClose()
        }

        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [error, planUpdated])

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={"md"}
            onClose={handleClose}>
            <DialogTitle>
                <Box display={'flex'} py={1} justifyContent={'space-between'} >
                    <Typography sx={{
                        fontFamily: 'Quicksand',
                        fontWeight: 'bold',
                        fontSize: 22,
                        color: '#FF0053',
                        letterSpacing: 1
                    }}>{!view ? 'UPDATE PLAN' : 'View Plan'}</Typography>
                    <HighlightOffIcon style={{ fontSize: '30px', cursor: 'pointer' }} onClick={() => handleClose('')} />
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.monthly}
                            fieldName="monthly"
                            fieldLabel="Monthly"
                            view={view ? true : false}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        <CustomSelect
                            control={control}
                            error={errors.country}
                            fieldName="country"
                            fieldLabel="Country"
                            background="#FFEFEC"
                            size="16px"
                            view={view ? true : false}
                            value={selectCountry}
                            onChangeValue={(e) => {
                                setSelectCountry(e.target.value)
                                setValue('country', e.target.value)
                                setError('country', '')
                            }}
                        >
                            <MenuItem value="" disabled >
                                <em>Select Country</em>
                            </MenuItem>
                            {['India', 'UAE'].map((res, i) => (
                                <MenuItem value={res} key={i} >
                                    {res}
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.quarterly}
                            fieldName="quarterly"
                            fieldLabel="Quarterly"
                            view={view ? true : false}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.halfyearly}
                            fieldName="halfyearly"
                            fieldLabel="Half Yearly"
                            view={view ? true : false}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.yearly}
                            fieldName="yearly"
                            fieldLabel="Yearly"
                            view={view ? true : false}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.trial}
                            fieldName="trial"
                            fieldLabel="Trial"
                            view={view ? true : false}
                        />
                    </Grid>
                </Grid>
                {!view && <Box py={4} display={'flex'} justifyContent={'center'}>
                    <CustomIconButton label={loading ? <CircularProgress style={{ color: '#FF0053' }} size={20} /> : res ? 'Update' : 'Add'}
                        Icon={AddIcon} onClick={handleSubmit(SubmitForm)} disabled={loading ? true : false} />
                </Box>}

            </DialogContent>
        </Dialog>
    )
}

export default CreatePlansModal