import React, { useState, useEffect, useContext } from 'react'
import { Avatar, Box, Stack, styled, Button, Typography, Grid } from '@mui/material';
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js';
import CustomInput from '../../components/Widgets/CustomInput/index.js';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomSelect from '../../components/Widgets/CustomSelect/index.js';
import MenuItem from '@mui/material/MenuItem';
import BackupIcon from '@mui/icons-material/Backup';
import CustomIconButton from '../../components/Widgets/CustomIconButton/index.js';
import AddIcon from '@mui/icons-material/Add';
import CustomTextArea from '../../components/Widgets/CustomTextArea/index.js';
import CustomTextEditor from '../../components/Widgets/CustomTextEditor.js/index.js';
import CustomImageUploader from '../../components/Widgets/CustomImageUploder/index.js';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux'
import { LOADING } from '../../Redux/constants/userConstant.js';
import customAxios from '../../CustomAxios.js';
import { isEmpty } from 'lodash'
import UserContext from '../../helpers/user/index.js';
import { useLocation } from 'react-router-dom';
import { IMAGE_URL } from '../../config/constants.js';
import CustomAvatar from '../../components/Widgets/CustomAvatar/index.js';
import CustomCardMedia from '../../components/Widgets/CustomCardMedia/index.js';



const ViewNews = ({route}) => {

    const { state: data } = useLocation();
  


    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    return (
        <Box >
            <CustomBackHeader label={`Post ID: ${data?.post_id}`} />
            <Box px={5} mt={5}>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput 
                            view
                            defaultValue={data?.grade_details?.name}
                            fieldName="grade"
                            fieldLabel={"Grade"}
                            control={control}
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                     
                        <CustomTextArea
                            control={control}
                            fieldName="Category"
                            multiline={true}
                            height={110}
                            row={10}
                            fieldLabel="Category"
                            background={'#FFEFEC'}
                            readOnly
                            defaultValue={data?.categories?.map((res)=>(res?.name))}
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput 
                            view
                            defaultValue={data?.country}
                            fieldName="Country"
                            fieldLabel={"Country"}
                            control={control}
                        />
                    </Grid>
                    <Grid item lg={6} md={8} xs={12}>
                        <CustomTextArea
                            control={control}
                            fieldName="postTitle"
                            multiline={true}
                            height={90}
                            row={10}
                            fieldLabel="Post Title"
                            background={'#FFEFEC'}
                            readOnly
                            defaultValue={data?.title}

                        />
                    </Grid>
                    <Grid item lg={6} md={8} xs={12}>
                        <CustomTextArea
                            control={control}
                            fieldName="postContent"
                            multiline={true}
                            height={110}
                            row={10}
                            fieldLabel="Post Content"
                            background={'#FFEFEC'}
                            readOnly
                            defaultValue={data?.content}
                        />

                    </Grid>
                    {data?.file_type === 'image' &&
                    <Grid item lg={3} md={4} xs={6}>
                        <CustomAvatar avatar={`${IMAGE_URL}${data.file}`} />
                    </Grid>
                }
                {data.file_type === "video" &&
                    <Grid item lg={3} md={4} xs={6}>
                        <CustomCardMedia videoProp={`${IMAGE_URL}${data.file}`} />
                    </Grid>
                }
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            fieldName="contentLink"
                            fieldLabel="Content Link"
                            defaultValue={data?.link}
                            view={true}
                        />
                    </Grid> 
                    {data?.words && data?.words?.length >0 && <Grid item lg={6} md={6} xs={12} >
                        <Typography fontFamily={'Quicksand'} fontWeight={'700'} px={'3px'} pb={2}
                            sx={{
                                fontSize: {
                                    lg: 16,
                                    md: 14,
                                    sm: 12,
                                    xs: 11,
                                },

                            }}
                        >{`Word & Meaning`}
                            <span style={{
                                color:'#FF0000'
                            }}>*</span>
                        </Typography>
                        <Box
                            bgcolor="#FFEFEC"
                            borderRadius={2}
                            boxShadow={1}
                            color="black"
                        >
                            <Grid container p={2} spacing={2}>
                            {data?.words?.map((mean, index) => (
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <CustomInput
                                        control={control}
                                        fieldName={mean?.word}
                                        fieldLabel={mean?.word?.slice(1)}
                                        defaultValue={mean?.meaning}
                                        view
                                    />
                                </Grid>
                            ))}
                             </Grid>
                       </Box>
                    </Grid>}
                </Grid>
            </Box>
        </Box>
    )
}

export default ViewNews