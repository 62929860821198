import { Box, Stack ,styled} from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../../../../Screens/Header'
import Sidebar from '../../../../Screens/Sidebar'


const HomeLayout = () => {
  const STACK = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        marginLeft:'unset',
    }

}))
  return (
    <Box display={'flex'}>
      <Sidebar />
      <STACK marginLeft={'16vw'} sx={{width: {lg:'84vw' ,md:'100vw'} ,height:'100vh'} }  >
        <Outlet/>
      </STACK>
    </Box>
  )
}

export default HomeLayout