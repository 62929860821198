import { Box, Grid, Typography } from '@mui/material';
import React from 'react'
import { useLocation } from 'react-router-dom';
import CustomAvatar from '../../components/Widgets/CustomAvatar/index.js';
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js';
import CustomCardMedia from '../../components/Widgets/CustomCardMedia/index.js';
import CustomTypography from '../../components/Widgets/CustomTypography/index.js';
import { IMAGE_URL } from '../../config/constants.js';
import { capitalize } from 'lodash'
const ViewSchoolNetwork = () => {
    const location = useLocation();

    console.log({location:location.state})
  

  return (
    <Box>
            <CustomBackHeader label={`View Post`} />
            <Grid container spacing={3} px={5} mt={2}>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography lg={'100%'} md={'100%'} sm={'100%'}  formlabel={'Creator Name'} label={location.state.item?.creator_details?.name} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography lg={'100%'} md={'100%'} sm={'100%'} formlabel={'Class'} label={location?.state?.item?.creator_details?.class} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography lg={'100%'} md={'100%'} sm={'100%'} formlabel={'Division'} label={location?.state?.item?.creator_details?.division} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography lg={'100%'} md={'100%'} sm={'100%'} formlabel={'Reviewed By'} label={location?.state?.item?.reviewd_by_details?.name} />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                    <CustomTypography lg={'100%'} md={'100%'} sm={'100%'} h={100} formlabel={'content'} label={location?.state?.item?.content} />
                </Grid>
                {location?.state?.item?.file_type === 'image' &&
                    <Grid item lg={4} md={4} xs={12}>
                        <CustomAvatar  label={'Post'} avatar={`${IMAGE_URL}${location?.state?.item?.file}`} />
                    </Grid>}
                {location?.state?.item?.file_type === 'video' &&
                    <Grid item lg={4} md={4} xs={12}>
                        <CustomCardMedia label={'Post'} videoProp={`${IMAGE_URL}${location?.state?.item?.file}`} />
                    </Grid>}
                    <Grid item lg={6} md={6} xs={12} py={3}>
                      <Typography sx={{
                        fontFamily: 'QuickSand', fontWeight: 'bold',letterSpacing:1
                      }} >Status : <span style={{  fontFamily: 'QuickSand', fontWeight: 'bold',
                    color:
                       location?.state?.item?.status === 'Rejected' ? '#FF0000' :
                           location?.state?.item?.status === 'Approved' ? '#18B023' :
                               location?.state?.item?.status === 'active' ? '#18B023' :
                                   location?.state?.item?.status === 'Under Review' ? '#FF8000' :
                                        ''}}>{capitalize(location?.state?.item?.status)}</span> </Typography>
                </Grid>
                    
            </Grid>
        </Box>
  )
}

export default ViewSchoolNetwork