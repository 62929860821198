import { Grid, Stack, Typography, Box, Modal, MenuItem, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react'

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CustomTypography from '../../components/Widgets/CustomTypography';


const ViewClassModal = ({ openV, handleCloseV,rowRes }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 380,
        bgcolor: 'background.paper',
        borderRadius: 3
    };

    return (
        <Modal
            keepMounted
            open={openV}
            onClose={handleCloseV}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack justifyContent={'space-between'} direction={'row'} px={2} py={2} alignItems='center'>
                    <Box></Box>
                    <Typography sx={{
                        fontFamily: 'Quicksand',
                        fontWeight: 'bold',
                        fontSize: 22,
                        color: '#FF0053',
                        letterSpacing: 1
                    }}>View Class</Typography>
                    <HighlightOffIcon onClick={() => handleCloseV()} sx={{
                        cursor: 'pointer'
                    }} />

                </Stack>

                <Grid container spacing={2} px={2} justifyContent={'center'}>
                    <Grid item xs={4.5}>
                        <CustomTypography sm={'100%'} xs={'100%'} lg={100} formlabel={'Class'} label={rowRes?.name} />
                    </Grid>
                    <Grid item xs={4.5}>
                       
                        <CustomTypography sm={'100%'} xs={'100%'} lg={100} formlabel={'Division'} label={rowRes?.division} />
                      
                    </Grid>
                </Grid>

                <Grid container spacing={2} px={5} py={2} justifyContent={'center'}>
                    <Grid item xs={4}>
                    <CustomTypography sm={'100%'} xs={'100%'} lg={80} formlabel={'From'} label={rowRes?.roll_no_from} />
                    </Grid>
                    <Grid item xs={4}>
                    <CustomTypography sm={'100%'} xs={'100%'} lg={80} formlabel={'To'} label={rowRes?.roll_no_to} />
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

export default ViewClassModal