
import thunk from 'redux-thunk';
import { combineReducers, applyMiddleware, compose, createStore } from 'redux';
import { authReducer} from './Reducer/authReducer';
import { userReducer } from './Reducer/userReducer';
import { profileReducer } from './Reducer/profileReducer';
import { schoolReducer } from './Reducer/schoolReducer';
import { adsReducer } from './Reducer/adsReducer';
import { categoryReducer } from './Reducer/categoryReducer';
import { vocabuloryReducer } from './Reducer/vocabularyReducer';
import { individualReducer } from './Reducer/individualReducer';
import { orderReducer } from './Reducer/orderReducer';
import { reviewReducer } from './Reducer/reviewReducer';





const appReducer = combineReducers({
    auth: authReducer,
    user:userReducer,
    profile:profileReducer,
    school:schoolReducer,
    ads:adsReducer,
    category:categoryReducer,
    vocabulory:vocabuloryReducer,
    individual:individualReducer,
    order:orderReducer,
    review:reviewReducer
})



const reducer = (state, action) => {
    if (action.type === 'RESET_USER') {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}





const middleware = [thunk];

const store = createStore(reducer, compose(applyMiddleware(...middleware)));

export default store;