import React from 'react'
import { Avatar, Box, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Modal, Stack, Typography } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CustomTypography from '../../components/Widgets/CustomTypography';
import CustomCardMedia from '../../components/Widgets/CustomCardMedia';

import DoneIcon from '@mui/icons-material/Done';
import AnswerComponents from './QuizeComponents/AnswerComponents';
import CustomButton from '../../components/Widgets/CustomButton';
import { useState } from 'react';
import { IMAGE_URL } from '../../config/constants';
import CustomAvatar from '../../components/Widgets/CustomAvatar';
import CloseIcon from '@mui/icons-material/Close';

const QuizeCompatitionView = ({ open, handleClose, res }) => {
    const [count, setCount] = useState(0);

     
    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={"sm"}
            onClose={handleClose}>
            <DialogTitle>
                <Stack justifyContent={'space-between'} direction={'row'} px={2} py={2} alignItems='center'>
                    <Box></Box>
                    <Typography sx={{
                        fontFamily: 'Quicksand',
                        fontWeight: 'bold',
                        fontSize: 22,
                        color: '#FF0053',
                        letterSpacing: 1
                    }}>{`Post ID : #${res?.[0]?.quiz_attendee_id}`}</Typography>
                    <HighlightOffIcon onClick={() => handleClose()} sx={{
                        cursor: 'pointer'
                    }} />

                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack justifyContent={'center'} alignItems={'center'}>
                    <Typography fontFamily={'QuickSand'} fontWeight={'bold'}>
                        Submitted by
                    </Typography>
                    <Typography fontFamily={'QuickSand'} fontWeight={'bold'}>
                        {res?.[0]?.student_details?.name}
                    </Typography>
                    {/* <Typography fontFamily={'QuickSand'} fontWeight={'bold'}>
                        CSIEMHSS
                    </Typography> */}
                </Stack>
                <Stack direction={'row'} mt={3} justifyContent={'space-between'} alignItems={'center'} px={4}>
                    <Typography fontFamily={'Quicksand'} fontWeight={'bold'}>marks Obtained</Typography>
                    <Box display={'flex'}>
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 50,
                                height: 50,
                                backgroundColor: '#FFEFEC'
                            }}>
                            <Typography color={'#ooo'} fontFamily={'Quicksand'} fontWeight={'bold'}>{res?.[0]?.mark_obtained}</Typography>
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 50,
                                height: 50,
                                borderRadius: '0px 10px 10px 0px',
                                backgroundColor: '#FF0053'
                            }}>
                            <Typography color={'#ffff'} fontFamily={'Quicksand'} fontWeight={'bold'}>{parseInt(res?.[0]?.markperquestion)* res?.[0]?.answersheet?.length}</Typography>
                        </Box>
                    </Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                </Stack>

                <Stack gap={1} px={3} mt={1}>
                    <Grid container spacing={2}>
                        <Grid item lg={12} md={12} xs={12}>
                            <CustomTypography formlabel={'Qusetions'} h={80} label={res?.[0]?.answersheet?.[count].question} />
                        </Grid>
                        {res?.[0]?.answersheet?.[count]?.image &&
                        <Grid item lg={8} md={8} xs={12}>
                            <CustomAvatar h={130} label={'Image'} avatar={`${IMAGE_URL}${res?.[0]?.answersheet?.[count]?.image}`} />
                        </Grid>}
                        <Grid item lg={6} md={6} xs={12}>
                            <AnswerComponents heading={'Answer Choosen'}
                                validation={res?.[0]?.answersheet[count]?.options[res?.[0]?.answersheet[count]?.answer_chosen] === res?.[0]?.answersheet[count]?.options[res?.[0]?.answersheet[count]?.correct_answer] ? true : false}
                                Icon={res?.[0]?.answersheet[count]?.options[res?.[0]?.answersheet[count]?.answer_chosen] === res?.[0]?.answersheet[count]?.options[res?.[0]?.answersheet[count]?.correct_answer] ? DoneIcon : CloseIcon}
                                label={res?.[0]?.answersheet[count]?.options[res?.[0]?.answersheet[count]?.answer_chosen]} />
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                            <AnswerComponents heading={'Correct Answer'}
                                validation={true}
                                Icon={DoneIcon}
                                label={res?.[0]?.answersheet[count]?.options[res?.[0]?.answersheet[count]?.correct_answer]} />
                        </Grid>
                    </Grid>
                </Stack>
                {res?.[0]?.answersheet?.length !== count + 1 &&
                    <Box display={'flex'} justifyContent={'center'} py={2}>
                        <CustomButton label={'Next'} onClick={() => setCount(count + 1)} />
                    </Box>
                }

            </DialogContent>
        </Dialog>
    )
}

export default QuizeCompatitionView