export const GET_INDIVIDUAL_STUDENT_SUCCESS = 'GET_INDIVIDUAL_STUDENT_SUCCESS'
export const GET_INDIVIDUAL_STUDENT_FAIL = 'GET_INDIVIDUAL_STUDENT_FAIL'
export const SINGLE_INDIVIDUAL_STUDENT_SUCCESS = 'SINGLE_INDIVIDUAL_STUDENT_SUCCESS'
export const SINGLE_INDIVIDUAL_STUDENT_FAIL = 'SINGLE_INDIVIDUAL_STUDENT_FAIL'
export const UPDATE_INDIVIDUAL_STUDENT_SUCCESS = 'UPDATE_INDIVIDUAL_STUDENT_SUCCESS'
export const UPDATE_INDIVIDUAL_STUDENT_FAIL = 'UPDATE_INDIVIDUAL_STUDENT_FAIL'
export const STATUS_INDIVIDUAL_STUDENT_SUCCESS = 'STATUS_INDIVIDUAL_STUDENT_SUCCESS'
export const STATUS_INDIVIDUAL_STUDENT_FAIL = 'STATUS_INDIVIDUAL_STUDENT_FAIL'


export const GET_INDIVIDUAL_NETWORK_SUCCESS = 'GET_INDIVIDUAL_NETWORK_SUCCESS'
export const GET_INDIVIDUAL_NETWORK_FAIL = 'GET_INDIVIDUAL_NETWORK_FAIL'
export const SINGLE_INDIVIDUAL_NETWORK_SUCCESS = 'SINGLE_INDIVIDUAL_NETWORK_SUCCESS'
export const SINGLE_INDIVIDUAL_NETWORK_FAIL = 'SINGLE_INDIVIDUAL_NETWORK_FAIL'
export const UPDATE_INDIVIDUAL_NETWORK_SUCCESS = 'UPDATE_INDIVIDUAL_NETWORK_SUCCESS'
export const UPDATE_INDIVIDUAL_NETWORK_FAIL = 'UPDATE_INDIVIDUAL_NETWORK_FAIL'
export const STATUS_INDIVIDUAL_NETWORK_SUCCESS = 'STATUS_INDIVIDUAL_NETWORK_SUCCESS'
export const STATUS_INDIVIDUAL_NETWORK_FAIL = 'STATUS_INDIVIDUAL_NETWORK_FAIL'


export const GET_PLAN_INDIVIDUAL_SUCCESS = 'GET_PLAN_INDIVIDUAL_SUCCESS'
export const GET_PLAN_INDIVIDUAL_FAIL = 'GET_PLAN_INDIVIDUAL_FAIL'
export const GET_PLAN_LOG_INDIVIDUAL_SUCCESS = 'GET_PLAN_LOG_INDIVIDUAL_SUCCESS'
export const GET_PLAN_LOG_INDIVIDUAL_FAIL = 'GET_PLAN_LOG_INDIVIDUAL_FAIL'
export const SINGLE_PLAN_INDIVIDUAL_SUCCESS = 'SINGLE_PLAN_INDIVIDUAL_SUCCESS'
export const SINGLE_PLAN_INDIVIDUAL_FAIL = 'SINGLE_PLAN_INDIVIDUAL_FAIL'
export const UPDATE_PLAN_INDIVIDUAL_SUCCESS = 'UPDATE_PLAN_INDIVIDUAL_SUCCESS'
export const UPDATE_PLAN_INDIVIDUAL_FAIL = 'UPDATE_PLAN_INDIVIDUAL_FAIL'
export const STATUS_PLAN_INDIVIDUAL_SUCCESS = 'STATUS_PLAN_INDIVIDUAL_SUCCESS'
export const STATUS_PLAN_INDIVIDUAL_FAIL = 'STATUS_PLAN_INDIVIDUAL_FAIL'



