import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Context from "./index";

const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
};

const getLocalCartItems = () => {
    try {
      const token = localStorage.getItem("token");
      const user = JSON.parse(localStorage.getItem("user")) 

      console.log({user},'user in  context api')
      if (token && user) {
        const decodedJwt = parseJwt(token);
        if (decodedJwt.exp * 1000 < Date.now()) {
          console.log('TOKEN EXPIRED')
            return null;
            
        }
        else{
            return user;
        }
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
};

const UserProvider = (props) => {
    const [user, setUser] = useState(getLocalCartItems());
    //const history = useNavigate();
    useEffect(() => {
      const alertMessage = () => {
        // console.log("storage change")
        const user = localStorage.getItem("user")
        // console.log({user},'user provider parse....')
        if(user){
          setUser(user)
        }
        else{
          setUser(null)
  
        }
      }
      window.addEventListener('storage', alertMessage)
  
      return () => {
        window.removeEventListener("storage", alertMessage);
      }
    }, [])
    
    

    return (
        <Context.Provider
            value={{
              ...props,
              user,
              setUser: setUser
            }}
        >
          {props.children}
        </Context.Provider>
      );
}

export default UserProvider;

