import { Avatar, Box, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Modal, Stack, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CustomInput from '../../components/Widgets/CustomInput';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomSelect from '../../components/Widgets/CustomSelect';
import BackupIcon from '@mui/icons-material/Backup';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import AddIcon from '@mui/icons-material/Add';
import CustomImageUploader from '../../components/Widgets/CustomImageUploder';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { createCategory, updateCategory } from '../../Redux/actions/categoryActions';
const AddCategoryModal = ({ open, handleClose, res }) => {


    const { loading, loader } = useSelector(state => state.auth);

    const dispatch = useDispatch();


    const [images, setimages] = useState(null)
    const [previewEdit, setPreviewEdit] = useState(null);
    const [type, setType] = useState(null);
    const [video, setVideo] = useState(null);
    const [selectCountry, setSelectCountry] = useState(null);




    const schema = object().shape({
        name: yup.string().max(30, "Name must be less than 30 characters").matches(
            /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
            'Name can only contain Latin letters.'
        )
            // .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, 'Please enter your full name.')
            .required('Name is Required'),
        country: yup.string().required('Country is required'),
        icon: yup.string().required('Icon is Required')
    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({

        resolver: yupResolver(schema)
    });


    const setimagefile = (e) => {
        setType(e.type.split('/', 1))
        if (e.type.split("/", 1).includes('video')) {
            setVideo(e)
        } else if (e.type.split("/", 1).includes('image')) {
            if (e.size > 500000) {
                toast.warning("Icon Size Should be less than 500KB");
                setPreviewEdit(null)
                setimages(null)
            } else {
                setPreviewEdit(null)
                setimages(e)
            }

        }
    }

    const SubmitForm = (data) => {

        if (res) {
            const formData = new FormData();
            formData.append('id', res?._id);
            formData.append('name', data?.name);
            formData.append('country', data?.country);
            if (images) {
                formData.append('icon', images);
            }
            dispatch(updateCategory(formData))
        } else {
            const formData = new FormData();
            formData.append('name', data?.name);
            formData.append('country', data?.country);
            formData.append('icon', images);
            dispatch(createCategory(formData))
        }
    }


    useEffect(() => {
        if (res) {
            setValue('name', res?.name)
            setValue('country', res?.country)
            setSelectCountry(res?.country)
            setPreviewEdit(res?.icon)
            setValue('icon', res?.icon)

        }
    }, [res])


    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={"sm"}
            onClose={handleClose}>
            <DialogTitle>
                <Stack justifyContent={'space-between'} direction={'row'} px={2} py={2} alignItems='center'>
                    <Box></Box>
                    <Typography sx={{
                        fontFamily: 'Quicksand',
                        fontWeight: 'bold',
                        fontSize: 22,
                        color: '#FF0053',
                        letterSpacing: 1
                    }}>{res ? 'Edit Category' : 'Add Category'}</Typography>
                    <HighlightOffIcon onClick={() => handleClose()}
                        sx={{
                            cursor: 'pointer'
                        }} />

                </Stack>
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={2} px={2}>
                    <Grid item md={6} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.name}
                            fieldName="name"
                            fieldLabel="Category Name"
                        />

                    </Grid>
                    <Grid item md={6} xs={12}>
                        <CustomSelect
                            control={control}
                            error={errors.department}
                            fieldName="department"
                            fieldLabel="Country"
                            background="#FFEFEC"
                            size="16px"
                            value={selectCountry}
                            onChangeValue={(e) => {
                                setSelectCountry(e.target.value)
                                setValue('country', e.target.value)
                                setError('country', '')
                            }}
                        >
                            <MenuItem value="" disabled >
                                <em>Select Country</em>
                            </MenuItem>
                            {['India', 'UAE'].map((res, i) => (
                                <MenuItem key={i} value={res}  >
                                    {res}
                                </MenuItem>

                            ))}

                        </CustomSelect>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'center'} py={1}>
                    <Grid item xs={12} lg={8} md={6}>
                        <CustomImageUploader
                            format={'image'}
                            accept={'image/*'}
                            fieldLabel='Upload Icon'
                            control={control}
                            error={errors.icon}
                            fieldName='icon'
                            type={"file"}
                            height={350}
                            width={500}
                            background="#ffff"
                            id="contained-button-file"
                            preview={images}
                            video={video}
                            previewEditimage={previewEdit}
                            onChangeValue={(file, e) => {
                                setimagefile(file)
                                setValue("icon", file)
                            }}
                        />
                    </Grid>

                </Grid>
                {/* <Box display={'flex'} justifyContent={'center'} py={5}>
                   
                </Box> */}
                <Box py={2} display={'flex'} justifyContent={'center'}>
                    <CustomIconButton label={loading ? <CircularProgress style={{ color: '#FF0053' }} size={20} /> : res ? 'Update' : 'Add'}
                        Icon={AddIcon} onClick={handleSubmit(SubmitForm)} disabled={loading ? true : false} />
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default AddCategoryModal