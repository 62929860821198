import { Backdrop, Box, CircularProgress, Paper } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CustomSearch from '../../components/Widgets/CustomSearch'
import Header from '../Header'
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Stack } from '@mui/system';
import DataTable from '../../components/Widgets/CustomTable';
import { toast } from "react-toastify";
import customAxios from '../../CustomAxios';
import AddSize from './AddSize';


const AdminSize = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const { loader, mediaSize } = useSelector(state => state.auth);



    const [loading, setLoading] = useState(false);
    const [res, setRes] = useState([])
    const [mode, setMode] = useState(false)
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    }



    const columns = [
        {
            field: 'dateTime',
            headerName: '#',
            flex: 1,
            renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'Image Size',
            headerName: 'Image Size',
            type: 'date',
            flex: 1,
            valueGetter: (params) => (`${params.row.imageOrginal} MB`),
            headerAlign: 'center',
            align: 'center',


        },
        {
            field: 'video',
            headerName: 'Video Size',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (`${params.row.videoOrginal} MB`),

        },
        {
            field: 'Action',
            headerName: 'Action',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} >
                    <BorderColorTwoToneIcon
                        onClick={() => {
                            setOpen(true)
                            setMode(true)
                        }}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />
                </Stack>
            )
        },

    ];

    return (
        <Box px={5}>
            <Paper style={{
                borderRadius: "20px"
            }}>
                <DataTable
                    rows={mediaSize ? mediaSize : []}
                    columns={columns}
                    id={"_id"}

                />
            </Paper>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {open && <AddSize
                res={mediaSize}
                open={open}
                mode={mode}
                handleClose={() => {
                    setOpen(false)
                    setMode(false)
                }
                } />}

        </Box>
    )
}

export default AdminSize