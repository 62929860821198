
import { Box, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import CustomAvatar from '../../components/Widgets/CustomAvatar'
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js'
import CustomTypography from '../../components/Widgets/CustomTypography'
const ViewSchoolTeacher = () => {
    const location = useLocation();
    
    console.log({location})


    return (
        <Box>
            <CustomBackHeader label={`Teacher : #${location.state.item.teacher_id}`} />
            <Grid container spacing={3} px={5} mt={2}>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Name'} label={location.state.item?.name} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Email Address'} label={location.state.item?.email} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Mobile Number'} label={location.state.item?.phone} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Class'} label={location.state.item?.classes?.map((res)=>res?.class ).join(',')} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Division'} label={location.state.item?.classes?.map((res)=>res?.division.map((res)=>res?.division).join(','))
                    } />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomAvatar avatar={location.state.item?.image} />
                </Grid>
            </Grid>

        </Box>
    )
}

export default ViewSchoolTeacher