import React, { useState, useEffect, useContext } from 'react'
import { Avatar, Box, Stack, styled, Button, Typography, Grid, CircularProgress } from '@mui/material';
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js';
import CustomInput from '../../components/Widgets/CustomInput/index.js';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomSelect from '../../components/Widgets/CustomSelect/index.js';
import MenuItem from '@mui/material/MenuItem';
import BackupIcon from '@mui/icons-material/Backup';
import CustomIconButton from '../../components/Widgets/CustomIconButton/index.js';
import AddIcon from '@mui/icons-material/Add';
import CustomTextArea from '../../components/Widgets/CustomTextArea/index.js';
import CustomTextEditor from '../../components/Widgets/CustomTextEditor.js/index.js';
import CustomImageUploader from '../../components/Widgets/CustomImageUploder/index.js';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux'
import { LOADING } from '../../Redux/constants/userConstant.js';
import customAxios from '../../CustomAxios.js';
import { isEmpty } from 'lodash'
import UserContext from '../../helpers/user/index.js';
import { json, useLocation, useNavigate } from 'react-router-dom';
import { IMAGE_URL } from '../../config/constants.js';
import CustomMultipleSelectInput from '../../components/Widgets/CustomMultiSelect/index.js';
import Plan from '../IndividaualManagement/Plan.js';
import {has} from 'lodash'



const AddNewsManagement = () => {

    const { state } = useLocation();

    const {  loader, mediaSize } = useSelector(state => state.auth);
  


      console.log({state})

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const context = useContext(UserContext)

    //console.log({context})

    const [type, setType] = useState('image')
    const [video, setVideo] = useState(null);
    const [images, setimages] = useState(null)
    const [previewEdit, setPreviewEdit] = useState(null);
    const [gradeList, setGradeList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [meaningsArray, setMeaninsArray] = useState([])
    const [meaningsError, setMeaninsError] = useState('')
    const [grade, setGrade] = useState('')
    const [multpleArray, setMultpleArray] = React.useState([]);
    const [selectCountry, setSelectCountry] = useState(null);
    const [thumbnails, setThumbnails] = useState(null);
    const [thumbnailsPreview, setThumbnailsPreview] = useState(null);
    const [postThumbnail, setPostThumbnail] = useState(false);
    const [videoPreviewEdit, setVideoPreviewEdit] = useState(null);
    const [loading, setLoading] = useState(null);
    const [typeList, setTypeList] = useState([
        { _id: 'Normal', value: 'Normal' },
        { _id: 'Competition', value: 'Competition' }
    ])
    const [contentType, setContentType] = useState('')


console.log({meaningsArray})

    const schema = object().shape({
        grade: yup.string().required('Grade is Required'),
        category: yup.array().nullable().required('Category is Required'),
        postTitle: yup.string().required('Post Title is Required'),
        postContent: yup.string().required('Post content is Required'),
        contentLink: yup.string().required('Content link is Required'),
   
    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({
        defaultValues: {
            grade: '',
            category: '',
            postTitle: '',
            postContent: '',
            contentLink: '',
            contentType: '',
        },
        resolver: yupResolver(schema),
        mode: 'onChange'
    });

    useEffect(() => {
        if (state) {
            setValue("postTitle", state?.title)
            setValue("postContent", state?.content)
            setValue("contentLink", state?.link)
            setPreviewEdit(`${IMAGE_URL}${state?.file}`)
            setValue('category', state?.category)
            setMultpleArray(state?.category)
            setSelectCountry(state?.country)
            setValue('country', state?.country)

            if(has(state,'file')){
                if (state?.file_type === 'image') {
                    setPreviewEdit(IMAGE_URL + state?.file)
                    setType('image')
                  } else {
                    setVideoPreviewEdit(`${IMAGE_URL}${state?.file}`)
                    setType('video')
                  }
            
                  if(state.file_type === 'video'){
                    setPostThumbnail(true)
                    setThumbnailsPreview(`${IMAGE_URL}${state?.thumbnail}`)
                  }

            }

            if(state.content.includes('#')){

                let stringArray = state.content.split(' ');
                let filterArr = stringArray.filter(str => str.startsWith('#'))
                let filterMeanings = meaningsArray?.filter(arr => filterArr.includes(arr.word));
                let savedWords = meaningsArray?.map(arr => arr.word);
                let filterNewMeanings = []
                state?.words?.map(arr => {
                    if (!savedWords.includes(arr.word)) {
                        filterNewMeanings.push({
                            type: 'text',
                            word: arr.word,
                            meaning: arr.meaning
                        })
                        setValue(arr.word, arr.meaning)
                    }

                });
                let meanArray = [...filterMeanings, ...filterNewMeanings]        
                setMeaninsArray([...meanArray])
                setValue("postContent", state.content)
            }
        }
    }, [state])

    useEffect(() => {
        getGrade()
        getCategory()
    }, [])


    const getGrade = async () => {
        dispatch({
            type: LOADING,
            payload: true
        })

        await customAxios.get(`admin/grades`)
            .then(async response => {
                setGradeList(response.data.data)
                if (state && state?._id) {
                    setGrade(state?.grade)
                    setValue("grade", state?.grade)
                }
                dispatch({
                    type: LOADING,
                    payload: false
                })

            }).catch(async error => {
                setGradeList(null)

                dispatch({
                    type: LOADING,
                    payload: false
                })

            })
    }

    const getCategory = async () => {
        dispatch({
            type: LOADING,
            payload: true
        })

        await customAxios.get(`admin/category/list`)
            .then(async response => {
                setCategoryList(response.data.data)
                if (state && state?._id) {

                    setValue("category", state?.category)
                }
                dispatch({
                    type: LOADING,
                    payload: false
                })

            }).catch(async error => {
                setCategoryList(null)

                dispatch({
                    type: LOADING,
                    payload: false
                })

            })
    }


    //    useEffect(() => {
    //     if(selectCountry){
    //         getCountryPlan(selectCountry)
    //     }

    //    }, [selectCountry])


    //    useEffect(() => {
    //     if(plan){
    //         let filter = plan.filter((item, 
    //             index) => plan.indexOf(item.plan_type) === index);


    //     }
    //    }, [plan])




    // const getCountryPlan = async (data) => {
    //     dispatch({
    //         type: LOADING,
    //         payload: true
    //     })
    //     await customAxios.get(`auth/plan/country/${data}`)
    //         .then(async response => {
    //             setPlanList(response.data.data)
    //             dispatch({
    //                 type: LOADING,
    //                 payload: false
    //             })
    //         }).catch(async error => {
    //             dispatch({
    //                 type: LOADING,
    //                 payload: false
    //             })

    //         })
    // }


 
    const setimagefile = (e) => {
        setPreviewEdit(null)
        setimages(null)
        setVideo(null)
        setType(e.type.split('/', 1))
        if (e.type.split("/", 1).includes('video')) {
          if (e.size > mediaSize[0]?.videoSize) {
            toast.warning(`Video Size Should be less than ${mediaSize[0]?.videoOrginal}MB`);
            setPreviewEdit(null)
            setimages(null)
            setVideo(null)
            setType('image')
          } else {
            setVideo(e);
            setPostThumbnail(true);
          }
        } else if (e.type.split("/", 1).includes('image')) {
          setPostThumbnail(false);
          setThumbnails(null);
          if (e.size > mediaSize[0]?.imageSize) {
            toast.warning(`Image Size Should be less than ${mediaSize[0]?.imageOrginal}MB`);
            setPreviewEdit(null)
            setimages(null)
          } else {
            setPreviewEdit(null)
            setimages(e)
          }
    
        }
      }


    //MULTI SELECT FEILD.................................................................
    const onChangeMultiple = (event) => {

        const values = event.target.value;

        setValue('category', values)
        setError('category', '')
        setMultpleArray(
            values
        );

    }

    const handlePostChange = (e) => {
        let stringArray = e.target.value.split(' ');
        let filterArr = stringArray.filter(str => str.startsWith('#'))
        let filterMeanings = meaningsArray?.filter(arr => filterArr.includes(arr.word));
        let savedWords = meaningsArray?.map(arr => arr.word);
        let filterNewMeanings = []
        filterArr?.map(arr => {
            if (!savedWords.includes(arr)) {
                filterNewMeanings.push({
                    type: 'text',
                    word: arr,
                    meaning: ''
                })
            }
        });
        let meanArray = [...filterMeanings, ...filterNewMeanings]
        setMeaninsArray(meanArray)
        setValue("postContent", e.target.value)
        setError("postContent", null)
    }

    const changeMeaning = (index, value) => {
        meaningsArray[index].meaning = value
        // console.log({ meaningsArray })
        //setMeaninsArray(...meaningsArray)
    }

    const onSubmit = async (data) => {

        let checkMeanings = meaningsArray?.filter(mean => isEmpty(mean?.meaning))
        console.log({ data, checkMeanings })
        if (checkMeanings?.length > 0) {
            setMeaninsError('All words must contain meaning')
        }
        else {
            setMeaninsError('')
            setLoading(true)
            let formdata = new FormData();
            if (state?._id) {
                formdata.append("id", state?._id);
            }
            if (selectCountry === 'UAE') {
                formdata.append("plan", data?.plans);
            } else {
                formdata.append("plan", null);
            }
            formdata.append("grade", data?.grade);
            formdata.append("category", JSON.stringify(multpleArray));
            formdata.append('country', data?.country);
            formdata.append("title", data?.postTitle);
            formdata.append("type", 'news');
            formdata.append("content", data?.postContent);
            if (data?.image_video) {
                formdata.append("file", data?.image_video);
            }
            if(thumbnails){
                formdata.append('thumbnail', thumbnails);
              }
            if (meaningsArray?.length > 0) {
                formdata.append("type", 'news');
            }
            formdata.append("link", data?.contentLink);
            formdata.append("words_meaning", JSON.stringify(meaningsArray));
            formdata.append("screen_type", 'home');
            formdata.append("posted_by", context?.user?.name);
            let url = state?._id ? 'admin/news/edit' : 'admin/news/create'
            await customAxios.post(url, formdata, {
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(async response => {
                    if (state?._id) {
                        toast.success("Post Updated successfully");
                        navigate(-1);

                    }
                    else {
                        toast.success("Post Created successfully");
                        navigate(-1);
                    }

                    reset()
                    setMeaninsArray([])
                    setLoading(false)
                    setimagefile(null)
                    setGrade('')


                }).catch(async error => {
                    setLoading(false)

                })

        }
    }




    return (
        <Box >
            <CustomBackHeader label={state ? 'Edit News Post' : 'Add News Post'} />
            <Box px={5} mt={5}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={4} xs={12}>
                        <CustomSelect
                            control={control}
                            error={errors.grade}
                            fieldName="grade"
                            fieldLabel="Grade"
                            background="#FFEFEC"
                            size="16px"
                            value={grade}
                            onChangeValue={(e) => {
                                setGrade(e.target.value)
                                setValue('grade', e.target.value)
                                setError('grade', '')
                            }}
                        >
                            <MenuItem value={""} disabled >
                                <em>Select Grade</em>
                            </MenuItem>
                            {gradeList?.map(grade => (
                                <MenuItem key={grade?._id} value={grade?._id}  >
                                    <em>{grade?.name}</em>
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </Grid>

                    <Grid item lg={4} md={4} xs={12}>
                        <CustomSelect
                            control={control}
                            error={errors.country}
                            fieldName="country"
                            fieldLabel="Country"
                            background="#FFEFEC"
                            size="16px"
                            value={selectCountry}
                            onChangeValue={(e) => {
                                setSelectCountry(e.target.value)
                                setValue('country', e.target.value)
                                setError('country', '')
                            }}
                        >
                            <MenuItem value="" disabled >
                                <em>Select Country</em>
                            </MenuItem>
                            {['India', 'UAE'].map((res, i) => (
                                <MenuItem key={i} value={res}  >
                                    {res}
                                </MenuItem>

                            ))}

                        </CustomSelect>
                    </Grid>
                    
                   
                    <Grid item lg={4} md={4} xs={12}>
                        <CustomMultipleSelectInput
                            // readOnly={!id ? false : true}
                            control={control}
                            error={errors.category}
                            fieldName="category"
                            fieldLabel="Category"
                            background="#FFEFEC"
                            multiline
                            height="48px"
                            size="15px"
                            multiple={true}
                            defaultValue={""}
                            value={multpleArray}

                            onChangeValue={(e) => {
                                onChangeMultiple(e)

                            }}
                        >
                            <MenuItem value="" disabled >
                                <em>Select  Category</em>
                            </MenuItem>
                            {categoryList?.filter(cat => cat?.status === "active").map(category => (
                                <MenuItem key={category?._id} value={category?._id}  >
                                    <em>{category?.name}</em>
                                </MenuItem>
                            ))}
                        </CustomMultipleSelectInput>
                    </Grid>

                    <Grid item lg={4} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.contentLink}
                            fieldName="contentLink"
                            fieldLabel="Author"
                        />
                    </Grid>
                    <Grid item lg={8} md={8} xs={12}>
                        <CustomTextArea
                            control={control}
                            error={errors.postTitle}
                            fieldName="postTitle"
                            multiline={true}
                            height={90}
                            row={10}
                            fieldLabel="Post Title"
                            background={'#FFEFEC'}

                        />
                    </Grid>
                    <Grid item lg={8} md={8} xs={12}>
                        <CustomTextArea
                            control={control}
                            error={errors.postContent}
                            fieldName="postContent"
                            multiline={true}
                            height={110}
                            row={10}
                            changeValue={(value) => handlePostChange(value)}
                            fieldLabel="Post Content"
                            background={'#FFEFEC'}
                            buttonEnable={true}
                            onClick={() => setOpen(true)}
                            buttonText="Open AI Editor"
                        />

                    </Grid>
            
                    <Grid item lg={3} md={4} xs={12}>

                        <CustomImageUploader
                            vedeoPreview={videoPreviewEdit}
                            fieldLabel='Post Image/Video'
                            accept={'image/*,video/*'}
                            control={control}
                            format={type ? type : 'image'}
                            error={errors.image_video}
                            fieldName='image_video'
                            type={"file"}
                            height={350}
                            width={500}
                            background="#ffff"
                            id="contained-button-file"
                            preview={images}
                            video={video}
                            previewEditimage={previewEdit}
                            onChangeValue={(file, e) => {
                                setimagefile(file)
                                setValue("image_video", file)
                            }}
                        />
                    </Grid>
                    {postThumbnail &&
                        <Grid item lg={4} md={4} xs={12}>
                            <CustomImageUploader
                                fieldLabel='Add Thumbnail'
                                accept={'image/*'}
                                control={control}
                                format={'image'}
                                error={errors.thumbnails}
                                fieldName='thumbnail'
                                type={"file"}
                                height={350}
                                width={500}
                                background="#ffff"
                                id="contained-button-file"
                                preview={thumbnails}
                                previewEditimage={thumbnailsPreview}
                                onChangeValue={(file, e) => {
                                    setThumbnails(file)
                                    setValue("thumbnail", file)
                                    setThumbnailsPreview(null)
                                }}
                            />
                        </Grid>}

                    {meaningsArray && meaningsArray?.length > 0 && <Grid item lg={6} md={6} xs={12} >
                        <Typography fontFamily={'Quicksand'} fontWeight={'700'} px={'3px'} pb={2}
                            sx={{
                                fontSize: {
                                    lg: 16,
                                    md: 14,
                                    sm: 12,
                                    xs: 11,
                                },

                            }}
                        >{`Word & Meaning`}
                            <span style={{
                                color: '#FF0000'
                            }}>*</span>
                        </Typography>
                        <Box
                            bgcolor="#FFEFEC"
                            borderRadius={2}
                            boxShadow={1}
                            color="black"
                        >
                            <Grid container p={2} spacing={2}>
                                {meaningsArray?.map((mean, index) => (
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <CustomInput
                                            control={control}
                                            error={errors[mean?.word]}
                                            fieldName={mean?.word}
                                            fieldLabel={mean?.word?.slice(1)}
                                            changeValue={(value) => changeMeaning(index, value)}
                                            defaultValue={mean?.meaning}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            <span style={{ color: 'red', fontWeight: 'bold', fontSize: 10, paddingLeft: 15 }}>{meaningsError}</span>
                        </Box>
                    </Grid>}
                </Grid>
            </Box>
            <Box display={'flex'} justifyContent={'center'} py={10}>
                <CustomIconButton label={loading ?
                    <CircularProgress style={{ color: '#FF0053' }} size={20} /> : state?._id ? 'Update' : 'Add'} Icon={AddIcon} onClick={handleSubmit(onSubmit)} />
            </Box>
            {open && <CustomTextEditor open={open} handleClose={(value) => {
                handleClose()
                console.log({ value })
                if (value) {
                    setValue("postContent", value)
                }

            }} />}
        </Box>
    )
}

export default AddNewsManagement