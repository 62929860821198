import { Avatar, Box, Dialog, DialogContent, DialogTitle, Grid, Modal, Stack, Typography } from '@mui/material';
import React, { memo } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CustomTypography from '../../components/Widgets/CustomTypography';
const ViewCategoryModal = memo(({ open, handleClose, res }) => {
    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={"sm"}
            onClose={handleClose}>
            <DialogTitle>
                <Stack justifyContent={'space-between'} direction={'row'} px={2} py={2} alignItems='center'>
                    <Box></Box>
                    <Typography sx={{
                        fontFamily: 'Quicksand',
                        fontWeight: 'bold',
                        fontSize: 22,
                        color: '#FF0053',
                        letterSpacing: 1
                    }}>{'View Category'}</Typography>
                    <HighlightOffIcon onClick={() => handleClose()} sx={{
                        cursor: 'pointer'
                    }} />
                </Stack>

            </DialogTitle>
            <DialogContent>
                <Box px={2}>
                    <Grid container spacing={2} py={2}  >
                        <Grid item md={6} xs={12}>
                            <Typography sx={{ px: 1, fontFamily: 'Quicksand', fontWeight: '700', fontSize: '14px' }} >{'Category Name'}</Typography>
                            <Typography sx={{ height: '40px', bgcolor: '#F3F3F3', px: 1, alignItems: 'center', display: 'flex', borderRadius: '10px' }} >{res?.name}</Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography sx={{ px: 1, fontFamily: 'Quicksand', fontWeight: '700', fontSize: '14px' }} >{'Country'}</Typography>
                            <Typography sx={{ height: '40px', bgcolor: '#F3F3F3', px: 1, alignItems: 'center', display: 'flex', borderRadius: '10px' }}>{res?.country}</Typography>
                        </Grid>
                    </Grid>
                    <Box display={'flex'} justifyContent={'center'} height={130} py={2} px={8}>
                        <Avatar
                            variant='square'
                            sx={{
                                width: '100%',
                                height: '100%',
                                borderRadius: 2,
                                objectFit: 'cover'
                            }}
                            src={res?.icon}></Avatar>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
})

export default ViewCategoryModal