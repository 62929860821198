import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'

const CustomAvatar = ({ avatar, label ,h}) => {
  return (
    <Box>
      <Typography px={1} mb={.5} style={{
        fontFamily: 'Quicksand',
        fontWeight: 'bold',
        fontSize: 16
      }}>{label}</Typography>
      <Avatar sx={{
        width: { lg: '95%', md: '18vw', sm: '35vw', xs: '80vw' },
        height: h ? h : 160,
        borderRadius: 5

      }}
        variant={'square'}
        src={avatar}
      ></Avatar>
    </Box>
  )
}

export default CustomAvatar