import { Backdrop, Box, CircularProgress, Paper, Stack } from '@mui/material';
import React, { useState, useEffect } from 'react'
import DataTable from '../../../components/Widgets/CustomTable';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import ClassModal from '../ClassModal';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { RESET_ERROR } from '../../../Redux/constants/userConstant';
import { deleteSchoolClass } from '../../../Redux/actions/schoolActions';
import ViewClassModal from '../ViewClassModal';
import CustomSpinner from '../../../components/Widgets/CustomSpinner';
import CustomDelete from '../../../components/Widgets/CustomDelete';
const Classes = ({ res }) => {
    const dispatch = useDispatch()

    const { error, schoolClassUpdated, schoolClassDeleted } = useSelector(state => state.school);
    const { loading, loader } = useSelector(state => state.auth);

    const [openM, setOpenM] = useState(false);
    const [openV, setOpenV] = useState(false)
    const [rowRes, setRowRes] = useState(null)
    const [iD, setID] = useState(null);
    const [openD, setOpenD] = useState(false);

    const handleOpenD = () => setOpenD(true);
    const handleCloseD = () => setOpenD(false);

    const handleOpenM = () => setOpenM(true);
    const handleCloseM = () => setOpenM(false);
    const handleOpenV = () => setOpenV(true);
    const handleCloseV = () => setOpenV(false);



    const handleDelete = (id) => {
        setID(id);
        handleOpenD();
    }


    useEffect(() => {
        if (schoolClassUpdated) {
            toast.success(`Class Updated Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            handleCloseM()
        }
        if (schoolClassDeleted) {
            toast.success(`Class Deleted Successfully`);
            dispatch({
                type: RESET_ERROR
            })
           handleCloseD();
        }
        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [error, schoolClassUpdated, schoolClassDeleted])


    const columns = [

        {
            field: 'name',
            headerName: 'Class',
            flex: 1,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'division',
            headerName: 'Division',
            flex: 1,
            headerAlign: 'center',
            align: 'center',

        },
        {

            headerName: 'Roll No',
            flex: 1,
            valueGetter: (params) => (`${params.row?.roll_no_from}-${params.row?.roll_no_to}`),
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'Action',
            headerName: 'Action',
            flex: 1,
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={3} direction={'row'}>
                    <RemoveRedEyeIcon
                        onClick={() => hadleOpenViewres(row)}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer',

                        }} />

                    <BorderColorTwoToneIcon
                        onClick={() => hadleOpenres(row)}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />
                    <DeleteOutlineTwoToneIcon
                        onClick={() => handleDelete(row?._id)}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }} />

                </Stack>
            )
        },
    ];


    const hadleOpenres = (row) => {
        setRowRes(row)
        handleOpenM()

    }
    const hadleOpenViewres = (row) => {
        setRowRes(row)
        handleOpenV()

    }



    return (
        <Box >
            <Box px={5} py={2}>
                <Paper style={{
                    borderRadius: "20px"
                }}>
                    <DataTable
                        rows={res}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {openV && <ViewClassModal
                rowRes={rowRes}
                openV={openV}
                handleCloseV={handleCloseV}
            />}

            {openM && <ClassModal
                rowRes={rowRes}
                openM={openM}
                handleCloseM={handleCloseM}
            />}
            {openD &&
                <CustomDelete
                    open={handleOpenD}
                    handleClose={handleCloseD}
                    mode={'Class'} id={iD} />}
        </Box>
    )
}

export default Classes