import { Button, CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const CustomButton = ({ label,active,onClick,focus, loading,colorText,disable}) => {
    return (
        <Box p={1}>
            <Button onClick={onClick}
                disabled={disable}
                style={{
                    borderRadius: 12,
                    backgroundColor:active ?   "#FF0053" : '#FFFAFA',
                    padding: "9px 18px",
                    fontSize: "16px",
                    color: active ? '#ffff' : colorText ? colorText :'#FF0053',
                    fontWeight: 'bold',
                    boxShadow:active ? '0' : '0px 6px #FADEDA'
                }}>
                    {loading ? <CircularProgress /> : label}
            </Button>
        </Box>
    )
}

export default CustomButton