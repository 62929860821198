import { Backdrop, Box, CircularProgress, Paper } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import CustomSearch from '../../components/Widgets/CustomSearch';
import DataTable from '../../components/Widgets/CustomTable';
import Header from '../Header';
import AddIcon from '@mui/icons-material/Add';
import CustomSwitch from '../../components/Widgets/CustomSwitch';
import { Stack } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
const Individual = ({ res }) => {
    const navigate = useNavigate();

    const { loading, loader } = useSelector(state => state.auth);
    const columns = [
        {
            field: 'Post ID',
            headerName: 'Post ID',
            width:200,
            valueGetter: (params) => (`#${params.row?.post_id}`),
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'createdOn',
            headerName: 'Reported Date',
            width:250,
             height:100,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (params.row?.reported_details?.map((res)=>moment(res?.created_at,"YYYY-MM-DD").format('DD/MM/YYYY')))
        },
        {
            field: 'name',
            headerName: 'Student  Name',
            headerAlign: 'center',
            align: 'center',
            width:200,
            valueGetter: (params) => (params.row?.creator_details?.name)

        },
        {
            field: 'class',
            headerName: 'Class',
            headerAlign: 'center',
            align: 'center',
            width:200,
            valueGetter: (params) => (params.row?.class)
        },
        {
            field: 'reviewed_by',
            headerName: 'Reviewed By',
            headerAlign: 'center',
            align: 'center',
            width:200,
            valueGetter: (params) => (params.row?.reviewd_by_details?.name)
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            width: 150,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                        <span style={{ color: row?.status === 'Rejected' ?'#DC1616' : row?.status === 'Pending' ? "#EAAD04" : 
                        row?.status === 'Approved' ? '#21AA12' :row?.status === 'Reported' ? '#DC1616': '', cursor: 'pointer' }}>{row?.status}</span>
                     )
        },
        {
            field: 'Action',
            headerName: 'Action',
            flex: 1,
            width: 150,
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={2} direction={'row'}>

                    {row?.status !== 'Rejected' &&
                    <BorderColorTwoToneIcon
                        onClick={() => navigate(`/editIndividualReview`, {
                            state: {
                                item: row,
                                mode: 'edit'
                            }
                        })}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />}
                    <RemoveRedEyeIcon
                        onClick={() => navigate(`/editIndividualReview`, {
                            state: {
                                item: row,
                                mode: 'view'
                            }
                        })}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />
                </Stack>
            )



        },

    ];


    return (
        <Box >
            <Box px={5} py={2}>
                <Paper style={{
                    borderRadius: "20px"
                }}>
                    <DataTable
                    rowheight={100}
                        rows={res ? res : []}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                //onClick={handleClose}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default Individual