import { CREATE_USER_FAIL, CREATE_USER_SUCCESS, DELETE_USER_SUCCESS, GET_ROLE_FAIL, GET_ROLE_SUCCESS, GET_USER_FAIL, GET_USER_SINGLE_FAIL, GET_USER_SINGLE_SUCCESS, GET_USER_SUCCESS, LOADER, RESET_ERROR, UPDATE_USER_FAIL, UPDATE_USER_SUCCESS, USER_STATUS_FAIL, USER_STATUS_SUCCESS } from "../constants/userConstant";

export const userReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                userCreated: null,
                userUpdated: null,
                userDeleted: null,
                userStatusChanged: null
            }
        case CREATE_USER_SUCCESS:
            return {
                ...state,
                userCreated: true,
                
            }
        case CREATE_USER_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_ROLE_SUCCESS:
            return {
                ...state,
                getRoleList: action.payload
            }
        case GET_ROLE_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_USER_SUCCESS:
            return {
                ...state,
                userList: action.payload
            }
        case GET_USER_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_USER_SINGLE_SUCCESS:
            return {
                ...state,
                userSingleList: action.payload
            }
        case GET_USER_SINGLE_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                userList: state.userList?.map(user => user._id === action.payload._id ? action.payload : user),
                userUpdated: true
            }
        case UPDATE_USER_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                userDeleted: true,
                userList: state?.userList.filter(user => user._id !== action.payload)
            }
        case USER_STATUS_SUCCESS:
            return {
                ...state,
                userList: state.userList?.map(user => user._id === action.payload._id ? action.payload : user),
                userStatusChanged: true,
            }

        case USER_STATUS_FAIL:
            return {
                ...state,
                error: action.payload
            }
            

        default:
            return state;
    }

}