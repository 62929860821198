
import { Dialog, DialogTitle, Box, DialogContent, Grid, Typography, Button } from '@mui/material'
import React, { useId, useState } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CustomTypography from '../../../components/Widgets/CustomTypography';
import CustomAvatar from '../../../components/Widgets/CustomAvatar';
import { IMAGE_URL } from '../../../config/constants';
import DoneIcon from '@mui/icons-material/Done';
const ViewQuestionTeacher = ({ open, handleClose, res }) => {

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={"sm"}
            onClose={handleClose}>
            <DialogTitle>
                <Box display={'flex'} py={1} justifyContent={'center'} >
                    <Typography variant='caption' fontFamily={'Quicksand'}
                        sx={{
                            fontSize: {
                                lg: 26,
                                md: 20,
                                sm: 18,
                                xs: 16,
                            },

                        }} letterSpacing={1} color={'#FF0053'} fontWeight={'700'}>
                        View Question
                    </Typography>
                    <HighlightOffIcon style={{ fontSize: '30px', cursor: 'pointer', position: 'absolute', right: 10 }} onClick={() => handleClose('')} />
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={4} px={2} justifyContent={'center'}>
                    <Grid item xs={6}>
                        <CustomTypography sm={'100%'} xs={'100%'} lg={'100%'} h={160} formlabel={'Question'} label={res?.question} />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomAvatar label={'Image'} avatar={`${IMAGE_URL}${res?.image}`} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ fontFamily: 'QuickSand', fontWeight: 'bold' }}>Answers</Typography>
                    </Grid>
                    {res?.options.map((val, i) => (
                        <Grid item lg={6} md={6} xs={12}>
                            <Typography style={{
                                fontFamily: 'QuickSand',
                                fontWeight: 'bold',
                                height: '40px',
                                paddingLeft: '10px',
                                borderRadius: '10px',
                                display: 'flex',
                                 overflow:'hidden',
                                 textOverflow: "ellipsis",
                                
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                background: i === res?.correct_answer ? '#DBFADA' : '#FFEFEC'
                            }} >{val}<Box
                                sx={{ height: '40px', width: '30px', background: i === res?.correct_answer ? '#F3FFF9' : '#E8E8E8',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'10px'}}> <DoneIcon sx={{color:i === res?.correct_answer ?'#29C617' : '#B4B4B4'}} /></Box>
                            </Typography>
                        </Grid>
                    ))}


                </Grid>


            </DialogContent>
        </Dialog>
    )
}

export default ViewQuestionTeacher