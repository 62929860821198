import { Backdrop, Box, CircularProgress, Paper, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { toast } from "react-toastify";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import CustomSwitch from '../../../components/Widgets/CustomSwitch';
import DataTable from '../../../components/Widgets/CustomTable';
import { RESET_ERROR } from '../../../Redux/constants/userConstant';
import { statusSchoolStudents } from '../../../Redux/actions/schoolActions';
const Students = ({ res }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, loader } = useSelector(state => state.auth);
    const { error, schoolStudentStatus } = useSelector(state => state.school);



    const columns = [
        {
            field: 'user_id',
            headerName: 'Students ID',
            flex: 1,
            valueGetter: (params) => (`#${params.row?.user_id}`),
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'Created Date',
            headerName: 'Created Date',
            flex: 1,
            valueGetter: (params) => (moment(params.row.created_at).format('DD/MM/YYYY')),
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        
        {
            field: 'class',
            headerName: 'Class & Division',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (`${params.row.class}-${params.row.division}`),
        },
        {
            field: 'roll_no',
            headerName: 'Roll No',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'email',
            headerName: 'Email Address',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'mobile',
            headerName: 'Mobile Number',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <Box>
                    <CustomSwitch
                        checked={row?.status === "active" ? true : false}
                        onClick={(e) => ChangeStatus(e.target.checked, row?._id)}
                    />
                </Box>
            )
        },
        {
            field: 'Action',
            headerName: 'Action',
            flex: 1,
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={1} direction={'row'}>
                    <RemoveRedEyeIcon
                    onClick={()=>navigate('/ViewSchoolStudent',{
                        state:{
                            item:row
                        }
                    })}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }} />
                    <BorderColorTwoToneIcon
                        onClick={() => navigate('/editSchoolStudent', {
                            state: {
                                item: row
                            }
                        })}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />
                    {/* <DeleteOutlineTwoToneIcon
                        onClick={() => handleOpenR()}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }} /> */}

                </Stack>
            )
        },
    ];


    useEffect(() => {
        if (schoolStudentStatus) {
            toast.success(`Status Changed Successfully`);
            dispatch({
                type: RESET_ERROR
            })
        }
        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, schoolStudentStatus])

    const ChangeStatus = (checked, row) => {
        let status = checked === true ? 'active' : 'inactive'
        let val = {
            id: row,
            status: status
        }
        dispatch(statusSchoolStudents(val))
    }


    return (
        <Box>

            <Box px={5} py={2}>
                <Paper style={{
                    borderRadius: "20px"
                }}>
                    <DataTable
                        rows={res}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default Students