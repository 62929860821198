import { Backdrop, Box, CircularProgress, Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import CustomSearch from '../../components/Widgets/CustomSearch';
import DataTable from '../../components/Widgets/CustomTable';
import Header from '../Header';
import AddIcon from '@mui/icons-material/Add';
import customAxios from '../../CustomAxios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Stack } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import { useTransition } from 'react';


const NewsPost = ({ search }) => {


    console.log({search})

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [newsArray, setNewsArray] = useState([]);
    const [pending, startTransition] = useTransition();
    const [data, setData] = useState([])



    useEffect(() => {
        getAllNews();
    }, [])


    const getAllNews = async () => {
        setLoading(true)
        await customAxios.get(`admin/news`)
            .then(async response => {
                setNewsArray(response?.data?.data)
                setData(response?.data?.data)
                setLoading(false)

            }).catch(async error => {
                setNewsArray([])
                setData([])
                setLoading(false)
                toast.error(error)
            })
    }

    const handleDelete = async (id) => {
        setLoading(true)
        await customAxios.delete(`admin/news/delete/${id}`)
            .then(async response => {
                toast.success("News Deleted successfully")
                getAllNews()

            }).catch(async error => {
                setLoading(false)
                toast.error(error)
            })
    }


    useEffect(() => {
        if (search) {
            let Result = data?.filter(ads => ads?.post_id?.toString().toLowerCase().includes(search.toLowerCase()) || ads?.country?.toString().toLowerCase().includes(search.toLowerCase()) || ads?.title?.toString().toLowerCase().includes(search.toLowerCase()) || ads?.posted_by?.toString().toLowerCase().includes(search.toLowerCase()))
            startTransition(() => {
                setNewsArray(Result)
            })


        }
        if (search === '') {
            getAllNews();
        }
    }, [search])



    const columns = [
        {
            field: 'post_id',
            headerName: 'Post ID',
            flex: 1,
            type: 'number',
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'grade',
            headerName: 'Grade',
            flex: 1,
            valueGetter: (params) => `${params.row.grade_details.name}`,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'title',
            headerName: 'Title',
            flex: 1,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'country',
            headerName: 'Country',
            flex: 1,
            headerAlign: 'center',
            align: 'center'
        },
        // {
        //     headerName: 'Category',
        //     field: 'Category',
        //     flex: 1,
        //     editable: true,
        //     valueGetter: (params) => `${params.row.categories.map((res) => (`${res?.name}`))}`,
        //     headerAlign: 'center',
        //     align: 'center'
        // },
        {
            field: 'posted_by',
            headerName: 'Posted By',
            flex: 1,
            editable: true,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'created_at',
            headerName: 'Created Date',
            flex: 1,
            valueGetter: (params) => moment(params?.row?.created_at).format("DD-MM-YYYY"),
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            width: 150,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <span style={{
                    color: row?.status === 'Ongoing' ? '#C2620E' : row?.status === 'active' ? "#2E75C7" :
                        row?.status === 'Completed' ? '#21AA12' : '', cursor: 'pointer'
                }}>{row?.status}</span>
            )
        },
        {
            field: 'Action',
            headerName: 'Action',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={1} direction={'row'}>
                    <RemoveRedEyeIcon
                        onClick={() => navigate(`/viewNews`, { state: row })}
                        style={{

                            color: '#FF0053',
                            cursor: 'pointer'
                        }} />
                    <BorderColorTwoToneIcon
                        onClick={() => navigate(`/AddNewsManagment`, { state: row })}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />
                    <DeleteOutlineTwoToneIcon
                        onClick={() => handleDelete(row?._id)}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }} />

                </Stack>
            )
        },
    ];

    return (
        <Box >
            <Box px={5} py={2}>
                <Paper style={{
                    borderRadius: "20px"
                }}>
                    <DataTable
                        rows={newsArray}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default NewsPost