import { Box, Stack, styled } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomIconButton from '../../../components/Widgets/CustomIconButton'
import Header from '../../Header';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import CustomSearch from '../../../components/Widgets/CustomSearch';
import CustomButton from '../../../components/Widgets/CustomButton';
import Text from './Text';
import Quiz from './Quiz';

const MyClassRoom = () => {

    const navigate = useNavigate()

    let focus = localStorage.getItem('defaultclass')
    const [active, setActive] = useState(focus ? focus : 'Test');
    const [search, setSearch] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    //style...........................
    const BUTTONBOX = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {
            display: 'none',
        }
    }))
    const BUTTONBOXSMALL = styled(Box)(({ theme }) => ({
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        }
    }))




    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    const handleNew = () => {
        switch (active) {
            case "Test":
                navigate('/addtextteacher');
                break;
            case "Quiz":
                navigate('/addquizteacher');
                break;
            default:
                break;
        }
    }


    




    return (
        <Box>
            <Header label={'My ClassRoom'} />
            <Stack
                direction={'row'}
                px={5}
                justifyContent={'space-between'}
                alignItems={'center'}
                py={1}>
                <BUTTONBOX display={'flex'} py={1} sx={{ gap: { xl: 2, md: 1.5, xs: 1 }, flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' } }} >
                    {['Test', 'Quiz'].map((res) => (
                        <CustomButton label={res === "Test" ? "Test" : res} active={active === res ? true : false}
                            onClick={() => {
                                setActive(res)
                                localStorage.setItem('defaultclass', res)
                            }}
                        />
                    ))}
                </BUTTONBOX>
                <BUTTONBOXSMALL>
                    <CustomIconButton
                        active={active}
                        Icon={FilterListIcon} onClick={handleClick} />
                </BUTTONBOXSMALL>
                <Stack direction={{ lg: 'row', md: 'row', sm: 'column' }} gap={2}>
                    <CustomIconButton label={'Add'} Icon={AddIcon} onClick={handleNew} />
                    <CustomSearch size={200} placeholder={'Search'} onSearch={(value) => setSearch(value)} />
                </Stack>
            </Stack>
            {active === 'Test' && <Text search={search} setSearch={setSearch} />}
            {active === 'Quiz' && <Quiz search={search} setSearch={setSearch} />}

        </Box>
    )
}

export default MyClassRoom