import { Box, Paper } from '@mui/material'
import moment from 'moment/moment.js'
import React,{useState} from 'react'
import { useLocation } from 'react-router-dom'
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js'
import DataTable from '../../components/Widgets/CustomTable/index.js'
import { Stack } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ViewQuestion from './viewQuestion.js'

const ViewOnlyQuestions = () => {
    const { state } = useLocation();
    const [open, setOpen] = useState(false)
    const [rowList, setRowlist] = useState(null);

    const OpenView = (data) => {
        setRowlist(data)
        setOpen(true);
    }

    const columns = [
        {
            field: 'quiz_id',
            headerName: '#',
            flex:1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (index) => index.api.getRowIndex(index.row._id) + 1
        },
        {
            field: 'question',
            headerName: 'Question',
            flex:1,
            headerAlign: 'center',
            align: 'center',
         
        },
        {
            field: 'Correct Option',
            headerName: 'Correct Option',
            flex:1,
          
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <span>{row.options[row?.correct_answer]}</span>
            )
          
        },
        {
            field: 'Action',
            headerName: 'Action',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={1} direction={'row'}>
                    <RemoveRedEyeIcon
                        onClick={() => OpenView(row)}
                        style={{

                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />
                   
                

                </Stack>
                )}
      
    ]
    return (
        <Box>
            <CustomBackHeader label={'View Quiz'} />
            <Box px={5} py={2} >
               
                <Paper style={{
                    borderRadius: "20px",
                    marginTop: '10px'
                }}>

                    <DataTable
                        rows={state ? state.questions :[]}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
            </Box>
            {open && <ViewQuestion open={open} handleClose={() =>  setOpen(false)} res={rowList} />}
        </Box>
    )
}

export default ViewOnlyQuestions