import { Avatar, Button } from '@mui/material'
import React from 'react'
import login from '../../../Assets/images/login.png'
const CustomLoginButton = ({onclick,onKeyPress}) => {
    return (
        <>
            <Button onClick={onclick} onKeyPress={onKeyPress}>
                <Avatar src={login} variant="square"
                    sx={{ width: 100, height: 100, cursor: 'pointer' }}
                />
            </Button>
        </>
    )
}

export default CustomLoginButton