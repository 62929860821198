import { Box, Grid, MenuItem, Typography } from '@mui/material'
import React, { useContext, useState, useEffect } from 'react';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import CustomSelect from '../../../components/Widgets/CustomSelect';
import CustomBackHeader from '../../../components/Widgets/CustomBackarrowHeader.js';
import CustomButton from '../../../components/Widgets/CustomButton';
import UserContext from '../../../helpers/user';
import { getValue } from '@mui/system';
import customAxios from '../../../CustomAxios';
import Header from '../../Header';

const Promote = () => {
    const user = useContext(UserContext);


    const [loading, setLoading] = useState(false);
    const [classList, setClassList] = useState([]);
    const [from_class, setFromClass] = useState(null);
    const [from_division, setFrom_division] = useState(null);
    const [from_division_array, setFrom_division_array] = useState([]);
    const [to_class_array, setTo_class_array] = useState([])
    const [to_class, setToClass] = useState(null);
    const [to_division, setTo_division] = useState(null);
    const [to_division_array, setTodivisionArray] = useState([]);




    const schema = object().shape({
        to_class: yup.string().typeError('required').required('Required'),
        to_division: yup.string().typeError('required').required('Required'),
        from_class: yup.string().typeError('required').required('Required'),
        from_division: yup.string().typeError('required').required('Required'),

    });


    const {
        control,
        formState: { errors },
        setValue,
        setError,
        handleSubmit
    } = useForm({
        defaultValues: {

        },
        resolver: yupResolver(schema)
    });

    const SubmitForm = async (data) => {
        let promote = {
            school_id: user?.user?.teacher_details?.school_id,
            promoted_by: user?.user?.teacher_details?.user_id,
            from_class: from_class,
            from_division: from_division,
            to_class: to_class,
            to_division: to_division,
        }
        setLoading(true);
        await customAxios.post(`teacher/promotestudent`, promote)
            .then(async response => {
                toast.success("promoted Successfully");
                setLoading(false);
                setFrom_division(null);
                setFromClass(null);
                setToClass(null);
                setTo_division(null);
            }).catch(async error => {
                toast.error(`${error}`);
                setLoading(false)

            })

    }



    useEffect(() => {
        getClasses()
    }, [])


    useEffect(() => {
        if (from_class) {
            let result = classList.filter((res) => res?.class === from_class);
            setFrom_division_array(result);
            let toResult = classList.filter((res) => res?.class !== from_class);
            setTo_class_array(toResult);
        }
        if (to_class) {
            let ToResult = classList?.filter((res) => res?.class === to_class);
            setTodivisionArray(ToResult)
        }
    }, [from_class, to_class])




    const getClasses = async (data) => {
        setLoading(true);
        await customAxios.get(`admin/class/classlist/${user?.user?.teacher_details.school_id}`)
            .then(async response => {
                setClassList(response.data.data);
                setLoading(false);
            }).catch(async error => {
                setLoading(false)

            })
    }


    return (
        <Box>
            <Header label={'Promote'} />
            <Grid container spacing={2} px={5} py={5}>
                <Grid item lg={2.5} md={4} xs={12}>
                    <CustomSelect
                        control={control}
                        error={errors.from_class}
                        fieldName="from_class"
                        fieldLabel=" From Class"
                        background="#FFEFEC"
                        size="16px"
                        value={from_class}
                        onChangeValue={(e) => {
                            setFromClass(e.target.value);
                            setValue('from_class', e.target.value);
                            setError('from_class', '');
                            setToClass(null);
                            setTo_division(null);
                        }}
                    >
                        <MenuItem value={""} disabled >
                            <em>Select Grade</em>
                        </MenuItem>
                        {classList?.map(cl => (
                            <MenuItem key={cl?._id} value={cl?.class}  >
                                <em>{cl?.class}</em>
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </Grid>
                <Grid item lg={2.5} md={4} xs={12}>
                    <CustomSelect
                        control={control}
                        error={errors.from_division}
                        fieldName="from_division"
                        fieldLabel="From Division"
                        background="#FFEFEC"
                        size="16px"
                        value={from_division}
                        onChangeValue={(e) => {
                            setFrom_division(e.target.value)
                            setValue('from_division', e.target.value)
                            setError('from_division', '')
                        }}
                    >
                        <MenuItem value={""} disabled >
                            <em>Select Division</em>
                        </MenuItem>
                        {from_division_array[0]?.division?.map(div => (
                            <MenuItem key={div?._id} value={div?.division}  >
                                <em>{div?.division}</em>
                            </MenuItem>
                        ))}
                    </CustomSelect>

                </Grid>
                <Grid item lg={2.5} md={4} xs={12}>
                    <CustomSelect
                        control={control}
                        error={errors.to_class}
                        fieldName="to_class"
                        fieldLabel="To Class"
                        background="#FFEFEC"
                        size="16px"
                        value={to_class}
                        onChangeValue={(e) => {
                            setToClass(e.target.value)
                            setValue('to_class', e.target.value)
                            setError('to_class', '')
                        }}
                    >
                        <MenuItem value={""} disabled >
                            <em>To class</em>
                        </MenuItem>

                        {from_division && to_class_array?.map(cl => (
                            <MenuItem key={cl?._id} value={cl?.class}  >
                                <em>{cl?.class}</em>
                            </MenuItem>
                        ))}
                    </CustomSelect>

                </Grid>
                <Grid item lg={2.5} md={4} xs={12}>
                    <CustomSelect
                        control={control}
                        error={errors.to_division}
                        fieldName="to_division"
                        fieldLabel="To  Division"
                        background="#FFEFEC"
                        size="16px"
                        value={to_division}
                        onChangeValue={(e) => {
                            setValue('to_division', e.target.value);
                            setError('to_division', '');
                            setTo_division(e.target.value);
                        }}
                    >
                        <MenuItem value={""} disabled >
                            <em>Select To Division</em>
                        </MenuItem>
                        {to_division_array[0]?.division?.map(div => (
                            <MenuItem key={div?._id} value={div?.division}  >
                                <em>{div?.division}</em>
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </Grid>
                <Grid item lg={2} md={4} xs={12}>
                    <Typography mt={1.5}></Typography>
                    <CustomButton label={'Promote'} onClick={handleSubmit(SubmitForm)
                    } />
                </Grid>
            </Grid>
        </Box>

    )
}
export default Promote