import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import moment from 'moment'
import React from 'react'
import { useLocation } from 'react-router-dom'
import CustomAvatar from '../../components/Widgets/CustomAvatar/index.js'
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js'
import CustomCardMedia from '../../components/Widgets/CustomCardMedia/index.js'
import CustomTypography from '../../components/Widgets/CustomTypography/index.js'

const ViewAds = () => {

    const location = useLocation()


    return (
        <Box>
            <CustomBackHeader label={`User :#${location.state.item?.ad_id}`} />
            <Grid container spacing={3} px={5} mt={2}>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Ad Heading'} label={location.state.item.heading} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Country'} label={location.state.item.country} />
                </Grid>
                {location.state.item.school !==  'null' &&
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomTypography formlabel={'School'} label={location.state.item.school} />
                    </Grid>}
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Ad Position'} label={location.state.item?.position} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Text'} h={130} label={location.state.item?.text} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Expiry date'} label={moment(location.state.item?.expiry_date).format('DD-MM-YYYY')} />
                </Grid>
                {location.state.item?.type === 'image' &&
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomAvatar avatar={location.state.item.file} />
                    </Grid>
                }
                {location.state.item?.type === "video" &&
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomCardMedia videoProp={location.state.item.file} />
                    </Grid>
                }
            </Grid>

        </Box>
    )
}

export default ViewAds