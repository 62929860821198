import React from 'react'
import { useState } from 'react'
import Context from "./index";

const RoutingProvider = (props) => {
    const [routing, setRouting] = useState(null)
    return (
        <Context.Provider
            value={{
                ...props,
                routing,
                setRouting: setRouting
            }}
        >
            {props.children}
        </Context.Provider>
    )
}

export default RoutingProvider