import React, { useState, useEffect, useContext } from 'react'
import { Avatar, Box, Stack, styled, Button, Typography, Grid, Backdrop, CircularProgress } from '@mui/material';
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js';
import CustomInput from '../../components/Widgets/CustomInput/index.js';
import CustomCalender from '../../components/Widgets/CustomCalender';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomSelect from '../../components/Widgets/CustomSelect/index.js';
import MenuItem from '@mui/material/MenuItem';
import BackupIcon from '@mui/icons-material/Backup';
import CustomIconButton from '../../components/Widgets/CustomIconButton/index.js';
import AddIcon from '@mui/icons-material/Add';
import CustomTextArea from '../../components/Widgets/CustomTextArea/index.js';
import CustomTextEditor from '../../components/Widgets/CustomTextEditor.js/index.js';
import CustomImageUploader from '../../components/Widgets/CustomImageUploder/index.js';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux'
import { LOADING } from '../../Redux/constants/userConstant.js';
import customAxios from '../../CustomAxios.js';
import { isEmpty } from 'lodash'
import UserContext from '../../helpers/user/index.js';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { IMAGE_URL } from '../../config/constants.js';
import { has } from 'lodash';



const NewText = () => {

    const { state } = useLocation();



    const { loader, mediaSize } = useSelector(state => state.auth);


    // screen_type  ,, home ,network


    const navigate = useNavigate()
    const dispatch = useDispatch()
    const context = useContext(UserContext)


    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const [type, setType] = useState('image')
    const [video, setVideo] = useState(null);
    const [images, setimages] = useState(null)
    const [previewEdit, setPreviewEdit] = useState(null);
    const [gradeList, setGradeList] = useState([])
    const [typeList, setTypeList] = useState([
        { _id: 'Normal', value: 'Normal' },
        { _id: 'Competition', value: 'Competition' }
    ])
    const [contentType, setContentType] = useState('')
    const [meaningsError, setMeaninsError] = useState('')
    const [grade, setGrade] = useState('')
    const [category, setCategory] = useState('')
    const [loading, setLoading] = useState(null);
    const [selectCountry, setSelectCountry] = useState(null);
    const [thumbnails, setThumbnails] = useState(null);
    const [thumbnailsPreview, setThumbnailsPreview] = useState(null);
    const [postThumbnail, setPostThumbnail] = useState(false);
    const [videoPreviewEdit, setVideoPreviewEdit] = useState(null);



    const schema = object().shape({
        grade: yup.string().required('Grade is Required'),
        contentType: yup.string().required('Type is Required'),
        title: yup.string().required('Title is Required'),
        taskContent: yup.string().required('Task content is Required'),
        marks: yup.string().required('Mark is Required'),
        expiry: yup.string().required('Expiry date is Required').nullable(),
        country: yup.string().required('Country is Required')
    });




    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({
        defaultValues: {
            grade: '',
            contentType: '',
            title: '',
            taskContent: '',
            marks: '',
            expiry: null,
            


        },
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        if (state) {
            setValue("title", state?.title)
            setValue("taskContent", state?.content)
            setValue("contentType", state?.test_type)
            setContentType(state?.test_type)
            setValue("marks", state?.max_mark)
            setValue("expiry", state?.expiry_date)
            setValue('country', state?.country)
            setSelectCountry(state?.country)

            if(has(state,'file')){
                if (state?.file_type === 'image') {
                    setPreviewEdit(state?.file)
                    setType('image')
                } else {
                    setVideoPreviewEdit(`${IMAGE_URL}${state?.file}`)
                    setType('video')
                }
    
                if (state.file_type === 'video') {
                    setPostThumbnail(true)
                    setThumbnailsPreview(`${IMAGE_URL}${state?.thumbnail}`)
                }
                
            }
           

        }
    }, [state])

    useEffect(() => {
        getGrade()
    }, [])


    const getGrade = async () => {
        dispatch({
            type: LOADING,
            payload: true
        })

        await customAxios.get(`admin/grades`)
            .then(async response => {
                setGradeList(response.data.data)
                if (state && state?._id) {
                    setGrade(state?.grade)
                    setValue("grade", state?.grade)
                }
                dispatch({
                    type: LOADING,
                    payload: false
                })

            }).catch(async error => {
                setGradeList(null)

                dispatch({
                    type: LOADING,
                    payload: false
                })

            })
    }




    const setimagefile = (e) => {
        setPreviewEdit(null)
        setimages(null)
        setVideo(null)
        setType(e.type.split('/', 1))
        if (e.type.split("/", 1).includes('video')) {
            if (e.size > mediaSize[0]?.videoSize) {
                toast.warning(`Video Size Should be less than ${mediaSize[0]?.videoOrginal}MB`);
                setPreviewEdit(null)
                setimages(null)
                setVideo(null)
                setType('image')
            } else {
                setVideo(e);
                setPostThumbnail(true);
            }
        } else if (e.type.split("/", 1).includes('image')) {
            setPostThumbnail(false);
            setThumbnails(null);
            if (e.size > mediaSize[0]?.imageSize) {
                toast.warning(`Image Size Should be less than ${mediaSize[0]?.imageOrginal}MB`);
                setPreviewEdit(null)
                setimages(null)
            } else {
                setPreviewEdit(null)
                setimages(e)
            }

        }
    }



    const onSubmit = async (data) => {
        setLoading(true);
        let formdata = new FormData();
        formdata.append("grade", data?.grade);
        formdata.append("title", data?.title);
        formdata.append("content", data?.taskContent);
        formdata.append("posted_by", context?.user?.name);
        formdata.append("test_type", data?.contentType);
        formdata.append("country", data?.country);
        if (data?.image_video) {
            formdata.append("file", data?.image_video);
        }
        if (thumbnails) {
            formdata.append('thumbnail', thumbnails);
        }
        formdata.append("max_mark", data?.marks);
        formdata.append("expiry_date", moment(data?.expiry).format("YYYY-MM-DD"));
        formdata.append("screen_type", context?.user?.role === 'admin' ? 'home' : 'network');

        let url
        if (state?._id) {
            formdata.append("id", state?._id);
            url = "admin/test/edit"
        }
        else {
            url = "admin/test/create"
        }
        await customAxios.post(url, formdata, {
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(async response => {
                if (state?._id) {
                    toast.success("Test Updated successfully")
                    navigate(-1)
                }
                else {
                    toast.success("Test Created successfully")
                    navigate(-1)
                }
                reset()
                setLoading(false)
                setimagefile(null)
                setGrade('')
                setContentType('')
            }).catch(async error => {
                setLoading(false)
                toast.error(error)
            })
    }

    return (
        <Box >
            <CustomBackHeader label={state ? 'Update Test' : 'Add Test'} />
            <Box px={5} mt={5}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={4} xs={12}>
                        <CustomSelect
                            control={control}
                            error={errors.grade}
                            fieldName="grade"
                            fieldLabel="Grade"
                            background="#FFEFEC"
                            size="16px"
                            value={grade}
                            onChangeValue={(e) => {
                                setGrade(e.target.value)
                                setValue('grade', e.target.value)
                                setError('grade', '')
                            }}
                        >
                            <MenuItem value={""} disabled >
                                <em>Select Grade</em>
                            </MenuItem>
                            {gradeList?.map(grade => (
                                <MenuItem key={grade?._id} value={grade?._id}  >
                                    <em>{grade?.name}</em>
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        <CustomSelect
                            control={control}
                            error={errors.contentType}
                            fieldName="contentType"
                            fieldLabel="Type"
                            background="#FFEFEC"
                            size="16px"
                            value={contentType}
                            onChangeValue={(e) => {
                                setContentType(e.target.value)
                                setValue('contentType', e.target.value)
                                setError('contentType', '')
                            }}
                        >
                            <MenuItem value="" disabled >
                                <em>Select Type</em>
                            </MenuItem>
                            {typeList?.map(type => (
                                <MenuItem key={type?._id} value={type?._id}  >
                                    <em>{type?.value}</em>
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        <CustomSelect
                            control={control}
                            error={errors.country}
                            fieldName="country"
                            fieldLabel="Country"
                            background="#FFEFEC"
                            size="16px"
                            value={selectCountry}
                            onChangeValue={(e) => {
                                setSelectCountry(e.target.value)
                                setValue('country', e.target.value)
                                setError('country', '')
                            }}
                        >
                            <MenuItem value="" disabled >
                                <em>Select Country</em>
                            </MenuItem>
                            {['India', 'UAE'].map((res, i) => (
                                <MenuItem key={i} value={res}  >
                                    {res}
                                </MenuItem>

                            ))}

                        </CustomSelect>
                    </Grid>
                    <Grid item lg={6} md={8} xs={12}>
                        <CustomTextArea
                            control={control}
                            error={errors.title}
                            fieldName="title"
                            multiline={true}
                            height={90}
                            row={10}
                            fieldLabel="Title"
                            background={'#FFEFEC'}

                        />
                    </Grid>
                    <Grid item lg={6} md={8} xs={12}>
                        <CustomTextArea
                            control={control}
                            error={errors.taskContent}
                            fieldName="taskContent"
                            multiline={true}
                            height={110}
                            row={10}
                            fieldLabel="Task Content"
                            background={'#FFEFEC'}
                        />

                    </Grid>


                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.marks}
                            fieldName="marks"
                            fieldLabel="Total Marks"
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomCalender
                            fieldName={"expiry"}
                            control={control}
                            fieldLabel="Expiry Date"
                            error={errors?.expiry}
                            minDateres={new Date()}
                        />
                    </Grid>

                    <Grid item lg={3} md={4} xs={12}>

                        <CustomImageUploader
                            fieldLabel='Post Image/Video'
                            accept={'image/*,video/*'}
                            control={control}
                            format={type ? type : 'image'}
                            error={errors.image_video}
                            fieldName='image_video'
                            type={"file"}
                            height="120px"
                            background="#ffff"
                            id="contained-button-file"
                            preview={images}
                            video={video}
                            previewEditimage={previewEdit}
                            onChangeValue={(file, e) => {
                                setimagefile(file)
                                setValue("image_video", file)
                            }}
                        />
                    </Grid>
                    {postThumbnail &&
                        <Grid item lg={3} md={4} xs={12}>
                            <CustomImageUploader
                                fieldLabel='Add Thumbnail'
                                accept={'image/*'}
                                control={control}
                                format={'image'}
                                error={errors.thumbnails}
                                fieldName='thumbnail'
                                type={"file"}
                                height="120px"
                                background="#ffff"
                                id="contained-button-file"
                                preview={thumbnails}
                                vedeoPreview={videoPreviewEdit}
                                previewEditimage={thumbnailsPreview}
                                onChangeValue={(file, e) => {
                                    setThumbnails(file)
                                    setValue("thumbnail", file)
                                    setThumbnailsPreview(null)
                                }}
                            />
                        </Grid>}
                </Grid>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box display={'flex'} justifyContent={'center'} py={10}>
                <CustomIconButton label={loading ? <CircularProgress style={{ color: '#FF0053' }} size={20} /> : state?._id ? 'Update' : 'Add'} Icon={AddIcon} onClick={handleSubmit(onSubmit)} />
            </Box>
            {open && <CustomTextEditor open={open} handleClose={(value) => {
                handleClose()
                console.log({ value })
                if (value) {
                    setValue("postContent", value)
                }

            }} />}
        </Box>
    )
}

export default NewText