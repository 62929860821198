import React from 'react'
import { Controller } from "react-hook-form";
import { Avatar, Box, FormGroup, styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import emailIcon from '../../../Assets/images/email.png'
const CustomLoginInput = ({ control, fieldName, placeholder, error,icon }) => {

    const BOX = styled(Box)({
        paddingBottom: '8px',
        background: '#FADEDA',
        borderRadius: '12px',

    })

    return (
        <>
            <FormGroup>
                <Controller
                    name={fieldName}
                    control={control}
                    render={({ field }) => (
                        <BOX>
                            <TextField
                                {...field}
                                aria-invalid={error ? "true" : "false"}
                                className="form-control"
                                placeholder={placeholder}
                                id="exampleInputEmail1"
                                
                                InputProps={{
                                    style: {
                                        borderRadius: "12px",
                                        opacity: "1",
                                        background: "#ffff",
                                        height: "48px",
                                        fontFamily: "Quicksand",
                                        letterSpacing:"1px",
                                        fontWeight: '600px',
                                        minWidth:280
                                    },
                                    startAdornment: (
                                        <InputAdornment position={'start'}>
                                            <Box>
                                                <Avatar src={icon ? icon : emailIcon} variant="square" style={{ width: "28px", height: "20px", display: "flex" }} />
                                            </Box>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </BOX>

                    )}
                />
                {error && (
                    <p
                        role="alert"
                        style={{
                            color: "red",
                            display: "flex",
                            flexDirection: "start",
                            paddingLeft: "10px",
                            fontSize: "12px",
                        }}
                    >
                        {error?.message}
                    </p>
                )}
            </FormGroup>
        </>
    )
}

export default CustomLoginInput