import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useLocation } from 'react-router-dom'
import CustomBackHeader from '../../../components/Widgets/CustomBackarrowHeader.js'
import CustomTypography from '../../../components/Widgets/CustomTypography/index.js'
const ViewTeacherStudents = () => {
    const { state } = useLocation();

    console.log({state})

    return (
        <Box>
            <CustomBackHeader label={`Student ID :#${state?.user_id}`} />
            <Grid container spacing={3} px={5} mt={2}>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Name'} label={state?.name} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Email Address'} label={state?.email} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Mobile Number'} label={state?.mobile} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'class'} label={state?.class} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Payment Status'} label={state?.payment_status} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default ViewTeacherStudents