import { Box, Button, Grid, MenuItem, Stack } from '@mui/material'
import React, { useEffect, useContext } from 'react'
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js'
import DataTable from '../../components/Widgets/CustomTable/index.js'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomSelect from '../../components/Widgets/CustomSelect/index.js';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import CustomInput from '../../components/Widgets/CustomInput/index.js';
import CustomTextArea from '../../components/Widgets/CustomTextArea/index.js';
import CustomAvatar from '../../components/Widgets/CustomAvatar/index.js';
import CustomApproveRejectButton from '../../components/Widgets/CustomApproveRejectButton/index.js';
import { useLocation, useNavigate } from 'react-router';
import { toast } from "react-toastify";
import { RESET_ERROR } from '../../Redux/constants/userConstant.js';
import UserContext from '../../helpers/user/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { updateReview } from '../../Redux/actions/reviewAction.js';
import { IMAGE_URL } from '../../config/constants.js';
import CustomCardMedia from '../../components/Widgets/CustomCardMedia/index.js';
const EditIndividualReview = () => {
    const location = useLocation();


    console.log(location?.state)
    const navigate = useNavigate();
    const dispatch = useDispatch()



    const context = useContext(UserContext)

    const { error, reviewUpdated } = useSelector(state => state.review);

    const schema = object().shape({

    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({

        resolver: yupResolver(schema)
    });


    useEffect(() => {
        if (location.state.item) {
            setValue('name', location?.state?.item?.creator_details
                .name)
            setValue('class', location?.state?.item?.creator_details
                .class)
            setValue('content', location?.state.item?.content)
        }
    }, [location.state.item])

    useEffect(() => {


        if (reviewUpdated) {
            toast.success(`Updated Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            navigate(-1)
        }


        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, reviewUpdated])


    const SubmitApprove = () => {
        let val = {
            "id": location?.state?.item?._id,
            "status": "Approved",
            "reviewed_by": context?.user?.name
        }
        dispatch(updateReview(val))

    }
    const SubmitReject = () => {
        let val = {
            "id": location?.state?.item?._id,
            "status": "Rejected",
            "reviewed_by": context?.user?.name
        }
        dispatch(updateReview(val))

    }


    return (
        <Box>
            <CustomBackHeader label={location?.state?.mode === "edit" ? 'Edit Post' : "View Post"} />
            <Box py={2} px={5}>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={4} xs={6}>
                        <CustomInput
                            view={true}
                            control={control}
                            error={errors.name}
                            fieldName="name"
                            fieldLabel="Name"
                            disabled={location?.state?.mode === "view" ? true : false}
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={6}>
                        <CustomInput
                            view={true}
                            control={control}
                            error={errors.class}
                            fieldName="class"
                            fieldLabel="Class"
                            disabled={location?.state?.mode === "view" ? true : false}
                        />
                    </Grid>
                    <Grid item lg={6} md={8} xs={12}>
                        <CustomTextArea
                            readOnly={false}
                            control={control}
                            error={errors.content}
                            fieldName="content"
                            multiline={true}
                            height={90}
                            row={10}
                            fieldLabel="Post Content"
                            background={'#FFEFEC'}
                            
                        />
                    </Grid>

                    <Grid item lg={3} md={4} xs={6}>
                        <Box display={'flex'} flexDirection='column' justifyContent={'center'}>
                        {location?.state?.item?.file_type === "video" ? <CustomCardMedia videoProp={`${IMAGE_URL}${location?.state?.item?.file}`}  label={'Post Video'} /> :
                            <CustomAvatar label={'Post Image'} avatar={IMAGE_URL + location?.state?.item?.file} />}
                            {location?.state?.mode === "edit" && <Stack direction={'row'} gap={4} justifyContent={'center'} py={5}
                                width={{ lg: '95%', md: '18vw', sm: '35vw', xs: '80vw' }}>
                                <CustomApproveRejectButton label={'Approve'} shadow={'#DBFADA'} color={'#29C617'} background={'#F3FFF9'} onClick={handleSubmit(SubmitApprove)} />
                                <CustomApproveRejectButton label={'Reject'} shadow={'#FFE8E8'} color={'#FF0000'} background={'#FFE8E8'} onClick={handleSubmit(SubmitReject)} />
                            </Stack>}
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        </Box>
    )
}

export default EditIndividualReview