import { Box, CircularProgress, Grid, MenuItem, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { json, useLocation, useNavigate, useParams } from 'react-router-dom'
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js'
import { toast } from "react-toastify";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomInput from '../../components/Widgets/CustomInput/index.js';
import CustomImageUploader from '../../components/Widgets/CustomImageUploder/index.js';
import CustomSelect from '../../components/Widgets/CustomSelect/index.js';
import CustomMultipleSelectInput from '../../components/Widgets/CustomMultiSelect/index.js';
import CustomButton from '../../components/Widgets/CustomButton/index.js';
import CustomIconOnlyButton from '../../components/Widgets/CustomIconOnlyButton/index.js';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { createSchooolteacher, getClasses, getDivision } from '../../Redux/actions/schoolActions.js';
import CustomIconButton from '../../components/Widgets/CustomIconButton/index.js';
import { RESET_ERROR } from '../../Redux/constants/userConstant.js';
import ClassModal from './ClassModal.js';
const CreateSchoolTeacher = () => {
	const { id } = useParams();
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();




	const { getClassesList, getDivisionList, teacherCreated, error, classCreated } = useSelector(state => state.school);
	const { loading, loader } = useSelector(state => state.auth);


	const [classes, setClasses] = useState(null)
	const [multpleArray, setMultpleArray] = React.useState([]);
	const [postArray, setPostArray] = useState([]);
	const [images, setimages] = useState(null)
	const [previewEdit, setPreviewEdit] = useState(null);
	const [openM, setOpenM] = useState(false)

	const handleOpenM = () => setOpenM(true);
	const handleCloseM = () => setOpenM(false);


	console.log({postArray})
	console.log({multpleArray})

	//mobile Validation
	const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

	const schema = object().shape({
		name: yup.string().max(30,"Maximum character exceed").required('Name is Required'),
		email: yup.string().max(30,"Maximum character exceed").email().required('Email is Required'),
		phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Mobile Number is Required'),
		division: yup.array().required('Division is Required'),
		class: yup.string().max(10,"Maximum character exceed").required('Class is Required'),
	});



	const {
		register,
		handleSubmit,
		setFieldValue,
		control,
		formState: { errors },
		reset,
		setValue,
		setError
	} = useForm({
		resolver: yupResolver(schema)
	});


	const setimagefile = (e) => {
		if (e.size > 500000) {
			toast.warning("Image Size Should be less than 500KB");
			setPreviewEdit(null)
			setimages(null)
		} else {
			setPreviewEdit(null)
			setimages(e)
		}
	}


	//MULTI SELECT FEILD.................................................................
	const onChangeMultiple = (event) => {
		// const {
		// 	target: { value },
		// } = event;
		const values = event.target.value;
		setValue('division', values)
		setError('division', '')
		setMultpleArray(
			values
		);
		let filter = getDivisionList?.filter((res) => res !== values).map((res) => (
			res?._id
		));



		setPostArray(filter)
	}

	//ONCHANGE Select ................................................................
	const onChangeSelect = (value) => {

		setClasses(value)
		setValue('class', value)
		setError('class', '')
		let val = {
			school_id: location?.state?.school_id,
			class: value
		}
		dispatch(getDivision(val))
		setPostArray([])
	}

	//Submit 

	const SubmitForm = (data) => {
		const formData = new FormData();
		formData.append('school_id', location?.state?.school_id);
		formData.append('name', data?.name);
		formData.append('email', data?.email);
		formData.append('phone', data?.phone);
		formData.append('division', JSON.stringify(multpleArray));
		if (images) {
			formData.append('image', images);
		}
		dispatch(createSchooolteacher(formData))		
	}




	useEffect(() => {
		if (teacherCreated) {
			toast.success(`Teacher Created Successfully`);
			dispatch({
				type: RESET_ERROR
			})
			navigate(-1)
		}

		if (classCreated) {
			toast.success(`Class Created Successfully`);
			dispatch({
				type: RESET_ERROR
			})
			handleCloseM()
		}

		if (error) {
			toast.error(`${error.message}`);
			dispatch({
				type: RESET_ERROR
			})
		}

	}, [error, teacherCreated, classCreated])

	useEffect(() => {
		if (location) {
			dispatch(getClasses(location?.state?.school_id));
		}
	}, [location, openM === false])





	return (
		<Box>
			<CustomBackHeader label={'Add Teacher'} />
			<Box px={5} mt={5}>
				<Grid container spacing={2}>
					<Grid item lg={3} md={4} sm={6} xs={12}>
						<CustomInput
							control={control}
							error={errors.name}
							fieldName="name"
							fieldLabel="Name"
						/>
					</Grid>
					<Grid item lg={3} md={4} sm={6} xs={12}>
						<CustomInput
							control={control}
							error={errors.email}
							fieldName="email"
							fieldLabel="Email Address"
						/>
					</Grid>
					<Grid item lg={3} md={4} sm={6} xs={12}>
						<CustomInput
							control={control}
							error={errors.phone}
							fieldName="phone"
							fieldLabel="Mobile Number"
						/>
					</Grid>

					<Grid item lg={3} md={4} sm={6} xs={12}>
						<CustomSelect
							control={control}
							error={errors.class}
							fieldName="class"
							fieldLabel="Class"
							background="#FFEFEC"
							size="16px"
							value={classes}
							onChangeValue={(e) =>
								onChangeSelect(e.target.value)

							}
						>
							<MenuItem value="" disabled >
								<>Select Class</>
							</MenuItem>
							{getClassesList?.map((C, I) => (
								<MenuItem key={I} value={C?.name} >
									{C?.name}
								</MenuItem>
							))}

						</CustomSelect>
					</Grid>
					<Grid item lg={3} md={4} sm={6} xs={12}>
						<CustomMultipleSelectInput
							// readOnly={!id ? false : true}
							control={control}
							error={errors.division}
							fieldName="division"
							fieldLabel="Division"
							background="#FFEFEC"
							multiline
							height="48px"
							size="15px"
							multiple={true}
							defaultValue={""}
							value={multpleArray}
							onChangeValue={onChangeMultiple}
						>
							<MenuItem value="" disabled >
								<em>Select  Division</em>
							</MenuItem>
							{getDivisionList?.map((C, I) => (
								<MenuItem key={C?._id} value={C?._id} >
									{C?.division}
								</MenuItem>
							))}
						</CustomMultipleSelectInput>
					</Grid>

					<Grid item lg={3} md={4} sm={6} xs={12}>
						<CustomImageUploader
							accept={'image/*'}
							format={'image'}
							fieldLabel='Profile Image'
							control={control}
							error={errors.image}
							fieldName='image'
							type={"file"}
							height={300}
							width={300}
							background="#ffff"
							id="contained-button-file"
							preview={images}
							previewEditimage={previewEdit}
							onChangeValue={(file, e) => {
								setimagefile(file)
								setValue("image", file)
							}}
						/>
					</Grid>
					<Grid item lg={3} md={4} sm={6} xs={12}>
						<Box height={20}></Box>
						<CustomIconOnlyButton Icon={AddIcon} onClick={() => handleOpenM()} />
					</Grid>
				</Grid>
			</Box>

			<Box display={'flex'} justifyContent={'center'} py={10}>
				<CustomIconButton
					onClick={handleSubmit(SubmitForm)}
					label={loading ? <CircularProgress style={{ color: '#FF0053' }} size={20} /> : 'Add'}
					Icon={AddIcon}
					disabled={loading ? true : false}
				/>
			</Box>
			{openM && <ClassModal
				openM={openM}
				handleCloseM={handleCloseM}
				schoolid={location?.state?.school_id} />}

		</Box>
	)
}

export default CreateSchoolTeacher