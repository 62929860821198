import { Stack, Typography } from '@mui/material'
import React from 'react'

const CustomTypography = ({ label, formlabel, lg, md, sm, xs,h }) => {
    return (
        <>
            <Stack gap={.5}>
                <Typography
                    sx={{
                        px: .5,
                        fontSize: {
                            lg: 14,
                            md: 13,
                            xs: 12,
                        },
                        fontFamily: 'Quicksand',
                        fontWeight: "bold",
                    }}>
                    {`${formlabel}`}
                    <span style={{
                        color:'#FF0000'
                    }}>*</span>
                </Typography>
                <Typography 
                sx={{
                    py:1,
                    minHeight: { xs: h ? h : 40  },
                    width: { lg: lg ? lg : '100%', md: md ? md : 180, sm: sm ? sm : '100%', xs: xs ? xs : '100%' },
                    bgcolor: '#F3F3F3',
                    display: 'flex',
                    alignItems: 'center',
                    px: 1,
                    borderRadius: 3,
                    fontSize: {
                        lg: 14,
                        md: 13,
                        xs: 12,
                    },
                    fontFamily: 'Quicksand',
                    fontWeight: "bold",
                }}>
                    {label}
                </Typography>
            </Stack>

        </>
    )
}

export default CustomTypography