import React, { useState, useEffect, useCallback } from 'react'
import { Avatar, Box, Stack, styled, Button, Typography, Grid, Backdrop } from '@mui/material';
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js';
import CustomInput from '../../components/Widgets/CustomInput/index.js';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomSelect from '../../components/Widgets/CustomSelect/index.js';
import MenuItem from '@mui/material/MenuItem';
import BackupIcon from '@mui/icons-material/Backup';
import CustomIconButton from '../../components/Widgets/CustomIconButton/index.js';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomImageUploader from '../../components/Widgets/CustomImageUploder/index.js';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { RESET_ERROR } from '../../Redux/constants/userConstant.js';
import { toast } from "react-toastify";
import { userTypeArray } from '../../components/Widgets/UserTypes/index.js';
import { CreateUser, getRole, getUserSingleList, updateUser } from '../../Redux/actions/userActions.js';
import CustomSpinner from '../../components/Widgets/CustomSpinner/index.js';
const AddUserManagement = () => {
  const { id } = useParams();



  const { loading, loader } = useSelector(state => state.auth);
  const { userCreated, error, getRoleList, userSingleList, userUpdated, } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [images, setimages] = useState(null)
  const [previewEdit, setPreviewEdit] = useState(null);
  const [userType, setUserType] = useState(null);





  //mobile Validation
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const schema = object().shape({
    name: yup.string().max(30, "Name must be less than 30 characters").matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      'Name can only contain Latin letters.'
    )
      // .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, 'Please enter your full name.')
      .required('Name is Required'),
    email: yup.string().max(30, "Email must be less than 30 characters").email("Please enter valid email").required('Email is Required').typeError("Valid Email Required"),
    phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10, "Phone No must be greater than 8 characters").max(13, "Phone No must be less than 13 characters").required('Mobile Number is Required'),
    role: yup.string().required('User Type is Required')
  });



  const {
    register,
    handleSubmit,
    setFieldValue,
    control,
    formState: { errors },
    reset,
    setValue,
    setError
  } = useForm({

    resolver: yupResolver(schema)
  });

  const setimagefile = (e) => {
    if (e.size > 500000) {
      toast.warning("Image Size Should be less than 500KB");
      setPreviewEdit(null)
      setimages(null)
    } else {
      setPreviewEdit(null)
      setimages(e)
    }
  }

  const SubmitForm = useCallback((data) => {
    if (id && userSingleList) {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('name', data?.name);
      formData.append('email', data?.email);
      formData.append('phone', data?.phone);
      formData.append('role', data?.role);
      if (images) {
        formData.append('image', images);
      }
      dispatch(updateUser(formData))
    } else {
      const formData = new FormData();
      formData.append('name', data?.name);
      formData.append('email', data?.email);
      formData.append('phone', data?.phone);
      formData.append('role', data?.role);
      if (images) {
        formData.append('image', images);
      }
      dispatch(CreateUser(formData))

    }
  }, [id,images])


  useEffect(() => {
    if (userCreated) {
      toast.success(`User Created Successfully`);
      dispatch({
        type: RESET_ERROR
      })
      navigate(-1)
    }
    if (userUpdated) {
      toast.success(`User Updated Successfully`);
      dispatch({
        type: RESET_ERROR
      })
      navigate(-1)
    }

    if (error) {
      toast.error(`${error.message}`);
      dispatch({
        type: RESET_ERROR
      })
    }

  }, [error, userCreated, userUpdated])


  useEffect(() => {
    if (userSingleList && id) {
      setValue('name', userSingleList?.name)
      setValue('email', userSingleList?.email)
      setValue('phone', userSingleList?.mobile)
      setValue('role', userSingleList?.role)
      setUserType(userSingleList?.role)
      setPreviewEdit(userSingleList?.image)
    }
  }, [userSingleList])



  useEffect(() => {
    if (id) {
      dispatch(getUserSingleList(id))
    }
  }, [id])



  useEffect(() => {
    dispatch(getRole())
  }, [])




  return (
    <Box>
      <CustomBackHeader label={!id ? 'Add User' : 'Edit User'} />
      <Box px={5} mt={5}>
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <CustomInput
              control={control}
              error={errors.name}
              fieldName="name"
              fieldLabel="Name"
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <CustomInput

              control={control}
              error={errors.email}
              fieldName="email"
              fieldLabel="Email Address"
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <CustomInput
              control={control}
              error={errors.phone}
              fieldName="phone"
              fieldLabel="Mobile Number"
            />
          </Grid>

          <Grid item lg={3} md={4} sm={6} xs={12}>
            <CustomSelect
              control={control}
              error={errors.role}
              fieldName="role"
              fieldLabel="User Type"
              background="#FFEFEC"
              size="16px"
              value={userType}
              onChangeValue={(e) => {
                setUserType(e.target.value)
                setValue('role', e.target.value)
                setError('role', '')
              }}
            >
              <MenuItem value="" disabled >
                <em>Select User Type</em>
              </MenuItem>
              {getRoleList?.filter((res) =>
                res?.slug !== "teacher"
                && res?.slug !== 'student').map((res) => (
                  <MenuItem key={res?._id} value={res?.slug}  >
                    {res?.name.charAt(0).toUpperCase() + res?.name.slice(1)}
                  </MenuItem>
                ))}


            </CustomSelect>
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <CustomImageUploader
              // fieldLabel='Upload Image'
              accept={'image/*'}
              control={control}
              format={'image'}
              error={errors.image}
              fieldName='image'
              type={"file"}
              height={300}
              background="#ffff"
              id="contained-button-file"
              preview={images}
              width={300}
              previewEditimage={previewEdit}
              onChangeValue={(file, e) => {
                setimages(null)
                setimagefile(file)
                setValue("image", file)
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box display={'flex'} justifyContent={'center'} py={10}>
        <CustomIconButton
          label={loading ? <CircularProgress style={{ color: '#FF0053' }} size={20} /> : !id ? 'Add' : 'Update'}
          Icon={AddIcon}
          onClick={handleSubmit(SubmitForm)}
          disabled={loading ? true : false} />
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      //onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}

export default AddUserManagement