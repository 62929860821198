import { RESET_ERROR } from "../constants/userConstant";
import { CREATE_VOCABULARY_FAIL, CREATE_VOCABULARY_SUCCESS, DELETE_VOCABULARY_FAIL, DELETE_VOCABULARY_SUCCESS, GET_GRADE_FAIL, GET_GRADE_SUCCESS, GET_VOCABULARY_LIST_FAIL, GET_VOCABULARY_LIST_SUCCESS, SINGLE_VOCABULARY_FAIL, SINGLE_VOCABULARY_SUCCESS, STATUS_VOCABULARY_FAIL, STATUS_VOCABULARY_SUCCESS, UPDATE_VOCABULARY_FAIL, UPDATE_VOCABULARY_SUCCESS } from "../constants/vocabularyConstants";
export const vocabuloryReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                vocabularyCreated: null,
                vocabularyDeleted: null,
                vocabularyStatusChanged: null,
                vocabularyUpdated:null
            }
        case CREATE_VOCABULARY_SUCCESS:
            return {
                ...state,
                vocabularyCreated: true
            }
        case CREATE_VOCABULARY_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_GRADE_SUCCESS:
            return {
                ...state,
                gradeList: action.payload
            }
        case GET_GRADE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case GET_VOCABULARY_LIST_SUCCESS:
            return {
                ...state,
                vocabularyList: action.payload
            }
        case GET_VOCABULARY_LIST_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case DELETE_VOCABULARY_SUCCESS:
            return {
                ...state,
                vocabularyDeleted: true,
                vocabularyList: state?.vocabularyList.filter(voc => voc._id !== action.payload)
            }
        case DELETE_VOCABULARY_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case STATUS_VOCABULARY_SUCCESS:
            return {
                ...state,
                vocabularyStatusChanged: true,
                vocabularyList: state.vocabularyList?.map(voc => voc._id === action.payload._id ? action.payload : voc),
            }
        case STATUS_VOCABULARY_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case UPDATE_VOCABULARY_SUCCESS:
            return {
                ...state,
                vocabularyUpdated: true,
                vocabularyList: state.vocabularyList?.map(voc => voc._id === action.payload._id ? action.payload : voc),

            }
        case UPDATE_VOCABULARY_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case SINGLE_VOCABULARY_SUCCESS:
            return {
                ...state,
                singleVocabularyList: action.payload
            }
        case SINGLE_VOCABULARY_FAIL:
            return {
                ...state,
                error: action.payload
            }

        default:
            return state;
    }

}