import React, { memo, useContext, useMemo } from 'react'
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Avatar, Box, Typography, Button, styled } from '@mui/material'
import { Stack } from '@mui/system'
import { useNavigate } from 'react-router-dom';
import backbutton from '../../../Assets/images/back.png';
import UserContext from '../../../helpers/user';
import { IMAGE_URL } from '../../../config/constants';
const CustomBackHeader = memo(({label,hidden, back}) => {
   const context =  useContext(UserContext)


 
   console.log({context:context?.user},'IMAGE KITTI')

    const navigate = useNavigate()
    const BOX = styled(Box)(({ theme }) => ({
        width:'100%',
    }))


    const FULLBOX = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    }))

    const HALFBOX = styled(Box)(({ theme }) => ({
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        }
    }))


    return (

        <BOX sx={{ height: 100, display: 'flex', alignItems: 'center' , py:1.5  }}  >
            <Box px={3} sx={{ display: 'flex', justifyContent: 'space-between' }} width={'100%'}>
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                    {!hidden &&
                    <Button sx={{ borderRadius: 3, cursor: 'pointer' }} onClick={ back ? back : () => navigate(-1)}>
                        <Avatar src={backbutton}
                            sx={{
                                width: 30,
                                height: 30,
                                p: 1.5,
                                bgcolor: '#FFF5F3',
                                borderRadius: 3,
                                boxShadow: '0px 6px #FADEDA'
                            }}  ></Avatar>
                    </Button>}
                    <Typography variant='caption' fontFamily={'Quicksand'}
                        sx={{
                            fontSize: {
                                lg: 30,
                                md: 25,
                                sm: 18,
                                xs: 16,
                            },

                        }} letterSpacing={1} color={'#FF0053'} fontWeight={'700'}>
                        {label}
                    </Typography>
                </Stack>
                <FULLBOX display={'flex'} p={1} alignItems={'center'} gap={2} bgcolor={'#FFF6ED'} borderRadius={5}>
                    <Stack gap={1}>
                        <Typography variant='h1'
                            fontFamily={'Quicksand'}
                            fontSize={14}
                            fontWeight={'bold'}
                            letterSpacing={1}
                            color={'#FF0053'}>{context?.user?.name}</Typography>
                        <Typography pl={1} fontFamily={'Quicksand'}
                            letterSpacing={1} color={'#808080'} fontWeight={'bold'}
                            fontSize={12} variant='caption'>{context?.user?.role}</Typography>
                    </Stack>
                    <Avatar alt="profile pic" src={`${IMAGE_URL}${context?.user?.image}`}  ></Avatar>
                    <Box><NotificationsIcon style={{ color: '#FF0053' }} /></Box>
                </FULLBOX>
                <HALFBOX display={'flex'} p={1} alignItems={'center'} bgcolor={'#FFF6ED'} borderRadius={5} >
                    <Stack direction={'row'} alignItems={'center'} gap={1}>
                        <Avatar  alt="profile pic" src={`${IMAGE_URL}${context?.user?.image}`}  />
                        <NotificationsIcon style={{ color: '#FF0053' }} />
                    </Stack>
                </HALFBOX>
            </Box>

        </BOX>
    )
})

export default CustomBackHeader