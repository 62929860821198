import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js'
import CustomTypography from '../../components/Widgets/CustomTypography'
import student from '../../Assets/images/Student.jpg'
import CustomAvatar from '../../components/Widgets/CustomAvatar/index.js'
import ToggleOnRoundedIcon from '@mui/icons-material/ToggleOnRounded';
import ToggleOffRoundedIcon from '@mui/icons-material/ToggleOffRounded';
import { useLocation } from 'react-router-dom'
const ViewSchoolManagement = ({ label }) => {
    const location = useLocation();
 
    return (    
        <Box>
            <CustomBackHeader label={location?.state?.item?.name} />
            <Grid container spacing={3} px={5} mt={2}>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography sm={'100%'} xs={'100%'} formlabel={'School Name'} label={location?.state?.item?.name} />
                </Grid>
                <Grid item lg={6} md={8} xs={12}>
                    <CustomTypography lg={'100%'} md={'100%'} sm={'100%'} xs={'100%'} formlabel={'Address'} label={location?.state?.item?.address} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography sm={'100%'} xs={'100%'} formlabel={'Country'} label={location?.state?.item?.country} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography sm={'100%'} xs={'100%'} formlabel={'Email Address'} label={location?.state?.item?.email} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography sm={'100%'} xs={'100%'} formlabel={'Mobile Number'} label={location?.state?.item?.phone} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography sm={'100%'} xs={'100%'} formlabel={'Fees'} label={location?.state?.item?.fees} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <Typography sx={{
                        px: .5,
                        fontSize: {
                            lg: 14,
                            md: 13,
                            xs: 12,
                        },
                        fontFamily: 'Quicksand',
                        fontWeight: "bold",
                    }}>School Network*</Typography>
                    {location?.state?.item?.network === true ?
                        <ToggleOnRoundedIcon 
                        style={{
                            color: '#42E927',
                            fontSize: '50px'
                        }} /> : <ToggleOffRoundedIcon
                        style={{
                            color: '#FF0000',
                            fontSize: '50px'
                        }}
                        />}
                </Grid>
            </Grid>
        </Box>
    )
}

export default ViewSchoolManagement