import { Box } from '@mui/material'
import React, { useState, useContext } from 'react'
import OtpInput from 'react-otp-input';
import './style.css'
const CustomOtp = ({ setOtp }) => {

    const [otpVal, setOtpVal] = useState(null)

  
    return (
        <Box>
            <OtpInput
                isInputNum={true}
                value={otpVal}
                onChange={(value) => {
                    setOtpVal(value)
                    
                    // if (value?.length === 4) {
                    //     setOtp(value)
                    // }
                    localStorage.setItem('otp',value)
                    //setOtp(value)
                }}
                inputStyle={'inputStyle'}
                numInputs={4}
                separator={<span></span>}
            />
        </Box>
    )
}

export default CustomOtp