import { Box, Button, IconButton } from '@mui/material'
import React from 'react'

const CustomIconOnlyButton = ({ onClick, Icon }) => {
    return (
        <Box 
        onClick={onClick}
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 12,
            backgroundColor: "#FFFAFA",
            bgcolor: '#FF0053',
            height: '50px',
            width: '50px',
            boxShadow: '0px 6px #FADEDA'
        }}
        >
            {/* <Button onClick={onClick} startIcon={<Icon fontWeight={'bold'}  />}
               style={{
                 display:'flex',
                 justifyContent:'center',
                fontSize: "18px",
                color: '#FF0053',
                fontWeight: 'bold',
                height:'50px',
                width: '50px',
               
              }}
            >

            </Button> */}
            <IconButton  style={{
                color: '#FF0053',
            }}>

                <Icon />
            </IconButton>
        </Box>
    )
}

export default CustomIconOnlyButton