import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'

const CustomWinnerIcon = ({img,value}) => {
  return (
    <Box
    borderRadius={3}
    style={{
        padding:3,
        width:200,
        heigth:'50px',
        background:'#FFEFEC',
        display:'flex',
      
        alignItems:'center'
    }}
    
    >
        <Avatar  src={img}></Avatar>
        <Box px={2}>
            <Typography>{value}</Typography>
        </Box>
         
    </Box>
  )
}

export default CustomWinnerIcon