import { Box } from '@mui/material'
import React from 'react'
import './style.css'
const CustomSpinner = () => {
  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
         <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </Box>
   
  )
}

export default CustomSpinner