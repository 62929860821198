import React from 'react'
import { Box, Typography, DialogTitle, Dialog, DialogContent, Button, Slide } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CustomButton from '../CustomButton'
import { useDispatch } from 'react-redux';
import { deleteUser } from '../../../Redux/actions/userActions';
import { deleteSchool, deleteSchoolClass } from '../../../Redux/actions/schoolActions';
import { deleteAds } from '../../../Redux/actions/adsActions';
import { deleteVocabulary } from '../../../Redux/actions/vocabularyActions';
import { deleteCategory } from '../../../Redux/actions/categoryActions';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDelete = ({ open, handleClose, id, mode }) => {


    const dispatch = useDispatch()

    const SubmitDelete = () => {
        switch (mode) {
            case 'User':
                dispatch(deleteUser(id));
                break;
            case 'School':
                dispatch(deleteSchool(id));
                break;
            case 'Ads':
                dispatch(deleteAds(id));
                break;
            case 'Vocabulary':
                dispatch(deleteVocabulary(id));
                break;
            case 'Category':
                dispatch(deleteCategory(id));
                break;
            case 'Class':
                dispatch(deleteSchoolClass(id));
                break;

        }
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleClose}>
            <DialogTitle>
                <Box display={'flex'} justifyContent={'space-between'} >
                    <Typography sx={{
                        fontFamily: 'Quicksand',
                        fontWeight: 'bold',
                        fontSize: 22,
                        color: '#FF0053',
                        letterSpacing: 1
                    }}></Typography>
                    <Typography sx={{
                        fontFamily: 'Quicksand',
                        fontWeight: 'bold',
                        fontSize: 22,
                        color: '#FF0053',
                        letterSpacing: 1
                    }}></Typography>
                    <HighlightOffIcon style={{ fontSize: '30px', cursor: 'pointer' }} onClick={() => handleClose()} />
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexDirection={'column'}>
                    <ErrorOutlineIcon style={{
                        fontSize: '50px',
                        color: "#FD085A"
                    }} />
                    <Typography
                        fontFamily={'Quicksand'}
                        px={2}
                        textAlign={'center'}
                        fontSize={'18px'}
                        color={"#FD085A"}
                        py={1}
                        letterSpacing={1}
                        fontWeight={'bold'}>Delete {mode}</Typography>
                    <Typography
                        fontFamily={'Quicksand'}
                        px={2}
                        textAlign={'center'}
                        fontSize={'18px'}>Are you sure you want to delete ?</Typography>
                </Box>
                <Box display={'flex'}
                    justifyContent={'center'}
                    mt={3}>
                    <CustomButton label={'Confirm'} onClick={SubmitDelete} />
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default CustomDelete