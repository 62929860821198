import { Backdrop, Box, CircularProgress, Paper, Stack, styled } from '@mui/material';
import React, { useState, useEffect, useTransition } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DataTable from '../../components/Widgets/CustomTable/index.js';
import { useSelector } from 'react-redux';
import QuizeCompatitionView from './QuizeCompatitionView.js';
import CustomWinnerIcon from './QuizeComponents/CustomWinnerIcon.js';
import CustomApproveRejectButton from '../../components/Widgets/CustomApproveRejectButton/index.js';
import CustomSearch from '../../components/Widgets/CustomSearch/index.js';
import silver from '../../Assets/images/medalsilver.png';
import gold from '../../Assets/images/medalgold.png';
import bromanze from '../../Assets/images/medalbronze.png';
import moment from 'moment/moment.js';
import { toast } from 'react-toastify';
import customAxios from '../../CustomAxios.js';
const QuizeCompatitons = () => {

    const { id } = useParams();


    const [loading, setLoading] = useState(false)
    const [rowRes, setRowres] = useState([]);
    const [open, setOpen] = useState(false)
    const [setSerachList, setSearchList] = useState([])
    const [pending, startTransition] = useTransition();
    const[quizCount,setQuizCount]=useState(null)


    console.log({quizCount})


    //style...........................
    const BUTTONBOX = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {

        }
    }))

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);






    const columns = [
        {
            field: 'quiz_attendee_id',
            headerName: 'Post ID',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>(`#${params?.row?.quiz_attendee_id}`),
        },
        {
            field: 'created_at',
            headerName: 'Submitted Date',
            width: 150,
            valueGetter: (params) => (moment(params.row.created_at).format('DD/MM/YYYY')),

            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'Submitted By', 
            headerName: 'Submitted By',
            width: 130,
            valueGetter: (params) =>(params.row.student_details?.name),
            headerAlign: 'center',
            align: 'center',
        },
        {
         
            field: 'Class & Division', 
            headerName: 'Class & Division',
            width: 130,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>(`${params.row.student_details?.class}`),
        },
        {
            field: 'test_type',
            headerName: 'School',
            width: 160,
            editable: true,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'expiry_date',
            headerName: 'Expire Date',
            width: 160,
            editable: true,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (moment(params.row.expiry).format('DD/MM/YYYY')),

        },
        {
            field: 'mark_obtained',
            headerName: 'Marks',
            sortable: false,
            width: 80,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            width: 150,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                        <span style={{ color: row?.status === 'Ongoing' ?'#C2620E' : row?.status === 'active' ? "#2E75C7" : 
                        row?.status === 'Completed' ? '#21AA12' :'', cursor: 'pointer' }}>{row?.status}</span>
                     )
        },
        {
            field: 'Action',
            headerName: 'Action',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={1} direction={'row'}>
                    <RemoveRedEyeIcon
                        onClick={() => handleOpen()}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }}
                    />
                </Stack>
            )
        },
    ];


    useEffect(() => {
        getCompetionList(id);
        getQuizCount(id);
    }, [id])



    const getCompetionList = async (id) => {
        setLoading(true)
        await customAxios.get(`admin/quiz/attendees/${id}`)
            .then(async response => {
                setRowres(response.data.data)
                setSearchList(response.data.data)
                setLoading(false)

            }).catch(async error => {
                setRowres([])
                setLoading(false)
                toast.error(error)
            })
    }


    const getQuizCount = async (id) => {
        setLoading(true)
        await customAxios.get(`admin/quiz/count/${id}`)
            .then(async response => {
                setQuizCount(response.data.data)
                setLoading(false)

            }).catch(async error => {
                setQuizCount(null)
                setLoading(false)
                toast.error(error)
            })
    }






  

    const searchQuiz = (value) => {
        let competitiions = setSerachList?.filter(qu =>
            `#${qu?.test_attendee_id}`.toString().toLowerCase().includes(value.toLowerCase())
        )
        startTransition(() => {
            setRowres(competitiions)
        })
    }

    return (
        <Box>
            <CustomBackHeader label={`Post ID : `} />
            <Box px={5} display={'flex'} justifyContent={'space-between'} gap={2} >
                <BUTTONBOX
                    display={'flex'} sx={{
                        gap: { xl: 1, md: 1, xs: 1 },
                        flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' }
                    }} >
                    <CustomWinnerIcon img={gold} value={quizCount?.goldCount} />
                    <CustomWinnerIcon img={silver} value={quizCount?.silverCount} />
                    <CustomWinnerIcon img={bromanze} value={quizCount?.bronzeCount} />
                </BUTTONBOX>
                <Box display={'flex'} sx={{
                    gap: { xl: 2, md: 4, xs: 4 },
                    flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' }
                }}  >
                    {/* <CustomApproveRejectButton label={'Confirm'} shadow={'#DBFADA'} color={'#29C617'} background={'#F3FFF9'} /> */}
                    <CustomSearch placeholder={'Search'} lg={'100%'} md={'100%'} sm={'100%'} xs={'100%'}  onSearch={(value) => searchQuiz(value)}/>
                </Box>
            </Box>
            <Box px={5} py={2}>

                <Paper style={{
                    borderRadius: "20px"
                }}>
                    <DataTable
                        rows={rowRes}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {open && <QuizeCompatitionView open={open} handleClose={handleClose} res={rowRes}  />}
        </Box>
    )
}

export default QuizeCompatitons