import NotificationsIcon from '@mui/icons-material/Notifications';
import { Avatar, Box, Typography, styled } from '@mui/material'
import { Stack } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState } from 'react'
import { useContext } from 'react';
import UserContext from '../../helpers/user';
import { IMAGE_URL } from '../../config/constants';
import ModalDrawer from '../../components/Widgets/CustomDrawer';


const Header = ({ label }) => {

    const context = useContext(UserContext)



    const [open, setopen] = useState(false);

    const BOX = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {
            width: '100%',
        }
    }))

    const HAMBURGER = styled(Box)(({ theme }) => ({
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            alignItems: 'center'
        }
    }))

    const FULLBOX = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    }))

    const HALFBOX = styled(Box)(({ theme }) => ({
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        }
    }))

    return (
        <BOX sx={{ height: 100, display: 'flex', alignItems: 'center' }}  >
            <Box px={3} sx={{ display: 'flex', justifyContent: 'space-between' }} width={'100%'}>
                <Stack direction={'row'} alignItems={'center'} gap={2}>
                    <HAMBURGER onClick={() => setopen(true)}>
                        <MenuIcon style={{ color: '#FF0053' }} />
                    </HAMBURGER>
                    <Typography variant='caption' fontFamily={'Quicksand'} sx={{
                        fontSize: {
                            lg: 35,
                            md: 30,
                            sm: 25,
                            xs: 18,
                        },

                    }} letterSpacing={1} color={'#FF0053'} fontWeight={'bold'}>
                        {label}
                    </Typography>
                </Stack>

                <FULLBOX display={'flex'} p={1} alignItems={'center'} gap={2} bgcolor={'#FFF6ED'} borderRadius={5}>
                    <Stack gap={1}>
                        <Typography variant='h1'
                            fontFamily={'Quicksand'}
                            fontSize={14}
                            fontWeight={'bold'}
                            letterSpacing={1}
                            color={'#FF0053'}>{context?.user?.name}</Typography>
                        <Typography pl={1} fontFamily={'Quicksand'}
                            letterSpacing={1} color={'#808080'} fontWeight={'bold'}
                            fontSize={12} variant='caption'>{context?.user?.role}</Typography>
                    </Stack>
                    <Avatar src={`${IMAGE_URL}${context?.user?.image}`}></Avatar>
                    <Box><NotificationsIcon style={{ color: '#FF0053' }} /></Box>
                </FULLBOX>
                <HALFBOX display={'flex'} p={1} alignItems={'center'} bgcolor={'#FFF6ED'} borderRadius={5} >
                    <Stack direction={'row'} alignItems={'center'} gap={1}>
                        <Avatar src={`${IMAGE_URL}${context?.user?.image}`}></Avatar>
                        <Box><NotificationsIcon style={{ color: '#FF0053' }} /></Box>
                    </Stack>
                </HALFBOX>
            </Box>

            {open && <ModalDrawer open={open} close={()=>setopen(false)} />}

        </BOX>
    )
}

export default Header