import axios from "../../CustomAxios";
import { LOADER, LOADING } from "../constants/userConstant";
import { CREATE_VOCABULARY_FAIL, CREATE_VOCABULARY_SUCCESS, DELETE_VOCABULARY_FAIL, DELETE_VOCABULARY_SUCCESS, GET_GRADE_FAIL, GET_GRADE_SUCCESS, GET_VOCABULARY_LIST_FAIL, GET_VOCABULARY_LIST_SUCCESS, SINGLE_VOCABULARY_FAIL, SINGLE_VOCABULARY_SUCCESS, STATUS_VOCABULARY_FAIL, STATUS_VOCABULARY_SUCCESS, UPDATE_VOCABULARY_FAIL, UPDATE_VOCABULARY_SUCCESS } from "../constants/vocabularyConstants";




export const getGrade = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/grades`)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: GET_GRADE_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_GRADE_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const createVocabulary = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/vocabulory/create`, data)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: CREATE_VOCABULARY_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: CREATE_VOCABULARY_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const getVocabulary = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`admin/vocabulory/list`)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: GET_VOCABULARY_LIST_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: GET_VOCABULARY_LIST_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const deleteVocabulary = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.delete(`admin/vocabulory/delete/${id}`)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: DELETE_VOCABULARY_SUCCESS,
                payload: id
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: DELETE_VOCABULARY_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const statusVocabulary = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/vocabulory/statusupdate`,data)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: STATUS_VOCABULARY_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: STATUS_VOCABULARY_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const updateVocabulary = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/vocabulory/edit`,data)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: UPDATE_VOCABULARY_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })

        }).catch(async error => {
            dispatch({
                type: UPDATE_VOCABULARY_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })

        })
}

export const singleVocabulary = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    dispatch({
        type: LOADER,
        payload: true
    })


    await axios.get(`admin/vocabulory/show/${id}`)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: SINGLE_VOCABULARY_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
            dispatch({
                type: LOADER,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: SINGLE_VOCABULARY_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
            dispatch({
                type: LOADER,
                payload: false
            })

        })
}