import { Box, Avatar, Grid, Typography, styled, CircularProgress, Backdrop } from '@mui/material'
import React, { useContext, useState, useEffect } from 'react'
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomInput from '../../components/Widgets/CustomInput';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import CustomIconButton from '../../components/Widgets/CustomIconButton/index.js';
import RefreshIcon from '@mui/icons-material/Refresh';
import UserContext from '../../helpers/user/index.js';
import CustomSpinner from '../../components/Widgets/CustomSpinner/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { getProfile, updateProfile } from '../../Redux/actions/profileActions.js';
import { RESET_ERROR } from '../../Redux/constants/userConstant.js';
import { memo } from 'react';
const Profile = memo(() => {
    const user = useContext(UserContext);
    const dispatch = useDispatch()


    const [Image, setImage] = useState(null)

    const { loading, loader } = useSelector(state => state.auth);

    const { profileList, profileUpdate, error } = useSelector(state => state.profile);



            


    //mobile Validation
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const schema = object().shape({
        name: yup.string().required('Name is required'),
        email: yup.string().email().required('Email is required'),
        address: yup.string().required('Adress is Required'),
        phone: yup.string().matches(phoneRegExp, 'Phone number is Required').required('Mobile Number is Required'),
    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({

        resolver: yupResolver(schema)
    });

    //style....................................................................

    const BOX = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    }))

    const IMGBOX = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '10px'
        }
    }))

    const SubmitForm = (data) => {
        let formData = new FormData();
        formData.append("name", data?.name)
        formData.append("email", data?.email)
        formData.append('id', user?.user?._id);
        formData.append('phone', data?.phone);
        formData.append('address', data?.address);
        if (Image) {
            formData.append('image', Image);
        }
        dispatch(updateProfile(formData));
    }
    useEffect(() => {
        if (user?.user) {
            dispatch(getProfile(user?.user?._id))
        }
    }, [user?.user])

    useEffect(() => {
        if (profileList) {
            setValue('name', profileList?.name)
            setValue('email', profileList?.email)
            setValue('phone', profileList?.phone)
            setValue('address', profileList?.address)
        }
    }, [profileList])


    useEffect(() => {
        if (profileUpdate) {
            toast.success(`Profile Updated Successfully`);
            dispatch({
                type: RESET_ERROR
            })
            user.setUser(profileList)

        }

        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, profileUpdate])


    return (
        <Box>
            <CustomBackHeader label={'User Profile'} hidden={true} />
            <BOX display={"flex"} flex={1} px={5} mt={10} gap={5}>
                <Box flex={2}>
                    <IMGBOX >
                        <Box position={"relative"} width={"200px"} height={"200px"} >
                            <Avatar src={Image ? URL.createObjectURL(Image) : profileList?.image} style={{ width: "200px", height: "200px", objectFit: "contain", border: '5px solid #FF0053' }}>
                            </Avatar>
                            <Box display={"flex"} position={"absolute"} zIndex={"100"} justifyContent={"center"} bottom={"0px"}
                                width={"200px"}  >
                                <IconButton color="primary" aria-label="upload picture" component="label" className='iconbutton' >
                                    <input hidden name="myImage" type="file" onChange={(e) => setImage(e.target.files[0])} />
                                    <PhotoCamera className='photoicon' sx={{
                                        color: '#FF0053',

                                    }} />
                                </IconButton>
                            </Box>
                        </Box>
                    </IMGBOX>
                </Box>
                <Box flex={8}>
                    <Box py={1}>
                        <Typography sx={{
                            fontFamily: 'Quicksand',
                            fontWeight: '700',
                            letterSpacing: '1px',
                            pl: '5px',
                            color: '#FF0053',
                            fontSize: {
                                lg: 25,
                                md: 20,
                                sm: 16,
                                xs: 14,
                            }
                        }}>Basic Details</Typography>
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6} xl={4}>
                            <CustomInput
                                control={control}
                                error={errors.name}
                                fieldName="name"
                                fieldLabel="Name"

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} xl={4}>
                            <CustomInput
                                control={control}
                                error={errors.email}
                                fieldName="email"
                                fieldLabel="Email Address"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} xl={4}>
                            <CustomInput
                                control={control}
                                error={errors.phone}
                                fieldName="phone"
                                fieldLabel="Mobile Number"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12} xl={12}>
                            <CustomInput
                                control={control}
                                error={errors.address}
                                fieldName="address"
                                fieldLabel="Address"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </BOX>
            <Box display={'flex'} justifyContent={'center'} py={10}>
                <CustomIconButton label={loading ? <CircularProgress style={{ color: '#FF0053' }} size={20} /> : 'Update'}
                    //  disabled={loading ? true : false}
                    Icon={RefreshIcon} onClick={handleSubmit(SubmitForm)} />
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
})

export default Profile