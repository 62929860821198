import React from 'react'
import { Box, Paper } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
const DataTable = ({ columns, rows, height, border, borderGrid, id, bg, rowheight }) => {
    return (

        <Box sx={{ height: height ? height : '70vh', width: '100%', overflow: 'auto' }}>
            <DataGrid
                style={{
                    background: bg ? bg : "#F9F9F9",
                    borderRadius: borderGrid ? borderGrid : '20px',
                    opacity: 1,
                    fontFamily: 'Quicksand',
                    fontWeight: '600',
                    letterSpacing: '.5px',
                  
                }}
           
                editMode="row"
                rows={rows}
                columns={columns}
             
                pageSize={20}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                getRowId={row => row[id]}
            />

        </Box>

    )
}

export default DataTable