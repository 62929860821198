import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from "react-toastify";
import banner from '../../../Assets/images/homescreen.png';
import { Avatar, Box, CircularProgress, Stack, styled, Typography } from '@mui/material';
import logo from '../../../Assets/images/Logo.png';
import CustomOtp from '../../../components/Widgets/CustomOtp';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import CustomLoginInput from '../../../components/Widgets/CustomLoginInput';
import emailIcon from '../../../Assets/images/email.png';
import phoneCall from '../../../Assets/images/phoneCall.png';
import CustomButton from '../../../components/Widgets/CustomButton';
import customAxios from '../../../CustomAxios';
import UserContext from '../../../helpers/user';
const ChangeProfile = () => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const context = useContext(UserContext)

    const [otp, setOTP] = useState(null);
    const [loading, setLoading] = useState(false)



    const BOX = styled(Box)({
        width: '100%',
        height: '100vh',
        backgroundImage: `url(${banner})`,
        alignItems: 'center',
        justifyContent: 'center',
        objectFit: 'contain',
        display: 'flex',
        flexDirection: 'column'

    })



    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm();

    


    const profileChange = async (data) => {
        setLoading(true)
        await customAxios.post(`auth/roleotpverify`,
            state.mode === 'email' ? {
                otp: parseInt(localStorage.getItem('otp')),
                email: data?.email_mobile,
                id: state?.item?._id,
            } : {
                otp: parseInt(localStorage.getItem('otp')),
                mobile_no: data?.email_mobile,
                id: state?.item?._id,
            })
            .then(async response => {
                navigate(-1)
                
                
                setValue('email_mobile', '')
                toast.success(`${state?.mode} updated successfully`)
                setLoading(false);
                context.setUser(response?.data?.data)
            }).catch(async error => {
                console.log({error})
                setValue('email_mobile', '')
                toast.error(error?.message)
                setLoading(false)
            })
    }



    return (
        <BOX>
            <Avatar src={logo} variant="square" sx={{ width: 250, height: 100 }} />
            <Typography fontFamily={'Quicksand'} letterSpacing={1} fontWeight={'bold'} py={2}>{`Enter OTP Received on your ${state?.mode}`} </Typography>
            <Stack gap={5} alignItems={'center'}>
                <CustomOtp setOtp={setOTP} />
                <CustomLoginInput
                    icon={state?.mode === 'email' ? emailIcon : phoneCall}
                    control={control}
                    error={errors.email_mobile}
                    fieldName="email_mobile"
                    placeholder={`Enter your ${state?.mode}`}
                />
            </Stack>
            <Box py={5}>
                <CustomButton
                    onClick={handleSubmit(profileChange)}
                    label={loading ? <CircularProgress style={{ color: '#FF0053' }} size={20} /> : 'UPDATE'} />
            </Box>
        </BOX>
    )
}

export default ChangeProfile