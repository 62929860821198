import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";
import { useDispatch } from 'react-redux';
import UserContext from '../../../helpers/user';
import { Backdrop, Box, CircularProgress, Grid, MenuItem } from '@mui/material';
import CustomBackHeader from '../../../components/Widgets/CustomBackarrowHeader.js';
import CustomSelect from '../../../components/Widgets/CustomSelect';
import CustomTextArea from '../../../components/Widgets/CustomTextArea';
import CustomInput from '../../../components/Widgets/CustomInput';
import CustomCalender from '../../../components/Widgets/CustomCalender';
import CustomButton from '../../../components/Widgets/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import { toast } from "react-toastify";
import moment from 'moment';
import customAxios from '../../../CustomAxios';

const AddQuiz = () => {
    const { state } = useLocation()

    const navigate = useNavigate()



    const [selectPlan, setSelectPlan] = useState(null)
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const context = useContext(UserContext)



    const [gradeList, setGradeList] = useState([])
    const [typeList, setTypeList] = useState([
        { _id: 'Normal', value: 'Normal' },
        { _id: 'Competition', value: 'Competition' }
    ])
    const [countryList, setCountryList] = useState([
        { _id: 'India', value: 'India' },
        { _id: 'UAE', value: 'UAE' }
    ])
    const [contentType, setContentType] = useState('')
    const [countryId, setCountryId] = useState('')
    const [grade, setGrade] = useState('')
    const [loading, setLoading] = useState(null)
    const [uniqueClassList, setUniqueClassList] = useState([]);

    const [classesList, setClassesList] = useState([])
    const [classes, setClasses] = useState(null)
    const [division, setDivision] = useState(null)
    const [divisionList, setDivisionLIst] = useState([])
    const dispatch = useDispatch()


    const schema = object().shape({
        grade: yup.string().required('Grade is Required'),
        title: yup.string().required('Title is Required'),
        markperquestion: yup.string().required('Mark per question is Required'),
        totalquestions: yup.string().required('Total questions count is Required'),
        expiry_date: yup.string().required('Expiry date is Required').nullable()
    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError,
        getValues
    } = useForm({
        defaultValues: {
            grade: '',
            type: '',
            title: '',
            markperquestion: '',
            totalquestions: '',
            expiry_date: null,
            posted_by: context?.user?.name,
            country: '',
            class_division: null,
        },
        resolver: yupResolver(schema)
    });


    useEffect(() => {
        if (classesList) {
            const uniqueClass = classesList.filter((obj, index) => classesList.findIndex((item) => item.name === obj.name) === index)
            setUniqueClassList(uniqueClass)
        }
    }, [classesList])


    useEffect(() => {
        if (division) {
            let division_class = classesList.filter((res) => res.division === division)
            setValue('class_division', division_class?.[0]?._id)

        }
    }, [division, state])

    useEffect(() => {
        if (classes) {
            let classList = classesList.filter((res) => res.name === classes);
            setDivisionLIst(classList)
        }

    }, [classes])



    useEffect(() => {
        getGrade()
        getClass()
    }, [])

    const getClass = async () => {
        setLoading(true)
        await customAxios.get(`teacher/classlist`)
            .then(async response => {
                setClassesList(response?.data?.data)
                let classList = response?.data?.data.filter((res) => res.name === state?.class);
                setDivisionLIst(classList)
                let division_class = response?.data?.data.filter((res) => res.division === state?.division)
                setValue('class_division', division_class?.[0]?._id)
                setLoading(false);
            }).catch(async error => {
                toast.error(error.message)
                setLoading(false)
            })
    }

    const getGrade = async () => {
        setLoading(true)
        await customAxios.get(`admin/grades`)
            .then(async response => {
                setGradeList(response.data.data)
                if (state && state?._id) {
                    setGrade(state?.grade)
                    setValue("grade", state?.grade)
                }
                setLoading(false)

            }).catch(async error => {
                setGradeList(null)
                setLoading(false)


            })
    }

    useEffect(() => {
        if (state?._id) {
            setValue("title", state?.title);
            setValue("markperquestion", state?.markperquestion);
            setValue("totalquestions", state?.totalquestions);
            setValue("expiry_date", state?.expiry_date);
            setValue("title", state?.title);
            setValue("type", state?.type);
            setValue("country", state?.country)
            setValue('plan', state?.plan);
            setContentType(state?.type);
            setCountryId(state?.country);
            setSelectPlan(state?.plan);
            setValue('class', state?.class)
            setClasses(state?.class)
            setValue('class_division', state?.class_division)
            setValue('division', state?.division)
            setDivision(state?.division)
        }
    }, [state])


    useEffect(() => {
        if (countryId === 'UAE') {
            setValue('plan', null)
        }
    }, [countryId])







    const onSubmit = async (data) => {
        setLoading(true);
        let datas = {
            ...data,
            type: 'Normal',
            country: context?.user?.country,
            expiry_date: moment(data?.expiry_date).format("YYYY-MM-DD"),
            id: state?._id ? state?._id : null,
            school: context.user?.teacher_details?.school_id,
            posted_by: context.user?._id,
            screen_type:'network'
        }
        let url;
        if (state?._id) {
            url = `teacher/quiz/editquiz`;
        }
        else {
            url = `teacher/quiz/create`
        }
        await customAxios.post(url, datas)
            .then(async response => {
                navigate("/questionteacher", { state: response?.data?.data })
                reset()
                setLoading(false)
                setGrade('')
                setContentType('')


            }).catch(async error => {
                setLoading(false)
                toast.error(error?.message)
            })
    }

    return (
        <Box>
            <CustomBackHeader label={!state ? 'Add Quiz' : 'Edit Quiz'} />
            <Box px={5} mt={5}>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomSelect
                            control={control}
                            error={errors.grade}
                            fieldName="grade"
                            fieldLabel="Grade"
                            background="#FFEFEC"
                            size="16px"
                            value={grade}
                            onChangeValue={(e) => {
                                setGrade(e.target.value)
                                setValue('grade', e.target.value)
                                setError('grade', '')
                            }}
                        >
                            <MenuItem value={""} disabled >
                                <em>Select Grade</em>
                            </MenuItem>
                            {gradeList?.map(grade => (
                                <MenuItem key={grade?._id} value={grade?._id}  >
                                    <em>{grade?.name}</em>
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </Grid>
                    {/* <Grid item lg={3} md={4} xs={12}>
                        <CustomSelect
                            control={control}
                            error={errors.type}
                            fieldName="type"
                            fieldLabel="Type"
                            background="#FFEFEC"
                            size="16px"
                            value={contentType}
                            onChangeValue={(e) => {
                                setContentType(e.target.value)
                                setValue('type', e.target.value)
                                setError('type', '')
                            }}
                        >
                            <MenuItem value="" disabled >
                                <em>Select Type</em>
                            </MenuItem>
                            {typeList?.map(type => (
                                <MenuItem key={type?._id} value={type?._id}  >
                                    <em>{type?.value}</em>
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </Grid> */}
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomSelect
                            control={control}
                            error={errors.classes}
                            fieldName="classes"
                            fieldLabel="Class"
                            background="#FFEFEC"
                            size="16px"
                            value={classes}
                            onChangeValue={(e) => {
                                setClasses(e.target.value)
                                setValue('class', e.target.value)
                                setError('class', '')
                            }}
                        >
                            <MenuItem value={""} disabled >
                                <em>Select Grade</em>
                            </MenuItem>
                            {uniqueClassList?.map(type => (
                                <MenuItem key={type?._id} value={type?.name}  >
                                    <em>{type?.name}</em>
                                </MenuItem>
                            ))}

                        </CustomSelect>
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomSelect
                            control={control}
                            error={errors.division}
                            fieldName="division"
                            fieldLabel="Division"
                            background="#FFEFEC"
                            size="16px"
                            value={division}
                            onChangeValue={(e) => {
                                setDivision(e.target.value)
                                setValue('division', e.target.value)
                                setError('division', '')
                            }}
                        >
                            <MenuItem value="" disabled >
                                <em>Division</em>
                            </MenuItem>
                            {divisionList?.map(type => (
                                <MenuItem key={type?._id} value={type?.division}  >
                                    <em>{type?.division}</em>
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomCalender
                            fieldName={"expiry_date"}
                            control={control}
                            fieldLabel="Expiry Date"
                            error={errors?.expiry_date}
                            minDateres={new Date()}
                        />
                    </Grid>
                    <Grid item lg={6} md={8} xs={12}>
                        <CustomTextArea
                            control={control}
                            error={errors.title}
                            fieldName="title"
                            multiline={true}
                            height={90}
                            row={10}
                            fieldLabel="Title"
                            background={'#FFEFEC'}

                        />
                    </Grid>
                    {/* <Grid item lg={3} md={4} xs={12}>
                        <CustomSelect
                            control={control}
                            error={errors.country}
                            fieldName="country"
                            fieldLabel="Country"
                            background="#FFEFEC"
                            size="16px"
                            value={countryId}
                            onChangeValue={(e) => {
                                setCountryId(e.target.value)
                                setValue('country', e.target.value)
                                setError('country', '')
                            }}
                        >
                            <MenuItem value="" disabled >
                                <em>Select Country</em>
                            </MenuItem>
                            {countryList?.map(type => (
                                <MenuItem key={type?._id} value={type?._id}  >
                                    <em>{type?.value}</em>
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </Grid> */}
                    {getValues('country') === 'UAE' &&
                        <Grid item lg={3} md={4} xs={12}>
                            <CustomSelect
                                control={control}
                                error={errors.plan}
                                fieldName="plan"
                                fieldLabel="Plan Type"
                                background="#FFEFEC"
                                size="16px"
                                value={selectPlan}
                                onChangeValue={(e) => {
                                    setSelectPlan(e.target.value)
                                    setValue('plan', e.target.value)
                                    setError('plan', '')
                                }}
                            >
                                <MenuItem value="" disabled >
                                    <em>Select Plan</em>
                                </MenuItem>
                                {[{ val: 'standard', cap: 'Standard' }, { val: 'gold premium', cap: 'Gold Premium' }].map((res, i) => (
                                    <MenuItem key={i} value={res.val}  >
                                        {res.cap}
                                    </MenuItem>

                                ))}

                            </CustomSelect>
                        </Grid>}
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.markperquestion}
                            fieldName="markperquestion"
                            fieldLabel="Marks ( Per Question )"
                        />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                        <CustomInput
                            control={control}
                            error={errors.totalquestions}
                            fieldName="totalquestions"
                            fieldLabel="Total Questions"
                        />
                    </Grid>

                </Grid>
            </Box>

            <Box display={'flex'} justifyContent={'flex-start'} py={10} px={5}>
                <CustomButton label={"Next"} Icon={AddIcon} onClick={handleSubmit(onSubmit)} />
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </Box>
    )
}

export default AddQuiz