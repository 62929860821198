
import { Action } from "@remix-run/router";
import {
    CLASS_SCHOOL_FAIL,
    CLASS_SCHOOL_SUCCESS,
    CREATE_SCHOOL_FAIL,
    CREATE_SCHOOL_SUCCESS,
    CREATE_SCHOOL_TEACHER_FAIL,
    CREATE_SCHOOL_TEACHER_SUCCESS,
    DELETE_SCHOOL_CLASS_SUCCESS,
    DELETE_SCHOOL_FAIL,
    DELETE_SCHOOL_SUCCESS,
    EDIT_SCHOOL_TEACHER_FAIL,
    EDIT_SCHOOL_TEACHER_SUCCESS,
    GET_CLASS_TEACHER_FAIL,
    GET_CLASS_TEACHER_SUCCESS,
    GET_DIVISION_TEACHER_FAIL,
    GET_DIVISION_TEACHER_SUCCESS,
    GET_FULL_CLASS_LIST_FAIL,
    GET_FULL_CLASS_LIST_SUCCESS,
    GET_SCHOOL_CLASS_FAIL,
    GET_SCHOOL_CLASS_SUCCESS,
    GET_SCHOOL_FAIL,
    GET_SCHOOL_NETWORK_FAIL,
    GET_SCHOOL_NETWORK_SUCCESS,
    GET_SCHOOL_STUDENT_FAIL,
    GET_SCHOOL_STUDENT_SUCCESS,
    GET_SCHOOL_SUCCESS,
    GET_SCHOOL_TEACHER_FAIL,
    GET_SCHOOL_TEACHER_SINGLE_LIST_FAIL,
    GET_SCHOOL_TEACHER_SINGLE_LIST_SUCCESS,
    GET_SCHOOL_TEACHER_SUCCESS,
    REASIGN_SCHOOL_TEACHER_FAIL,
    REASIGN_SCHOOL_TEACHER_SUCCESS,
    SCHOOL_TEACHER_STATUS_FAIL,
    SCHOOL_TEACHER_STATUS_SUCCESS,
    SINGLE_SCHOOL_FAIL,
    SINGLE_SCHOOL_SUCCESS,
    STATUS_SCHOOL_FAIL,
    STATUS_SCHOOL_STUDENT_FAIL,
    STATUS_SCHOOL_STUDENT_SUCCESS,
    STATUS_SCHOOL_SUCCESS,
    UPDATE_SCHOOL_CLASS_FAIL,
    UPDATE_SCHOOL_CLASS_SUCCESS,
    UPDATE_SCHOOL_FAIL,
    UPDATE_SCHOOL_NETWORK_FAIL,
    UPDATE_SCHOOL_NETWORK_SUCCESS,
    UPDATE_SCHOOL_SUCCESS
} from "../constants/schoolConstant";
import { RESET_ERROR } from "../constants/userConstant";
export const schoolReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                schoolCreated: null,
                schoolDeleted: null,
                error: null,
                schoolStatusChanged: null,
                schoolEdited: null,
                classCreated: null,
                schoolClassUpdated: null,
                schoolClassDeleted: null,
                teacherCreated: null,
                schoolTeacherStatusChange: null,
                schoolTeacherReasigned: null,
                schoolTeacherUpdated: null,
                schoolStudentStatus: null,
                schoolNetworkUpdated: null
            }
        case CREATE_SCHOOL_SUCCESS:
            return {
                ...state,
                schoolCreated: true,
            }
        case CREATE_SCHOOL_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_SCHOOL_SUCCESS:
            return {
                ...state,
                schoolList: action.payload
            }
        case GET_SCHOOL_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case STATUS_SCHOOL_SUCCESS:
            return {
                ...state,
                schoolStatusChanged: true,
                schoolList: state.schoolList?.map(school => school._id === action.payload._id ? action.payload : school),
            }
        case STATUS_SCHOOL_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_SCHOOL_SUCCESS:
            return {
                ...state,
                schoolDeleted: true,
                schoolList: state?.schoolList.filter(school => school._id !== action.payload)
            }
        case DELETE_SCHOOL_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case SINGLE_SCHOOL_SUCCESS:
            return {
                ...state,
                singleSchoolList: action.payload
            }
        case SINGLE_SCHOOL_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case UPDATE_SCHOOL_SUCCESS:
            return {
                ...state,
                schoolEdited: true,
                schoolList: state.schoolList?.map(school => school._id === action.payload._id ? action.payload : school),
            }
        case UPDATE_SCHOOL_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CLASS_SCHOOL_SUCCESS:
            return {
                ...state,
                classCreated: true,
                classSchoolList: state.classSchoolList ? [...state.classSchoolList, action.payload] : [action.payload],
            }

        case CLASS_SCHOOL_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_SCHOOL_CLASS_SUCCESS:
            return {
                ...state,
                classSchoolList: action.payload
            }
        case GET_SCHOOL_CLASS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case UPDATE_SCHOOL_CLASS_SUCCESS:
            return {
                ...state,
                schoolClassUpdated: true,
                classSchoolList: state.classSchoolList?.map(schoolClass => schoolClass._id === action.payload._id ? action.payload : schoolClass),
            }

        case UPDATE_SCHOOL_CLASS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case DELETE_SCHOOL_CLASS_SUCCESS:
            return {
                ...state,
                schoolClassDeleted: true,
                classSchoolList: state?.classSchoolList.filter(schoolClass => schoolClass._id !== action.payload)
            }
        case GET_CLASS_TEACHER_SUCCESS:
            return {
                ...state,
                getClassesList: action.payload
            }
        case GET_CLASS_TEACHER_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_DIVISION_TEACHER_SUCCESS:
            return {
                ...state,
                getDivisionList: action.payload
            }
        case GET_DIVISION_TEACHER_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CREATE_SCHOOL_TEACHER_SUCCESS:
            return {
                ...state,
                teacherCreated: true,
                schoolTeacherList: action.payload
            }
        case CREATE_SCHOOL_TEACHER_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_SCHOOL_TEACHER_SUCCESS:
            return {
                ...state,
                schoolTeacherList: action.payload
            }
        case GET_SCHOOL_TEACHER_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case SCHOOL_TEACHER_STATUS_SUCCESS:
            return {
                ...state,
                schoolTeacherStatusChange: true,
                schoolTeacherList: state.schoolTeacherList?.map(teacher => teacher._id === action.payload._id ? action.payload : teacher),
            }
        case SCHOOL_TEACHER_STATUS_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_SCHOOL_TEACHER_SINGLE_LIST_SUCCESS:
            return {
                ...state,
                schoolTeacherSingleList: action.payload,
            }
        case GET_SCHOOL_TEACHER_SINGLE_LIST_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case REASIGN_SCHOOL_TEACHER_SUCCESS:
            return {
                ...state,
                schoolTeacherReasigned: true,
                schoolTeacherSingleList: action.payload
            }
        case REASIGN_SCHOOL_TEACHER_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case EDIT_SCHOOL_TEACHER_SUCCESS:
            return {
                ...state,
                schoolTeacherUpdated: true,
                schoolTeacherList: state.schoolTeacherList?.map(SchlTeacher => SchlTeacher._id === action.payload._id ? action.payload : SchlTeacher),

            }
        case EDIT_SCHOOL_TEACHER_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case GET_FULL_CLASS_LIST_SUCCESS:
            return {
                ...state,
                classFullList: action.payload
            }
        case GET_FULL_CLASS_LIST_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_SCHOOL_STUDENT_SUCCESS:
            return {
                ...state,
                schoolStudentList: action.payload
            }
        case GET_SCHOOL_STUDENT_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case STATUS_SCHOOL_STUDENT_SUCCESS:
            return {
                ...state,
                schoolStudentStatus: true,
                schoolStudentList: state.schoolStudentList?.map(stud => stud._id === action.payload._id ? action.payload : stud),

            }
        case STATUS_SCHOOL_STUDENT_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_SCHOOL_NETWORK_SUCCESS:
            return {
                ...state,
                SchoolNetworkList: action.payload
            }
        case GET_SCHOOL_NETWORK_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case UPDATE_SCHOOL_NETWORK_SUCCESS:
            return {
                ...state,
                schoolNetworkUpdated: true,
                SchoolNetworkList: state.SchoolNetworkList?.map(Network => Network._id === action.payload._id ? action.payload : Network),

            }
        case UPDATE_SCHOOL_NETWORK_FAIL:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }

}