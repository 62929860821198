import { Backdrop, Box, CircularProgress, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import CustomBackHeader from '../../components/Widgets/CustomBackarrowHeader.js'
import CustomTypography from '../../components/Widgets/CustomTypography'
import student from '../../Assets/images/Student.jpg'
import CustomAvatar from '../../components/Widgets/CustomAvatar/index.js'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUserSingleList } from '../../Redux/actions/userActions.js'
import CustomSpinner from '../../components/Widgets/CustomSpinner/index.js'
const ViewUserManagement = () => {
    const { id } = useParams();
    const dispatch = useDispatch()
    const { loading, loader } = useSelector(state => state.auth);
    const { userSingleList } = useSelector(state => state.user);



    useEffect(() => {
        if (id) {
            dispatch(getUserSingleList(id))
        }
    }, [id])

    
    return (
        <Box>
            <CustomBackHeader label={`User :#${userSingleList?.user_id}`} />
            <Grid container spacing={3} px={5} mt={2}>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Name'} label={userSingleList?.name} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Email Address'} label={userSingleList?.email} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Mobile Number'} label={userSingleList?.mobile} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'User Type'} label={userSingleList?.role} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomAvatar avatar={userSingleList?.image} />
                </Grid>
            </Grid>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </Box>
    )
}

export default ViewUserManagement