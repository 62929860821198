import { Backdrop, Box, CircularProgress, Paper, Stack } from '@mui/material'
import React from 'react'
import DataTable from '../../../components/Widgets/CustomTable'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import CustomSwitch from '../../../components/Widgets/CustomSwitch';
import { fontFamily } from '@mui/system';
import { capitalize } from 'lodash'
const Network = ({ res }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    console.log({ res })

    const { loading, loader } = useSelector(state => state.auth);

    const columns = [

        {

            headerName: 'Student ID',
            flex: 1,
            valueGetter: (params) => (`#${params.row?.post_id}`),
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'user_id',
            headerName: 'Created Date',
            flex: 1,
            valueGetter: (params) => (moment(params.row.created_at).format('DD/MM/YYYY')),
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'Creator Name',
            headerName: 'Creator Name',
            flex: 1,
            valueGetter: (params) => (params.row?.creator_details?.name),
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'Class & Division',
            headerName: 'Class & Division',
            flex: 1,
            valueGetter: (params) => (`${params.row?.creator_details?.class}-${params.row?.creator_details?.division}`),
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'reviewd_by_details',
            headerName: 'Reveiwed By',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => (params.row?.reviewd_by_details?.name),
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                <span style={{
                    fontFamily: 'QuickSand', fontWeight: 'bold',
                    color:
                        row?.status === 'Rejected' ? '#FF0000' :
                            row?.status === 'Approved' ? '#18B023' :
                                row?.status === 'active' ? '#18B023' :
                                    row?.status === 'Under Review' ? '#FF8000' :
                                        ''
                }}>{capitalize(row?.status)}</span>
            )

        },
        {
            field: 'Action',
            headerName: 'Action',
            flex: 1,
            renderCell: ({ row }) => (
                <Stack alignItems={'center'} gap={1} direction={'row'}>
                    <RemoveRedEyeIcon
                        onClick={() => navigate(`/viewSchoolNetwork`, {
                            state: {
                                item: row
                            }
                        })}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }} />

                    {row?.status !== 'Rejected' &&
                        <BorderColorTwoToneIcon
                            onClick={() => navigate(`/editSchoolNetwork`, {
                                state: {
                                    item: row
                                }

                            })}
                            style={{
                                color: '#FF0053',
                                cursor: 'pointer'
                            }}
                        />}
                    {/* <DeleteOutlineTwoToneIcon
                        onClick={() => handleOpenR()}
                        style={{
                            color: '#FF0053',
                            cursor: 'pointer'
                        }} /> */}

                </Stack>
            )
        },
    ];

    const ChangeStatus = (checked, row) => {
        let status = checked === true ? 'active' : 'inactive'
        let val = {
            id: row,
            status: status
        }

    }

    return (
        <Box>
            <Box px={5} py={2}>
                <Paper style={{
                    borderRadius: "20px"
                }}>
                    <DataTable
                        rows={res}
                        columns={columns}
                        id={"_id"}
                    />
                </Paper>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default Network