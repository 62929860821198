import { Avatar, Box, Button, Stack, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import Drawer from '@mui/material/Drawer';
import Logo from '../../../Assets/images/Logo.png';
import RoutingContext from '../../../helpers/Routing';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import menu from '../../../Assets/images/menu.png'
import setting from '../../../Assets/images/setting.png'
import logout from '../../../Assets/images/logout.png'
import UserContext from '../../../helpers/user';
import LogoutModal from '../../../Screens/Sidebar/LogoutModal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

function ModalDrawer({ open, close }) {

    const navigate = useNavigate();
    const routesContext = useContext(RoutingContext);
    const userContext = useContext(UserContext);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [openL, setOpenL] = React.useState(false);


    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const local = localStorage.getItem('link')
    useEffect(() => {
        if (local) {
            setSelectedIndex(parseInt(local))
        }
    }, [local])

    return (
        <Box>
            <Drawer
                anchor={'left'}
                open={open}
                onClose={close}
            >
                <Box px={3} >
                    <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
                        <HighlightOffIcon style={{ fontSize: '30px' }} onClick={() => close()} />
                    </Box>
                    <Box
                        justifyContent={'center'}
                        width={'100%'}
                        display={'flex'}>
                        <Avatar src={Logo} variant="square"
                            style={{ width: '160px', height: '60px' }} ></Avatar>
                    </Box>

                    <List style={{ maxHeight: '65%', overflow: 'scroll', minHeight: '65%' }} component="nav" aria-label="main mailbox folders">
                        {routesContext.routing?.map((res, i) => {
                            if (res?.title) {
                                return (
                                    <ListItemButton
                                        key={i}
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                        selected={selectedIndex === i}
                                        onClick={
                                            (event) => {
                                                localStorage.setItem('link', i)
                                                handleListItemClick(event, i)
                                                navigate(res?.path)
                                            }
                                        }
                                    >
                                        <ListItemText
                                            key={i}
                                            disableTypography
                                            primary={<Typography type="body2"
                                                style={{
                                                    color: selectedIndex === i ? '#FD085A' : '#grey',
                                                    fontFamily: 'Quicksand',
                                                    fontWeight: selectedIndex === i ? '700' : '600'
                                                }}>{res?.title ? res?.title : ''}</Typography>}
                                        ></ListItemText>
                                        {selectedIndex === i && <Avatar src={menu} sx={{ height: 15, width: 20 }} variant={'square'}></Avatar>}
                                    </ListItemButton>
                                )
                            }
                        }
                        )}
                    </List>
                </Box>
                {open && <LogoutModal open={openL} handleClose={() => setOpenL(false)} />}
                <Stack direction={'row'} justifyContent={'center'} my={1} gap={2}  >
                    {userContext.user?.role === 'admin' &&
                        <Button sx={{ borderRadius: 3, cursor: 'pointer' }} onClick={() => navigate('/Profile')}>
                            <Avatar src={setting}
                                sx={{
                                    width: 30,
                                    height: 30,
                                    p: 1.5,
                                    bgcolor: '#fff',
                                    borderRadius: 3,
                                    boxShadow: '0px 8px #FADEDA'
                                }}  ></Avatar>
                        </Button>}
                    <Button sx={{ borderRadius: 3, cursor: 'pointer' }} onClick={() => setOpenL(true)}>
                        <Avatar src={logout} sx={{
                            width: 30,
                            height: 30,
                            p: 1.5,
                            bgcolor: '#fff',
                            borderRadius: 3,
                            boxShadow: 1,
                            boxShadow: '0px 8px #FADEDA',
                            cursor: 'pointer'
                        }}></Avatar>
                    </Button>
                </Stack>
            </Drawer>
        </Box>
    )
}

export default ModalDrawer