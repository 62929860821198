
import { Avatar, Box, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Modal, Stack, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CustomInput from '../../components/Widgets/CustomInput';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import * as yup from "yup";

import BackupIcon from '@mui/icons-material/Backup';
import CustomIconButton from '../../components/Widgets/CustomIconButton';
import AddIcon from '@mui/icons-material/Add';
import CustomImageUploader from '../../components/Widgets/CustomImageUploder';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { createCategory, updateCategory } from '../../Redux/actions/categoryActions';
import CustomButton from '../../components/Widgets/CustomButton';
import customAxios from '../../CustomAxios';
import { IMAGE_RESOLUTION_SUCCESS } from '../../Redux/constants/userConstant';
import { getImageResolution } from '../../Redux/actions/userActions';
const AddSize = ({ open, handleClose, res, mode }) => {




    const dispatch = useDispatch();

    const schema = object().shape({
        imageSize: yup.string().required('Required'),
        videoSize: yup.string().required('Required'),

    });

    const {
        register,
        handleSubmit,
        setFieldValue,
        control,
        formState: { errors },
        reset,
        setValue,
        setError
    } = useForm({

        resolver: yupResolver(schema)
    });

    const [loading, setLoading] = useState(false);


    const SubmitForm = async (data) => {
        setLoading(true)
        await customAxios.post(`admin/setting/add`, data)
            .then(async response => {
                setLoading(false);
                dispatch(getImageResolution());
                if(mode){
                    toast.success('Size added Successfully')
                }else {
                    toast.success('Size updated Successfully')
                }
               
                handleClose()
            }).catch(async error => {
                toast.error({ error })
                setLoading(false)
            })
    }

    useEffect(() => {
        if (mode && res) {
            setValue('imageSize', parseInt(res[0]?.imageOrginal))
            setValue('videoSize', parseInt(res[0]?.videoOrginal))
        }
    }, [mode, res])



    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={'xs'}
            onClose={handleClose}>
            <DialogTitle>
                <Stack justifyContent={'space-between'} direction={'row'} px={2} py={2} alignItems='center'>
                    <Box></Box>
                    <Typography sx={{
                        fontFamily: 'Quicksand',
                        fontWeight: 'bold',
                        fontSize: 22,
                        color: '#FF0053',
                        letterSpacing: 1
                    }}>{res ? 'Edit Size' : 'Add Size'}</Typography>
                    <HighlightOffIcon onClick={() => handleClose()}
                        sx={{
                            cursor: 'pointer'
                        }} />

                </Stack>
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={2} px={2}>
                    <Grid item md={12} xs={12}>
                        <CustomInput
                            type={'number'}
                            control={control}
                            error={errors.imageSize}
                            fieldName="imageSize"
                            fieldLabel="Image Size(MB)"
                        />

                    </Grid>
                    <Grid item md={12} xs={12}>
                        <CustomInput
                            type={'number'}
                            control={control}
                            error={errors.videoSize}
                            fieldName="videoSize"
                            fieldLabel="Video Size(MB)"
                        />
                    </Grid>
                </Grid>
                <Box py={2} display={'flex'} justifyContent={'center'}>
                    <CustomButton label={'Submit'} onClick={handleSubmit(SubmitForm)} />
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default AddSize