import { GET_PROFILE_FAIL, GET_PROFILE_SUCCESS, UPDATE_PROFILE_FAIL, UPDATE_PROFILE_SUCCESS } from "../constants/profileConstant";
import { LOADER, LOADING } from "../constants/userConstant";
import axios from "../../CustomAxios";


export const getProfile = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    dispatch({
        type: LOADER,
        payload: true
    })
    await axios.get(`admin/profile/show/${id}`)
        .then(async response => {

            let data = response.data.data;

            await localStorage.setItem("user", JSON.stringify(data  ));

            dispatch({
                type: GET_PROFILE_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
            dispatch({
                type: LOADER,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: GET_PROFILE_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
            dispatch({
                type: LOADER,
                payload: false
            })
        })
}

export const updateProfile = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/profile/update`, data)
        .then(async response => {

            let data = response.data.data;

            dispatch({
                type: UPDATE_PROFILE_SUCCESS,
                payload: data
            });
            dispatch({
                type: LOADING,
                payload: false
            })
        }).catch(async error => {
            dispatch({
                type: UPDATE_PROFILE_FAIL,
                payload: error
            })

            dispatch({
                type: LOADING,
                payload: false
            })
        })
}