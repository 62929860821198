export const LOADING = 'LOADING'
export const LOADER = 'LOADER'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const FORGET_SUCCESS = "FORGET_SUCCESS"
export const FORGET_FAIL = "FORGET_FAIL"
export const RESET_ERROR = 'RESET_ERROR'
export const PASSWORD_UPDATE_SUCCES = "PASSWORD_UPDATE_SUCCES"
export const PASSWORD_UPDATE_FAIL = "PASSWORD_UPDATE_FAIL"
export const EMAIL_ADDRESS_STORE = "EMAIL_ADDRESS_STORE"

export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL'
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS'
export const GET_ROLE_FAIL = 'GET_ROLE_FAIL'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAIL = 'GET_USER_FAIL'
export const REG_USER_SUCCESS = "REG_USER_SUCCESS"
export const REG_USER_FAIL = "REG_USER_FAIL"
export const GET_USER_SINGLE_SUCCESS = "GET_USER_SINGLE_SUCCESS"
export const GET_USER_SINGLE_FAIL = "GET_USER_SINGLE_FAIL"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"
export const USER_STATUS_SUCCESS = "USER_STATUS_SUCCESS"
export const USER_STATUS_FAIL = "USER_STATUS_FAIL"


export const IMAGE_RESOLUTION_SUCCESS = "IMAGE_RESOLUTION_SUCCESS"
export const IMAGE_RESOLUTION_FAIL = 'IMAGE_RESOLUTION_FAIL' 