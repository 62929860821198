import { Box, Typography } from '@mui/material'
import React from 'react'
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
const CustomBox = ({ label, heading }) => {
    return (
        <>
            <Typography fontFamily={'Quicksand'} fontWeight={'bold'} px={1} py={1}>{`${heading}`}
                <span style={{
                    color: '#FF0000'
                }}>*</span></Typography>
            <Box sx={{
                height: "48px",
                // width: 200,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                bgcolor: '#FFEFEC',
                borderRadius: '10px'
            }}>
                <Typography px={2}>{label}</Typography>
                <Box sx={{
                    width: '40px',
                    height: '48px',
                    display: "flex",
                    bgcolor: "#ff0053",
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: "10px",
                }}>
                    <KeyboardArrowDownSharpIcon style={{ fontSize: 30, fontWeight: 'bold', color: '#fff' }} />
                </Box>
            </Box>
        </>

    )
}

export default CustomBox