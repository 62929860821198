import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useLocation } from 'react-router-dom'
import CustomBackHeader from '../../../components/Widgets/CustomBackarrowHeader.js'
import CustomTypography from '../../../components/Widgets/CustomTypography/index.js'
import moment from 'moment'
import CustomAvatar from '../../../components/Widgets/CustomAvatar/index.js'
import { IMAGE_URL } from '../../../config/constants.js'
import CustomCardMedia from '../../../components/Widgets/CustomCardMedia/index.js'

const ViewTeacherText = () => {
    const { state } = useLocation()

    console.log({state})

    return (
        <Box>
            <CustomBackHeader label={`Post ID :#${state?.test_id}`} />
            <Grid container spacing={3} px={5} mt={2}>
              
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Type'} label={state?.test_type} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Class'} label={state?.class} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Division'} label={state?.division} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Country'} label={state?.country} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Expiry Date'} label={moment(state?.expiry_date, "YYYY-MM-DD").format("DD-MM-YYYY") } />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Total Marks'} label={state?.max_mark} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Title'} label={state?.title} h={100} />
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                    <CustomTypography formlabel={'Task Content'} label={state?.content}  h={100}  />
                </Grid>
                {state?.file_type === 'image' &&
                    <Grid item lg={3} md={4} xs={6}>
                        <CustomAvatar avatar={`${IMAGE_URL}${state.file}`} />
                    </Grid>
                }
                {state.file_type === "video" &&
                    <Grid item lg={3} md={4} xs={6}>
                        <CustomCardMedia videoProp={`${IMAGE_URL}${state.file}`} />
                    </Grid>
                }
            </Grid>
        </Box>
    )
}

export default ViewTeacherText